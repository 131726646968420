import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React, { useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { IconButton } from "../IconButton/IconButton";
import styles from "./Tag.module.css";
import { messages } from "./Tag.messages";
export var Tag = function (_ref) {
  var onClick = _ref.onClick,
    renderIcon = _ref.renderIcon,
    label = _ref.label,
    ariaLabelProp = _ref.ariaLabel,
    disabled = _ref.disabled,
    _ref$icon = _ref.icon,
    icon = void 0 === _ref$icon ? "gw-close" : _ref$icon,
    className = _ref.className,
    labelClassName = _ref.labelClassName,
    iconWrapperClassName = _ref.iconWrapperClassName,
    iconClassName = _ref.iconClassName,
    id = _ref.id,
    translator = useTranslator(),
    classes = cx(styles.tag, _defineProperty({}, styles.disabled, disabled), className),
    labelClasses = cx(styles.label, labelClassName),
    iconWrapperClasses = cx(styles.deleteButtonWrapper, _defineProperty({}, styles.empty, !onClick), iconWrapperClassName),
    iconClasses = cx(styles.deleteButton, iconClassName),
    ariaLabel = translator(null != ariaLabelProp ? ariaLabelProp : messages.closeTagLabel, {
      label: translator(label)
    }),
    deleteIcon = useMemo(function () {
      if (onClick) {
        var iconButton = React.createElement(IconButton, {
          disabled: disabled,
          className: iconClasses,
          icon: icon,
          onKeyDown: function (e) {
            return e.stopPropagation();
          },
          onClick: onClick,
          ariaLabel: ariaLabel
        });
        return renderIcon ? renderIcon(iconButton) : iconButton;
      }
    }, [disabled, icon, iconClasses, onClick, renderIcon, ariaLabel]);
  return React.createElement("span", {
    id: id,
    className: classes,
    "aria-disabled": disabled
  }, React.createElement("span", {
    className: labelClasses
  }, translator(label)), React.createElement("span", {
    className: iconWrapperClasses
  }, deleteIcon));
};
var tagPropTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  renderIcon: PropTypes.func,
  label: intlMessageShape,
  ariaLabel: intlMessageShape,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  iconClassName: PropTypes.string
};
Tag.propTypes = tagPropTypes, Tag.defaultProps = {
  icon: "gw-close"
}, Tag.__docgenInfo = {
  description: "Tag\n\n@metadataType element",
  methods: [],
  displayName: "Tag",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-close'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name to render inside this button"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The id for this Tag"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for icon button click, If not provided, the button isn't rendered"
    },
    renderIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "A render prop for adding a custom wrapper for the icon button"
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The label for the tag"
    },
    ariaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: 'The aria-label for the tag icon button, overrides the default one with "delete" text'
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Renders the tag as disabled"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the tag"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the label"
    },
    iconWrapperClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the icon wrapper"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the icon"
    }
  }
}, Tag.__docgenInfo = {
  componentName: "Tag",
  packageName: "@jutro/components",
  description: "Tag",
  displayName: "Tag",
  methods: [],
  actualName: "Tag",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The id for this Tag"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for icon button click, If not provided, the button isn't rendered"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name to render inside this button",
      defaultValue: {
        value: "'gw-close'",
        computed: !1
      }
    },
    renderIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "A render prop for adding a custom wrapper for the icon button"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The label for the tag"
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: 'The aria-label for the tag icon button, overrides the default one with "delete" text'
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Renders the tag as disabled"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the tag"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the label"
    },
    iconWrapperClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the icon wrapper"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the icon"
    }
  }
};