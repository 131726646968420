import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "className", "headerClassName", "children", "renderHeader", "renderCardHeader", "title", "subTitle", "tooltip", "isPanel", "fullWidth", "headerAlignment", "dangerouslySetInnerHTML"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { uniqueInnerId, sanitizeProps, useId } from "@jutro/platform";
import { nestedTooltipShape, intlMessageShape } from "@jutro/prop-types";
import styles from "./Card.module.css";
import { CardHeader } from "./CardHeader";
import { PanelLayout } from "../../layouts/PanelLayout";
var cardPropTypes = {
  id: PropTypes.string,
  title: intlMessageShape,
  subTitle: intlMessageShape,
  headerAlignment: PropTypes.oneOf(["center", "left", "right"]),
  renderHeader: PropTypes.func,
  renderCardHeader: PropTypes.func,
  headerClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  tooltip: nestedTooltipShape,
  isPanel: PropTypes.bool,
  fullWidth: PropTypes.bool
};
export var Card = function (_ref) {
  var _cx,
    id = _ref.id,
    className = _ref.className,
    headerClassName = _ref.headerClassName,
    children = _ref.children,
    renderHeader = _ref.renderHeader,
    renderCardHeader = _ref.renderCardHeader,
    title = _ref.title,
    subTitle = _ref.subTitle,
    tooltip = _ref.tooltip,
    isPanel = _ref.isPanel,
    fullWidth = _ref.fullWidth,
    headerAlignment = _ref.headerAlignment,
    other = (_ref.dangerouslySetInnerHTML, _objectWithoutProperties(_ref, _excluded)),
    cardId = useId({
      default: id
    }),
    cardClass = cx(styles.card, className, (_defineProperty(_cx = {}, styles.panelLike, isPanel), _defineProperty(_cx, styles.fullWidth, isPanel && fullWidth), _cx)),
    tooltipId = uniqueInnerId(cardId, "tooltipId").tooltipId,
    ariaDescribedByProp = renderHeader && tooltip ? tooltipId : void 0,
    tooltipProps = tooltip ? function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
          _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
        });
      }
      return target;
    }({
      id: tooltipId
    }, tooltip) : void 0,
    headerProps = {
      title: title,
      subTitle: subTitle,
      tooltip: tooltipProps,
      renderHeader: renderHeader,
      headerClassName: headerClassName,
      headerAlignment: headerAlignment || (isPanel ? "center" : "left")
    },
    Layout = !isPanel || fullWidth ? "div" : PanelLayout;
  return React.createElement(Layout, _extends({
    id: cardId,
    className: cardClass,
    "aria-describedby": ariaDescribedByProp
  }, sanitizeProps(other)), renderCardHeader ? renderCardHeader(headerProps) : React.createElement(CardHeader, headerProps), children);
};
Card.propTypes = cardPropTypes, Card.defaultProps = {
  isPanel: !1
}, Card.__docgenInfo = {
  description: "Renders a card with a header and a body.\n\n@type {React.FC<PropTypes.InferProps<typeof cardPropTypes>>}\n\n@metadataType container",
  methods: [],
  displayName: "Card",
  props: {
    isPanel: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Support for legacy styling, to look like a Panel"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "A unique ID of this card"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The header title to be displayed in the Card"
    },
    subTitle: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Subtitle of the Card"
    },
    headerAlignment: {
      type: {
        name: "enum",
        value: [{
          value: "'center'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Alignment of the header"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render a header title component to be displayed in the Card Header"
    },
    renderCardHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to override default and render a custom Header for Card"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An additional CSS class name for header"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The children elements to render inside of the Card body"
    },
    tooltip: {
      type: {
        name: "custom",
        raw: "nestedTooltipShape"
      },
      required: !1,
      description: "Card header tooltip"
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Content spans the entire width, applies only to the panel"
    }
  }
}, Card.__docgenInfo = {
  componentName: "Card",
  packageName: "@jutro/components",
  description: "Renders a card with a header and a body.",
  displayName: "Card",
  methods: [],
  actualName: "Card",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "A unique ID of this card"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The header title to be displayed in the Card"
    },
    subTitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Subtitle of the Card"
    },
    headerAlignment: {
      type: {
        name: "enum",
        value: [{
          value: "'center'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Alignment of the header"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render a header title component to be displayed in the Card Header"
    },
    renderCardHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to override default and render a custom Header for Card"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An additional CSS class name for header"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The children elements to render inside of the Card body"
    },
    tooltip: {
      type: {
        name: "custom",
        raw: "nestedTooltipShape"
      },
      required: !1,
      description: "Card header tooltip"
    },
    isPanel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Support for legacy styling, to look like a Panel",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Content spans the entire width, applies only to the panel"
    }
  }
};