import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["id", "basePath", "path", "visible", "fieldType", "fieldDatatype", "fieldComponent"];
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { prepareComponentFromMetadata, renderComponentFromMetadata } from "@jutro/uiconfig";
import { FieldValue } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { TableColumn } from "../table/TableColumn";
import styles from "./FieldColumn.module.css";
import tableStyles from "../DataTable.module.css";
export var FieldColumn = function () {
  return React.createElement(React.Fragment, null);
};
FieldColumn.propTypes = _objectSpread(_objectSpread({}, TableColumn.propTypes), {}, {
  model: PropTypes.object,
  basePath: PropTypes.string,
  uiMetadata: PropTypes.object,
  pageProps: PropTypes.object,
  readOnly: PropTypes.bool,
  onAccessor: PropTypes.func
});
export var DefaultCell = function (_ref) {
  var _context,
    row = _ref.row,
    rowId = _ref.rowId,
    innerProps = _ref.innerProps,
    translator = useTranslator(),
    path = innerProps.path,
    visible = innerProps.visible,
    fieldDatatype = innerProps.fieldDatatype,
    id = innerProps.id,
    _innerProps$available = innerProps.availableValues,
    availableValues = void 0 === _innerProps$available ? [] : _innerProps$available;
  if (!1 === visible) return null;
  var option,
    _option$name,
    cellValue = get(row, path),
    selectOption = _findInstanceProperty(availableValues).call(availableValues, function (el) {
      return (null !== (_option$code = null == (option = el) ? void 0 : option.code) && void 0 !== _option$code ? _option$code : option.id) === (null == cellValue ? void 0 : cellValue.toString());
      var option, _option$code;
    }),
    value = selectOption ? null !== (_option$name = null == (option = selectOption) ? void 0 : option.name) && void 0 !== _option$name ? _option$name : option.displayName : cellValue;
  return React.createElement(FieldValue, {
    id: _concatInstanceProperty(_context = "".concat(id, "_")).call(_context, rowId),
    datatype: fieldDatatype,
    value: translator(value)
  });
};
FieldColumn.defaultCell = function (row, rowId, props) {
  return React.createElement(DefaultCell, {
    row: row,
    rowId: rowId,
    innerProps: props
  });
}, FieldColumn.editCell = function (_ref2) {
  var _context2,
    _context3,
    _context4,
    props = _ref2.props,
    editProps = _ref2.editProps,
    editActions = _ref2.editActions,
    id = props.id,
    basePath = props.basePath,
    path = props.path,
    visible = props.visible,
    datatype = (props.fieldType, props.fieldDatatype),
    component = props.fieldComponent,
    rest = _objectWithoutProperties(props, _excluded),
    row = editProps.row,
    rowId = editProps.rowId,
    index = editProps.index,
    update = editActions.update;
  if (!1 === visible) return null;
  var results = prepareComponentFromMetadata({
    type: "field",
    datatype: datatype,
    component: component,
    componentProps: _objectSpread(_objectSpread({}, rest), {}, {
      id: _concatInstanceProperty(_context2 = "".concat(id, "_")).call(_context2, rowId),
      hideLabel: !0,
      className: styles.fullWidth,
      value: get(row, props.path),
      path: basePath ? _concatInstanceProperty(_context3 = _concatInstanceProperty(_context4 = "".concat(basePath, ".")).call(_context4, index, ".")).call(_context3, path) : path,
      onValueChange: function (newValue) {
        return update(row, path, newValue);
      }
    })
  });
  return renderComponentFromMetadata(results);
}, FieldColumn.editCellClass = tableStyles.editedCell, FieldColumn.displayName = "FieldColumn", FieldColumn.defaultProps = {
  renderCell: FieldColumn.defaultCell,
  renderEditCell: FieldColumn.editCell,
  columnProportion: 1,
  fieldDatatype: "string"
}, FieldColumn.__docgenInfo = {
  description: "FieldColumn\n@returns {null} - The FieldColumn component does not render anything\n\n@metadataType container",
  methods: [{
    name: "defaultCell",
    docblock: "Render the default cell\n@param {object} row - Each row in the data table\n@param {number | string} rowId - The index of the current row\n@param {object} props - the properties used by TableColumn\n\n@returns {React.ReactElement}",
    modifiers: ["static"],
    params: [{
      name: "row",
      description: "Each row in the data table",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "rowId",
      description: "The index of the current row",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }, {
      name: "props",
      description: "the properties used by TableColumn",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the default cell"
  }, {
    name: "editCell",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ props, editProps, editActions }",
      type: null
    }],
    returns: null
  }],
  displayName: "FieldColumn",
  props: {
    renderCell: {
      defaultValue: {
        value: "FieldColumn.defaultCell",
        computed: !0
      },
      required: !1
    },
    renderEditCell: {
      defaultValue: {
        value: "FieldColumn.editCell",
        computed: !0
      },
      required: !1
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      required: !1
    },
    fieldDatatype: {
      defaultValue: {
        value: "'string'",
        computed: !1
      },
      required: !1
    },
    model: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    basePath: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    uiMetadata: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    pageProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    }
  },
  composes: ["../table/TableColumn"]
}, DefaultCell.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DefaultCell"
}, FieldColumn.__docgenInfo = {
  componentName: "FieldColumn",
  packageName: "@jutro/datatable",
  description: "FieldColumn",
  displayName: "FieldColumn",
  methods: [{
    name: "defaultCell",
    docblock: "Render the default cell\n@param {object} row - Each row in the data table\n@param {number | string} rowId - The index of the current row\n@param {object} props - the properties used by TableColumn\n\n@returns {React.ReactElement}",
    modifiers: ["static"],
    params: [{
      name: "row",
      description: "Each row in the data table",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "rowId",
      description: "The index of the current row",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }, {
      name: "props",
      description: "the properties used by TableColumn",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the default cell"
  }, {
    name: "editCell",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ props, editProps, editActions }",
      optional: void 0,
      type: null
    }],
    returns: null
  }],
  actualName: "FieldColumn",
  metadataType: "container",
  props: {
    model: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    basePath: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    uiMetadata: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    pageProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    },
    renderCell: {
      defaultValue: {
        value: "FieldColumn.defaultCell",
        computed: !0
      },
      required: !1
    },
    renderEditCell: {
      defaultValue: {
        value: "FieldColumn.editCell",
        computed: !0
      },
      required: !1
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      required: !1
    },
    fieldDatatype: {
      defaultValue: {
        value: "'string'",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["../table/TableColumn"]
}, DefaultCell.__docgenInfo = {
  componentName: "DefaultCell",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "DefaultCell",
  methods: [],
  actualName: "DefaultCell"
};