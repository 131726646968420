import React from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { IconButton } from "../../../IconButton/IconButton";
import styles from "./GenericDatePicker.module.css";
var getDisabledClass = function (disabled) {
  return disabled ? styles.navigationDisabled : "";
};
export var PrevButton = function (_ref) {
  var label = _ref.label,
    disabled = _ref.disabled,
    onClick = _ref.onClick,
    className = _ref.className,
    translator = useTranslator(),
    navigationIconPrevClassName = cx(styles.navigationPrevious, getDisabledClass(disabled), className);
  return React.createElement(IconButton, {
    icon: "gw-chevron-left",
    className: navigationIconPrevClassName,
    onClick: onClick,
    disabled: disabled,
    ariaLabel: translator(label)
  });
};
export var NextButton = function (_ref2) {
  var label = _ref2.label,
    disabled = _ref2.disabled,
    onClick = _ref2.onClick,
    className = _ref2.className,
    translator = useTranslator(),
    navigationIconNextClassName = cx(styles.navigationNext, getDisabledClass(disabled), className);
  return React.createElement(IconButton, {
    icon: "gw-chevron-right",
    className: navigationIconNextClassName,
    onClick: onClick,
    disabled: disabled,
    ariaLabel: translator(label)
  });
};
PrevButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "PrevButton"
}, NextButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "NextButton"
}, PrevButton.__docgenInfo = {
  componentName: "PrevButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "PrevButton",
  methods: [],
  actualName: "PrevButton"
}, NextButton.__docgenInfo = {
  componentName: "NextButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "NextButton",
  methods: [],
  actualName: "NextButton"
};