import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import clamp from "lodash/clamp";
import { mappingDelta, getMessageProp } from "@jutro/platform";
import { intlMessageShape } from "@jutro/prop-types";
import { getValueForInput, isNumber } from "@jutro/data";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import styles from "./StepperField.module.css";
import inputStyles from "../InputField/InputField.module.css";
import { Button } from "../../button/Button";
import { messages } from "./StepperField.messages";
export var StepperField = function (_FieldComponent) {
  _inherits(StepperField, FieldComponent);
  var _super = _createSuper(StepperField);
  function StepperField() {
    var _context, _this;
    _classCallCheck(this, StepperField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "state", {
      min: 0,
      max: 0
    }), _defineProperty(_assertThisInitialized(_this), "getValidValue", function (value) {
      if ("" === value) return "";
      var _this$state = _this.state,
        min = _this$state.min,
        max = _this$state.max;
      return clamp(value, min, max);
    }), _defineProperty(_assertThisInitialized(_this), "handleBlur", function (evt) {
      "" === evt.target.value && _this.notifyChange("");
      var value = getValueForInput("number", evt.target.value);
      _this.notifyChange(_this.getValidValue(value));
      var onBlur = _this.props.onBlur;
      if (onBlur) {
        var _this$props = _this.props,
          label = _this$props.label,
          model = _this$props.model,
          path = _this$props.path,
          dataPath = _this$props.dataPath,
          _assertThisInitialize = _assertThisInitialized(_this);
        onBlur(evt, {
          fieldUniqueId: _assertThisInitialize.fieldUniqueId,
          label: (0, _assertThisInitialize.translator)(label),
          value: value,
          beforeValue: _this.beforeValue,
          model: model || path,
          dataPath: dataPath
        });
      }
    }), _defineProperty(_assertThisInitialized(_this), "isValueInBoundaries", function (value) {
      return value >= _this.state.min && value <= _this.state.max;
    }), _defineProperty(_assertThisInitialized(_this), "handleStep", function (evt, increase) {
      evt.preventDefault();
      var _this$state2 = _this.state,
        step = _this$state2.step,
        value = _this$state2.value,
        newValue = increase ? value + step : value - step;
      _this.notifyChange(newValue), _this.replaceInputAriaValue(newValue);
    }), _defineProperty(_assertThisInitialized(_this), "stepUp", function (evt) {
      return _this.handleStep(evt, !0);
    }), _defineProperty(_assertThisInitialized(_this), "stepDown", function (evt) {
      return _this.handleStep(evt, !1);
    }), _defineProperty(_assertThisInitialized(_this), "replaceInputAriaValue", function (value) {
      var _context2,
        _ariaInputValue$first,
        ariaInputValue = document.getElementById("".concat(_this.fieldUniqueId, "-aria-messages")),
        span = document.createElement("span");
      span.textContent = _this.props.label ? _concatInstanceProperty(_context2 = "".concat(_this.props.label, " ")).call(_context2, value) : value, null === (_ariaInputValue$first = ariaInputValue.firstElementChild) || void 0 === _ariaInputValue$first || _ariaInputValue$first.remove(), ariaInputValue.append(span);
    }), _this;
  }
  return _createClass(StepperField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(StepperField.prototype), "render", this).call(this);
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function () {
      return _objectSpread(_objectSpread({}, _get(_getPrototypeOf(StepperField.prototype), "generateAccessibilityProperties", this).call(this)), {}, {
        "aria-live": "off"
      });
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var disabled = breakpointProps.disabled,
        required = breakpointProps.required,
        controlClassName = breakpointProps.controlClassName,
        messageProps = breakpointProps.messageProps,
        id = breakpointProps.id,
        testId = breakpointProps.testId,
        fieldUniqueId = this.fieldUniqueId,
        controlClasses = cx(styles.stepper, controlClassName),
        inputClasses = cx(inputStyles.input, styles.input),
        translator = this.translator,
        decreaseBtnLabel = translator(getMessageProp("decreaseBtnLabel", messageProps, messages)),
        increaseBtnLabel = translator(getMessageProp("increaseBtnLabel", messageProps, messages)),
        _this$state3 = this.state,
        min = _this$state3.min,
        max = _this$state3.max,
        value = _this$state3.value,
        step = _this$state3.step,
        isMinimumReached = !this.isValueInBoundaries(value - step),
        isMaximumReached = !this.isValueInBoundaries(value + step);
      return React.createElement("div", {
        className: controlClasses
      }, React.createElement(Button, {
        icon: "gw-remove",
        "aria-label": decreaseBtnLabel,
        onClick: this.stepDown,
        disabled: disabled || isMinimumReached,
        type: "outlined"
      }), React.createElement("input", _extends({
        id: fieldUniqueId,
        type: "number",
        className: inputClasses,
        step: step,
        value: value,
        min: min,
        max: max,
        onChange: this.handleChange,
        onBlur: this.handleBlur
      }, this.getInputFocusHandlers(), {
        disabled: disabled,
        required: required,
        tabIndex: "0"
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(), {
        "data-testid": testId || id
      })), React.createElement(Button, {
        icon: "gw-add",
        "aria-label": increaseBtnLabel,
        onClick: this.stepUp,
        disabled: disabled || isMaximumReached,
        type: "outlined"
      }), React.createElement("span", {
        className: styles.hidden,
        "aria-live": "polite",
        id: "".concat(this.fieldUniqueId, "-aria-messages")
      }, isMinimumReached && translator(messages.minimumReachedLabel, {
        value: value
      }), isMaximumReached && translator(messages.maximumReachedLabel, {
        value: value
      })));
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function (nextProps, prevState) {
      var minValue = nextProps.minValue,
        maxValue = nextProps.maxValue,
        stepValue = nextProps.stepValue,
        value = nextProps.value,
        min = isNumber(minValue) ? minValue : -1 / 0,
        max = isNumber(maxValue) ? maxValue : 1 / 0,
        step = stepValue > 0 ? stepValue : 1,
        newValue = getValueForInput("number", value);
      return !newValue || prevState && prevState.min === min && prevState.max === max || (newValue = clamp(newValue, min, max)), mappingDelta({
        min: min,
        max: max,
        step: step,
        value: newValue
      }, prevState);
    }
  }]), StepperField;
}();
_defineProperty(StepperField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stepValue: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  messageProps: PropTypes.shape({
    decreaseBtnLabel: intlMessageShape,
    increaseBtnLabel: intlMessageShape
  })
})), _defineProperty(StepperField, "contextType", FieldComponent.contextType), _defineProperty(StepperField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps)), StepperField.__docgenInfo = {
  description: "Renders a numeric value and buttons to increase or decrease the value.\n@typedef {typeof StepperField.propTypes} StepperFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<StepperFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "getValidValue",
    docblock: "Given a number value, will clamp the value within the current max and min\n\n@param {number} value - Value to be clamped\n@returns {number|string} - The clamped number value or an empty string",
    modifiers: [],
    params: [{
      name: "value",
      description: "Value to be clamped",
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: {
      description: "The clamped number value or an empty string",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      }
    },
    description: "Given a number value, will clamp the value within the current max and min"
  }, {
    name: "handleBlur",
    docblock: "Overridden blur handler for `input` element. Converts possible string input into a number and clamps value.\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Overridden blur handler for `input` element. Converts possible string input into a number and clamps value."
  }, {
    name: "isValueInBoundaries",
    docblock: "Checks whether value is between props.minValue and props.maxValue\n\n@param {number} value to be tested\n@returns {boolean} true if the value is between properties boundaries",
    modifiers: [],
    params: [{
      name: "value",
      description: "to be tested",
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: {
      description: "true if the value is between properties boundaries",
      type: {
        name: "boolean"
      }
    },
    description: "Checks whether value is between props.minValue and props.maxValue"
  }, {
    name: "handleStep",
    docblock: "Custom handler for `button` element. Increments/decrements the stepper input control\n\n@param {object} evt - React event wrapper\n@param {boolean} increase - indicates whether to increase or decrease the value",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "increase",
      description: "indicates whether to increase or decrease the value",
      type: {
        name: "boolean"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom handler for `button` element. Increments/decrements the stepper input control"
  }, {
    name: "stepUp",
    docblock: "Custom handler to increase the stepper input control\n\n@param {object} evt - React event wrapper\n@returns {undefined}",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: null
    },
    description: "Custom handler to increase the stepper input control"
  }, {
    name: "stepDown",
    docblock: "Custom handler to decrease the stepper input control\n\n@param {object} evt - React event wrapper\n@returns {undefined}",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: null
    },
    description: "Custom handler to decrease the stepper input control"
  }, {
    name: "replaceInputAriaValue",
    docblock: "Replace current value in aria live region section\nIt is needed to fix the bug JUT-11673 without breaking changes\nand to make it work for different sets of browsers and screen readers (generally Firefox)\nwhere aria live regions changes are not fully read by SR\n\n@param {object} value - new value to be pronounced by a screen reader",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value to be pronounced by a screen reader",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Replace current value in aria live region section\nIt is needed to fix the bug JUT-11673 without breaking changes\nand to make it work for different sets of browsers and screen readers (generally Firefox)\nwhere aria live regions changes are not fully read by SR"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "StepperField",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "String or number passed as value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Default value"
    },
    stepValue: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Step increment value for the input field"
    },
    minValue: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Minimum value for the input field (note: this is different than aspect)"
    },
    maxValue: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Maximum value for the input field (note: this is different than aspect)"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          decreaseBtnLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "stepper decrease button aria-label",
            required: !1
          },
          increaseBtnLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "stepper increase button aria-label",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, StepperField.__docgenInfo = {
  componentName: "StepperField",
  packageName: "@jutro/components",
  description: "Renders a numeric value and buttons to increase or decrease the value.",
  displayName: "StepperField",
  methods: [{
    name: "getValidValue",
    docblock: "Given a number value, will clamp the value within the current max and min\n\n@param {number} value - Value to be clamped\n@returns {number|string} - The clamped number value or an empty string",
    modifiers: [],
    params: [{
      name: "value",
      description: "Value to be clamped",
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: {
      description: "The clamped number value or an empty string",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      }
    },
    description: "Given a number value, will clamp the value within the current max and min"
  }, {
    name: "handleBlur",
    docblock: "Overridden blur handler for `input` element. Converts possible string input into a number and clamps value.\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Overridden blur handler for `input` element. Converts possible string input into a number and clamps value."
  }, {
    name: "isValueInBoundaries",
    docblock: "Checks whether value is between props.minValue and props.maxValue\n\n@param {number} value to be tested\n@returns {boolean} true if the value is between properties boundaries",
    modifiers: [],
    params: [{
      name: "value",
      description: "to be tested",
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: {
      description: "true if the value is between properties boundaries",
      type: {
        name: "boolean"
      }
    },
    description: "Checks whether value is between props.minValue and props.maxValue"
  }, {
    name: "handleStep",
    docblock: "Custom handler for `button` element. Increments/decrements the stepper input control\n\n@param {object} evt - React event wrapper\n@param {boolean} increase - indicates whether to increase or decrease the value",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "increase",
      description: "indicates whether to increase or decrease the value",
      type: {
        name: "boolean"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom handler for `button` element. Increments/decrements the stepper input control"
  }, {
    name: "stepUp",
    docblock: "Custom handler to increase the stepper input control\n\n@param {object} evt - React event wrapper\n@returns {undefined}",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: null
    },
    description: "Custom handler to increase the stepper input control"
  }, {
    name: "stepDown",
    docblock: "Custom handler to decrease the stepper input control\n\n@param {object} evt - React event wrapper\n@returns {undefined}",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: null
    },
    description: "Custom handler to decrease the stepper input control"
  }, {
    name: "replaceInputAriaValue",
    docblock: "Replace current value in aria live region section\nIt is needed to fix the bug JUT-11673 without breaking changes\nand to make it work for different sets of browsers and screen readers (generally Firefox)\nwhere aria live regions changes are not fully read by SR\n\n@param {object} value - new value to be pronounced by a screen reader",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value to be pronounced by a screen reader",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Replace current value in aria live region section\nIt is needed to fix the bug JUT-11673 without breaking changes\nand to make it work for different sets of browsers and screen readers (generally Firefox)\nwhere aria live regions changes are not fully read by SR"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "StepperField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "String or number passed as value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Default value"
    },
    stepValue: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Step increment value for the input field"
    },
    minValue: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Minimum value for the input field (note: this is different than aspect)"
    },
    maxValue: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Maximum value for the input field (note: this is different than aspect)"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          decreaseBtnLabel: {
            name: "union",
            description: "stepper decrease button aria-label",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          increaseBtnLabel: {
            name: "union",
            description: "stepper increase button aria-label",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};