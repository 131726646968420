import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["value", "onChange", "onFocus", "onBlur", "decimalPlaces", "disabled", "id", "testId", "showGrouping", "label", "fieldComponentAriaLabel"];
import React, { useContext, useState, useCallback, useMemo } from "react";
import cx from "classnames";
import { IntlContext, useTranslator } from "@jutro/locale";
import { getDecimalRegex, isSafeNumber } from "@jutro/data";
import { IconButton } from "../../IconButton/IconButton";
import { messages } from "./InputNumberField.messages";
import styles from "./NumberInput.module.css";
export var NumberInput = function (_ref) {
  var value = _ref.value,
    onChange = _ref.onChange,
    onFocus = _ref.onFocus,
    onBlur = _ref.onBlur,
    decimalPlaces = _ref.decimalPlaces,
    disabled = _ref.disabled,
    id = _ref.id,
    testId = _ref.testId,
    _ref$showGrouping = _ref.showGrouping,
    showGrouping = void 0 === _ref$showGrouping || _ref$showGrouping,
    label = _ref.label,
    fieldComponentAriaLabel = _ref.fieldComponentAriaLabel,
    restProps = _objectWithoutProperties(_ref, _excluded),
    formatNumber = useContext(IntlContext).formatNumber,
    _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    isFocused = _useState2[0],
    setFocused = _useState2[1],
    translator = useTranslator(),
    numericValue = Number(value) || 0,
    step = Number(restProps.step),
    handleValueChange = useCallback(function (event) {
      var _event$target$value,
        _event$target,
        newValue = null !== (_event$target$value = null === (_event$target = event.target) || void 0 === _event$target ? void 0 : _event$target.value) && void 0 !== _event$target$value ? _event$target$value : "";
      isSafeNumber(newValue) && getDecimalRegex(decimalPlaces).test(newValue) && onChange(newValue);
    }, [decimalPlaces, onChange]),
    handleUpArrowClick = useCallback(function () {
      if (!disabled) {
        var newValue = numericValue + step;
        (void 0 === restProps.max || newValue <= restProps.max) && onChange(newValue);
      }
    }, [numericValue, step, disabled]),
    handleDownArrowClick = useCallback(function () {
      if (!disabled) {
        var newValue = numericValue - step;
        (void 0 === restProps.min || newValue >= restProps.min) && onChange(newValue);
      }
    }, [numericValue, step, disabled]),
    handleOnArrowMouseDown = useCallback(function (event) {
      event.preventDefault();
    }, []),
    handleBlur = useCallback(function (event) {
      setFocused(!1), onBlur(event);
    }, [onBlur]),
    handleFocus = useCallback(function (event) {
      onFocus(event), setFocused(!0);
    }, [onFocus]),
    handleBeforeInput = useCallback(function (event) {
      event.data.match(/^[0-9,-. ]+$/) || event.preventDefault();
    }, []),
    formattedValue = useMemo(function () {
      return value || 0 === value ? formatNumber(value, {
        maximumFractionDigits: decimalPlaces,
        useGrouping: showGrouping
      }) : "";
    }, [decimalPlaces, formatNumber, value, showGrouping]),
    inputAriaLabel = 1 !== step ? translator(messages.numberFieldStepAriaLabel, {
      label: label,
      step: step
    }) : fieldComponentAriaLabel,
    input = value || 0 === value ? React.createElement("input", _extends({
      id: id,
      "data-testid": testId,
      inputMode: "decimal",
      type: isFocused ? "number" : "text",
      value: isFocused ? value : formattedValue,
      onChange: handleValueChange,
      onBlur: handleBlur,
      onBeforeInput: handleBeforeInput,
      onFocus: handleFocus,
      disabled: disabled,
      "aria-label": inputAriaLabel
    }, restProps)) : React.createElement("input", _extends({
      value: "",
      id: id,
      "data-testid": testId,
      inputMode: "decimal",
      type: "text",
      onChange: handleValueChange,
      onBlur: handleBlur,
      onBeforeInput: handleBeforeInput,
      onFocus: handleFocus,
      disabled: disabled,
      "aria-label": inputAriaLabel
    }, restProps));
  return React.createElement("div", {
    className: styles.numberInput
  }, input, isFocused && React.createElement(React.Fragment, null, React.createElement(IconButton, {
    icon: "gw-arrow-drop-up",
    onMouseDown: handleOnArrowMouseDown,
    onClick: handleUpArrowClick,
    className: cx(styles.numberInputButton, styles.numberInputUp),
    tabIndex: -1,
    ariaLabel: messages.increment
  }), React.createElement(IconButton, {
    icon: "gw-arrow-drop-down",
    onMouseDown: handleOnArrowMouseDown,
    onClick: handleDownArrowClick,
    className: cx(styles.numberInputButton, styles.numberInputDown),
    tabIndex: -1,
    ariaLabel: messages.decrement
  })));
};
NumberInput.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "NumberInput",
  props: {
    showGrouping: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    }
  }
}, NumberInput.__docgenInfo = {
  componentName: "NumberInput",
  packageName: "@jutro/components",
  description: "",
  displayName: "NumberInput",
  methods: [],
  actualName: "NumberInput",
  props: {
    showGrouping: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    }
  }
};