import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useContext, useMemo } from "react";
import cx from "classnames";
import { Transition, TransitionGroup } from "react-transition-group";
import { InputField, IconButton, ModalNextContext } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { useTable } from "./TableContext";
import { ColumnsConfigModal } from "./columnsConfig/ColumnsConfigModal";
import { messages } from "./DataTable.messages";
export var TableBar = function (_ref) {
  var filterValue = _ref.filterValue,
    onFilterChange = _ref.onFilterChange,
    filterPlaceholder = _ref.filterPlaceholder,
    _ref$selectionActions = _ref.selectionActions,
    selectionActions = void 0 === _ref$selectionActions ? [] : _ref$selectionActions,
    showSelectionActions = _ref.showSelectionActions,
    disabled = _ref.disabled,
    translator = useTranslator(),
    _useTable = useTable(),
    styles = _useTable.styles,
    columns = _useTable.columns,
    columnsConfigurable = columns.configurable,
    columnsConfig = columns.config,
    setColumnsConfig = columns.setConfig,
    showModal = useContext(ModalNextContext).showModal,
    columnsConfigAction = useMemo(function () {
      if (!columnsConfigurable) return null;
      var _ref2,
        triggerColumnsConfigModal = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
          var updatedConfig;
          return _regeneratorRuntime.wrap(function (_context) {
            for (;;) switch (_context.prev = _context.next) {
              case 0:
                return _context.prev = 0, _context.next = 3, showModal(React.createElement(ColumnsConfigModal, {
                  initialColumns: columnsConfig
                }));
              case 3:
                updatedConfig = _context.sent, setColumnsConfig(updatedConfig), _context.next = 9;
                break;
              case 7:
                _context.prev = 7, _context.t0 = _context.catch(0);
              case 9:
              case "end":
                return _context.stop();
            }
          }, _callee, null, [[0, 7]]);
        })), function () {
          return _ref2.apply(this, arguments);
        });
      return React.createElement("div", {
        className: styles.columnsConfigAction
      }, React.createElement(IconButton, {
        icon: "gw-view-column",
        label: translator(messages.columnsConfig),
        ariaLabel: translator(messages.columnsConfig),
        onClick: triggerColumnsConfigModal
      }));
    }, [columnsConfigurable, styles.columnsConfigAction, translator, columnsConfig, setColumnsConfig]),
    searchWrapperClassName = cx(styles.searchWrapper, _defineProperty({}, styles.searchWrapperDisabled, disabled));
  return React.createElement("div", {
    className: styles.tableBar
  }, React.createElement(InputField, {
    id: "search",
    icon: "gw-search",
    iconPosition: "right",
    placeholder: filterPlaceholder,
    className: searchWrapperClassName,
    controlClassName: styles.search,
    onValueChange: onFilterChange,
    value: filterValue,
    editable: !disabled,
    name: "search"
  }), React.createElement("div", {
    className: styles.actionPanel
  }, showSelectionActions && React.createElement(TransitionGroup, {
    component: null
  }, React.createElement(Transition, {
    timeout: 400
  }, function (state) {
    var _cx2,
      actionBarStyles = cx(styles.selectionActions, (_defineProperty(_cx2 = {}, styles.withBorder, columnsConfigAction), _defineProperty(_cx2, styles.transition, "entered" !== state), _cx2)),
      buttonsStyles = cx(styles.buttons, _defineProperty({}, styles.enter, _startsWithInstanceProperty(state).call(state, "enter")));
    return React.createElement("div", {
      className: actionBarStyles
    }, React.createElement("div", {
      className: buttonsStyles
    }, _mapInstanceProperty(selectionActions).call(selectionActions, function (_ref3) {
      var icon = _ref3.icon,
        label = _ref3.label,
        callback = _ref3.callback;
      return React.createElement(IconButton, {
        key: label,
        icon: icon,
        label: label,
        onClick: callback
      });
    })));
  })), columnsConfigAction));
};
TableBar.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TableBar",
  props: {
    selectionActions: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      required: !1
    }
  }
}, TableBar.__docgenInfo = {
  componentName: "TableBar",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "TableBar",
  methods: [],
  actualName: "TableBar",
  props: {
    selectionActions: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      required: !1
    }
  }
};