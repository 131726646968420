import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children", "value", "className", "dangerouslySetInnerHTML"];
import React, { forwardRef } from "react";
import { useTranslator } from "@jutro/locale";
import PropTypes from "prop-types";
import cx from "classnames";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./Radio.module.css";
export var RadioLabel = forwardRef(function (_ref, ref) {
  var children = _ref.children,
    value = _ref.value,
    className = _ref.className,
    inputDOMProps = (_ref.dangerouslySetInnerHTML, _objectWithoutProperties(_ref, _excluded)),
    translator = useTranslator();
  return React.createElement("label", _extends({}, inputDOMProps, {
    ref: ref,
    onMouseDown: function (e) {
      return e.preventDefault();
    }
  }, value ? {
    "aria-label": translator(value)
  } : {}, {
    className: cx(styles.label, className)
  }), children);
});
RadioLabel.propTypes = {
  children: PropTypes.node,
  value: intlMessageShape,
  className: PropTypes.string,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(HTMLLabelElement)
  })])
}, RadioLabel.displayName = "RadioLabel", RadioLabel.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "RadioLabel",
  props: {
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to be rendered inside of the label. Text content will be used as a label value, if *value* prop is not provided"
    },
    value: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "value of the label, used by the screenreaders, if not provided - children will be used as a label text, just how native html label works"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "additional className to underlying `<label>` element"
    },
    ref: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "instanceOf",
              value: "HTMLLabelElement",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "React Ref to underlying `<label>` element"
    }
  }
}, RadioLabel.__docgenInfo = {
  componentName: "RadioLabel",
  packageName: "@jutro/components",
  description: "",
  displayName: "RadioLabel",
  methods: [],
  actualName: "RadioLabel",
  props: {
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to be rendered inside of the label. Text content will be used as a label value, if *value* prop is not provided"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "value of the label, used by the screenreaders, if not provided - children will be used as a label text, just how native html label works"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "additional className to underlying `<label>` element"
    },
    ref: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "instanceOf",
              value: "HTMLLabelElement",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "React Ref to underlying `<label>` element"
    }
  }
};