import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
var _ref2,
  _excluded = ["children", "oktaAuth", "noService"];
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useState, useMemo, useEffect, useContext, createContext } from "react";
import isEqual from "lodash/isEqual";
import { OktaAuth } from "@okta/okta-auth-js";
import { useHistory } from "react-router-dom";
import { joinUrl } from "@jutro/platform";
import { getConfigValue } from "@jutro/config";
export var AuthStateContext = createContext(void 0);
export var useAuthState = function () {
  return useContext(AuthStateContext);
};
function convertAuthState(state) {
  var _ref = state || {},
    isAuthenticated = _ref.isAuthenticated,
    accessToken = _ref.accessToken,
    idToken = _ref.idToken,
    error = _ref.error,
    _ref$userInfo = _ref.userInfo,
    userInfo = void 0 === _ref$userInfo ? null : _ref$userInfo,
    newState = {
      isAuthenticated: !!isAuthenticated,
      isPending: null === state,
      accessToken: accessToken ? accessToken.accessToken : null,
      idToken: idToken ? idToken.idToken : null,
      userInfo: userInfo
    };
  return error && (newState.error = error), newState;
}
function isIdTokenWithCache(token) {
  return "cache" in token;
}
function getUserInfo(_x, _x2) {
  return _getUserInfo.apply(this, arguments);
}
function _getUserInfo() {
  return (_getUserInfo = _asyncToGenerator(_regeneratorRuntime.mark(function _callee3(oktaAuth, idToken) {
    var userInfo;
    return _regeneratorRuntime.wrap(function (_context3) {
      for (;;) switch (_context3.prev = _context3.next) {
        case 0:
          if (!isIdTokenWithCache(idToken)) {
            _context3.next = 2;
            break;
          }
          return _context3.abrupt("return", idToken.cache.claims);
        case 2:
          return _context3.next = 4, oktaAuth.getUser();
        case 4:
          return userInfo = _context3.sent, oktaAuth.tokenManager.add("idToken", _objectSpread(_objectSpread({}, idToken), {}, {
            cache: {
              claims: userInfo
            }
          })), _context3.abrupt("return", userInfo);
        case 7:
        case "end":
          return _context3.stop();
      }
    }, _callee3);
  }))).apply(this, arguments);
}
export var transformAuthState = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(oktaAuth, authState) {
  return _regeneratorRuntime.wrap(function (_context) {
    for (;;) switch (_context.prev = _context.next) {
      case 0:
        if (!authState.accessToken || !authState.idToken) {
          _context.next = 9;
          break;
        }
        return _context.t0 = _objectSpread, _context.t1 = _objectSpread({}, authState), _context.t2 = {}, _context.next = 6, getUserInfo(oktaAuth, authState.idToken);
      case 6:
        return _context.t3 = _context.sent, _context.t4 = {
          userInfo: _context.t3
        }, _context.abrupt("return", (0, _context.t0)(_context.t1, _context.t2, _context.t4));
      case 9:
        return _context.abrupt("return", authState);
      case 10:
      case "end":
        return _context.stop();
    }
  }, _callee);
})), function (_x3, _x4) {
  return _ref2.apply(this, arguments);
});
export var AuthStateProvider = function (_ref3) {
  var _ref4,
    children = _ref3.children,
    oktaAuthProp = _ref3.oktaAuth,
    _ref3$noService = _ref3.noService,
    noService = void 0 !== _ref3$noService && _ref3$noService,
    props = _objectWithoutProperties(_ref3, _excluded),
    isServiceEnabled = !noService,
    history = useHistory(),
    oktaAuth = useMemo(function () {
      return oktaAuthProp || new OktaAuth(_objectSpread({
        transformAuthState: transformAuthState
      }, props));
    }, [oktaAuthProp]);
  oktaAuth.options.restoreOriginalUri || (oktaAuth.options.restoreOriginalUri = (_ref4 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2(_, originalUri) {
    return _regeneratorRuntime.wrap(function (_context2) {
      for (;;) switch (_context2.prev = _context2.next) {
        case 0:
          if (originalUri) {
            _context2.next = 2;
            break;
          }
          return _context2.abrupt("return");
        case 2:
          history.replace(originalUri.replace(joinUrl(window.location.origin, getConfigValue("JUTRO_ROUTER_BASENAME", "")), ""));
        case 3:
        case "end":
          return _context2.stop();
      }
    }, _callee2);
  })), function (_x5, _x6) {
    return _ref4.apply(this, arguments);
  }));
  var _useState = useState(function () {
      return convertAuthState(oktaAuth.authStateManager.getAuthState());
    }),
    _useState2 = _slicedToArray(_useState, 2),
    authState = _useState2[0],
    setAuthState = _useState2[1];
  useEffect(function () {
    var subscriptionHandler = function () {
      setAuthState(function (oldState) {
        var newState = convertAuthState(oktaAuth.authStateManager.getAuthState());
        return isEqual(oldState, newState) ? oldState : newState;
      });
    };
    return oktaAuth.authStateManager.subscribe(subscriptionHandler), subscriptionHandler(), isServiceEnabled ? oktaAuth.start() : oktaAuth.authStateManager.updateAuthState(), function () {
      oktaAuth.authStateManager.unsubscribe(subscriptionHandler), isServiceEnabled && oktaAuth.stop();
    };
  }, [oktaAuth, isServiceEnabled]);
  var stateMemo = useMemo(function () {
    return {
      oktaAuth: oktaAuth,
      authState: authState
    };
  }, [oktaAuth, authState]);
  return React.createElement(AuthStateContext.Provider, {
    value: stateMemo
  }, children);
};
AuthStateProvider.__docgenInfo = {
  description: "Initiate Okta's OktaAuth and store auth state\nSimilar to okta-react's Secure component",
  methods: [],
  displayName: "AuthStateProvider",
  props: {
    noService: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    }
  }
}, AuthStateProvider.__docgenInfo = {
  componentName: "AuthStateProvider",
  packageName: "@jutro/auth",
  description: "Initiate Okta's OktaAuth and store auth state\nSimilar to okta-react's Secure component",
  displayName: "AuthStateProvider",
  methods: [],
  actualName: "AuthStateProvider",
  props: {
    noService: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    }
  }
};