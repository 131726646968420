import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _toastStylesByType;
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import cx from "classnames";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { generateComponentId } from "@jutro/platform";
import { linkPropTypes } from "../Link/Link";
import styles from "./Toast.module.css";
import { InlineNotification } from "../inlineNotification/InlineNotification";
import { CloseToastButton } from "./CloseToastButton";
var toastTypes_INFO = "info",
  toastTypes_SUCCESS = "success",
  toastTypes_WARNING = "warning",
  toastTypes_ERROR = "error",
  toastStylesByType = (_defineProperty(_toastStylesByType = {}, toastTypes_INFO, styles.toastContentInfo), _defineProperty(_toastStylesByType, toastTypes_SUCCESS, styles.toastContentSuccess), _defineProperty(_toastStylesByType, toastTypes_WARNING, styles.toastContentWarning), _defineProperty(_toastStylesByType, toastTypes_ERROR, styles.toastContentError), _toastStylesByType);
export var ToastProvider = function (props) {
  var children = props.children;
  return React.createElement(React.Fragment, null, children, React.createElement(ToastContainer, {
    hideProgressBar: !0,
    transition: Slide,
    closeOnClick: !1,
    role: "alert",
    className: styles.toastContainer
  }));
};
ToastProvider.toast = function (props) {
  var message = props.message,
    _props$type = props.type,
    type = void 0 === _props$type ? toastTypes_INFO : _props$type,
    autoClose = props.autoClose,
    autoFocus = props.autoFocus,
    linkProps = props.linkProps,
    onClosed = props.onClosed,
    toastId = props.toastId,
    closeButtonClasses = cx(styles.dismissButton, _defineProperty({}, styles.toastWarning, "warning" === type)),
    setFocus = autoFocus || !autoClose,
    toastContent = React.createElement(InlineNotification, {
      id: generateComponentId("toast"),
      className: cx(styles.toastContent, toastStylesByType[type]),
      message: message,
      type: type,
      isEmbeddedNotification: !0,
      isDismissable: !1,
      linkProps: linkProps,
      isTooltipEnabled: !1
    });
  toast(toastContent, {
    autoClose: autoClose,
    type: type,
    onClose: onClosed,
    closeButton: React.createElement(CloseToastButton, {
      iconColor: "dark",
      className: closeButtonClasses,
      setFocus: setFocus
    }),
    className: styles.toast,
    bodyClassName: styles.toastBody,
    icon: !1,
    toastId: toastId
  });
}, ToastProvider.setToastTrigger = function (trigger) {
  ToastProvider.toast = trigger;
};
var propTypes = {
  autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  type: PropTypes.oneOf([toastTypes_INFO, toastTypes_SUCCESS, toastTypes_WARNING, toastTypes_ERROR]),
  message: intlMessageShape,
  autoFocus: PropTypes.bool,
  linkProps: PropTypes.shape(function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = null != arguments[i] ? arguments[i] : {};
      i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
        _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
      });
    }
    return target;
  }({}, linkPropTypes)),
  onClosed: PropTypes.func,
  toastId: PropTypes.string
};
ToastProvider.propTypes = propTypes, ToastProvider.__docgenInfo = {
  description: "",
  methods: [{
    name: "toast",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "props",
      optional: !1,
      type: {
        name: "ToastProps",
        alias: "ToastProps"
      }
    }],
    returns: null
  }, {
    name: "setToastTrigger",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "trigger",
      optional: !1,
      type: null
    }],
    returns: null
  }],
  displayName: "ToastProvider",
  props: {
    autoClose: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Set the delay in ms to close the toast automatically. By default it's closed after 5 seconds.\nUse `false` to prevent the toast from closing."
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: !1
        }, {
          value: "'success'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase allowing to set color and appearance of the toast (success, warning, error, info)"
    },
    message: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Text to display on notification"
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, focus will be drawn to the dismiss button on opening even if autoClose={true}"
    },
    linkProps: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Link prop used to navigate the user to an internal or external link"
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called when the notification disappears"
    },
    toastId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Toast id"
    }
  }
}, ToastProvider.__docgenInfo = {
  componentName: "ToastProvider",
  packageName: "@jutro/components",
  description: "",
  displayName: "ToastProvider",
  methods: [{
    name: "toast",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "props",
      optional: !1,
      type: null
    }],
    returns: null
  }, {
    name: "setToastTrigger",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "trigger",
      optional: !1,
      type: null
    }],
    returns: null
  }],
  actualName: "ToastProvider",
  props: {
    autoClose: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Set the delay in ms to close the toast automatically. By default it's closed after 5 seconds.\nUse `false` to prevent the toast from closing."
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: !1
        }, {
          value: "'success'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase allowing to set color and appearance of the toast (success, warning, error, info)"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Text to display on notification"
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, focus will be drawn to the dismiss button on opening even if autoClose={true}"
    },
    linkProps: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Link prop used to navigate the user to an internal or external link"
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called when the notification disappears"
    },
    toastId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Toast id"
    }
  }
};