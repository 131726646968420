import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useKeyActive } from "@jutro/platform";
import styles from "./ClickableCard.module.css";
import { getKeyPressHandler } from "../../accessibility/getKeyPressHandler";
export var ClickableCard = function (props) {
  var _cx,
    id = props.id,
    className = props.className,
    children = props.children,
    onClick = props.onClick,
    disabled = props.disabled,
    ref = useRef(null),
    isActiveKeyPressed = useKeyActive(ref),
    handleClick = useCallback(function (evt) {
      evt.target === evt.currentTarget && onClick && onClick(evt);
    }, [onClick]),
    handleKeyPress = useCallback(getKeyPressHandler(handleClick), [handleClick]),
    clickableCardClass = cx((_defineProperty(_cx = {}, styles.activeKeypress, isActiveKeyPressed), _defineProperty(_cx, styles.disabled, disabled), _cx), styles.clickableCard, className);
  return React.createElement("button", {
    id: id,
    disabled: disabled,
    className: clickableCardClass,
    onClick: handleClick,
    onKeyPress: handleKeyPress,
    ref: ref
  }, children);
};
var clickableCardPropTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};
ClickableCard.propTypes = clickableCardPropTypes, ClickableCard.displayName = "ClickableCard", ClickableCard.__docgenInfo = {
  description: "ClickableCard\n\n@metadataType container",
  methods: [],
  displayName: "ClickableCard",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "A unique ID of this component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Card's content"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Card's click handler"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the entire Card component is disabled"
    }
  }
}, ClickableCard.__docgenInfo = {
  componentName: "ClickableCard",
  packageName: "@jutro/components",
  description: "ClickableCard",
  displayName: "ClickableCard",
  methods: [],
  actualName: "ClickableCard",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "A unique ID of this component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Card's content"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Card's click handler"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the entire Card component is disabled"
    }
  }
};