import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { createContext, useRef, useCallback } from "react";
import { IntlProvider as ReactIntlProvider, useIntl } from "react-intl";
import { warning } from "@jutro/logger";
import debounce from "lodash/debounce";
export var IntlContext = createContext(void 0);
var ReactIntlConsumer = function (_ref) {
  var children = _ref.children,
    intl = useIntl();
  return React.createElement(IntlContext.Provider, {
    value: intl
  }, children);
};
export var IntlProvider = function (_ref2) {
  var defaultLocale = _ref2.defaultLocale,
    locale = _ref2.locale,
    messages = _ref2.messages,
    textComponent = _ref2.textComponent,
    children = _ref2.children,
    bulkErrors = useRef({
      locale: "",
      ids: []
    }),
    deferWarning = useCallback(debounce(function () {
      var _context, _context2;
      warning(_concatInstanceProperty(_context = _concatInstanceProperty(_context2 = "".concat(bulkErrors.current.locale, " locale translations are not provided or missing next ")).call(_context2, bulkErrors.current.ids.length, " id's: ")).call(_context, bulkErrors.current.ids.join(", "))), bulkErrors.current = {
        locale: "",
        ids: []
      };
    }, 500), [bulkErrors]);
  return React.createElement(ReactIntlProvider, {
    onError: function (err) {
      return errMessage = err.message, void ((missingId = errMessage.match(/Missing message: "(.*)" for locale "(.*)"/)) && (bulkErrors.current.locale = bulkErrors.current.locale || missingId[2], bulkErrors.current.ids.push(missingId[1]), deferWarning()));
      var errMessage, missingId;
    },
    defaultLocale: defaultLocale,
    locale: locale,
    messages: messages,
    textComponent: textComponent
  }, React.createElement(ReactIntlConsumer, null, children));
};
IntlProvider.displayName = "IntlProvider", IntlProvider.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "IntlProvider"
}, ReactIntlConsumer.__docgenInfo = {
  componentName: "ReactIntlConsumer",
  packageName: "@jutro/locale",
  description: "",
  displayName: "ReactIntlConsumer",
  methods: [],
  actualName: "ReactIntlConsumer"
}, IntlProvider.__docgenInfo = {
  componentName: "IntlProvider",
  packageName: "@jutro/locale",
  description: "",
  displayName: "IntlProvider",
  methods: [],
  actualName: "IntlProvider"
};