import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useMemo, useRef, useEffect, useState, useLayoutEffect } from "react";
import isNumber from "lodash/isNumber";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import isNull from "lodash/isNull";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { Tag } from "./Tag";
import styles from "./TagCollection.module.css";
import { messages } from "./Tag.messages";
var getTypeOfChild = function (child) {
  return isArray(child) ? "array" : isPlainObject(child) ? "object" : isNull(child) ? "null" : "other";
};
var getStyleProps = function (child) {
  return {
    className: cx(child.className, styles.collectionTag),
    iconWrapperClassName: cx(child.iconWrapperClassName, styles.tagButton),
    labelClassName: cx(child.labelClassName, styles.tagLabel)
  };
};
export var TagCollection = function (_ref2) {
  var id = _ref2.id,
    defaultGroupThreshold = _ref2.groupThreshold,
    defaultExpanded = _ref2.expanded,
    className = _ref2.className,
    children = _ref2.children,
    translator = useTranslator(),
    _getChildrenTags = function (children) {
      var childrenArray = React.Children.toArray(children),
        optionsMenu = childrenArray[childrenArray.length - 1];
      return [_sliceInstanceProperty(childrenArray).call(childrenArray, 0, childrenArray.length - 1), optionsMenu];
    }(children),
    _getChildrenTags2 = _slicedToArray(_getChildrenTags, 2),
    initialTags = _getChildrenTags2[0],
    optionsMenu = _getChildrenTags2[1],
    initialTagsLength = "array" === getTypeOfChild(children) ? initialTags.length : 0,
    ref = useRef(),
    minimumTagWidth = useRef(0),
    _useState = useState(defaultExpanded),
    _useState2 = _slicedToArray(_useState, 2),
    expanded = _useState2[0],
    setExpanded = _useState2[1],
    _useState3 = useState(null),
    _useState4 = _slicedToArray(_useState3, 2),
    maxWidth = _useState4[0],
    setMaxWidth = _useState4[1],
    _useState5 = useState(0),
    _useState6 = _slicedToArray(_useState5, 2),
    tagsLength = _useState6[0],
    setTagsLength = _useState6[1],
    _useState7 = useState(0),
    _useState8 = _slicedToArray(_useState7, 2),
    groupThreshold = _useState8[0],
    setGroupThreshold = _useState8[1],
    _useState9 = useState(getTypeOfChild(children[0])),
    _useState10 = _slicedToArray(_useState9, 2),
    typeOfFirstChild = _useState10[0],
    setTypeOfFirstChild = _useState10[1],
    isMaxWidthNull = useMemo(function () {
      return null === maxWidth;
    }, [maxWidth]);
  useEffect(function () {
    var _ref$current;
    if (!isMaxWidthNull) return initialTagsLength !== tagsLength && (setTagsLength(initialTagsLength), setGroupThreshold(0)), getTypeOfChild(children[0]) !== typeOfFirstChild && setTypeOfFirstChild(getTypeOfChild(children[0])), void (null !== (_ref$current = ref.current) && void 0 !== _ref$current && _ref$current.clientWidth && ref.current.clientWidth < maxWidth && setMaxWidth(ref.current.clientWidth));
    isMaxWidthNull && (setTagsLength(initialTagsLength), setMaxWidth(ref.current.clientWidth));
  }, [isMaxWidthNull, children, typeOfFirstChild, expanded]), useLayoutEffect(function () {
    var _ref$current2, _ref$current2$childre;
    if (null !== (_ref$current2 = ref.current) && void 0 !== _ref$current2 && null !== (_ref$current2$childre = _ref$current2.children) && void 0 !== _ref$current2$childre && _ref$current2$childre.length && !isMaxWidthNull && tagsLength === initialTagsLength) {
      minimumTagWidth.current = minimumTagWidth.current || function (children, max) {
        var _context,
          _context2,
          minimum = _reduceInstanceProperty(_context = _sliceInstanceProperty(_context2 = _Object$values(children)).call(_context2, 0, children.length - 1)).call(_context, function (min, _ref) {
            var clientWidth = _ref.clientWidth;
            return clientWidth < min ? clientWidth : min;
          }, max);
        return minimum === max ? 0 : minimum;
      }(ref.current.children, maxWidth);
      var divider = initialTagsLength;
      expanded || (divider = function (tags, currentRef, maxWidth, minimumTagWidth, maxBoundary) {
        var remainingWidth = maxWidth,
          divider = _findIndexInstanceProperty(tags).call(tags, function (tag, elementIndex) {
            var _currentRef$children,
              indexOfTag = _indexOfInstanceProperty(tags).call(tags, tag),
              childWidth = null === (_currentRef$children = currentRef.children[tags.length - indexOfTag - 1]) || void 0 === _currentRef$children ? void 0 : _currentRef$children.clientWidth;
            return !!(remainingWidth - childWidth - minimumTagWidth.current < 0 || isNumber(maxBoundary) && elementIndex >= maxBoundary - 1) || (remainingWidth -= childWidth, !1);
          });
        return -1 === divider ? tags.length : divider;
      }(initialTags, ref.current, maxWidth, minimumTagWidth, defaultGroupThreshold)), setTagsLength(divider), setGroupThreshold(initialTagsLength - divider);
    }
  }, [children, expanded, maxWidth, tagsLength, typeOfFirstChild, initialTagsLength]);
  var _useMemo = useMemo(function () {
      var _initialTags$, _initialTags$$props, _context3, _context5;
      if (isMaxWidthNull) return [[], !1];
      var tagsToClone = _sliceInstanceProperty(initialTags).call(initialTags, groupThreshold, initialTagsLength),
        childrenToClone = initialTagsLength > 0 ? tagsToClone : initialTags,
        disabledCheck = null === (_initialTags$ = initialTags[0]) || void 0 === _initialTags$ || null === (_initialTags$$props = _initialTags$.props) || void 0 === _initialTags$$props ? void 0 : _initialTags$$props.isDisabled,
        listOfTags = _mapInstanceProperty(_context3 = React.Children).call(_context3, childrenToClone, function (child, childIndex) {
          var _context4,
            flippedIndex = childrenToClone.length - childIndex - 1,
            newProps = _objectSpread(_objectSpread({}, child.props), {}, {
              id: _concatInstanceProperty(_context4 = "".concat(id, "_")).call(_context4, flippedIndex)
            }, getStyleProps(child));
          return React.cloneElement(child, newProps);
        }),
        updatedOptionsMenu = React.cloneElement(optionsMenu, _objectSpread(_objectSpread({}, optionsMenu.props), getStyleProps(optionsMenu)));
      return [_concatInstanceProperty(_context5 = []).call(_context5, _toConsumableArray(listOfTags), [updatedOptionsMenu]), disabledCheck];
    }, [styles.collectionTag, tagsLength, isMaxWidthNull, typeOfFirstChild, children, groupThreshold, id]),
    _useMemo2 = _slicedToArray(_useMemo, 2),
    tagsList = _useMemo2[0],
    isDisabled = _useMemo2[1];
  return React.createElement("span", {
    ref: ref,
    className: cx(styles.collection, _defineProperty({}, styles.expanded, expanded), className)
  }, groupThreshold > 0 ? React.createElement(Tag, {
    id: "".concat(id, "_expandButton"),
    disabled: isDisabled,
    label: "+".concat(groupThreshold),
    ariaLabel: translator(messages.expandLabel, {
      items: groupThreshold
    }),
    className: cx(styles.collectionTag),
    icon: "gw-chevron-right",
    onClick: function () {
      return setExpanded(!0);
    },
    iconWrapperClassName: styles.tagButton,
    labelClassName: styles.tagLabel
  }) : null, tagsList);
};
TagCollection.propTypes = {
  id: PropTypes.string,
  expanded: PropTypes.bool,
  groupThreshold: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node
}, TagCollection.__docgenInfo = {
  description: "TagCollection\n@type {React.FC<TagCollectionProps>}",
  methods: [],
  displayName: "TagCollection",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "id of the wrapping component"
    },
    expanded: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Render an expanded collection on initial component load"
    },
    groupThreshold: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Default state for the number of visible tags, additional items will be represented as single tag (like `+12`)"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the tag collection"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Tag components"
    }
  }
}, TagCollection.__docgenInfo = {
  componentName: "TagCollection",
  packageName: "@jutro/components",
  description: "TagCollection",
  displayName: "TagCollection",
  methods: [],
  actualName: "TagCollection",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "id of the wrapping component"
    },
    expanded: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Render an expanded collection on initial component load"
    },
    groupThreshold: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Default state for the number of visible tags, additional items will be represented as single tag (like `+12`)"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the tag collection"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Tag components"
    }
  }
};