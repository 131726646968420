import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React from "react";
import { getFileName, TYPE_THIN } from "../utils";
import { UploadingProgress } from "../UploadingProgress/UploadingProgress";
import { LargeUploadArea } from "./LargeUploadArea";
import { messages as progressMessages } from "../UploadingProgress/UploadingProgress.messages";
import styles from "../FileUploadField.module.css";
export var UploadedArea = function (_ref) {
  var value = _ref.value,
    messageProps = _ref.messageProps,
    type = _ref.type,
    imageSource = _ref.imageSource,
    buttonType = _ref.buttonType,
    buttonSize = _ref.buttonSize,
    buttonIcon = _ref.buttonIcon,
    id = _ref.id,
    _ref$uploadState = _ref.uploadState,
    uploadState = void 0 === _ref$uploadState ? "selection" : _ref$uploadState,
    onCancel = _ref.onCancel,
    completed = _ref.completed,
    total = _ref.total,
    onFileClear = _ref.onFileClear,
    renderValidationMessages = _ref.renderValidationMessages,
    showExplorerDialog = _ref.showExplorerDialog,
    actionHandle = "progress" !== uploadState ? function () {
      return null == onFileClear ? void 0 : onFileClear("string" == typeof value ? value : value.name);
    } : function () {
      return null == onCancel ? void 0 : onCancel(value);
    },
    fileName = getFileName(value),
    progress = React.createElement(UploadingProgress, {
      variant: "single",
      id: id,
      filename: fileName,
      messageProps: messageProps,
      state: uploadState,
      completed: completed,
      total: total,
      onActionClick: actionHandle,
      onActionKeyDown: function (e) {
        var _context;
        e.preventDefault(), _includesInstanceProperty(_context = [" ", "Spacebar", "Enter"]).call(_context, e.key) && actionHandle();
      },
      renderMessages: renderValidationMessages
    });
  return type === TYPE_THIN ? progress : React.createElement(React.Fragment, null, React.createElement("div", {
    className: styles.overlay
  }, progress), React.createElement(LargeUploadArea, {
    imageSource: imageSource,
    disabled: !0,
    messageProps: _objectSpread(_objectSpread({}, messageProps), {}, {
      uploadFilesMessage: progressMessages.backgroundUploadFileMessage
    }),
    buttonType: buttonType,
    buttonSize: buttonSize,
    buttonIcon: buttonIcon,
    onAction: value ? onFileClear : showExplorerDialog,
    variant: "uploaded",
    fileName: fileName
  }));
};
UploadedArea.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "UploadedArea",
  props: {
    uploadState: {
      defaultValue: {
        value: "'selection'",
        computed: !1
      },
      required: !1
    }
  }
}, UploadedArea.__docgenInfo = {
  componentName: "UploadedArea",
  packageName: "@jutro/components",
  description: "",
  displayName: "UploadedArea",
  methods: [],
  actualName: "UploadedArea",
  props: {
    uploadState: {
      defaultValue: {
        value: "'selection'",
        computed: !1
      },
      required: !1
    }
  }
};