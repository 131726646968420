import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["columns", "repeat", "rows", "autoRows", "gap", "vgap", "hgap", "justifyContent", "valignContent", "justifyItems", "valignItems", "tag", "children", "className", "blockPointerEvents", "style", "dangerouslySetInnerHTML"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _repeatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/repeat";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import mapValues from "lodash/mapValues";
import map from "lodash/fp/map";
import styles from "./Grid.module.css";
import { useBreakpoint } from "../breakpoint/useBreakpoint";
var mapToFraction = map(function (el) {
  return Number.isNaN(Number(el)) ? el : "".concat(el, "fr");
});
export var gaps = {
  none: "None",
  small: "Small",
  medium: "Medium",
  large: "Large"
};
var vgapMapping = mapValues(gaps, function (type) {
    return styles["vgap".concat(type)];
  }),
  hgapMapping = mapValues(gaps, function (type) {
    return styles["hgap".concat(type)];
  }),
  alignments = {
    top: "Start",
    middle: "Center",
    bottom: "End",
    baseline: "Baseline",
    stretch: "Stretch"
  };
export var gridAlignments = _Object$keys(alignments);
var valignItemsMapping = mapValues(alignments, function (type) {
    return styles["alignItems".concat(type)];
  }),
  valignContentMapping = mapValues(alignments, function (type) {
    return styles["alignContent".concat(type)];
  }),
  justifications = {
    left: "Start",
    center: "Center",
    right: "End",
    around: "Around",
    between: "Between",
    evenly: "Evenly",
    stretch: "Stretch"
  },
  justifyItemsMapping = mapValues(justifications, function (type) {
    return styles["justifyItems".concat(type)];
  }),
  justifyContentMapping = mapValues(justifications, function (type) {
    return styles["justifyContent".concat(type)];
  });
export var Grid = React.forwardRef(function (props, ref) {
  var gridColumns,
    gridRows,
    gridAutoRows,
    breakpointProps = useBreakpoint(props).breakpointProps,
    columnsProp = breakpointProps.columns,
    repeat = _repeatInstanceProperty(breakpointProps),
    rowsProp = breakpointProps.rows,
    autoRows = breakpointProps.autoRows,
    gap = breakpointProps.gap,
    _breakpointProps$vgap = breakpointProps.vgap,
    vgap = void 0 === _breakpointProps$vgap ? gap : _breakpointProps$vgap,
    _breakpointProps$hgap = breakpointProps.hgap,
    hgap = void 0 === _breakpointProps$hgap ? gap : _breakpointProps$hgap,
    justifyContent = breakpointProps.justifyContent,
    valignContent = breakpointProps.valignContent,
    justifyItems = breakpointProps.justifyItems,
    valignItems = breakpointProps.valignItems,
    tag = breakpointProps.tag,
    children = breakpointProps.children,
    className = breakpointProps.className,
    blockPointerEvents = breakpointProps.blockPointerEvents,
    style = breakpointProps.style,
    other = (breakpointProps.dangerouslySetInnerHTML, _objectWithoutProperties(breakpointProps, _excluded)),
    columns = mapToFraction(columnsProp),
    rows = mapToFraction(rowsProp),
    vgapClass = vgap && vgapMapping[vgap],
    hgapClass = hgap && hgapMapping[hgap],
    valignContentClass = valignContent && valignContentMapping[valignContent],
    justifyContentClass = justifyContent && justifyContentMapping[justifyContent],
    valignItemsClass = valignItems && valignItemsMapping[valignItems],
    justifyItemsClass = justifyItems && justifyItemsMapping[justifyItems],
    classes = cx(styles.grid, vgapClass, hgapClass, valignContentClass, justifyContentClass, valignItemsClass, justifyItemsClass, className),
    hasHiddenColumns = !1;
  if (columns) if (repeat) {
    var _context;
    gridColumns = _concatInstanceProperty(_context = "repeat(".concat(repeat, ", ")).call(_context, columns.join(" "));
  } else {
    var filteredColumns = _filterInstanceProperty(columns).call(columns, function (column) {
      return "0fr" !== column;
    });
    gridColumns = filteredColumns.join(" "), hasHiddenColumns = columns.length !== filteredColumns.length;
  }
  rows && (gridRows = rows.join(" ")), autoRows && (gridAutoRows = autoRows.join(" "));
  var inlineStyles = function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = null != arguments[i] ? arguments[i] : {};
      i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
        _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
      });
    }
    return target;
  }({}, style);
  (gridColumns || gridRows || gridAutoRows) && (gridColumns && (inlineStyles.gridTemplateColumns = gridColumns), gridRows && (inlineStyles.gridTemplateRows = gridRows), gridAutoRows && (inlineStyles.gridAutoRows = gridAutoRows), blockPointerEvents && (inlineStyles.pointerEvents = "none"));
  var realChildren = children;
  if (hasHiddenColumns && columns) {
    var _context2,
      colLength = columns.length;
    realChildren = _mapInstanceProperty(_context2 = React.Children).call(_context2, children, function (child, index) {
      return "0fr" !== columns[index % colLength] ? child : null;
    });
  }
  var Tag = tag;
  return React.createElement(Tag, _extends({
    className: classes,
    style: inlineStyles
  }, other, {
    ref: ref
  }), realChildren);
});
export var gridPropTypes = {
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired),
  repeat: PropTypes.oneOfType([PropTypes.oneOf(["auto-fit", "auto-fill"]), PropTypes.number, PropTypes.string]),
  rows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired),
  autoRows: PropTypes.array,
  gap: PropTypes.oneOf(_Object$keys(gaps)),
  hgap: PropTypes.oneOf(_Object$keys(gaps)),
  vgap: PropTypes.oneOf(_Object$keys(gaps)),
  valignContent: PropTypes.oneOf(gridAlignments),
  justifyContent: PropTypes.oneOf(_Object$keys(justifications)),
  valignItems: PropTypes.oneOf(gridAlignments),
  justifyItems: PropTypes.oneOf(_Object$keys(justifications)),
  tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  blockPointerEvents: PropTypes.bool
};
Grid.propTypes = gridPropTypes, Grid.defaultProps = {
  gap: "medium",
  tag: "div"
}, Grid.displayName = "Grid", Grid.__docgenInfo = {
  description: "Renders a css grid. For each grid item, place its children in an Jutro `GridItem`.\n\n@metadataType layout",
  methods: [],
  displayName: "Grid",
  props: {
    gap: {
      defaultValue: {
        value: "'medium'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: '"none"',
          computed: !1
        }, {
          value: '"small"',
          computed: !1
        }, {
          value: '"medium"',
          computed: !1
        }, {
          value: '"large"',
          computed: !1
        }]
      },
      required: !1,
      description: "Gap between rows and columns ('none', 'small', 'medium', 'large')"
    },
    tag: {
      defaultValue: {
        value: "'div'",
        computed: !1
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Optional dom tag to render"
    },
    columns: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !1,
      description: "Define explicit column widths"
    },
    repeat: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'auto-fit'",
            computed: !1
          }, {
            value: "'auto-fill'",
            computed: !1
          }]
        }, {
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Repeat columns"
    },
    rows: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !1,
      description: "Define explicit row heights"
    },
    autoRows: {
      type: {
        name: "array"
      },
      required: !1,
      description: "Define implicit rows"
    },
    hgap: {
      type: {
        name: "enum",
        value: [{
          value: '"none"',
          computed: !1
        }, {
          value: '"small"',
          computed: !1
        }, {
          value: '"medium"',
          computed: !1
        }, {
          value: '"large"',
          computed: !1
        }]
      },
      required: !1,
      description: "Gap between columns ('none', 'small', 'medium', 'large').\nIf unspecified, falls back to the 'gap' property."
    },
    vgap: {
      type: {
        name: "enum",
        value: [{
          value: '"none"',
          computed: !1
        }, {
          value: '"small"',
          computed: !1
        }, {
          value: '"medium"',
          computed: !1
        }, {
          value: '"large"',
          computed: !1
        }]
      },
      required: !1,
      description: "Gap between rows ('none', 'small', 'medium', 'large').\nIf unspecified, falls back to the 'gap' property."
    },
    valignContent: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Vertical align the grid within its container; default - 'stretch'"
    },
    justifyContent: {
      type: {
        name: "enum",
        value: [{
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"around"',
          computed: !1
        }, {
          value: '"between"',
          computed: !1
        }, {
          value: '"evenly"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Justify the grid within its container (left, center, right, around, between, evenly)"
    },
    valignItems: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Vertical align all items within the grid; default - 'stretch'"
    },
    justifyItems: {
      type: {
        name: "enum",
        value: [{
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"around"',
          computed: !1
        }, {
          value: '"between"',
          computed: !1
        }, {
          value: '"evenly"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Justify all items within the grid (left, center, right, around, between, evenly)"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class(es) to add to the grid tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children; preferably 'GridItem'; works with any child"
    },
    style: {
      type: {
        name: "object"
      },
      required: !1,
      description: "DOM element styles"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Grid property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Grid property for use at the 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Grid property for use at the 'tablet' and 'phone' breakpoint"
    },
    blockPointerEvents: {
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    }
  }
}, Grid.__docgenInfo = {
  componentName: "Grid",
  packageName: "@jutro/layout",
  description: "Renders a css grid. For each grid item, place its children in an Jutro `GridItem`.",
  displayName: "Grid",
  methods: [],
  actualName: "Grid",
  metadataType: "layout",
  props: {
    columns: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !1,
      description: "Define explicit column widths"
    },
    repeat: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'auto-fit'",
            computed: !1
          }, {
            value: "'auto-fill'",
            computed: !1
          }]
        }, {
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Repeat columns"
    },
    rows: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !1,
      description: "Define explicit row heights"
    },
    autoRows: {
      type: {
        name: "array"
      },
      required: !1,
      description: "Define implicit rows"
    },
    gap: {
      type: {
        name: "enum",
        value: [{
          value: '"none"',
          computed: !1
        }, {
          value: '"small"',
          computed: !1
        }, {
          value: '"medium"',
          computed: !1
        }, {
          value: '"large"',
          computed: !1
        }]
      },
      required: !1,
      description: "Gap between rows and columns ('none', 'small', 'medium', 'large')",
      defaultValue: {
        value: "'medium'",
        computed: !1
      }
    },
    hgap: {
      type: {
        name: "enum",
        value: [{
          value: '"none"',
          computed: !1
        }, {
          value: '"small"',
          computed: !1
        }, {
          value: '"medium"',
          computed: !1
        }, {
          value: '"large"',
          computed: !1
        }]
      },
      required: !1,
      description: "Gap between columns ('none', 'small', 'medium', 'large').\nIf unspecified, falls back to the 'gap' property."
    },
    vgap: {
      type: {
        name: "enum",
        value: [{
          value: '"none"',
          computed: !1
        }, {
          value: '"small"',
          computed: !1
        }, {
          value: '"medium"',
          computed: !1
        }, {
          value: '"large"',
          computed: !1
        }]
      },
      required: !1,
      description: "Gap between rows ('none', 'small', 'medium', 'large').\nIf unspecified, falls back to the 'gap' property."
    },
    valignContent: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Vertical align the grid within its container; default - 'stretch'"
    },
    justifyContent: {
      type: {
        name: "enum",
        value: [{
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"around"',
          computed: !1
        }, {
          value: '"between"',
          computed: !1
        }, {
          value: '"evenly"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Justify the grid within its container (left, center, right, around, between, evenly)"
    },
    valignItems: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Vertical align all items within the grid; default - 'stretch'"
    },
    justifyItems: {
      type: {
        name: "enum",
        value: [{
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"around"',
          computed: !1
        }, {
          value: '"between"',
          computed: !1
        }, {
          value: '"evenly"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Justify all items within the grid (left, center, right, around, between, evenly)"
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Optional dom tag to render",
      defaultValue: {
        value: "'div'",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class(es) to add to the grid tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children; preferably 'GridItem'; works with any child"
    },
    style: {
      type: {
        name: "object"
      },
      required: !1,
      description: "DOM element styles"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Grid property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Grid property for use at the 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Grid property for use at the 'tablet' and 'phone' breakpoint"
    },
    blockPointerEvents: {
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    }
  }
};