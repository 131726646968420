import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import cx from "classnames";
import { intlMessageShape } from "@jutro/prop-types";
import { defaultTheme } from "@jutro/theme";
import styles from "./OldFieldMessage.module.css";
export var OldFieldMessage = function (_PureComponent) {
  _inherits(OldFieldMessage, PureComponent);
  var _super = _createSuper(OldFieldMessage);
  function OldFieldMessage() {
    return _classCallCheck(this, OldFieldMessage), _super.apply(this, arguments);
  }
  return _createClass(OldFieldMessage, [{
    key: "render",
    value: function () {
      var _cx,
        _this$props = this.props,
        id = _this$props.id,
        translator = _this$props.translator,
        errorMessage = _this$props.errorMessage,
        successMessage = _this$props.successMessage,
        classes = cx(styles.fieldMessage, (_defineProperty(_cx = {}, styles.error, errorMessage), _defineProperty(_cx, styles.success, !errorMessage && successMessage), _cx)),
        message = errorMessage || successMessage;
      return React.createElement("div", {
        id: id,
        key: id,
        className: classes,
        role: "alert",
        "aria-hidden": !message
      }, isFunction(message) ? message() : translator(message));
    }
  }]), OldFieldMessage;
}();
_defineProperty(OldFieldMessage, "propTypes", {
  id: PropTypes.string.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.func, intlMessageShape]),
  successMessage: PropTypes.oneOfType([PropTypes.func, intlMessageShape]),
  theme: PropTypes.object,
  translator: PropTypes.func
}), _defineProperty(OldFieldMessage, "defaultProps", {
  theme: defaultTheme
}), OldFieldMessage.__docgenInfo = {
  description: "Class renders messages for `FieldComponent`.\nValidation messages are expected to be translated before they are passed.",
  methods: [],
  displayName: "OldFieldMessage",
  props: {
    theme: {
      defaultValue: {
        value: "defaultTheme",
        computed: !0
      },
      type: {
        name: "object"
      },
      required: !1,
      description: "Theme to apply to the component"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    errorMessage: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "Error message to display"
    },
    successMessage: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "Success message to display"
    },
    translator: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The function used to translate strings"
    }
  }
}, OldFieldMessage.__docgenInfo = {
  componentName: "OldFieldMessage",
  packageName: "@jutro/components",
  description: "Class renders messages for `FieldComponent`.\nValidation messages are expected to be translated before they are passed.",
  displayName: "OldFieldMessage",
  methods: [],
  actualName: "OldFieldMessage",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    errorMessage: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "Error message to display"
    },
    successMessage: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "Success message to display"
    },
    theme: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Theme to apply to the component",
      defaultValue: {
        value: "defaultTheme",
        computed: !0
      }
    },
    translator: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The function used to translate strings"
    }
  }
};