import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _sortInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/sort";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useState, useCallback } from "react";
import cx from "classnames";
import { getMessageProp } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import size from "lodash/size";
import styles from "./InlineNotification.module.css";
import { Icon } from "../Icon/Icon";
import { IconButton } from "../IconButton/IconButton";
import { ToggleField } from "../inputs/ToggleField/ToggleField";
import { messages as genericMessages } from "./InlineNotification.messages";
import { getKeyPressHandler } from "../../accessibility/getKeyPressHandler";
import { Button } from "../button/Button";
import { Link } from "../Link/Link";
import { styleIconWrapperByType, stylesNotificationByType } from "./InlineNotificationStyleValues";
var TypeIconMap = {
    warning: "gw-warning",
    error: "gw-error",
    info: "gw-info",
    success: "gw-check-circle"
  },
  severityToggleButtons = {
    error: {
      code: "error",
      icon: "gw-error",
      name: "Error",
      iconSize: "medium"
    },
    warning: {
      code: "warning",
      icon: "gw-warning",
      name: "Warning",
      iconSize: "medium"
    },
    success: {
      code: "success",
      icon: "gw-check-circle",
      name: "Success",
      iconSize: "medium"
    },
    info: {
      code: "info",
      icon: "gw-info",
      name: "Info",
      iconSize: "medium"
    }
  },
  severityOrder = {
    all: 1,
    error: 2,
    warning: 3,
    success: 4,
    info: 5
  },
  scrollAndFocus = function (fieldId) {
    var element = document.querySelector("[id^=".concat(fieldId, "]"));
    element && (element.focus({
      preventScroll: !0
    }), element.scrollIntoView({
      behavior: "smooth",
      block: "center"
    }));
  },
  getRemainingMessagesCount = function (messageObject) {
    var _context;
    return isUndefined(messageObject) || isEmpty(messageObject) ? 0 : _reduceInstanceProperty(_context = _Object$values(messageObject)).call(_context, function (acc, cur) {
      return acc + (isEmpty(cur) ? 0 : 1);
    }, 0);
  };
export var InlineNotificationBundle = function (_ref) {
  var messageObject,
    _context8,
    id = _ref.id,
    messages = _ref.messages,
    handleDismiss = _ref.handleDismiss,
    isDismissable = _ref.isDismissable,
    isEmbeddedNotification = _ref.isEmbeddedNotification,
    messageProps = _ref.messageProps,
    _ref$isBundleOpenByDe = _ref.isBundleOpenByDefault,
    isBundleOpenByDefault = void 0 !== _ref$isBundleOpenByDe && _ref$isBundleOpenByDe,
    translator = useTranslator(),
    highestSeverity = isEmpty((messageObject = messages).error) ? isEmpty(messageObject.warning) ? isEmpty(messageObject.success) ? isEmpty(messageObject.info) ? void 0 : {
      messages: messageObject.info,
      type: "info"
    } : {
      messages: messageObject.success,
      type: "success"
    } : {
      messages: messageObject.warning,
      type: "warning"
    } : {
      messages: messageObject.error,
      type: "error"
    },
    _useState = useState(isBundleOpenByDefault),
    _useState2 = _slicedToArray(_useState, 2),
    showAll = _useState2[0],
    setShowAll = _useState2[1],
    _useState3 = useState(null == highestSeverity ? void 0 : highestSeverity.type),
    _useState4 = _slicedToArray(_useState3, 2),
    severityToggleButton = _useState4[0],
    setSeverityToggleButton = _useState4[1],
    messageCount = null != highestSeverity && highestSeverity.messages ? _Object$values(highestSeverity.messages).length : 0,
    classes = cx(styles.inlineNotification, (null == highestSeverity ? void 0 : highestSeverity.type) && stylesNotificationByType[null == highestSeverity ? void 0 : highestSeverity.type]),
    renderTitleElement = useCallback(function () {
      var key,
        highestSeverityMessageKey = "error" === (key = null == highestSeverity ? void 0 : highestSeverity.type) ? "messagesCountError" : "warning" === key ? "messagesCountWarning" : "info" === key ? "messagesCountInfo" : "success" === key ? "messagesCountSuccess" : void 0;
      if (highestSeverityMessageKey) return React.createElement("span", {
        className: styles.messageDefault
      }, translator(genericMessages[highestSeverityMessageKey], {
        count: String(messageCount)
      }));
    }, [null == highestSeverity ? void 0 : highestSeverity.type, messageCount, translator]),
    getSeverityToggleButtons = useCallback(function (bundledMessages) {
      var _context3,
        severityButtonsValues = _reduceInstanceProperty(_context3 = _Object$entries(bundledMessages)).call(_context3, function (acc, _ref2) {
          var _context4,
            _ref3 = _slicedToArray(_ref2, 2),
            messageType = _ref3[0],
            messagesValues = _ref3[1];
          return isEmpty(messagesValues) ? _toConsumableArray(acc) : _concatInstanceProperty(_context4 = []).call(_context4, _toConsumableArray(acc), [_objectSpread(_objectSpread({}, severityToggleButtons[messageType]), {}, {
            name: size(messagesValues),
            id: "noti-toggle-button".concat(messageType)
          })]);
        }, [{
          code: "all",
          name: translator(genericMessages.all),
          id: "noti-toggle-button-all"
        }]);
      return _sortInstanceProperty(severityButtonsValues).call(severityButtonsValues, function (a, b) {
        return severityOrder[a.code] - severityOrder[b.code];
      });
    }, [translator]),
    renderSeverityMessageCard = useCallback(function (type) {
      var _context5,
        messageByType = messages[type];
      if (messageByType) return _mapInstanceProperty(_context5 = _Object$entries(messageByType)).call(_context5, function (_ref4) {
        var _val$,
          _ref5 = _slicedToArray(_ref4, 2),
          fieldIdentifier = _ref5[0],
          val = _ref5[1],
          fieldId = null === (_val$ = val[0]) || void 0 === _val$ ? void 0 : _val$.fieldId;
        return React.createElement("div", {
          className: styles.messageCard,
          key: fieldIdentifier
        }, React.createElement("span", {
          className: cx(styles.iconWrapper, styleIconWrapperByType[type])
        }, React.createElement(Icon, {
          icon: TypeIconMap[type],
          size: "medium",
          "aria-label": translator(type)
        })), React.createElement("div", {
          className: styles.message
        }, fieldId ? React.createElement(Link, {
          to: "/#".concat(fieldId),
          onClick: function () {
            return scrollAndFocus(fieldId);
          }
        }, translator(fieldIdentifier)) : React.createElement("span", {
          className: cx(_defineProperty({}, styles.messageSuccessInfo, "success" === type || "info" === type))
        }, translator(fieldIdentifier)), _mapInstanceProperty(val).call(val, function (_ref6, indx) {
          var message = _ref6.message;
          return React.createElement("div", {
            key: indx
          }, translator(message));
        })));
      });
    }, [messages, translator]),
    renderMessages = useCallback(function () {
      var _context6, _context7;
      return "all" === severityToggleButton ? _mapInstanceProperty(_context6 = _sortInstanceProperty(_context7 = _Object$keys(messages)).call(_context7, function (a, b) {
        return severityOrder[a] - severityOrder[b];
      })).call(_context6, function (type) {
        return renderSeverityMessageCard(type);
      }) : (function (messageObject) {
        var _context2;
        return isUndefined(messageObject) || isEmpty(messageObject) ? 0 : _reduceInstanceProperty(_context2 = _Object$keys(messageObject)).call(_context2, function (accu, keyName) {
          var messageDetails = messageObject[keyName];
          return accu + (Array.isArray(messageDetails) ? messageDetails.length : 1);
        }, 0);
      }(messages[severityToggleButton]) || setSeverityToggleButton(null == highestSeverity ? void 0 : highestSeverity.type), renderSeverityMessageCard(severityToggleButton));
    }, [null == highestSeverity ? void 0 : highestSeverity.type, messages, renderSeverityMessageCard, severityToggleButton]),
    renderHeaderContent = useCallback(function () {
      if (1 === messageCount && 1 === getRemainingMessagesCount(messages) && null != highestSeverity && highestSeverity.messages) {
        var _notificationContent$,
          _notificationContent$2,
          notificationContent = _Object$values(highestSeverity.messages)[0],
          notificationLabel = _Object$keys(highestSeverity.messages)[0],
          notificationType = null === (_notificationContent$ = notificationContent[0]) || void 0 === _notificationContent$ ? void 0 : _notificationContent$.type,
          fieldId = null === (_notificationContent$2 = notificationContent[0]) || void 0 === _notificationContent$2 ? void 0 : _notificationContent$2.fieldId,
          labelStyle = "success" === notificationType || "info" === notificationType ? styles.messageSuccessInfo : styles.singleErrorMessage;
        return React.createElement(React.Fragment, null, fieldId ? React.createElement(Link, {
          to: "/#".concat(fieldId),
          onClick: function () {
            return scrollAndFocus(fieldId);
          },
          className: labelStyle
        }, translator(notificationLabel)) : React.createElement("span", {
          className: labelStyle
        }, translator(notificationLabel)), _mapInstanceProperty(notificationContent).call(notificationContent, function (val, indx) {
          return React.createElement("span", {
            key: indx
          }, translator(val.message));
        }));
      }
      return React.createElement(React.Fragment, null, renderTitleElement(), React.createElement(Button, {
        type: "text",
        className: styles.showAllButton,
        size: "small",
        onClick: function () {
          return setShowAll(!showAll);
        },
        iconPosition: "right",
        "aria-expanded": showAll,
        icon: showAll ? "gw-keyboard-arrow-up" : "gw-keyboard-arrow-down"
      }, showAll ? "Hide All" : "Show All"));
    }, [null == highestSeverity ? void 0 : highestSeverity.messages, messageCount, messages, renderTitleElement, showAll, translator]);
  return React.createElement("div", {
    id: id,
    className: cx(classes, styles.bundledMessages),
    "data-testid": "inlineNotificationBundleWrapper"
  }, React.createElement("div", {
    className: styles.notificationContainer
  }, React.createElement("span", {
    className: cx(styles.iconWrapper, (null == highestSeverity ? void 0 : highestSeverity.type) && styleIconWrapperByType[null == highestSeverity ? void 0 : highestSeverity.type]),
    "aria-hidden": "true"
  }, React.createElement(Icon, {
    icon: null != highestSeverity && highestSeverity.type ? TypeIconMap[highestSeverity.type] : "",
    size: "large"
  })), React.createElement("div", {
    className: styles.message
  }, renderHeaderContent())), showAll && messageCount > 0 && React.createElement(React.Fragment, null, getRemainingMessagesCount(messages) > 1 && React.createElement("div", {
    className: styles.toggleField,
    role: "navigation",
    "aria-label": translator(genericMessages.notificationSeverity)
  }, React.createElement(ToggleField, {
    id: "noti-toggle-".concat(id),
    value: severityToggleButton,
    availableValues: getSeverityToggleButtons(messages),
    onValueChange: function (value) {
      return setSeverityToggleButton(value);
    },
    buttonAriaLabels: _mapInstanceProperty(_context8 = getSeverityToggleButtons(messages)).call(_context8, function (_ref7) {
      var _context9,
        name = _ref7.name,
        code = _ref7.code;
      if ("all" === code) return translator(genericMessages.allAriaLabel);
      var translatedCode = translator(genericMessages[code]);
      return _concatInstanceProperty(_context9 = "".concat(translatedCode, " ")).call(_context9, name);
    })
  })), getRemainingMessagesCount(messages) < 2 && 1 === messageCount ? void 0 : React.createElement("div", null, renderMessages())), !isEmbeddedNotification && isDismissable && handleDismiss && React.createElement(IconButton, {
    icon: "gw-close",
    className: styles.dismissButton,
    iconClassName: styles.dismissButtonIcon,
    onClick: handleDismiss,
    ariaLabel: getMessageProp("dismiss", messageProps, genericMessages),
    onKeyPress: getKeyPressHandler(handleDismiss)
  }));
};
InlineNotificationBundle.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "InlineNotificationBundle",
  props: {
    isBundleOpenByDefault: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    }
  }
}, InlineNotificationBundle.__docgenInfo = {
  componentName: "InlineNotificationBundle",
  packageName: "@jutro/components",
  description: "",
  displayName: "InlineNotificationBundle",
  methods: [],
  actualName: "InlineNotificationBundle",
  props: {
    isBundleOpenByDefault: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    }
  }
};