import { datadogLogs } from "@datadog/browser-logs";
export default function loadDataDog(dataDogClientToken, dataDogservice, environment) {
  var jutroversion = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : "8.6.1",
    datadogSever = arguments.length > 4 ? arguments[4] : void 0;
  datadogLogs.init({
    clientToken: dataDogClientToken,
    site: datadogSever,
    env: "atmos-".concat(environment),
    forwardErrorsToLogs: !0,
    sampleRate: 100,
    version: jutroversion,
    service: dataDogservice
  });
}