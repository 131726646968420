import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["children"],
  _excluded2 = ["hasActions"],
  _excluded3 = ["cell", "renderCell", "renderEditCell", "getRowActions", "columnClassName"],
  _excluded4 = ["callback"];
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _sortInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/sort";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ReactTable, { ReactTableDefaults } from "react-table";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import get from "lodash/get";
import identity from "lodash/identity";
import flatten from "lodash/flatten";
import mapValues from "lodash/mapValues";
import debounce from "lodash/debounce";
import { sortWithLocale, useTranslator, useLanguage } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { useBreakpoint } from "@jutro/layout";
import cx from "classnames";
import { TransitionGroup } from "react-transition-group";
import { Chevron, AnimationGroup, getKeyPressHandler, RadioButton, CheckboxField } from "@jutro/components";
import { uniqueInnerId } from "@jutro/platform";
import { error, warning } from "@jutro/logger";
import { Pagination } from "./Pagination/Pagination";
import { SHOW_ALL_PAGES } from "./Pagination/utils";
import { TableBar } from "./TableBar";
import { defaultColumnFilter } from "./helper";
import { useExpansion } from "./state/expansion/useExpansion";
import { useSelection } from "./state/selection/useSelection";
import { useConfig } from "./state/config/useConfig";
import { useAsync } from "./state/async/useAsync";
import { useAccessibilityProps } from "./accessibility/useAccessibilityProps";
import { TableContext } from "./TableContext";
import { RadioColumn } from "./columns/RadioColumn";
import { ActionColumn } from "./columns/ActionColumn";
import { messages } from "./DataTable.messages";
import styles from "./DataTable.module.css";
import "react-table/react-table.css";
var nonConfigurableColumns = [RadioColumn, ActionColumn],
  textAlignMapping = {
    left: styles.textLeft,
    right: styles.textRight
  },
  expanderDefaults = _objectSpread(_objectSpread({}, ReactTableDefaults.expanderDefaults), {}, {
    width: 28
  }),
  AnimatedTrGroupComponent = function (_ref) {
    var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);
    return React.createElement(ReactTableDefaults.TrGroupComponent, props, React.createElement(TransitionGroup, {
      component: null
    }, children));
  },
  transitionGroupProps = ["onExited", "in", "appear", "enter", "exit"],
  AnimationResistantTrComponent = function (_ref2) {
    var hasActions = _ref2.hasActions,
      props = _objectWithoutProperties(_ref2, _excluded2),
      a11yProps = {};
    return hasActions && (a11yProps.tabIndex = 0, a11yProps.role = "button", a11yProps["aria-label"] = "row"), React.createElement(ReactTableDefaults.TrComponent, _extends({}, a11yProps, omit(props, transitionGroupProps)));
  },
  splitHeaderText = function (text) {
    return React.createElement("span", {
      className: styles.headerMultiline
    }, text);
  },
  renderNull = function () {
    return null;
  },
  ignoredClickTargets = ["INPUT", "BUTTON", "A", "LABEL", "SELECT", "I"],
  handleNestedClick = function (event, callback) {
    var currentTarget = event.target;
    currentTarget && _includesInstanceProperty(ignoredClickTargets).call(ignoredClickTargets, currentTarget.tagName) || callback(event);
  },
  DEFAULT_CONFIG = {
    filterValue: "",
    page: 0,
    pageSize: 10
  },
  findById = function (id) {
    return function (_ref3) {
      var configId = _ref3.id;
      return id === configId;
    };
  },
  orderColumnsUsingConfig = function (config) {
    return function (columnA, columnB) {
      var aInitialIndex = _findIndexInstanceProperty(config).call(config, findById(columnA.props.id)),
        bInitialIndex = _findIndexInstanceProperty(config).call(config, findById(columnB.props.id));
      return -1 === aInitialIndex || -1 === bInitialIndex ? 0 : aInitialIndex - bInitialIndex;
    };
  },
  getAsyncParams = function (params) {
    return pick(params, ["page", "pageSize", "sorted", "filterValue"]);
  },
  getTbodyProps = function () {
    return {
      className: styles.tableBody
    };
  },
  dataTableConfigColumnsShape = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired
  })),
  dataTableConfigColumnsSortedShape = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    desc: PropTypes.bool.isRequired
  })),
  dataTableConfigShape = PropTypes.shape({
    editedRow: PropTypes.number,
    columns: dataTableConfigColumnsShape,
    filterValue: PropTypes.string,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    sorted: dataTableConfigColumnsSortedShape
  }),
  rowIdShape = PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTablePropTypes = {
    id: PropTypes.string.isRequired,
    showSearch: PropTypes.bool,
    showPagination: PropTypes.bool,
    config: dataTableConfigShape,
    defaultConfig: dataTableConfigShape,
    onConfigChange: PropTypes.func,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    data: PropTypes.arrayOf(PropTypes.object),
    onFilter: PropTypes.func,
    onColumnFilter: PropTypes.func,
    filterPlaceholder: intlMessageShape,
    strictSearch: PropTypes.bool,
    children: PropTypes.node.isRequired,
    expandable: PropTypes.bool,
    multipleRowsExpandable: PropTypes.bool,
    renderExpanderContent: PropTypes.func,
    headerMultiline: PropTypes.bool,
    onRowClick: PropTypes.func,
    expandOnRowClick: PropTypes.bool,
    selectOnRowClick: PropTypes.bool,
    selectionType: PropTypes.oneOf(["none", "single", "multi"]),
    selectedRows: PropTypes.arrayOf(rowIdShape),
    selectionActions: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired
    })),
    onSelectionChange: PropTypes.func,
    columnsConfigurable: PropTypes.bool,
    loadingText: intlMessageShape,
    noDataText: intlMessageShape,
    localSorting: PropTypes.bool,
    phone: PropTypes.object,
    phoneWide: PropTypes.object,
    tablet: PropTypes.object,
    onSaveRow: PropTypes.func,
    onEditCancel: PropTypes.func,
    onEditStart: PropTypes.func,
    path: PropTypes.string,
    onFetchData: PropTypes.func,
    onGetRowId: PropTypes.func,
    rowIdPath: PropTypes.string,
    tableLabel: intlMessageShape,
    numberOfRows: PropTypes.number
  };
export var DataTable = function (dataTableProps) {
  var _context12,
    _ref18,
    _useBreakpoint = useBreakpoint(dataTableProps, !0),
    breakpointProps = _useBreakpoint.breakpointProps,
    applyBreakpoint = _useBreakpoint.applyBreakpoint,
    breakpoint = _useBreakpoint.breakpoint,
    id = breakpointProps.id,
    showSearch = breakpointProps.showSearch,
    showPagination = breakpointProps.showPagination,
    pageSizeOptions = breakpointProps.pageSizeOptions,
    syncData = breakpointProps.data,
    onFilter = breakpointProps.onFilter,
    onColumnFilter = breakpointProps.onColumnFilter,
    defaultConfigProp = breakpointProps.defaultConfig,
    config = breakpointProps.config,
    filterPlaceholder = breakpointProps.filterPlaceholder,
    strictSearch = breakpointProps.strictSearch,
    children = breakpointProps.children,
    onConfigChange = breakpointProps.onConfigChange,
    onSaveRow = breakpointProps.onSaveRow,
    onEditCancel = breakpointProps.onEditCancel,
    onEditStart = breakpointProps.onEditStart,
    expandable = breakpointProps.expandable,
    multipleRowsExpandable = breakpointProps.multipleRowsExpandable,
    renderExpanderContent = breakpointProps.renderExpanderContent,
    basePath = breakpointProps.path,
    headerMultiline = breakpointProps.headerMultiline,
    onRowClick = breakpointProps.onRowClick,
    expandOnRowClick = breakpointProps.expandOnRowClick,
    selectOnRowClick = breakpointProps.selectOnRowClick,
    selectionType = breakpointProps.selectionType,
    onGetRowIdProp = breakpointProps.onGetRowId,
    rowIdPath = breakpointProps.rowIdPath,
    selectedRows = breakpointProps.selectedRows,
    selectionActions = breakpointProps.selectionActions,
    onSelectionChange = breakpointProps.onSelectionChange,
    onFetchData = breakpointProps.onFetchData,
    columnsConfigurable = breakpointProps.columnsConfigurable,
    loadingText = breakpointProps.loadingText,
    noDataText = breakpointProps.noDataText,
    localSorting = breakpointProps.localSorting,
    tableLabel = breakpointProps.tableLabel,
    numberOfRowsProp = breakpointProps.numberOfRows,
    defaultConfig = function (defaultConfig) {
      var previousRef = useRef(),
        previous = previousRef.current,
        next = useMemo(function () {
          return _objectSpread(_objectSpread({}, DEFAULT_CONFIG), defaultConfig);
        }, [defaultConfig]),
        areEqual = isEqual(previous, next);
      return useEffect(function () {
        areEqual || (previousRef.current = next);
      }), areEqual ? previous : next;
    }(defaultConfigProp),
    onGetRowId = useMemo(function () {
      return rowIdPath ? function (row) {
        return get(row, rowIdPath);
      } : onGetRowIdProp;
    }, [onGetRowIdProp, rowIdPath]),
    childrenArray = React.Children.toArray(children),
    reactTable = useRef(),
    shouldShowSearch = !1 !== showSearch,
    shouldShowPagination = !1 !== showPagination,
    shouldBeExpandable = expandable && !!renderExpanderContent,
    shouldBeSelectable = "none" !== selectionType,
    translator = useTranslator(),
    language = useLanguage().language,
    _useExpansion = useExpansion(),
    _useExpansion2 = _slicedToArray(_useExpansion, 2),
    expandedRows = _useExpansion2[0],
    _useExpansion2$ = _useExpansion2[1],
    collapse = _useExpansion2$.collapse,
    toggleExpansion = _useExpansion2$.toggle,
    collapseAll = _useExpansion2$.collapseAll,
    _useSelection = useSelection({
      all: !1,
      rows: selectedRows || []
    }, selectedRows, selectionType, onSelectionChange),
    _useSelection2 = _slicedToArray(_useSelection, 2),
    selectedState = _useSelection2[0],
    _useSelection2$ = _useSelection2[1],
    selectRow = _useSelection2$.select,
    toggleSelection = _useSelection2$.toggle,
    setAll = _useSelection2$.setAll,
    clearAll = _useSelection2$.clearAll,
    _useAsync = useAsync(),
    _useAsync2 = _slicedToArray(_useAsync, 2),
    _useAsync2$ = _useAsync2[0],
    asyncData = _useAsync2$.asyncData,
    loading = _useAsync2$.loading,
    totalNumberOfRows = _useAsync2$.numberOfRows,
    asyncActions = _useAsync2[1],
    data = onFetchData ? asyncData : syncData,
    getEditProps = function (rowId) {
      return void 0 !== rowId ? {
        rowId: rowId,
        row: _findInstanceProperty(data).call(data, function (row, index) {
          return onGetRowId(row, index) === rowId;
        })
      } : {};
    },
    _useState = useState(getEditProps((null == config ? void 0 : config.editedRow) || defaultConfig.editedRow)),
    _useState2 = _slicedToArray(_useState, 2),
    editProps = _useState2[0],
    setEditProps = _useState2[1],
    updateEditedRow = function (rowId) {
      return setEditProps(getEditProps(rowId));
    },
    initialColumnsConfig = useMemo(function () {
      var _config$columns,
        _context,
        initialConfig = null !== (_config$columns = null == config ? void 0 : config.columns) && void 0 !== _config$columns ? _config$columns : defaultConfig.columns,
        withInitialConfig = null == initialConfig ? void 0 : initialConfig.length;
      return childrenArray.length ? (withInitialConfig && _sortInstanceProperty(childrenArray).call(childrenArray, orderColumnsUsingConfig(initialConfig)), _filterInstanceProperty(_context = _mapInstanceProperty(childrenArray).call(childrenArray, function (childColumn) {
        var columnChildId = childColumn.props.id,
          type = childColumn.type;
        if (!_includesInstanceProperty(nonConfigurableColumns).call(nonConfigurableColumns, type)) {
          var columnConfig = {
            id: columnChildId,
            enabled: !0
          };
          if (withInitialConfig) {
            var initialColumnConfig = _findInstanceProperty(initialConfig).call(initialConfig, function (_ref4) {
              var configId = _ref4.id;
              return columnChildId === configId;
            });
            columnConfig.enabled = function () {
              var _config$enabled;
              return null === (_config$enabled = (arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}).enabled) || void 0 === _config$enabled || _config$enabled;
            }(initialColumnConfig);
          }
          return columnConfig;
        }
      })).call(_context, Boolean)) : [];
    }, [childrenArray, config, defaultConfig]),
    _useConfig = useConfig(_objectSpread(_objectSpread({}, defaultConfig), {}, {
      columns: initialColumnsConfig
    }), config && function () {
      return config.editedRow !== (null == editProps ? void 0 : editProps.rowId) && updateEditedRow(config.editedRow), _objectSpread(_objectSpread({}, config), {}, {
        columns: initialColumnsConfig
      });
    }, onConfigChange),
    _useConfig2 = _slicedToArray(_useConfig, 2),
    _useConfig2$ = _useConfig2[0],
    filterValue = _useConfig2$.filterValue,
    pageSizeSelectedOption = _useConfig2$.pageSize,
    page = _useConfig2$.page,
    sorted = _useConfig2$.sorted,
    columns = _useConfig2$.columns,
    editedRow = _useConfig2$.editedRow,
    _useConfig2$2 = _useConfig2[1],
    setFilterValue = _useConfig2$2.setFilterValue,
    setPageSize = _useConfig2$2.setPageSize,
    setPage = _useConfig2$2.setPage,
    setSorted = _useConfig2$2.setSorted,
    setColumns = _useConfig2$2.setColumns,
    setEditedRow = _useConfig2$2.setEditedRow,
    _useAccessibilityProp = useAccessibilityProps({
      selectionType: selectionType,
      isExpandable: expandable && renderExpanderContent,
      tableId: id,
      sorted: sorted,
      tableLabel: translator(tableLabel)
    }),
    getA11yTrGroupProps = _useAccessibilityProp.getA11yTrGroupProps,
    getA11yTrProps = _useAccessibilityProp.getA11yTrProps,
    getA11yTableProps = _useAccessibilityProp.getA11yTableProps,
    getA11yTheadThPropsHeader = _useAccessibilityProp.getA11yTheadThPropsHeader,
    getA11yTheadThPropsSortableHeader = _useAccessibilityProp.getA11yTheadThPropsSortableHeader,
    getA11yTdProps = _useAccessibilityProp.getA11yTdProps,
    isEdited = useCallback(function (rowId) {
      return editedRow === rowId;
    }, [editedRow]),
    editActions = {
      start: function (row, index) {
        var rowId = onGetRowId(row, index);
        onEditStart && onEditStart(row, rowId), setEditedRow(rowId), updateEditedRow(rowId), collapse(rowId);
      },
      update: function (_row, path, newValue) {
        setEditProps(function (oldEditProps) {
          return _objectSpread(_objectSpread({}, oldEditProps), {}, {
            row: _objectSpread(_objectSpread({}, oldEditProps.row), {}, _defineProperty({}, path, newValue))
          });
        });
      },
      cancel: function () {
        onEditCancel && onEditCancel(editProps.row, editProps.rowId), setEditedRow(), updateEditedRow();
      },
      save: function () {
        onSaveRow && onSaveRow(editProps.row, editProps.rowId), setEditedRow(), updateEditedRow();
      }
    },
    _useState3 = useState(null),
    _useState4 = _slicedToArray(_useState3, 2),
    pressedRowId = _useState4[0],
    setPressedRowId = _useState4[1],
    handleExpand = useCallback(function (rowId, viewIndex) {
      return !isEdited(rowId) && toggleExpansion(rowId, viewIndex, multipleRowsExpandable);
    }, [isEdited, multipleRowsExpandable, toggleExpansion]),
    filterFunction = useMemo(function () {
      return onFilter || function (value) {
        var valueFilters = _mapInstanceProperty(childrenArray).call(childrenArray, function (_ref5) {
          var props = _ref5.props;
          return (props.onFilter || onColumnFilter || defaultColumnFilter)(value, props, strictSearch);
        });
        return function (item, index, tableData) {
          return valueFilters.some(function (valueFilter) {
            return valueFilter(item, index, tableData);
          });
        };
      };
    }, [onFilter, childrenArray, onColumnFilter, strictSearch]),
    contextColumnsConfig = useMemo(function () {
      return _mapInstanceProperty(columns).call(columns, function (columnConfig) {
        var _childColumn$props,
          childColumn = _findInstanceProperty(childrenArray).call(childrenArray, function (_ref6) {
            var columnChildId = _ref6.props.id;
            return columnConfig.id === columnChildId;
          });
        return _objectSpread(_objectSpread({}, columnConfig), {}, {
          header: null == childColumn || null === (_childColumn$props = childColumn.props) || void 0 === _childColumn$props ? void 0 : _childColumn$props.header
        });
      });
    }, [childrenArray, columns]),
    tableContext = useMemo(function () {
      return {
        styles: styles,
        columns: _objectSpread({
          configurable: columnsConfigurable,
          config: contextColumnsConfig
        }, columnsConfigurable && {
          setConfig: setColumns
        })
      };
    }, [columnsConfigurable, contextColumnsConfig, setColumns]),
    filteredData = useMemo(function () {
      return data && children ? shouldShowSearch && filterValue ? _filterInstanceProperty(data).call(data, filterFunction(filterValue)) : data : [];
    }, [shouldShowSearch, data, children, filterFunction, filterValue]),
    numberOfRows = onFetchData ? totalNumberOfRows : numberOfRowsProp || filteredData.length,
    pageSize = useMemo(function () {
      return shouldShowPagination && pageSizeSelectedOption !== SHOW_ALL_PAGES ? pageSizeSelectedOption : numberOfRows;
    }, [shouldShowPagination, pageSizeSelectedOption, numberOfRows]),
    Expander = useCallback(function (_ref7) {
      var isExpanded = _ref7.isExpanded,
        row = _ref7.row,
        index = _ref7.index,
        viewIndex = _ref7.viewIndex;
      return React.createElement(Chevron, {
        isOpen: isExpanded,
        className: styles.expanderIcon,
        tabIndex: "0",
        role: "button",
        messageProps: {
          label: isExpanded ? messages.expandedRowLabel : messages.collapsedRowLabel
        },
        onMouseDown: function (event) {
          event.preventDefault();
        },
        onKeyPress: getKeyPressHandler(function () {
          return toggleExpansion(onGetRowId(row, index), viewIndex, multipleRowsExpandable);
        })
      });
    }, [toggleExpansion, multipleRowsExpandable, onGetRowId]),
    getExpandRowActions = useCallback(function (_ref8) {
      var index = _ref8.index,
        viewIndex = _ref8.viewIndex,
        row = _ref8.row;
      return shouldBeExpandable && expandOnRowClick && function () {
        return handleExpand(onGetRowId(row, index), viewIndex);
      };
    }, [expandOnRowClick, handleExpand, shouldBeExpandable, onGetRowId]),
    SelectionHeader = useMemo(function () {
      if ("multi" === selectionType) return function () {
        return React.createElement(CheckboxField, {
          id: "".concat(id, "-select-all"),
          "aria-label": translator(messages.selectAllRows),
          onValueChange: function (value) {
            if (value) {
              var _context2,
                allRows = reactTable.current.getResolvedState().sortedData,
                startIndex = page * pageSize,
                visibleRows = _mapInstanceProperty(_context2 = _sliceInstanceProperty(allRows).call(allRows, startIndex, startIndex + pageSize)).call(_context2, function (_ref9) {
                  var _original = _ref9._original,
                    _index = _ref9._index;
                  return onGetRowId(_original, _index);
                });
              setAll(visibleRows);
            } else clearAll();
          },
          value: selectedState.all
        });
      };
    }, [id, pageSize, page, selectionType, selectedState.all, clearAll, setAll, onGetRowId]),
    SelectionCell = useMemo(function () {
      if ("none" !== selectionType) return "single" === selectionType ? function (_ref10) {
        var _context3,
          index = _ref10.index,
          viewIndex = _ref10.viewIndex,
          row = _ref10.original,
          rowId = onGetRowId(row, index);
        return React.createElement(RadioButton, {
          id: _concatInstanceProperty(_context3 = "".concat(id, "-select-")).call(_context3, viewIndex),
          "aria-label": translator(messages.selectOneRow, {
            rowNumber: rowId
          }),
          option: {
            code: rowId
          },
          onValueChange: function () {
            return selectRow(rowId);
          },
          value: "".concat(selectedState.rows[0]),
          className: styles.radio,
          tabIndex: 0
        });
      } : function (_ref11) {
        var _context4,
          _context5,
          index = _ref11.index,
          viewIndex = _ref11.viewIndex,
          row = _ref11.original,
          rowId = onGetRowId(row, index);
        return React.createElement(CheckboxField, {
          id: _concatInstanceProperty(_context4 = "".concat(id, "-select-")).call(_context4, viewIndex),
          "aria-label": translator(messages.selectOneRow, {
            rowNumber: rowId
          }),
          onValueChange: function () {
            return toggleSelection(rowId);
          },
          value: _includesInstanceProperty(_context5 = selectedState.rows).call(_context5, rowId)
        });
      };
    }, [id, selectionType, selectedState.rows, selectRow, toggleSelection, onGetRowId]),
    shouldDisableActions = selectedState.rows.length > 1,
    columnsArray = useMemo(function () {
      var _context7,
        _context6,
        visibleColumns = childrenArray;
      null != columns && columns.length && (visibleColumns = _sortInstanceProperty(_context6 = _filterInstanceProperty(visibleColumns).call(visibleColumns, function (_ref12) {
        var _columnConfig$enabled,
          colId = _ref12.props.id,
          columnConfig = _findInstanceProperty(columns).call(columns, function (_ref13) {
            var configId = _ref13.id;
            return colId === configId;
          });
        return null === (_columnConfig$enabled = null == columnConfig ? void 0 : columnConfig.enabled) || void 0 === _columnConfig$enabled || _columnConfig$enabled;
      })).call(_context6, orderColumnsUsingConfig(columns)));
      var childrenColumns = _mapInstanceProperty(visibleColumns).call(visibleColumns, function (_ref14, columnIndex) {
          var props = _ref14.props,
            type = _ref14.type,
            _applyBreakpoint = applyBreakpoint(props),
            cell = _applyBreakpoint.cell,
            renderCell = _applyBreakpoint.renderCell,
            renderEditCell = _applyBreakpoint.renderEditCell,
            getRowActions = _applyBreakpoint.getRowActions,
            columnClassName = _applyBreakpoint.columnClassName,
            rest = _objectWithoutProperties(_applyBreakpoint, _excluded3),
            childId = rest.id,
            header = rest.header,
            headerAriaLabel = rest.headerAriaLabel,
            renderHeader = rest.renderHeader,
            textAlign = rest.textAlign,
            visible = rest.visible,
            headerClassName = rest.headerClassName,
            cellClassName = rest.cellClassName,
            columnProportion = rest.columnProportion,
            width = rest.width,
            sortable = rest.sortable,
            readOnly = rest.readOnly,
            editCellClass = rest.editCellClass,
            path = rest.path,
            onSort = rest.onSort,
            onAccessor = rest.onAccessor,
            cellCallback = cell || renderCell,
            editCellCallback = renderEditCell,
            propsToCallback = _objectSpread(_objectSpread({}, rest), {}, {
              basePath: basePath,
              translator: translator,
              disableActions: shouldDisableActions,
              breakpoint: breakpoint
            }),
            Cell = cellCallback && function (_ref15) {
              var original = _ref15.original,
                index = _ref15.index,
                rowId = onGetRowId(original, index);
              return !readOnly && editCellCallback && isEdited(rowId) ? editCellCallback({
                props: propsToCallback,
                editProps: _objectSpread(_objectSpread({}, editProps), {}, {
                  index: index
                }),
                editActions: editActions
              }) : cellCallback(original, rowId, propsToCallback, editActions.start);
            },
            headerText = translator(header),
            headerFunction = renderHeader || (headerMultiline ? splitHeaderText : identity);
          return {
            id: childId || columnIndex,
            type: "ActionColumn" === (null == type ? void 0 : type.name) ? "actions" : "display",
            Header: function () {
              return headerFunction(headerText);
            },
            Cell: Cell,
            className: cx(textAlignMapping[textAlign], columnClassName, cellClassName),
            headerClassName: cx(textAlignMapping[textAlign], _defineProperty({}, styles.headerOneLine, !headerMultiline), headerClassName),
            show: void 0 === visible || visible,
            minWidth: columnProportion,
            width: width,
            sortable: sortable,
            readOnly: readOnly,
            editCellClass: editCellClass,
            textAlign: textAlign,
            accessor: onAccessor || path,
            header: headerText,
            headerAriaLabel: headerAriaLabel,
            getRowActions: getRowActions,
            props: rest,
            sortMethod: onSort
          };
        }),
        selectionClassName = cx(styles.selectionCell, _defineProperty({}, styles.serviceCell, shouldBeExpandable)),
        expanderClassName = cx(styles.expanderCell, _defineProperty({}, styles.serviceCell, shouldBeSelectable)),
        serviceColumns = _filterInstanceProperty(_context7 = [shouldBeSelectable && {
          id: "selection",
          type: "".concat(selectionType, "_selection"),
          Header: SelectionHeader,
          Cell: SelectionCell,
          sortable: !1,
          resizable: !1,
          minWidth: 44,
          width: 44,
          textAlign: "center",
          headerClassName: selectionClassName,
          className: selectionClassName
        }, shouldBeExpandable && {
          id: "expander",
          type: "expander",
          expander: !0,
          readOnly: !0,
          getRowActions: getExpandRowActions,
          Expander: Expander,
          className: expanderClassName,
          isEdited: isEdited,
          width: 28,
          minWidth: 28
        }]).call(_context7, Boolean);
      return _concatInstanceProperty(serviceColumns).call(serviceColumns, childrenColumns);
    }, [childrenArray, columns, shouldBeExpandable, shouldBeSelectable, SelectionHeader, SelectionCell, getExpandRowActions, Expander, isEdited, applyBreakpoint, basePath, shouldDisableActions, breakpoint, translator, headerMultiline, editActions, editProps, onGetRowId]),
    getTrProps = useCallback(function (item, row) {
      var _context8,
        _context9,
        _context10,
        _cx4,
        rowColumns = item.columns,
        original = row.original,
        index = row.index,
        viewIndex = row.viewIndex,
        availableActions = _filterInstanceProperty(_context8 = flatten(_concatInstanceProperty(_context9 = [onRowClick && function (event) {
          return onRowClick(original, index, event);
        }]).call(_context9, _toConsumableArray(_mapInstanceProperty(rowColumns).call(rowColumns, function (_ref16) {
          var getRowActions = _ref16.getRowActions,
            props = _ref16.props;
          return getRowActions && getRowActions({
            row: original,
            index: index,
            viewIndex: viewIndex,
            props: props,
            onEditStart: editActions.start,
            selectOnRowClick: selectOnRowClick,
            expandOnRowClick: expandOnRowClick
          });
        }))))).call(_context8, Boolean),
        rowId = onGetRowId(original, index),
        hasActions = availableActions.length,
        isRowPressed = pressedRowId === rowId,
        isRowSelected = _includesInstanceProperty(_context10 = selectedState.rows).call(_context10, rowId),
        isRowEdited = isEdited(rowId),
        actionsHandler = function (event) {
          event.target === event.currentTarget && event.preventDefault(), availableActions.forEach(function (action) {
            return handleNestedClick(event, action);
          });
        },
        getRowPressHandler = function (callback) {
          return function (event) {
            " " !== event.key && "Enter" !== event.key || handleNestedClick(event, callback);
          };
        },
        props = _objectSpread({
          hasActions: hasActions,
          className: cx(styles.tableRow, (_cx4 = {}, _defineProperty(_cx4, styles.clickableRow, hasActions), _defineProperty(_cx4, styles.activeRow, isRowPressed), _defineProperty(_cx4, styles.selectedRow, isRowSelected), _defineProperty(_cx4, styles.editedRow, isRowEdited), _cx4))
        }, getA11yTrProps());
      return hasActions && (props.onClick = actionsHandler, props.onKeyPress = getRowPressHandler(actionsHandler), props.onKeyDown = getRowPressHandler(function () {
        return pressedRowId !== rowId && setPressedRowId(rowId);
      }), props.onKeyUp = getRowPressHandler(function () {
        return setPressedRowId(null);
      })), props;
    }, [editActions.start, expandOnRowClick, onRowClick, pressedRowId, selectOnRowClick, selectedState.rows, onGetRowId, getA11yTrProps, isEdited]),
    getTdProps = useCallback(function (state, row, _ref17) {
      var _cx5,
        readOnly = _ref17.readOnly,
        textAlign = _ref17.textAlign,
        editCellClass = _ref17.editCellClass,
        type = _ref17.type,
        columnId = _ref17.id,
        index = row.index,
        viewIndex = row.viewIndex,
        original = row.original,
        rowId = onGetRowId(original, index),
        edited = isEdited(rowId),
        isExpander = "expander" === type,
        shouldBeFocusable = "display" !== type;
      return _objectSpread({
        className: cx(styles.tableCell, (_cx5 = {}, _defineProperty(_cx5, editCellClass, editCellClass && !readOnly && edited), _defineProperty(_cx5, styles.rightAlignCell, "right" === textAlign), _defineProperty(_cx5, "disabled", isExpander && edited), _cx5)),
        onClick: isExpander && function () {
          return handleExpand(rowId, viewIndex);
        }
      }, getA11yTdProps(state, row, columnId, shouldBeFocusable));
    }, [isEdited, handleExpand, onGetRowId, getA11yTdProps]),
    getHeaderCellProps = useCallback(function (_props, _columnInfo, column) {
      var sortable = void 0 === column.sortable || column.sortable,
        sharedProps = {
          className: cx(styles.tableHeaderCell, _defineProperty({}, styles.unsortedHeaderCell, !sortable))
        };
      return sortable ? _objectSpread(_objectSpread({}, sharedProps), getA11yTheadThPropsSortableHeader(_props, column.id, column.type, column.headerAriaLabel)) : _objectSpread(_objectSpread({}, sharedProps), {}, {
        pointerEvents: "none"
      }, getA11yTheadThPropsHeader(_props, column.id, column.type));
    }, [getA11yTheadThPropsSortableHeader, getA11yTheadThPropsHeader]),
    defaultSortMethod = useCallback(function (a, b) {
      return sortWithLocale(language)(normalize(a), normalize(b));
    }, [language]),
    tbodyProps = 0 === numberOfRows && {
      TbodyComponent: function () {
        return React.createElement("div", {
          className: styles.noDataMessage
        }, translator(noDataText));
      }
    },
    handleAsyncDataChange = useCallback((_ref18 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(asyncConfigs) {
      var _reactTable$current, setAsyncData, setLoading, reactTableState, asyncParams, fetchedData;
      return _regeneratorRuntime.wrap(function (_context11) {
        for (;;) switch (_context11.prev = _context11.next) {
          case 0:
            return setAsyncData = asyncActions.setAsyncData, setLoading = asyncActions.setLoading, reactTableState = null == reactTable || null === (_reactTable$current = reactTable.current) || void 0 === _reactTable$current ? void 0 : _reactTable$current.state, asyncParams = _objectSpread(_objectSpread({}, getAsyncParams(reactTableState)), getAsyncParams(asyncConfigs)), _context11.prev = 3, setLoading(!0), _context11.next = 7, onFetchData(asyncParams);
          case 7:
            fetchedData = _context11.sent, setAsyncData(fetchedData.rows, fetchedData.numberOfRows), _context11.next = 14;
            break;
          case 11:
            _context11.prev = 11, _context11.t0 = _context11.catch(3), error("Failed to fetch data, ".concat(_context11.t0));
          case 14:
            return _context11.prev = 14, setLoading(!1), _context11.finish(14);
          case 17:
          case "end":
            return _context11.stop();
        }
      }, _callee, null, [[3, 11, 14, 17]]);
    })), function (_x) {
      return _ref18.apply(this, arguments);
    }), [asyncActions, onFetchData]),
    debounceFilter = useCallback(debounce(function (newFilterValue) {
      handleAsyncDataChange({
        filterValue: newFilterValue,
        page: 0
      });
    }, 500), [handleAsyncDataChange]),
    modeProps = onFetchData ? {
      data: data,
      loading: loading,
      filterValue: filterValue,
      loadingText: translator(loadingText)
    } : {
      sorted: sorted,
      defaultSortMethod: defaultSortMethod,
      page: page
    },
    isSortedOnServer = onFetchData && !localSorting,
    isControlledMode = !onFetchData && void 0 !== numberOfRowsProp,
    handleSortedChange = useCallback(function (newSortedValue) {
      setSorted(newSortedValue, isSortedOnServer || isControlledMode), collapseAll(), isSortedOnServer && handleAsyncDataChange({
        sorted: newSortedValue,
        page: 0
      });
    }, [setSorted, isSortedOnServer, collapseAll, handleAsyncDataChange]),
    handlePageChange = useCallback(function (newPage) {
      setPage(newPage), collapseAll(), onFetchData && handleAsyncDataChange({
        page: newPage
      });
    }, [setPage, collapseAll, onFetchData, handleAsyncDataChange]),
    getPaginationComponent = useCallback(function () {
      var paginationId = uniqueInnerId(id, "paginationId").paginationId;
      return React.createElement(Pagination, {
        id: "".concat(paginationId, "_pagination"),
        numberOfRows: numberOfRows,
        pageSize: pageSize,
        pageSizeOptions: pageSizeOptions,
        pageSizeSelectedOption: pageSizeSelectedOption,
        onSelectPageSizeChange: function (value) {
          var newPageSize = value === SHOW_ALL_PAGES ? value : Number(value);
          setPageSize(newPageSize, !0), collapseAll(), onFetchData && handleAsyncDataChange({
            page: 0,
            pageSize: newPageSize
          });
        },
        page: page,
        onPageChange: handlePageChange,
        canNext: page < Math.ceil(numberOfRows / pageSize) - 1,
        canPrevious: 0 !== page
      });
    }, [collapseAll, handleAsyncDataChange, handlePageChange, id, numberOfRows, onFetchData, page, pageSize, pageSizeOptions, pageSizeSelectedOption, setPageSize]),
    paginationProps = shouldShowPagination && numberOfRows > 0 ? {
      defaultPageSize: defaultConfig.pageSize,
      PaginationComponent: getPaginationComponent
    } : {
      showPagination: !1,
      pageSize: numberOfRows
    },
    expanderContentWrapper = useCallback(function (_ref19) {
      var rowData = _ref19.original,
        expanderContentWrapperStyles = cx(styles.expandableRowWrapper, styles.expandableRow);
      return React.createElement(AnimationGroup, {
        animation: "expand",
        appear: !0,
        in: !0
      }, React.createElement("div", {
        className: expanderContentWrapperStyles,
        "data-testid": "expander"
      }, renderExpanderContent(rowData)));
    }, [renderExpanderContent]),
    subComponent = shouldBeExpandable ? expanderContentWrapper : null,
    renderTableBar = useCallback(function () {
      var actions = null == selectionActions ? void 0 : _mapInstanceProperty(selectionActions).call(selectionActions, function (_ref20) {
        var _callback = _ref20.callback;
        return _objectSpread(_objectSpread({}, _objectWithoutProperties(_ref20, _excluded4)), {}, {
          callback: function () {
            _callback(selectedState.rows), clearAll();
          }
        });
      });
      return React.createElement(TableContext.Provider, {
        value: tableContext
      }, React.createElement(TableBar, {
        filterValue: filterValue,
        onFilterChange: function (newFilterValue) {
          setFilterValue(newFilterValue, !0), onFetchData && debounceFilter(newFilterValue), collapseAll();
        },
        filterPlaceholder: translator(filterPlaceholder),
        selectionActions: actions,
        showSelectionActions: !!selectedState.rows.length,
        disabled: loading
      }));
    }, [debounceFilter, filterPlaceholder, filterValue, clearAll, loading, onFetchData, selectedState.rows, selectionActions, setFilterValue, tableContext, translator, collapseAll]),
    reactTableKey = _concatInstanceProperty(_context12 = "".concat(id, "_")).call(_context12, onFetchData ? "async" : "sync"),
    getStyleProps = useCallback(function () {
      return mapValues({
        getTheadProps: styles.tableHeader,
        getTrGroupProps: styles.tableRowGroup
      }, function (className) {
        return function () {
          return _objectSpread({
            className: className
          }, getA11yTrGroupProps());
        };
      });
    }, [getA11yTrGroupProps]);
  return useEffect(function () {
    if (!onFetchData && void 0 === numberOfRowsProp) {
      var isFirstPage = 0 === page,
        isPageEmpty = page === Math.floor(filteredData.length / pageSize) && filteredData.length % pageSize == 0;
      !isFirstPage && isPageEmpty && setPage(page - 1);
    }
  }, [pageSize, page, onFetchData, setPage, filteredData, numberOfRowsProp]), useEffect(function () {
    onFetchData && ((syncData || numberOfRowsProp) && warning("The props `data` and `numberOfRows` will not be used when `onFetchData` prop is provided"), handleAsyncDataChange(defaultConfig));
  }, [handleAsyncDataChange, onFetchData, syncData, numberOfRowsProp, defaultConfig]), React.createElement("div", {
    id: id,
    className: styles.dataTable
  }, shouldShowSearch && renderTableBar(), React.createElement(ReactTable, _extends({
    key: reactTableKey,
    ref: reactTable,
    data: filteredData,
    columns: columnsArray,
    getTheadThProps: getHeaderCellProps,
    minRows: 0,
    styles: styles,
    className: cx(styles.reactTable, "-striped", "-highlight"),
    SubComponent: subComponent,
    expanded: expandedRows
  }, paginationProps, getStyleProps(), tbodyProps, {
    getTdProps: getTdProps,
    getTrProps: getTrProps,
    getTheadTrProps: getA11yTrProps,
    getTbodyProps: getTbodyProps,
    getTableProps: getA11yTableProps,
    NoDataComponent: renderNull,
    expanderDefaults: expanderDefaults,
    TrGroupComponent: AnimatedTrGroupComponent,
    TrComponent: AnimationResistantTrComponent,
    defaultSorted: defaultConfig.sorted,
    pageSize: pageSize,
    onPageChange: handlePageChange,
    onSortedChange: handleSortedChange
  }, modeProps, {
    manual: isSortedOnServer || isControlledMode
  })));
};
function normalize(value) {
  return null == value ? "" : value;
}
DataTable.propTypes = dataTablePropTypes, DataTable.defaultProps = {
  showSearch: !0,
  showPagination: !0,
  defaultConfig: DEFAULT_CONFIG,
  pageSizeOptions: [10, 25, 50],
  expandable: !0,
  multipleRowsExpandable: !0,
  headerMultiline: !1,
  selectionType: "none",
  columnsConfigurable: !0,
  noDataText: messages.noDataText,
  loadingText: messages.loadingText,
  localSorting: !1,
  onGetRowId: function (row, index) {
    return index;
  },
  tableLabel: messages.tableLabel,
  filterPlaceholder: messages.searchPlaceholder
}, DataTable.__docgenInfo = {
  description: "Component for displaying tabular data\n\n@type {React.FC<PropTypes.InferProps<typeof dataTablePropTypes>>}\n\n@metadataType container",
  methods: [],
  displayName: "DataTable",
  props: {
    showSearch: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines whether search bar should be shown"
    },
    showPagination: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines whether pagination should be shown"
    },
    defaultConfig: {
      defaultValue: {
        value: "{\n    filterValue: '',\n    page: 0,\n    pageSize: 10,\n}",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dataTableConfigShape"
      },
      required: !1,
      description: "The configuration applied only on component initialization.\nIt includes sorting method, filtering value, page, page size, edited row and column sorting."
    },
    pageSizeOptions: {
      defaultValue: {
        value: "[10, 25, 50]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: !1,
      description: "List of page size options"
    },
    expandable: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, rows are expandable"
    },
    multipleRowsExpandable: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, multiple rows can be expanded at the same time"
    },
    headerMultiline: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the header can be on 2 lines"
    },
    selectionType: {
      defaultValue: {
        value: "'none'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'none'",
          computed: !1
        }, {
          value: "'single'",
          computed: !1
        }, {
          value: "'multi'",
          computed: !1
        }]
      },
      required: !1,
      description: "Sets how many rows the user can select (none, single row, multi row)"
    },
    columnsConfigurable: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If false, the user cannot change the visibility of columns"
    },
    noDataText: {
      defaultValue: {
        value: "messages.noDataText",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Text message, when there is no data to show"
    },
    loadingText: {
      defaultValue: {
        value: "messages.loadingText",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Text to show while data is loading, by default: 'Loading...'"
    },
    localSorting: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true, sorting is done locally after the data is fetched\n(only for async mode)"
    },
    onGetRowId: {
      defaultValue: {
        value: "(row, index) => index",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback (row) that returns unique id for the row.\nThe id should not change after editing the row.\nBy default index from data is used, but it does not work correctly when filtering."
    },
    tableLabel: {
      defaultValue: {
        value: "messages.tableLabel",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Data table aria label"
    },
    filterPlaceholder: {
      defaultValue: {
        value: "messages.searchPlaceholder",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Placeholder text for search input"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the component"
    },
    config: {
      type: {
        name: "custom",
        raw: "dataTableConfigShape"
      },
      required: !1,
      description: "Used to set data table config. Use it together with onConfigChange to control data table from outside.\nIt includes sorting method, filtering value, page, page size, edited row and columns sorting."
    },
    onConfigChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to call after config value changes. The new config value is passed\nas an argument. It includes a sorting method, a filtering value, page number,\npage size and column visibility"
    },
    data: {
      type: {
        name: "arrayOf",
        value: {
          name: "object"
        }
      },
      required: !1,
      description: "Cell data"
    },
    onFilter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Predicate function for searching data"
    },
    onColumnFilter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Predicate function for searching data per column"
    },
    strictSearch: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, narrows the search results to values containing exact search phrase"
    },
    children: {
      type: {
        name: "node"
      },
      required: !0,
      description: "Column of any type (e.g., DisplayColumn)"
    },
    renderExpanderContent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Content of the expandable rows"
    },
    onRowClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for row click"
    },
    expandOnRowClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the row will expand on click"
    },
    selectOnRowClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, row will be selected on click"
    },
    selectedRows: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "rowIdShape"
        }
      },
      required: !1,
      description: "Currently selected rows (passing a value for this prop makes the component fully controlled)"
    },
    selectionActions: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            icon: {
              name: "string",
              required: !0
            },
            label: {
              name: "string",
              required: !0
            },
            callback: {
              name: "func",
              required: !0
            }
          }
        }
      },
      required: !1,
      description: "List of actions available for selection"
    },
    onSelectionChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be invoked when the selection changes"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any DataTable property to use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any DataTable property to use the 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any DataTable property to use at the 'tablet', 'phoneWide' and 'phone' breakpoint"
    },
    onSaveRow: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback (row, rowId) to be invoked when the row is saved"
    },
    onEditCancel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback (row, rowId) to be invoked when the row edit is canceled"
    },
    onEditStart: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback (row, rowId) to be invoked when the row editing is started"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The full path of view model, it also acts as an accessor for the data"
    },
    onFetchData: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be invoked when new data has to be fetched in async mode,\nwith one argument of the current state of config object '{ filterValue, sorted, pageSize, page }'.\nShould return a promise of an object '{ rows, numberOfRows }', in which 'rows' is the filtered data to be presented for the current page,\n'numberOfRows' is the total length of whole data.\nIt takes precedence over `data` and `numberOfRows` sync props."
    },
    rowIdPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The name of the row object property which contains the row id. Can accept\nnested paths. Takes precedence over the `onGetRowId` prop."
    },
    numberOfRows: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of available rows"
    }
  }
}, AnimatedTrGroupComponent.__docgenInfo = {
  componentName: "AnimatedTrGroupComponent",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "AnimatedTrGroupComponent",
  methods: [],
  actualName: "AnimatedTrGroupComponent"
}, AnimationResistantTrComponent.__docgenInfo = {
  componentName: "AnimationResistantTrComponent",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "AnimationResistantTrComponent",
  methods: [],
  actualName: "AnimationResistantTrComponent"
}, splitHeaderText.__docgenInfo = {
  componentName: "splitHeaderText",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "splitHeaderText",
  methods: [],
  actualName: "splitHeaderText"
}, DataTable.__docgenInfo = {
  componentName: "DataTable",
  packageName: "@jutro/datatable",
  description: "Component for displaying tabular data",
  displayName: "DataTable",
  methods: [],
  actualName: "DataTable",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the component"
    },
    showSearch: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines whether search bar should be shown",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    showPagination: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines whether pagination should be shown",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    config: {
      type: {
        name: "custom",
        raw: "dataTableConfigShape"
      },
      required: !1,
      description: "Used to set data table config. Use it together with onConfigChange to control data table from outside.\nIt includes sorting method, filtering value, page, page size, edited row and columns sorting."
    },
    defaultConfig: {
      type: {
        name: "custom",
        raw: "dataTableConfigShape"
      },
      required: !1,
      description: "The configuration applied only on component initialization.\nIt includes sorting method, filtering value, page, page size, edited row and column sorting.",
      defaultValue: {
        value: "{\n    filterValue: '',\n    page: 0,\n    pageSize: 10,\n}",
        computed: !1
      }
    },
    onConfigChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to call after config value changes. The new config value is passed\nas an argument. It includes a sorting method, a filtering value, page number,\npage size and column visibility"
    },
    pageSizeOptions: {
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: !1,
      description: "List of page size options",
      defaultValue: {
        value: "[10, 25, 50]",
        computed: !1
      }
    },
    data: {
      type: {
        name: "arrayOf",
        value: {
          name: "object"
        }
      },
      required: !1,
      description: "Cell data"
    },
    onFilter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Predicate function for searching data"
    },
    onColumnFilter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Predicate function for searching data per column"
    },
    filterPlaceholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Placeholder text for search input",
      defaultValue: {
        value: "messages.searchPlaceholder",
        computed: !0
      }
    },
    strictSearch: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, narrows the search results to values containing exact search phrase"
    },
    children: {
      type: {
        name: "node"
      },
      required: !0,
      description: "Column of any type (e.g., DisplayColumn)"
    },
    expandable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, rows are expandable",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    multipleRowsExpandable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, multiple rows can be expanded at the same time",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    renderExpanderContent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Content of the expandable rows"
    },
    headerMultiline: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the header can be on 2 lines",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    onRowClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for row click"
    },
    expandOnRowClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the row will expand on click"
    },
    selectOnRowClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, row will be selected on click"
    },
    selectionType: {
      type: {
        name: "enum",
        value: [{
          value: "'none'",
          computed: !1
        }, {
          value: "'single'",
          computed: !1
        }, {
          value: "'multi'",
          computed: !1
        }]
      },
      required: !1,
      description: "Sets how many rows the user can select (none, single row, multi row)",
      defaultValue: {
        value: "'none'",
        computed: !1
      }
    },
    selectedRows: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "rowIdShape"
        }
      },
      required: !1,
      description: "Currently selected rows (passing a value for this prop makes the component fully controlled)"
    },
    selectionActions: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            icon: {
              name: "string",
              required: !0
            },
            label: {
              name: "string",
              required: !0
            },
            callback: {
              name: "func",
              required: !0
            }
          }
        }
      },
      required: !1,
      description: "List of actions available for selection"
    },
    onSelectionChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be invoked when the selection changes"
    },
    columnsConfigurable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If false, the user cannot change the visibility of columns",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    loadingText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Text to show while data is loading, by default: 'Loading...'",
      defaultValue: {
        value: "messages.loadingText",
        computed: !0
      }
    },
    noDataText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Text message, when there is no data to show",
      defaultValue: {
        value: "messages.noDataText",
        computed: !0
      }
    },
    localSorting: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true, sorting is done locally after the data is fetched\n(only for async mode)",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any DataTable property to use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any DataTable property to use the 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any DataTable property to use at the 'tablet', 'phoneWide' and 'phone' breakpoint"
    },
    onSaveRow: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback (row, rowId) to be invoked when the row is saved"
    },
    onEditCancel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback (row, rowId) to be invoked when the row edit is canceled"
    },
    onEditStart: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback (row, rowId) to be invoked when the row editing is started"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The full path of view model, it also acts as an accessor for the data"
    },
    onFetchData: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be invoked when new data has to be fetched in async mode,\nwith one argument of the current state of config object '{ filterValue, sorted, pageSize, page }'.\nShould return a promise of an object '{ rows, numberOfRows }', in which 'rows' is the filtered data to be presented for the current page,\n'numberOfRows' is the total length of whole data.\nIt takes precedence over `data` and `numberOfRows` sync props."
    },
    onGetRowId: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback (row) that returns unique id for the row.\nThe id should not change after editing the row.\nBy default index from data is used, but it does not work correctly when filtering.",
      defaultValue: {
        value: "(row, index) => index",
        computed: !1
      }
    },
    rowIdPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The name of the row object property which contains the row id. Can accept\nnested paths. Takes precedence over the `onGetRowId` prop."
    },
    tableLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Data table aria label",
      defaultValue: {
        value: "messages.tableLabel",
        computed: !0
      }
    },
    numberOfRows: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of available rows"
    }
  }
};