import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
export default {
  create: function (config) {
    return function (event) {
      var _context;
      return _reduceInstanceProperty(_context = _Object$keys(event)).call(_context, function (result, key) {
        var _config$eventKeys;
        return null != config && null !== (_config$eventKeys = config.eventKeys) && void 0 !== _config$eventKeys && _config$eventKeys[key] ? (result[key] = config.eventKeys[key], result) : (result[key] = event[key], result);
      }, {});
    };
  }
};