import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import { useRef, useEffect, useCallback } from "react";
var moveFocus = function (list, currentFocusIndex, traversalFunction) {
    for (var focusableMenuElements = list.querySelectorAll('[role="menuitem"], button'), nextFocusIndex = traversalFunction(focusableMenuElements, currentFocusIndex), traversed = !1; null != focusableMenuElements && focusableMenuElements[nextFocusIndex];) {
      if (0 === nextFocusIndex) {
        if (traversed) return null;
        traversed = !0;
      }
      var nextFocus = focusableMenuElements[nextFocusIndex],
        nextFocusHasNoTabIndex = "button" !== nextFocus.tagName.toLowerCase() && !nextFocus.hasAttribute("tabindex"),
        nextFocusDisabled = nextFocus.disabled || "true" === nextFocus.getAttribute("aria-disabled"),
        nextFocusHidden = "hidden" === window.getComputedStyle(nextFocus).visibility;
      if (!(nextFocusHasNoTabIndex || nextFocusDisabled || nextFocusHidden)) return nextFocus.focus(), nextFocusIndex;
      nextFocusIndex = traversalFunction(focusableMenuElements, nextFocusIndex);
    }
    return null;
  },
  nextItemIndex = function (menuItems, currentFocusIndex) {
    return currentFocusIndex === menuItems.length - 1 || null === currentFocusIndex ? 0 : currentFocusIndex + 1;
  },
  previousItemIndex = function (menuItems, currentFocusIndex) {
    return 0 === currentFocusIndex || null === currentFocusIndex ? menuItems.length - 1 : currentFocusIndex - 1;
  };
export var useDropdownMenuKeyboardNavigation = function (isMenuOpen, autoFocus) {
  var dropdownRef = useRef(),
    currentFocusIndexRef = useRef(null);
  useEffect(function () {
    isMenuOpen ? autoFocus && (currentFocusIndexRef.current = moveFocus(dropdownRef.current, null, nextItemIndex)) : currentFocusIndexRef.current = null;
  }, [isMenuOpen, autoFocus]);
  var onKeyDown = useCallback(function (event) {
      var currentFocusIndex,
        traversalFunction,
        list = dropdownRef.current;
      switch (event.key) {
        case "ArrowDown":
          currentFocusIndex = currentFocusIndexRef.current, traversalFunction = nextItemIndex;
          break;
        case "ArrowUp":
          currentFocusIndex = currentFocusIndexRef.current, traversalFunction = previousItemIndex;
          break;
        case "Home":
          currentFocusIndex = null, traversalFunction = nextItemIndex;
          break;
        case "End":
          currentFocusIndex = null, traversalFunction = previousItemIndex;
          break;
        default:
          return;
      }
      event.preventDefault(), event.stopPropagation(), currentFocusIndexRef.current = moveFocus(list, currentFocusIndex, traversalFunction);
    }, []),
    onHover = useCallback(function (itemId) {
      var focusableMenuElements = _toConsumableArray(dropdownRef.current.querySelectorAll('[role="menuitem"], button')),
        focusIndex = _findIndexInstanceProperty(focusableMenuElements).call(focusableMenuElements, function (item) {
          return item.id === itemId;
        });
      -1 !== focusIndex && (focusableMenuElements[focusIndex].focus(), currentFocusIndexRef.current = focusIndex);
    }, []);
  return {
    dropdownRef: dropdownRef,
    onKeyDown: onKeyDown,
    onHover: onHover
  };
};