import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React, { useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import cx from "classnames";
import { createPortal } from "react-dom";
import styles from "./Popper.module.css";
import { popperPlacement } from "./types";
export var Popper = function (_ref) {
  var isOpen = _ref.isOpen,
    triggerElement = _ref.triggerElement,
    children = _ref.children,
    placement = _ref.placement,
    popperOptions = _ref.popperOptions,
    onClose = _ref.onClose,
    noPortal = _ref.noPortal,
    portalTargetElement = _ref.portalTargetElement,
    popperRef = useRef(null),
    _usePopper = usePopper(triggerElement, popperRef.current, function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
          _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
        });
      }
      return target;
    }({
      placement: placement
    }, popperOptions)),
    popperStyles = _usePopper.styles.popper,
    attributes = _usePopper.attributes,
    update = _usePopper.update;
  useLayoutEffect(function () {
    isOpen && (null == update || update());
  }, [update, isOpen]), function (popperRef, triggerElement, isOpen, onOutsideClick) {
    useEffect(function () {
      var handleClickOutside = function (event) {
        isOpen && event.target instanceof Element && popperRef.current instanceof Element && (null != triggerElement && triggerElement.contains(event.target) || !popperRef.current.contains(event.target) && isOpen && (null == onOutsideClick || onOutsideClick()));
      };
      return document.addEventListener("mousedown", handleClickOutside), function () {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [popperRef, triggerElement, isOpen]);
  }(popperRef, triggerElement, isOpen, onClose), function (popperRef, triggerElement, isOpen, onEscKeyPressed) {
    useEffect(function () {
      var handleKeyPress = function (event) {
        var _triggerElement$focus;
        isOpen && "Escape" === event.key && (null == onEscKeyPressed || onEscKeyPressed(), triggerElement instanceof HTMLElement && (null == triggerElement || null === (_triggerElement$focus = triggerElement.focus) || void 0 === _triggerElement$focus || _triggerElement$focus.call(triggerElement)));
      };
      return document.addEventListener("keydown", handleKeyPress), function () {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }, [popperRef, triggerElement, isOpen]);
  }(popperRef, triggerElement, isOpen, onClose), function (popperRef, isOpen, onBlurOutside) {
    useEffect(function () {
      var handleBlur = function (event) {
        isOpen && popperRef.current instanceof Element && event.relatedTarget && !(event.relatedTarget instanceof Element && popperRef.current.contains(event.relatedTarget)) && (null == onBlurOutside || onBlurOutside());
      };
      return document.addEventListener("focusout", handleBlur), function () {
        document.removeEventListener("focusout", handleBlur);
      };
    }, [popperRef, isOpen]);
  }(popperRef, isOpen, onClose);
  var popperClassName = cx(styles.popper, _defineProperty({}, styles.hidden, !isOpen)),
    popper = React.createElement("div", _extends({
      "aria-hidden": !isOpen,
      ref: popperRef,
      style: isOpen ? popperStyles : {
        transform: "scale(0) translateY(-1000px)"
      },
      className: popperClassName
    }, attributes.popper), children);
  return noPortal ? popper : createPortal(popper, null != portalTargetElement ? portalTargetElement : document.querySelector("body"));
};
Popper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  triggerElement: PropTypes.instanceOf(Element),
  children: PropTypes.node,
  placement: PropTypes.oneOf(popperPlacement),
  popperOptions: PropTypes.shape({}),
  onClose: PropTypes.func,
  noPortal: PropTypes.bool,
  portalTargetElement: PropTypes.instanceOf(HTMLElement)
}, Popper.__docgenInfo = {
  description: "Creates popover near to referenced element\n\n@type {React.FC<PropTypes.InferProps<typeof PopperProps>>}\n\n@metadataType container",
  methods: [],
  displayName: "Popper",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: !0,
      description: "Controls whether container should be hidden"
    },
    triggerElement: {
      type: {
        name: "instanceOf",
        value: "Element"
      },
      required: !1,
      description: "Reference element for Popper.\nPopper will be positioned based on this element position."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Node to be rendered inside Popper"
    },
    placement: {
      type: {
        name: "enum",
        computed: !0,
        value: "popperPlacement"
      },
      required: !1,
      description: "Defines where container should show up"
    },
    popperOptions: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Additional options passed as third argument to usePopper hook\nList of options with descriptions: https://popper.js.org/docs/v2/constructors/#options"
    },
    onClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Optional callback that's supposed to be triggered when container gets closed"
    },
    noPortal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, Popper will not be rendered inside React Portal.\nRendering Popper in React Portal is helpful if want to position a tooltip inside\nan overflow: hidden container that you want to make overflow."
    },
    portalTargetElement: {
      type: {
        name: "instanceOf",
        value: "HTMLElement"
      },
      required: !1,
      description: "React Portal target element, preferably one of the top-level DOM elements.\nMust be unique, defaults to body element."
    }
  }
}, Popper.__docgenInfo = {
  componentName: "Popper",
  packageName: "@jutro/components",
  description: "Creates popover near to referenced element",
  displayName: "Popper",
  methods: [],
  actualName: "Popper",
  metadataType: "container",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: !0,
      description: "Controls whether container should be hidden"
    },
    triggerElement: {
      type: {
        name: "instanceOf",
        value: "Element"
      },
      required: !1,
      description: "Reference element for Popper.\nPopper will be positioned based on this element position."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Node to be rendered inside Popper"
    },
    placement: {
      type: {
        name: "enum",
        computed: !1,
        value: [{
          value: "'auto'",
          computed: !1
        }, {
          value: "'auto-end'",
          computed: !1
        }, {
          value: "'auto-start'",
          computed: !1
        }, {
          value: "'bottom'",
          computed: !1
        }, {
          value: "'bottom-end'",
          computed: !1
        }, {
          value: "'bottom-start'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }, {
          value: "'left-end'",
          computed: !1
        }, {
          value: "'left-start'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'right-end'",
          computed: !1
        }, {
          value: "'right-start'",
          computed: !1
        }, {
          value: "'top'",
          computed: !1
        }, {
          value: "'top-end'",
          computed: !1
        }, {
          value: "'top-start'",
          computed: !1
        }]
      },
      required: !1,
      description: "Defines where container should show up"
    },
    popperOptions: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Additional options passed as third argument to usePopper hook\nList of options with descriptions: https://popper.js.org/docs/v2/constructors/#options"
    },
    onClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Optional callback that's supposed to be triggered when container gets closed"
    },
    noPortal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, Popper will not be rendered inside React Portal.\nRendering Popper in React Portal is helpful if want to position a tooltip inside\nan overflow: hidden container that you want to make overflow."
    },
    portalTargetElement: {
      type: {
        name: "instanceOf",
        value: "HTMLElement"
      },
      required: !1,
      description: "React Portal target element, preferably one of the top-level DOM elements.\nMust be unique, defaults to body element."
    }
  }
};