import React from "react";
import { useAuth } from "@jutro/auth";
import { ErrorNotice } from "@jutro/components";
import { messages } from "./AuthErrorPage.messages";
var managedErrors = {
  access_denied: {
    name: messages.accessDenied,
    message: messages.accessDeniedMessage
  },
  missing_auth: {
    name: messages.authErrorTitle,
    message: messages.authErrorMessage
  }
};
export var AuthErrorPage = function (_ref) {
  var _useMessageDetails = function (error) {
      var auth = useAuth(),
        _error$name = error.name,
        name = void 0 === _error$name ? messages.genericError : _error$name,
        errorCode = error.errorCode,
        _error$message = error.message,
        message = void 0 === _error$message ? error.toString() : _error$message,
        _error$actions = error.actions,
        actions = void 0 === _error$actions ? auth ? [{
          label: messages.logout,
          callback: auth.logout
        }] : [] : _error$actions;
      return managedErrors[errorCode] || {
        name: name,
        message: message,
        actions: actions
      };
    }(_ref.error),
    name = _useMessageDetails.name,
    message = _useMessageDetails.message,
    actions = _useMessageDetails.actions;
  return React.createElement(ErrorNotice, {
    noticeStyle: "fullHeight",
    mainMessage: name,
    detailedMessage: message,
    actions: actions
  });
};
AuthErrorPage.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AuthErrorPage"
}, AuthErrorPage.__docgenInfo = {
  componentName: "AuthErrorPage",
  packageName: "@jutro/app",
  description: "",
  displayName: "AuthErrorPage",
  methods: [],
  actualName: "AuthErrorPage"
};