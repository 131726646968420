import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { createRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { defaultAvailableValuePropType, dataTypeShape, DATA_TYPE_STRING, availableValueObjectShape } from "@jutro/prop-types";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { getOptionCode } from "../availableValues";
import { RadioButton } from "./RadioButton";
import { messages } from "./RadioButtonField.messages";
import { createHandleRadioButtonKeyDown, getTabIndexForRadioGroup } from "../../../radiobuttonUtils";
import styles from "./RadioButton.module.css";
var defaultAvailableValues = [{
  code: "true",
  name: messages.yes
}, {
  code: "false",
  name: messages.no
}];
export var RadioButtonField = function (_FieldComponent) {
  _inherits(RadioButtonField, FieldComponent);
  var _super = _createSuper(RadioButtonField);
  function RadioButtonField() {
    var _context, _this;
    _classCallCheck(this, RadioButtonField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "ref", createRef(null)), _this;
  }
  return _createClass(RadioButtonField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(RadioButtonField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderLabel",
    value: function (props) {
      return _get(_getPrototypeOf(RadioButtonField.prototype), "renderLabel", this).call(this, _objectSpread(_objectSpread({}, props), {}, {
        labelContainerClassName: styles.fieldLabelContainer
      }));
    }
  }, {
    key: "renderRadioOptions",
    value: function (options, props) {
      var _this2 = this,
        id = props.id,
        disabled = props.disabled,
        required = props.required,
        selectedValue = this.getDataTypeAwareSelectedValue();
      return _mapInstanceProperty(options).call(options, function (option, index) {
        var optionCode = getOptionCode(option),
          subtitle = option.subtitle,
          secondaryLabel = option.secondaryLabel;
        return React.createElement(RadioButton, _extends({
          secondaryLabel: subtitle || secondaryLabel,
          key: optionCode,
          option: option,
          id: id,
          tabIndex: getTabIndexForRadioGroup(option, selectedValue, index),
          value: selectedValue,
          onValueChange: _this2.handleAvailableValuesValueChange,
          disabled: disabled,
          required: required
        }, _this2.generateDataPathProperty()));
      });
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function () {
      var translator = this.translator,
        label = this.props.label;
      return _objectSpread(_objectSpread({}, _get(_getPrototypeOf(RadioButtonField.prototype), "generateAccessibilityProperties", this).call(this)), {}, {
        "aria-label": translator(label)
      });
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var _this3 = this,
        id = breakpointProps.id,
        availableValues = breakpointProps.availableValues,
        controlClassName = breakpointProps.controlClassName,
        isHorizontal = breakpointProps.isHorizontal,
        handleRadioButtonKeyDown = createHandleRadioButtonKeyDown(function (el, value) {
          el.querySelector("div[data-value=".concat(value, "]")).focus();
        }),
        options = this.renderRadioOptions(availableValues, breakpointProps),
        classes = cx(_defineProperty({}, styles.horizontal, isHorizontal), controlClassName),
        _this$props = this.props,
        onFocus = _this$props.onFocus,
        onBlur = _this$props.onBlur;
      return React.createElement("div", _extends({
        ref: this.ref,
        id: id,
        className: classes,
        role: "radiogroup",
        onFocusCapture: function (e) {
          var _this3$ref$current;
          return !(null !== (_this3$ref$current = _this3.ref.current) && void 0 !== _this3$ref$current && _this3$ref$current.contains(e.relatedTarget)) && onFocus && onFocus(e);
        },
        onBlurCapture: function (e) {
          var _this3$ref$current2;
          return !(null !== (_this3$ref$current2 = _this3.ref.current) && void 0 !== _this3$ref$current2 && _this3$ref$current2.contains(e.relatedTarget)) && onBlur && onBlur(e);
        },
        onKeyDown: function (event) {
          return handleRadioButtonKeyDown(event, breakpointProps, _this3.handleAvailableValuesValueChange);
        }
      }, this.generateAccessibilityProperties()), options);
    }
  }]), RadioButtonField;
}();
_defineProperty(RadioButtonField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: defaultAvailableValuePropType,
  defaultValue: defaultAvailableValuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.arrayOf(availableValueObjectShape),
  isHorizontal: PropTypes.bool
})), _defineProperty(RadioButtonField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {
  availableValues: defaultAvailableValues,
  dataType: DATA_TYPE_STRING,
  isHorizontal: !1
})), _defineProperty(RadioButtonField, "contextType", FieldComponent.contextType), RadioButtonField.__docgenInfo = {
  description: "Renders a list of radio buttons. You specify the items using component props.\n@typedef {typeof RadioButtonField.propTypes} RadioButtonFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<RadioButtonFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "renderLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: {
      description: "JSX for the label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "renderRadioOptions",
    docblock: "Render the radio buttons for the specified options\n\n@param {Array<any>} options - options to render\n@param {object} props\n@returns {React.ReactElement} JSX for the radio buttons",
    modifiers: [],
    params: [{
      name: "options",
      description: "options to render",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      },
      optional: !1
    }, {
      name: "props",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the radio buttons",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the radio buttons for the specified options"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "RadioButtonField",
  props: {
    availableValues: {
      defaultValue: {
        value: "[\n    {\n        code: 'true',\n        name: messages.yes,\n    },\n    {\n        code: 'false',\n        name: messages.no,\n    },\n]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "availableValueObjectShape"
        }
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain 'code' and 'name'; if not provided, default 'Yes'/'No' will be used"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShape"
      },
      required: !1,
      description: "The format of the value"
    },
    isHorizontal: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the component will be oriented horizontally"
    },
    value: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "Default value"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, RadioButtonField.__docgenInfo = {
  componentName: "RadioButtonField",
  packageName: "@jutro/components",
  description: "Renders a list of radio buttons. You specify the items using component props.",
  displayName: "RadioButtonField",
  methods: [{
    name: "renderLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: {
      description: "JSX for the label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "renderRadioOptions",
    docblock: "Render the radio buttons for the specified options\n\n@param {Array<any>} options - options to render\n@param {object} props\n@returns {React.ReactElement} JSX for the radio buttons",
    modifiers: [],
    params: [{
      name: "options",
      description: "options to render",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      },
      optional: !1
    }, {
      name: "props",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the radio buttons",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the radio buttons for the specified options"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "RadioButtonField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "Default value"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }]
      },
      required: !1,
      description: "The format of the value",
      defaultValue: {
        value: "'string'",
        computed: !1
      }
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              code: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: !1
              },
              name: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            }
          }, {
            name: "shape",
            value: {
              id: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: !1
              },
              displayName: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            }
          }]
        }
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain 'code' and 'name'; if not provided, default 'Yes'/'No' will be used",
      defaultValue: {
        value: "[\n    {\n        code: 'true',\n        name: messages.yes,\n    },\n    {\n        code: 'false',\n        name: messages.no,\n    },\n]",
        computed: !1
      }
    },
    isHorizontal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the component will be oriented horizontally",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};