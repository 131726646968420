import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["className", "chevronIcon", "isOpen", "messageProps"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { getMessageProp } from "@jutro/platform";
import styles from "./Chevron.module.css";
import { Icon } from "../Icon/Icon";
import { messages } from "./Chevron.messages";
var chevronPropTypes = {
    className: PropTypes.string,
    chevronIcon: PropTypes.string,
    isOpen: PropTypes.bool,
    messageProps: PropTypes.shape({
      label: intlMessageShape
    }),
    ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  },
  defaultProps = {
    chevronIcon: "gw-chevron-right",
    isOpen: !1
  },
  ChevronInternal = React.forwardRef(function (props, forwardRef) {
    var _defaultProps$props = _objectSpread(_objectSpread({}, defaultProps), props),
      className = _defaultProps$props.className,
      chevronIcon = _defaultProps$props.chevronIcon,
      isOpen = _defaultProps$props.isOpen,
      messageProps = _defaultProps$props.messageProps,
      other = _objectWithoutProperties(_defaultProps$props, _excluded),
      translator = useTranslator(),
      accordionLabel = getMessageProp("label", messageProps, messages),
      chevronClass = cx(styles.chevron, _defineProperty({}, styles.open, isOpen), className);
    return React.createElement(Icon, _extends({
      icon: chevronIcon,
      size: "large",
      className: chevronClass,
      role: "button",
      "aria-label": translator(accordionLabel),
      "aria-expanded": Boolean(isOpen)
    }, other, {
      ref: forwardRef
    }));
  });
ChevronInternal.propTypes = chevronPropTypes, ChevronInternal.defaultProps = defaultProps, ChevronInternal.displayName = "Chevron";
export var Chevron = memo(ChevronInternal);
ChevronInternal.__docgenInfo = {
  description: 'The `Chevron` component is used to render the Chevron icon for an `Accordion` or `Card`.  This component is\nbuilt with optional props: `className`, `chevronIcon` and `isOpen`.  The optional `className` prop is used to provide any\ncustom styling to the chevron.  The optional `chevronIcon` prop will alter the chevron styling using a well-known,\npre-defined variant of the icon.  The optional `isOpen` prop, when `true`, will alter the style of the chevron icon\nto indicate the chevron is opened.\n\n@example\n<Chevron isOpen={this.state.isOpen}/>\n\n@example\n<Chevron isOpen={this.state.isOpen} chevronIcon="gw-chevron-right"/>\n\n@metadataType element',
  methods: [],
  displayName: "Chevron",
  props: {
    chevronIcon: {
      defaultValue: {
        value: "'gw-chevron-right'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The optional icon of this chevron"
    },
    isOpen: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "An optional flag indicating whether this chevron is open"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape",
            description: "accordion icon aria-label",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    ref: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "object"
        }]
      },
      required: !1,
      description: "Ref passed to the underlying Icon component"
    }
  }
}, Chevron.__docgenInfo = {
  componentName: "Chevron",
  packageName: "@jutro/components",
  description: "The `Chevron` component is used to render the Chevron icon for an `Accordion` or `Card`.  This component is\nbuilt with optional props: `className`, `chevronIcon` and `isOpen`.  The optional `className` prop is used to provide any\ncustom styling to the chevron.  The optional `chevronIcon` prop will alter the chevron styling using a well-known,\npre-defined variant of the icon.  The optional `isOpen` prop, when `true`, will alter the style of the chevron icon\nto indicate the chevron is opened.",
  displayName: "Chevron",
  methods: [],
  actualName: "Chevron",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    chevronIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The optional icon of this chevron",
      defaultValue: {
        value: "'gw-chevron-right'",
        computed: !1
      }
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "An optional flag indicating whether this chevron is open",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "union",
            description: "accordion icon aria-label",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    ref: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "object"
        }]
      },
      required: !1,
      description: "Ref passed to the underlying Icon component"
    }
  }
};