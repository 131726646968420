import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
var _excluded = ["config", "trackingConfig", "rootId", "themeConfig", "themeProviderProps", "disableThemeProvider", "onRender", "onInit"];
import _WeakMap from "@babel/runtime-corejs3/core-js-stable/weak-map";
import React from "react";
import ReactDOM from "react-dom";
import { loadConfiguration, getConfigValue } from "@jutro/config";
import { initDefaultGA, initDefaultMixpanel, initDefaultDataDog, publish, JUTRO_TOPICS } from "@jutro/events";
import { ThemeProvider } from "@jutro/theme";
import { ApplicationRoot } from "./ApplicationRoot";
var errorListener = function (errorEvent) {
  publish(JUTRO_TOPICS.UNKNOWN_ERROR, errorEvent);
};
export var initGlobalErrorCapture = function () {
  return window.addEventListener("error", errorListener);
};
var initAnalytics = function (trackingConfig) {
    var gaTrackingId = getConfigValue("GA_TRACKING_ID"),
      mixpanelTrackingId = getConfigValue("MIXPANEL_TRACKING_ID");
    getConfigValue("JUTRO_DATA_DOG_CLIENT_TOKEN") && initDefaultDataDog(trackingConfig), gaTrackingId && initDefaultGA(trackingConfig), mixpanelTrackingId && initDefaultMixpanel(trackingConfig), initGlobalErrorCapture();
  },
  appRootHoc = function (Main) {
    var Root = function (props) {
      return React.createElement(ApplicationRoot, _extends({
        main: Main
      }, props));
    };
    return Root.displayName = "AppRoot(".concat(Main.displayName || "JutroRoot", ")"), Root;
  };
export var start = function (Main) {
  var launchProps = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
    _launchProps$config = launchProps.config,
    config = void 0 === _launchProps$config ? [] : _launchProps$config,
    trackingConfig = launchProps.trackingConfig,
    rootId = launchProps.rootId,
    themeConfig = launchProps.themeConfig,
    _launchProps$themePro = launchProps.themeProviderProps,
    themeProviderProps = void 0 === _launchProps$themePro ? {} : _launchProps$themePro,
    disableThemeProvider = launchProps.disableThemeProvider,
    onRender = launchProps.onRender,
    onInit = launchProps.onInit,
    appProps = _objectWithoutProperties(launchProps, _excluded);
  loadConfiguration.apply(void 0, _toConsumableArray(config)), initAnalytics(trackingConfig), null == onInit || onInit();
  var CachedRoot = getCachedRoot(Main, getHotRoot(appRootHoc(Main))),
    WrappedComponent = disableThemeProvider ? React.createElement(CachedRoot, appProps) : React.createElement(ThemeProvider, _extends({
      initialConfig: themeConfig
    }, themeProviderProps), React.createElement(CachedRoot, appProps));
  ReactDOM.render(WrappedComponent, document.getElementById(rootId), onRender);
};
var rootCache = new _WeakMap();
function getCachedRoot(main, defaultRoot) {
  return rootCache.has(main) ? rootCache.get(main) : (rootCache.set(main, defaultRoot), defaultRoot);
}
function getHotRoot(Root) {
  var _module;
  return "production" !== process.env.NODE_ENV && null !== (_module = module) && void 0 !== _module && _module.hot && !0 !== process.env.JUTRO_WP5_BUILD ? (0, require("react-hot-loader").hot)(module)(Root) : Root;
}