import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["children", "component", "render"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { useAuth } from "./AuthContext";
var secureRoutePropTypes = _objectSpread(_objectSpread({}, Route.propTypes), {}, {
  children: PropTypes.func,
  component: PropTypes.elementType,
  render: PropTypes.func
});
export var SecureRoute = function (_ref) {
  var children = _ref.children,
    Component = _ref.component,
    render = _ref.render,
    props = _objectWithoutProperties(_ref, _excluded),
    auth = useAuth();
  return !auth || auth.authenticated || auth.isPending || auth.login(), React.createElement(Route, props, function (routeProps) {
    return children ? children(_objectSpread(_objectSpread({}, routeProps), {}, {
      auth: auth
    })) : Component ? auth ? (null == auth ? void 0 : auth.authenticated) && React.createElement(Component, routeProps) : React.createElement(Component, routeProps) : render ? render(_objectSpread(_objectSpread({}, routeProps), {}, {
      auth: auth
    })) : null;
  });
};
SecureRoute.propTypes = secureRoutePropTypes, SecureRoute.displayName = "SecureRoute", SecureRoute.__docgenInfo = {
  description: "Route which requires authentication\nInspired by Octa's SecureRoute\nhttps://github.com/okta/okta-oidc-js/blob/master/packages/okta-react/src/SecureRoute.js\nhttps://github.com/okta/okta-oidc-js/blob/8fe896a383fc5e02999d63068175e72bec538115/packages/okta-react/src/SecureRoute.js\n\n@type {React.FC<PropTypes.InferProps<typeof secureRoutePropTypes>>}",
  methods: [],
  displayName: "SecureRoute",
  props: {
    children: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Functions called when rendering route. Takes precedence over component and render\n({ auth: Auth, ...routeProps }) => React.ReactNode"
    },
    component: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Component to be render when authenticated. Takes precedence over render"
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Functions called when rendering route\n({ auth: Auth, ...routeProps }) => React.ReactNode"
    }
  },
  composes: ["react-router-dom"]
}, SecureRoute.__docgenInfo = {
  componentName: "SecureRoute",
  packageName: "@jutro/auth",
  description: "Route which requires authentication\nInspired by Octa's SecureRoute\nhttps://github.com/okta/okta-oidc-js/blob/master/packages/okta-react/src/SecureRoute.js\nhttps://github.com/okta/okta-oidc-js/blob/8fe896a383fc5e02999d63068175e72bec538115/packages/okta-react/src/SecureRoute.js",
  displayName: "SecureRoute",
  methods: [],
  actualName: "SecureRoute",
  props: {
    children: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Functions called when rendering route. Takes precedence over component and render\n({ auth: Auth, ...routeProps }) => React.ReactNode"
    },
    component: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Component to be render when authenticated. Takes precedence over render"
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Functions called when rendering route\n({ auth: Auth, ...routeProps }) => React.ReactNode"
    }
  },
  composes: ["react-router-dom"]
};