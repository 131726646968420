import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["icon", "onClick", "className", "label", "isEditTrigger", "singleActionType", "isOpen"],
  _excluded2 = ["isOpen", "menuId", "ref"],
  _excluded3 = ["label", "icon", "onClick", "isEditTrigger", "singleActionType", "id"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { Children } from "react";
import PropTypes from "prop-types";
import { Button, Link, Tooltip, DropdownMenu, DropdownMenuLink } from "@jutro/components";
import { uniqueInnerId } from "@jutro/platform";
import { BreakpointTracker } from "@jutro/layout";
import { ComponentPropTypes } from "@jutro/prop-types";
import cx from "classnames";
import { TableColumn } from "../table/TableColumn";
import { ActionItem } from "./ActionItem";
import styles from "./ActionColumn.module.css";
import tableStyles from "../DataTable.module.css";
export var ActionColumn = function () {
  return React.createElement(React.Fragment, null);
};
ActionColumn.propTypes = _objectSpread(_objectSpread({}, TableColumn.propTypes), {}, {
  singleActionType: PropTypes.oneOf(["icon", "link"]),
  wrapActions: PropTypes.oneOfType([PropTypes.oneOf(["never", "always"]), PropTypes.shape({
    allWhen: PropTypes.number
  }), PropTypes.shape({
    maxItems: PropTypes.number
  })]),
  hideIconsInDropdown: PropTypes.bool,
  children: ComponentPropTypes.childOfComponentType(ActionItem),
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
}), ActionColumn.getRowActions = function (_ref) {
  var row = _ref.row,
    rowId = _ref.rowId,
    props = _ref.props,
    onEditStart = _ref.onEditStart,
    actionsProps = getBreakpointProps(props.children, props.breakpoint);
  return _mapInstanceProperty(actionsProps).call(actionsProps, function (_ref2) {
    var onClick = _ref2.onClick,
      triggerOnRowClick = _ref2.triggerOnRowClick,
      isEditTrigger = _ref2.isEditTrigger;
    if (triggerOnRowClick) return isEditTrigger ? function () {
      return onEditStart(row, rowId);
    } : function () {
      return onClick(row, rowId);
    };
  });
};
ActionColumn.defaultCell = function (row, rowId, props, onEditStart) {
  var children = props.children,
    visible = props.visible,
    disableActions = props.disableActions,
    wrapActions = props.wrapActions,
    hideIconsInDropdown = props.hideIconsInDropdown,
    breakpoint = props.breakpoint,
    tooltip = props.tooltip,
    _props$translator = props.translator,
    translator = void 0 === _props$translator ? function (str) {
      return str;
    } : _props$translator;
  if (!1 === visible || disableActions || !children) return null;
  var _splitActions = function (wrapActions, actions) {
      if ("never" === wrapActions) return {
        inlineActions: actions,
        dropdownActions: []
      };
      if ("always" === wrapActions) return {
        inlineActions: [],
        dropdownActions: actions
      };
      if ("object" === _typeof(wrapActions)) {
        var allWhen = wrapActions.allWhen,
          maxItems = wrapActions.maxItems;
        if (allWhen) return allWhen <= actions.length ? {
          inlineActions: [],
          dropdownActions: actions
        } : {
          inlineActions: actions,
          dropdownActions: []
        };
        if (maxItems) return maxItems >= actions.length ? {
          inlineActions: actions,
          dropdownActions: []
        } : {
          inlineActions: _sliceInstanceProperty(actions).call(actions, 0, maxItems - 1),
          dropdownActions: _sliceInstanceProperty(actions).call(actions, maxItems - 1)
        };
      }
      return {
        inlineActions: actions,
        dropdownActions: []
      };
    }(wrapActions, getBreakpointProps(children, breakpoint)),
    inlineActions = _splitActions.inlineActions,
    dropdownActions = _splitActions.dropdownActions,
    menu = dropdownActions.length > 0 ? renderActionMenu(dropdownActions, row, rowId, onEditStart, hideIconsInDropdown, translator) : null,
    getTooltipForButton = function (buttonProps) {
      return !1 !== tooltip && _objectSpread(_objectSpread({}, tooltip), buttonProps.tooltip);
    },
    inline = 1 === inlineActions.length ? renderOneAction(inlineActions[0], row, rowId, onEditStart, translator) : _mapInstanceProperty(inlineActions).call(inlineActions, function (buttonProps, buttonIndex) {
      return renderActionButton(_objectSpread(_objectSpread({}, buttonProps), {}, {
        tooltip: getTooltipForButton(buttonProps)
      }), row, rowId, buttonIndex, onEditStart, translator);
    });
  return React.createElement(React.Fragment, null, inline, menu && React.createElement("div", {
    className: styles.dropdownMenuWrapper
  }, menu));
};
export function renderOneAction(actionProps, row, rowId, onEditStart) {
  var translator = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : function (str) {
      return str;
    },
    icon = actionProps.icon,
    onClick = actionProps.onClick,
    className = actionProps.className,
    label = actionProps.label,
    isEditTrigger = actionProps.isEditTrigger,
    singleActionType = actionProps.singleActionType,
    isOpen = actionProps.isOpen,
    other = _objectWithoutProperties(actionProps, _excluded),
    classes = cx(styles.actionItem, _defineProperty({}, styles.focusedActionItem, isOpen), className),
    handleOnClick = isEditTrigger ? onEditStart : onClick,
    onClickHandler = function () {
      return handleOnClick(row, rowId);
    };
  return "icon" === singleActionType && icon ? renderActionButton(actionProps, row, rowId, 0, onEditStart, translator) : "link" === singleActionType ? React.createElement(Link, _extends({
    className: classes,
    icon: icon,
    onClick: onClickHandler
  }, other), translator(label)) : React.createElement(Button, _extends({
    className: classes,
    onClick: onClickHandler,
    size: "small",
    icon: icon
  }, other), translator(label));
}
export function renderActionButton(_ref3, row, rowId) {
  var _translator,
    _tooltip$content,
    icon = _ref3.icon,
    onClick = _ref3.onClick,
    onKeyDown = _ref3.onKeyDown,
    className = _ref3.className,
    isEditTrigger = _ref3.isEditTrigger,
    isOpen = _ref3.isOpen,
    label = _ref3.label,
    ariaLabel = _ref3.ariaLabel,
    disabled = _ref3.disabled,
    tooltip = _ref3.tooltip,
    ref = _ref3.ref,
    key = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : rowId,
    onEditStart = arguments.length > 4 ? arguments[4] : void 0,
    translator = arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : function (str) {
      return str;
    },
    classes = cx(styles.actionItem, styles.actionButton, _defineProperty({}, styles.focusedActionItem, isOpen), className),
    handleOnClick = isEditTrigger ? onEditStart : onClick,
    onClickHandler = function () {
      return handleOnClick(row, rowId);
    },
    btnLabel = ariaLabel || (null === (_translator = translator(label)) || void 0 === _translator ? void 0 : _translator.toLowerCase()) || icon,
    btnContent = React.createElement(Button, {
      key: "".concat(key),
      className: classes,
      onClick: onClickHandler,
      onKeyDown: onKeyDown,
      size: "small",
      type: "text",
      icon: icon,
      disabled: disabled,
      "aria-label": btnLabel,
      "aria-expanded": isOpen,
      tabIndex: "0",
      ref: ref
    }),
    tooltipContent = null !== (_tooltip$content = null == tooltip ? void 0 : tooltip.content) && void 0 !== _tooltip$content ? _tooltip$content : label,
    showTooltip = tooltip && tooltipContent;
  return showTooltip ? React.createElement(Tooltip, _extends({
    id: "tooltip-".concat(key),
    key: "tooltip-".concat(key)
  }, tooltip, {
    content: tooltipContent
  }), btnContent) : btnContent;
}
export function renderActionMenu(actionsProps, row, rowId, onEditStart, hideIconsInDropdown) {
  var toggleDropdownMenu,
    translator = arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : function (str) {
      return str;
    },
    handleRenderTrigger = function (props, toggleMenu) {
      var isOpen = props.isOpen,
        ref = (props.menuId, props.ref),
        rest = _objectWithoutProperties(props, _excluded2);
      toggleDropdownMenu = toggleMenu;
      return renderActionButton(_objectSpread(_objectSpread({}, rest), {}, {
        icon: "gw-more-horizontal",
        onClick: function () {
          return toggleMenu(!isOpen);
        },
        isOpen: isOpen,
        ariaLabel: "View more",
        ref: ref
      }), row, rowId, translator);
    },
    _uniqueInnerId = uniqueInnerId("".concat(rowId), "menuId"),
    menuId = _uniqueInnerId.menuId,
    renderDropdownMenuItem = function (props, linkIndex) {
      var _context,
        label = props.label,
        icon = props.icon,
        onClick = props.onClick,
        isEditTrigger = props.isEditTrigger,
        id = (props.singleActionType, props.id),
        rest = _objectWithoutProperties(props, _excluded3),
        handleOnClick = isEditTrigger ? onEditStart : onClick,
        linkId = _concatInstanceProperty(_context = "".concat(menuId, "__")).call(_context, id || rowId),
        labelClassName = cx(_defineProperty({}, styles.menuItemLabel, !hideIconsInDropdown)),
        labelComponent = React.createElement("span", {
          className: labelClassName
        }, translator(label));
      return React.createElement(DropdownMenuLink, _extends({
        id: linkId,
        key: "".concat(linkIndex),
        onClick: function (e) {
          var _toggleDropdownMenu;
          null == e || e.stopPropagation(), null == e || e.preventDefault(), null === (_toggleDropdownMenu = toggleDropdownMenu) || void 0 === _toggleDropdownMenu || _toggleDropdownMenu(!1), handleOnClick(row, rowId);
        },
        icon: hideIconsInDropdown ? void 0 : icon
      }, rest), labelComponent);
    },
    popperConfig = {
      modifiers: [{
        name: "preventOverflow",
        options: {
          mainAxis: !1
        }
      }]
    };
  return React.createElement("div", {
    className: styles.dropdownMenu
  }, React.createElement(DropdownMenu, {
    id: menuId,
    renderTrigger: handleRenderTrigger,
    menuClassName: styles.menu,
    alignRight: !0,
    popperOptions: popperConfig
  }, _mapInstanceProperty(actionsProps).call(actionsProps, renderDropdownMenuItem)));
}
function getBreakpointProps(children, breakpoint) {
  var _context2, _context3;
  return _mapInstanceProperty(_context2 = _filterInstanceProperty(_context3 = Children.toArray(children)).call(_context3, Boolean)).call(_context2, function (_ref5) {
    var props = _ref5.props;
    return BreakpointTracker.applyBreakpointOverrides(props, breakpoint);
  });
}
ActionColumn.editCell = function (_ref4) {
  var editActions = _ref4.editActions,
    save = editActions.save,
    cancel = editActions.cancel,
    classes = cx(styles.actionButton, styles.editButton),
    cancelClasses = cx(classes, styles.cancelButton);
  return React.createElement(React.Fragment, null, React.createElement(Button, {
    className: classes,
    onClick: save,
    size: "small",
    icon: "gw-check",
    tabIndex: "0"
  }), React.createElement(Button, {
    className: cancelClasses,
    onClick: cancel,
    size: "small",
    type: "text",
    icon: "gw-close",
    tabIndex: "0"
  }));
}, ActionColumn.displayName = "ActionColumn", ActionColumn.defaultProps = {
  renderCell: ActionColumn.defaultCell,
  renderEditCell: ActionColumn.editCell,
  getRowActions: ActionColumn.getRowActions,
  columnClassName: tableStyles.actionColumn,
  cellClassName: styles.actionColumnCell,
  wrapActions: {
    allWhen: 3
  },
  hideIconsInDropdown: !1,
  width: 120,
  sortable: !1,
  textAlign: "right",
  onFilter: function () {
    return function () {
      return !1;
    };
  }
}, ActionColumn.__docgenInfo = {
  description: "ActionColumn\n@returns {null} - The ActionColumn component does not render anything\n\n@metadataType container",
  methods: [{
    name: "getRowActions",
    docblock: "Render action button(s) or ellipsis with dropdown menu of actions\n\n@param {object} row - the row to be rendered from data table\n@param {number} index - the index of the row\n@param {object} props - the properties used by ActionColumn\n@param {Function} onEditStart - is called when clicked on action item with `isEditTrigger` prop enabled\n@returns {React.ReactElement}",
    modifiers: ["static"],
    params: [{
      name: "{ row, rowId, props, onEditStart }"
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render action button(s) or ellipsis with dropdown menu of actions"
  }, {
    name: "defaultCell",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "row",
      optional: !1,
      type: null
    }, {
      name: "rowId",
      optional: !1,
      type: null
    }, {
      name: "props",
      optional: !1,
      type: null
    }, {
      name: "onEditStart",
      optional: !1,
      type: null
    }],
    returns: null
  }, {
    name: "editCell",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ editActions }",
      type: null
    }],
    returns: null
  }],
  displayName: "ActionColumn",
  props: {
    renderCell: {
      defaultValue: {
        value: "ActionColumn.defaultCell",
        computed: !0
      },
      required: !1
    },
    renderEditCell: {
      defaultValue: {
        value: "ActionColumn.editCell",
        computed: !0
      },
      required: !1
    },
    getRowActions: {
      defaultValue: {
        value: "ActionColumn.getRowActions",
        computed: !0
      },
      required: !1
    },
    columnClassName: {
      defaultValue: {
        value: "tableStyles.actionColumn",
        computed: !0
      },
      required: !1
    },
    cellClassName: {
      defaultValue: {
        value: "styles.actionColumnCell",
        computed: !0
      },
      required: !1
    },
    wrapActions: {
      defaultValue: {
        value: "{ allWhen: 3 }",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'never'",
            computed: !1
          }, {
            value: "'always'",
            computed: !1
          }]
        }, {
          name: "shape",
          value: {
            allWhen: {
              name: "number",
              required: !1
            }
          }
        }, {
          name: "shape",
          value: {
            maxItems: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.'
    },
    hideIconsInDropdown: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, only labels are displayed in the dropdown with actions, icons are hidden"
    },
    width: {
      defaultValue: {
        value: "120",
        computed: !1
      },
      required: !1
    },
    sortable: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    },
    textAlign: {
      defaultValue: {
        value: "'right'",
        computed: !1
      },
      required: !1
    },
    onFilter: {
      defaultValue: {
        value: "() => () => false",
        computed: !1
      },
      required: !1
    },
    singleActionType: {
      type: {
        name: "enum",
        value: [{
          value: "'icon'",
          computed: !1
        }, {
          value: "'link'",
          computed: !1
        }]
      },
      required: !1,
      description: "Specifies the type of action 'icon' or 'link', when one action is rendered.\nBy default renders a `Button` action."
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(ActionItem)"
      },
      required: !1,
      description: "List of ActionItems"
    },
    tooltip: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "object"
        }]
      },
      required: !1,
      description: "Tooltip configuration which is shared between all actions\n- false - disables tooltips in column\n- object - has the same fields as a regular tooltip component"
    }
  },
  composes: ["../table/TableColumn"]
}, renderOneAction.__docgenInfo = {
  description: "Render the button with action defined on onclick listener\n\n@param {object} actionProps - The action properties provided by application\n@param {object} row - Current row to be rendered from the data table\n@param {number | string} rowId - The id of the current row\n@param {Function} onEditStart - is called when clicked on action item with `isEditTrigger` prop enabled\n@returns {React.ReactElement}",
  methods: [],
  displayName: "renderOneAction"
}, renderActionButton.__docgenInfo = {
  description: "Render the button with action defined on onclick listener\n\n@param {object} actionProps - The action properties provided by application\n@param {object} row - Current row to be rendered from the data table\n@param {number | string} rowId - The id of the current row\n@param {number} key - Unique key for the component\n@param {Function} onEditStart - is called when clicked on action item with `isEditTrigger` prop enabled\n@returns {React.ReactElement}",
  methods: [],
  displayName: "renderActionButton"
}, renderActionMenu.__docgenInfo = {
  description: "Render ellipsis with dropdown menu of actions\n\n@param {object} actionsProps - The action's children properties provided by application\n@param {object} row - Current row to be rendered from the data table\n@param {number | string} rowId - The id of the current row\n@param {Function} onEditStart - is called when clicked on action item with `isEditTrigger` prop enabled\n@returns {React.ReactElement}",
  methods: [],
  displayName: "renderActionMenu"
}, ActionColumn.__docgenInfo = {
  componentName: "ActionColumn",
  packageName: "@jutro/datatable",
  description: "ActionColumn",
  displayName: "ActionColumn",
  methods: [{
    name: "getRowActions",
    docblock: "Render action button(s) or ellipsis with dropdown menu of actions\n\n@param {object} row - the row to be rendered from data table\n@param {number} index - the index of the row\n@param {object} props - the properties used by ActionColumn\n@param {Function} onEditStart - is called when clicked on action item with `isEditTrigger` prop enabled\n@returns {React.ReactElement}",
    modifiers: ["static"],
    params: [{
      name: "{ row, rowId, props, onEditStart }"
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render action button(s) or ellipsis with dropdown menu of actions"
  }, {
    name: "defaultCell",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "row",
      optional: !1,
      type: null
    }, {
      name: "rowId",
      optional: !1,
      type: null
    }, {
      name: "props",
      optional: !1,
      type: null
    }, {
      name: "onEditStart",
      optional: !1,
      type: null
    }],
    returns: null
  }, {
    name: "editCell",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ editActions }",
      optional: void 0,
      type: null
    }],
    returns: null
  }],
  actualName: "ActionColumn",
  metadataType: "container",
  props: {
    singleActionType: {
      type: {
        name: "enum",
        value: [{
          value: "'icon'",
          computed: !1
        }, {
          value: "'link'",
          computed: !1
        }]
      },
      required: !1,
      description: "Specifies the type of action 'icon' or 'link', when one action is rendered.\nBy default renders a `Button` action."
    },
    wrapActions: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'never'",
            computed: !1
          }, {
            value: "'always'",
            computed: !1
          }]
        }, {
          name: "shape",
          value: {
            allWhen: {
              name: "number",
              required: !1
            }
          }
        }, {
          name: "shape",
          value: {
            maxItems: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.',
      defaultValue: {
        value: "{ allWhen: 3 }",
        computed: !1
      }
    },
    hideIconsInDropdown: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, only labels are displayed in the dropdown with actions, icons are hidden",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(ActionItem)"
      },
      required: !1,
      description: "List of ActionItems"
    },
    tooltip: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "object"
        }]
      },
      required: !1,
      description: "Tooltip configuration which is shared between all actions\n- false - disables tooltips in column\n- object - has the same fields as a regular tooltip component"
    },
    renderCell: {
      defaultValue: {
        value: "ActionColumn.defaultCell",
        computed: !0
      },
      required: !1
    },
    renderEditCell: {
      defaultValue: {
        value: "ActionColumn.editCell",
        computed: !0
      },
      required: !1
    },
    getRowActions: {
      defaultValue: {
        value: "ActionColumn.getRowActions",
        computed: !0
      },
      required: !1
    },
    columnClassName: {
      defaultValue: {
        value: "tableStyles.actionColumn",
        computed: !0
      },
      required: !1
    },
    cellClassName: {
      defaultValue: {
        value: "styles.actionColumnCell",
        computed: !0
      },
      required: !1
    },
    width: {
      defaultValue: {
        value: "120",
        computed: !1
      },
      required: !1
    },
    sortable: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    },
    textAlign: {
      defaultValue: {
        value: "'right'",
        computed: !1
      },
      required: !1
    },
    onFilter: {
      defaultValue: {
        value: "() => () => false",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["../table/TableColumn"]
}, renderOneAction.__docgenInfo = {
  componentName: "renderOneAction",
  packageName: "@jutro/datatable",
  description: "Render the button with action defined on onclick listener",
  displayName: "renderOneAction",
  methods: [],
  actualName: "renderOneAction"
}, renderActionButton.__docgenInfo = {
  componentName: "renderActionButton",
  packageName: "@jutro/datatable",
  description: "Render the button with action defined on onclick listener",
  displayName: "renderActionButton",
  methods: [],
  actualName: "renderActionButton"
}, renderActionMenu.__docgenInfo = {
  componentName: "renderActionMenu",
  packageName: "@jutro/datatable",
  description: "Render ellipsis with dropdown menu of actions",
  displayName: "renderActionMenu",
  methods: [],
  actualName: "renderActionMenu"
};