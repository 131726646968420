import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _sortInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/sort";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
export var ScrollToError = function (_PureComponent) {
  _inherits(ScrollToError, PureComponent);
  var _super = _createSuper(ScrollToError);
  function ScrollToError() {
    var _context, _this;
    _classCallCheck(this, ScrollToError);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "isVisible", function (element) {
      var elementStyle = window.getComputedStyle(element);
      return !("" === element.getAttribute("hidden") || "none" === elementStyle.display || "hidden" === elementStyle.visibility || "collapse" === elementStyle.visibility || !element.offsetWidth || !element.offsetHeight || !element.getClientRects().length);
    }), _defineProperty(_assertThisInitialized(_this), "getFocusableElement", function (firstErrorField) {
      var _context2,
        parentElement = firstErrorField.parentElement,
        elementsWithTabIndexSet = _Array$from(parentElement.querySelectorAll("[tabindex]:not([tabindex='-1'])")),
        elementsWithTabIndexEmbedded = _Array$from(parentElement.querySelectorAll("input, button, select, textarea, a[href]")),
        possiblyFocusableElements = _concatInstanceProperty(elementsWithTabIndexSet).call(elementsWithTabIndexSet, elementsWithTabIndexEmbedded),
        _possiblyFocusableEle = _sortInstanceProperty(_context2 = _filterInstanceProperty(possiblyFocusableElements).call(possiblyFocusableElements, function (el) {
          return el.tabIndex >= 0 && !el.disabled && _this.isVisible(el);
        })).call(_context2, function (el1, el2) {
          return el1.tabIndex - el2.tabIndex;
        });
      return _slicedToArray(_possiblyFocusableEle, 1)[0] || parentElement;
    }), _this;
  }
  return _createClass(ScrollToError, [{
    key: "componentDidUpdate",
    value: function () {
      var containerNode = ReactDOM.findDOMNode(this).parentNode;
      this.scrollToError(containerNode);
    }
  }, {
    key: "scrollToError",
    value: function (containerNode) {
      var initialQuery = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "div[role=alert][class*=error]";
      if (containerNode) {
        var errorFields = _Array$from(containerNode.querySelectorAll(initialQuery));
        if (0 === errorFields.length && "div[role=alert][class*=error]" === initialQuery) return void this.scrollToError(containerNode, "div[role=alert][class*=fieldMessage]");
        var firstErrorField = _findInstanceProperty(errorFields).call(errorFields, function (elem) {
          return elem.offsetTop > 0 && elem.offsetHeight > 0;
        }) || errorFields[0];
        if (firstErrorField) {
          var element = this.getFocusableElement(firstErrorField);
          element && element.focus({
            preventScroll: !0
          }), setTimeout(function () {
            firstErrorField.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, this.props.timeout);
        }
      }
    }
  }, {
    key: "render",
    value: function () {
      return "";
    }
  }]), ScrollToError;
}();
_defineProperty(ScrollToError, "propTypes", {
  counter: PropTypes.number,
  timeout: PropTypes.number
}), _defineProperty(ScrollToError, "defaultProps", {
  counter: 0,
  timeout: 0
}), ScrollToError.__docgenInfo = {
  description: "ScrollToError is an Jutro component.\n\nThis is a helper component that can be added to\na page where form fields are displayed and validated.\nThis doesn't render anything, but uses the side effects\nof React lifecycle to inspect the DOM and scroll to the\nfirst field in error.\n\n@example\nthis.setState({errorTimestamp: Date.now()});\n<ScrollToError counter={this.state.errorTimestamp} />\n\n@extends PureComponent<{}>",
  methods: [{
    name: "isVisible",
    docblock: "Function to retrieve element visibility on the screen\n@param {Node} element - element to check its visibility\n@returns either if the element is visible or not",
    modifiers: [],
    params: [{
      name: "element",
      description: "element to check its visibility",
      type: {
        name: "Node"
      },
      optional: !1
    }],
    returns: {
      description: "either if the element is visible or not",
      type: null
    },
    description: "Function to retrieve element visibility on the screen"
  }, {
    name: "getFocusableElement",
    docblock: "Function to retrieve the next element to focus on\n@param {Node} firstErrorField - container node that contains the error message\n@returns the next focusable element or its parentElement if the query has not succeed",
    modifiers: [],
    params: [{
      name: "firstErrorField",
      description: "container node that contains the error message",
      type: {
        name: "Node"
      },
      optional: !1
    }],
    returns: {
      description: "the next focusable element or its parentElement if the query has not succeed",
      type: null
    },
    description: "Function to retrieve the next element to focus on"
  }, {
    name: "scrollToError",
    docblock: "Searches for error fields in the container node and then scrolls to the first one\n\n@param {Node} containerNode - container node to search for error fields",
    modifiers: [],
    params: [{
      name: "containerNode",
      description: "container node to search for error fields",
      type: {
        name: "Node"
      },
      optional: !1
    }, {
      name: "initialQuery"
    }],
    returns: null,
    description: "Searches for error fields in the container node and then scrolls to the first one"
  }],
  displayName: "ScrollToError",
  props: {
    counter: {
      defaultValue: {
        value: "0",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "A counter that should be incremented to trigger a scroll to error"
    },
    timeout: {
      defaultValue: {
        value: "0",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Timeout used when the page is using some transition and we need to wait\nit finish to verify errors, e.g., coverages inside an accordion"
    }
  }
}, ScrollToError.__docgenInfo = {
  componentName: "ScrollToError",
  packageName: "@jutro/wizard-next",
  description: "ScrollToError is an Jutro component.\n\nThis is a helper component that can be added to\na page where form fields are displayed and validated.\nThis doesn't render anything, but uses the side effects\nof React lifecycle to inspect the DOM and scroll to the\nfirst field in error.",
  displayName: "ScrollToError",
  methods: [{
    name: "isVisible",
    docblock: "Function to retrieve element visibility on the screen\n@param {Node} element - element to check its visibility\n@returns either if the element is visible or not",
    modifiers: [],
    params: [{
      name: "element",
      description: "element to check its visibility",
      type: {
        name: "Node"
      },
      optional: !1
    }],
    returns: {
      description: "either if the element is visible or not",
      type: null
    },
    description: "Function to retrieve element visibility on the screen"
  }, {
    name: "getFocusableElement",
    docblock: "Function to retrieve the next element to focus on\n@param {Node} firstErrorField - container node that contains the error message\n@returns the next focusable element or its parentElement if the query has not succeed",
    modifiers: [],
    params: [{
      name: "firstErrorField",
      description: "container node that contains the error message",
      type: {
        name: "Node"
      },
      optional: !1
    }],
    returns: {
      description: "the next focusable element or its parentElement if the query has not succeed",
      type: null
    },
    description: "Function to retrieve the next element to focus on"
  }, {
    name: "scrollToError",
    docblock: "Searches for error fields in the container node and then scrolls to the first one\n\n@param {Node} containerNode - container node to search for error fields",
    modifiers: [],
    params: [{
      name: "containerNode",
      description: "container node to search for error fields",
      type: {
        name: "Node"
      },
      optional: !1
    }, {
      name: "initialQuery"
    }],
    returns: null,
    description: "Searches for error fields in the container node and then scrolls to the first one"
  }],
  actualName: "ScrollToError",
  props: {
    counter: {
      type: {
        name: "number"
      },
      required: !1,
      description: "A counter that should be incremented to trigger a scroll to error",
      defaultValue: {
        value: "0",
        computed: !1
      }
    },
    timeout: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Timeout used when the page is using some transition and we need to wait\nit finish to verify errors, e.g., coverages inside an accordion",
      defaultValue: {
        value: "0",
        computed: !1
      }
    }
  }
};