import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["isOpen", "timeout", "collapsedSize", "isHorizontal", "expandOverContent", "isReversed", "onEnter", "onEntering", "onEntered", "onExit", "onExiting", "onExited", "mountOnEnter", "unmountOnExit", "children", "className", "outerContentWrapperClassName", "contentWrapperClassName", "allowOverflowContent", "id"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import cx from "classnames";
import { useIsMounted } from "@jutro/platform";
import styles from "./Collapse.module.css";
var timeoutShape = {
    enter: PropTypes.number,
    exit: PropTypes.number,
    appear: PropTypes.number
  },
  collapsePropTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    timeout: PropTypes.oneOfType([PropTypes.shape(timeoutShape), PropTypes.number]),
    collapsedSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isHorizontal: PropTypes.bool,
    expandOverContent: PropTypes.bool,
    isReversed: PropTypes.bool,
    onEnter: PropTypes.func,
    onEntering: PropTypes.func,
    onEntered: PropTypes.func,
    onExit: PropTypes.func,
    onExiting: PropTypes.func,
    onExited: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    outerContentWrapperClassName: PropTypes.string,
    contentWrapperClassName: PropTypes.string,
    allowOverflowContent: PropTypes.bool,
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool
  };
export var Collapse = function (_ref) {
  var _cx2,
    isOpen = _ref.isOpen,
    _ref$timeout = _ref.timeout,
    timeoutProp = void 0 === _ref$timeout ? 350 : _ref$timeout,
    _ref$collapsedSize = _ref.collapsedSize,
    collapsedSizeProp = void 0 === _ref$collapsedSize ? "0px" : _ref$collapsedSize,
    isHorizontal = _ref.isHorizontal,
    expandOverContent = _ref.expandOverContent,
    isReversed = _ref.isReversed,
    onEnter = _ref.onEnter,
    onEntering = _ref.onEntering,
    onEntered = _ref.onEntered,
    onExit = _ref.onExit,
    onExiting = _ref.onExiting,
    onExited = _ref.onExited,
    mountOnEnter = _ref.mountOnEnter,
    unmountOnExit = _ref.unmountOnExit,
    children = _ref.children,
    className = _ref.className,
    outerContentWrapperClassName = _ref.outerContentWrapperClassName,
    contentWrapperClassName = _ref.contentWrapperClassName,
    allowOverflowContent = _ref.allowOverflowContent,
    id = _ref.id,
    other = _objectWithoutProperties(_ref, _excluded),
    isMounted = useIsMounted(),
    wrapperRef = useRef(null),
    phantomBoxRef = useRef(null),
    outerContentRef = useRef(null),
    contentRef = useRef(null),
    timeout = "number" == typeof timeoutProp ? {
      enter: timeoutProp,
      exit: timeoutProp,
      appear: 0
    } : timeoutProp,
    collapsedSize = "string" == typeof collapsedSizeProp ? collapsedSizeProp : "".concat(collapsedSizeProp, "px"),
    isHidden = !isOpen && "0px" === collapsedSize,
    isUsingPhantomBox = isHorizontal && expandOverContent && isReversed,
    transitionedProperty = isHorizontal ? "width" : "height",
    getContentSize = function () {
      var property = isHorizontal ? "clientWidth" : "clientHeight";
      return contentRef.current ? "".concat(contentRef.current[property], "px") : "0";
    },
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    hasFinishedAnimation = _useState2[0],
    setHasFinishedAnimation = _useState2[1];
  useEffect(function () {
    isOpen && isUsingPhantomBox && phantomBoxRef.current && (phantomBoxRef.current.style.width = getContentSize());
  }, []), useEffect(function () {
    isOpen || isUsingPhantomBox || !outerContentRef.current || (outerContentRef.current.style[transitionedProperty] = collapsedSize);
  }, [isUsingPhantomBox, transitionedProperty, collapsedSize]);
  var wrapperInlineStyle = function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
          _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
        });
      }
      return target;
    }({}, expandOverContent && _defineProperty({}, isHorizontal ? "marginRight" : "marginBottom", collapsedSize)),
    wrapperClasses = cx(className, styles.wrapper, _defineProperty({}, styles.fullHeight, isHorizontal)),
    outerContentWrapperClasses = cx(outerContentWrapperClassName, styles.outerContentWrapper, (_defineProperty(_cx2 = {}, styles.fullHeight, isHorizontal), _defineProperty(_cx2, styles.overContent, expandOverContent && !isUsingPhantomBox), _defineProperty(_cx2, styles.hidden, isHidden), _cx2)),
    contentWrapperClasses = cx(contentWrapperClassName, styles.contentWrapper, _defineProperty({}, styles.fullHeight, isHorizontal)),
    appendNoOverflow = function (opened) {
      for (var _len = arguments.length, classes = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) classes[_key - 1] = arguments[_key];
      return cx.apply(void 0, _concatInstanceProperty(classes).call(classes, [_defineProperty({}, styles.noOverflow, opened && hasFinishedAnimation && allowOverflowContent)]));
    },
    transition = React.createElement(Transition, {
      in: isOpen,
      timeout: timeout,
      onEnter: function (node, isAppearing) {
        contentRef.current && (contentRef.current.style.position = "absolute"), isUsingPhantomBox ? (phantomBoxRef.current && (phantomBoxRef.current.style.width = getContentSize()), node.style.transform = "translateX(".concat(getContentSize(), ")")) : node.style[transitionedProperty] = collapsedSize, onEnter && onEnter(node, isAppearing);
      },
      onEntering: function (node, isAppearing) {
        var _timeout;
        contentRef.current && (contentRef.current.style.position = ""), node.style.transitionTimingFunction = "ease-in", isUsingPhantomBox ? node.style.transform = "translateX(0)" : node.style[transitionedProperty] = getContentSize();
        var duration = "object" === _typeof(timeout) ? null !== (_timeout = timeout[isAppearing ? "appear" : "enter"]) && void 0 !== _timeout ? _timeout : timeout.enter : timeout;
        node.style.transitionDuration = "".concat(duration, "ms"), onEntering && onEntering(node, isAppearing);
      },
      onEntered: function (node, isAppearing) {
        var _timeout2;
        isHorizontal || (node.style[transitionedProperty] = "auto"), onEntered && onEntered(node, isAppearing);
        var duration = "object" === _typeof(timeout) ? null !== (_timeout2 = timeout[isAppearing ? "appear" : "enter"]) && void 0 !== _timeout2 ? _timeout2 : timeout.enter : timeout;
        setTimeout(function () {
          return isMounted.current && setHasFinishedAnimation(!0);
        }, duration);
      },
      onExit: function (node) {
        isUsingPhantomBox ? node.style.transform = "translateX(0)" : node.style[transitionedProperty] = getContentSize(), onExit && onExit(node);
      },
      onExiting: function (node) {
        isUsingPhantomBox ? node.style.transform = "translateX(".concat(getContentSize(), ")") : setTimeout(function () {
          node.style[transitionedProperty] = collapsedSize;
        }, 0), node.style.transitionTimingFunction = "ease-in";
        var duration = "object" === _typeof(timeout) ? timeout.exit : timeout;
        node.style.transitionDuration = "".concat(duration, "ms"), onExiting && onExiting(node);
      },
      onExited: function (node) {
        isUsingPhantomBox && phantomBoxRef.current && (phantomBoxRef.current.style.width = "0"), onExited && onExited(node);
        var duration = "object" === _typeof(timeout) ? timeout.exit : timeout;
        node.style.transitionDuration = "".concat(duration, "ms"), setTimeout(function () {
          return isMounted.current && setHasFinishedAnimation(!1);
        }, duration);
      },
      mountOnEnter: mountOnEnter,
      unmountOnExit: unmountOnExit,
      appear: !0
    }, React.createElement("div", {
      className: appendNoOverflow(isOpen, outerContentWrapperClasses),
      ref: outerContentRef,
      "data-testid": "collapse-outer-content-wrapper"
    }, React.createElement("div", {
      className: appendNoOverflow(isOpen, contentWrapperClasses),
      ref: contentRef
    }, children)));
  return React.createElement("div", _extends({
    id: id,
    className: wrapperClasses,
    style: wrapperInlineStyle,
    ref: wrapperRef
  }, other), isUsingPhantomBox ? React.createElement("div", {
    className: styles.phantomBox,
    ref: phantomBoxRef
  }, transition) : transition);
};
Collapse.propTypes = collapsePropTypes, Collapse.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Collapse",
  props: {
    timeout: {
      defaultValue: {
        value: "350",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            enter: {
              name: "number",
              required: !1
            },
            exit: {
              name: "number",
              required: !1
            },
            appear: {
              name: "number",
              required: !1
            }
          }
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Duration of the transition, in milliseconds."
    },
    collapsedSize: {
      defaultValue: {
        value: "'0px'",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Width/height of the component in collapsed state"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, component will expand, if false component will collapse"
    },
    isHorizontal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, transition orientation will be horizontal (width will be transitioned)\nIf false, it will be vertical (height will be transitioned)"
    },
    expandOverContent: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, absolute positioning will be used and element will expand over page content\nIf false, content will be squeezed"
    },
    isReversed: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "When collapse expands within document flow (that is, expandOverContent is set to false) direction of expansion is determined by container.\nHowever, when we use absolute positioning it always expands right/bottom.\nIf this prop is true, collapse will expand reverse direction (for example in right side bar with expandOverContent set to true).\nNOTE: it only takes effect when expandOverContent and isHorizontal are true."
    },
    onEnter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired before the “entering” status is applied.\nAn extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount"
    },
    onEntering: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired after the “entering” status is applied.\nAn extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount"
    },
    onEntered: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired after the “entered” status is applied.\nAn extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount"
    },
    onExit: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired before the “exiting” status is applied."
    },
    onExiting: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired after the “exiting” status is applied."
    },
    onExited: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired after the “exited” status is applied."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Expanding/collapsing element"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS classname applied to root element"
    },
    outerContentWrapperClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS classname applied to direct Transition child element"
    },
    contentWrapperClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class applied to element that wraps the content"
    },
    allowOverflowContent: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "allow inner content to overflow container"
    },
    mountOnEnter: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'By default the child component is mounted immediately along with the\nparent Collapse component. If you want to "lazy mount" the component on\nthe first `in={true}` you can set `mountOnEnter`. After the first enter\ntransition the component will stay mounted, even on "exited", unless you\nalso specify `unmountOnExit`.'
    },
    unmountOnExit: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "By default the child component stays mounted after it reaches the\n'exited' state. Set `unmountOnExit` if you'd prefer to unmount the\ncomponent after it finishes exiting."
    }
  }
}, Collapse.__docgenInfo = {
  componentName: "Collapse",
  packageName: "@jutro/components",
  description: "",
  displayName: "Collapse",
  methods: [],
  actualName: "Collapse",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, component will expand, if false component will collapse"
    },
    timeout: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            enter: {
              name: "number",
              required: !1
            },
            exit: {
              name: "number",
              required: !1
            },
            appear: {
              name: "number",
              required: !1
            }
          }
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Duration of the transition, in milliseconds.",
      defaultValue: {
        value: "350",
        computed: !1
      }
    },
    collapsedSize: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Width/height of the component in collapsed state",
      defaultValue: {
        value: "'0px'",
        computed: !1
      }
    },
    isHorizontal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, transition orientation will be horizontal (width will be transitioned)\nIf false, it will be vertical (height will be transitioned)"
    },
    expandOverContent: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, absolute positioning will be used and element will expand over page content\nIf false, content will be squeezed"
    },
    isReversed: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "When collapse expands within document flow (that is, expandOverContent is set to false) direction of expansion is determined by container.\nHowever, when we use absolute positioning it always expands right/bottom.\nIf this prop is true, collapse will expand reverse direction (for example in right side bar with expandOverContent set to true).\nNOTE: it only takes effect when expandOverContent and isHorizontal are true."
    },
    onEnter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired before the “entering” status is applied.\nAn extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount"
    },
    onEntering: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired after the “entering” status is applied.\nAn extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount"
    },
    onEntered: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired after the “entered” status is applied.\nAn extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount"
    },
    onExit: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired before the “exiting” status is applied."
    },
    onExiting: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired after the “exiting” status is applied."
    },
    onExited: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback fired after the “exited” status is applied."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Expanding/collapsing element"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS classname applied to root element"
    },
    outerContentWrapperClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS classname applied to direct Transition child element"
    },
    contentWrapperClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class applied to element that wraps the content"
    },
    allowOverflowContent: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "allow inner content to overflow container"
    },
    mountOnEnter: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'By default the child component is mounted immediately along with the\nparent Collapse component. If you want to "lazy mount" the component on\nthe first `in={true}` you can set `mountOnEnter`. After the first enter\ntransition the component will stay mounted, even on "exited", unless you\nalso specify `unmountOnExit`.'
    },
    unmountOnExit: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "By default the child component stays mounted after it reaches the\n'exited' state. Set `unmountOnExit` if you'd prefer to unmount the\ncomponent after it finishes exiting."
    }
  }
};