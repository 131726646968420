import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["navLink"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { Collapse, IconButton } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { contextSwitcherShape, routesShape } from "@jutro/prop-types";
import { shouldShowIcons, removeNavLinkIcon } from "../navBarHelper";
import styles from "./SideNavigation.module.css";
import { messages } from "./SideNavigation.messages";
import { NavigationContent } from "../NavigationContent";
import { NavigationContext } from "../NavigationContext";
import { NavBarAccordion } from "./NavBarAccordion/NavBarAccordion";
export var SideNavigation = function (_ref) {
  var className = _ref.className,
    routes = _ref.routes,
    contextSwitcher = _ref.contextSwitcher,
    renderContextSwitcher = _ref.renderContextSwitcher,
    collapsibleProp = _ref.collapsible,
    collapsed = _ref.collapsed,
    expandOverContent = _ref.expandOverContent,
    wrapperRef = useRef(),
    _useState = useState(""),
    _useState2 = _slicedToArray(_useState, 2),
    activeRoute = _useState2[0],
    setActiveRoute = _useState2[1],
    _useState3 = useState(collapsed),
    _useState4 = _slicedToArray(_useState3, 2),
    isCollapsed = _useState4[0],
    setIsCollapsed = _useState4[1],
    _useState5 = useState(""),
    _useState6 = _slicedToArray(_useState5, 2),
    collapsedWidth = _useState6[0],
    setCollapsedWidth = _useState6[1],
    breakpoint = useBreakpoint().breakpoint,
    isCollapsible = !contextSwitcher && collapsibleProp;
  useEffect(function () {
    setIsCollapsed(collapsed);
  }, [collapsed]), useEffect(function () {
    setCollapsedWidth(getComputedStyle(wrapperRef.current).getPropertyValue("--GW-SIDE-NAV-COLLAPSED-WIDTH"));
  }, []);
  var classes = cx(styles.sideNavigation, _defineProperty({}, styles.collapsed, isCollapsible && isCollapsed)),
    addSideNavStyles = useCallback(function (_ref2) {
      var navLink = _ref2.navLink;
      return _objectSpread(_objectSpread({}, _objectWithoutProperties(_ref2, _excluded)), {}, {
        navLink: _objectSpread(_objectSpread({}, navLink), {}, {
          className: cx(navLink && navLink.className, styles.navBarLink)
        })
      });
    }, [styles.navBarLink]),
    sideRoutes = shouldShowIcons(routes) ? routes : _mapInstanceProperty(routes).call(routes, removeNavLinkIcon),
    translator = useTranslator(),
    footer = isCollapsible && React.createElement("div", {
      className: styles.footer
    }, React.createElement(IconButton, {
      icon: isCollapsed ? "gw-chevron-right" : "gw-chevron-left",
      className: styles.chevronWrapper,
      iconClassName: styles.chevronIcon,
      onClick: function () {
        setIsCollapsed(!isCollapsed);
      },
      ariaLabel: translator(isCollapsed ? messages.collapsedButtonLabel : messages.expandedButtonLabel)
    })),
    showContextSwitcher = contextSwitcher && "phone" !== breakpoint,
    navigationContext = useMemo(function () {
      return {
        activeRoute: activeRoute,
        setActiveRoute: setActiveRoute,
        isCollapsed: isCollapsed,
        expand: function () {
          return setIsCollapsed(!1);
        },
        collapse: function () {
          return setIsCollapsed(!0);
        }
      };
    }, [activeRoute, setActiveRoute, isCollapsed]);
  return React.createElement("div", {
    className: classes,
    ref: wrapperRef
  }, React.createElement(Collapse, {
    isOpen: !isCollapsed,
    timeout: 250,
    isHorizontal: !0,
    expandOverContent: expandOverContent,
    collapsedSize: collapsedWidth,
    contentWrapperClassName: styles.content,
    outerContentWrapperClassName: cx(styles.collapseInnerWrapper, className)
  }, React.createElement("div", {
    className: styles.layout
  }, React.createElement(NavigationContext.Provider, {
    value: navigationContext
  }, React.createElement(NavigationContent, {
    routes: _mapInstanceProperty(sideRoutes).call(sideRoutes, addSideNavStyles),
    contextSwitcher: showContextSwitcher ? contextSwitcher : void 0,
    renderContextSwitcher: renderContextSwitcher,
    alignment: "left",
    className: styles.navigationContent,
    nestedRoutesComponent: NavBarAccordion
  })), footer)));
};
SideNavigation.propTypes = {
  className: PropTypes.string,
  routes: routesShape.isRequired,
  contextSwitcher: contextSwitcherShape,
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  expandOverContent: PropTypes.bool,
  renderContextSwitcher: PropTypes.func
}, SideNavigation.defaultProps = {
  collapsible: !1,
  collapsed: !1
}, SideNavigation.__docgenInfo = {
  description: "The `SideNavigation` component is designed as a container for navigation links and dropdowns\n\n@param {object} props properties for SideNavigation component\n@returns {React.ReactElement}\n\n@metadataType container",
  methods: [],
  displayName: "SideNavigation",
  props: {
    collapsible: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, component can be collapsed"
    },
    collapsed: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Default state for SideNavigation"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    routes: {
      type: {
        name: "custom",
        raw: "routesShape.isRequired"
      },
      required: !1,
      description: "The routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "custom",
        raw: "contextSwitcherShape"
      },
      required: !1,
      description: "The context switcher object"
    },
    expandOverContent: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, menu will expand over content. If false, content will be squeezed"
    },
    renderContextSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom ContextSwitcher instead of default one"
    }
  }
}, SideNavigation.__docgenInfo = {
  componentName: "SideNavigation",
  packageName: "@jutro/router",
  description: "The `SideNavigation` component is designed as a container for navigation links and dropdowns",
  displayName: "SideNavigation",
  methods: [],
  actualName: "SideNavigation",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: !0,
      description: "The routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "shape",
        value: {
          defaultLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Default button label when no context is active",
            required: !1
          },
          values: {
            name: "arrayOf",
            value: {
              name: "custom",
              raw: "contextShape.isRequired"
            },
            description: "Array of values for the contexts",
            required: !0
          }
        }
      },
      required: !1,
      description: "The context switcher object"
    },
    collapsible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, component can be collapsed",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    collapsed: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Default state for SideNavigation",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    expandOverContent: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, menu will expand over content. If false, content will be squeezed"
    },
    renderContextSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom ContextSwitcher instead of default one"
    }
  }
};