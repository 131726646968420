import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "filename", "completed", "total", "state", "messageProps", "renderMessages", "onActionClick", "onActionKeyDown", "variant", "errorMessages"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useContext } from "react";
import cx from "classnames";
import prettyBytes from "pretty-bytes";
import { getMessageProp } from "@jutro/platform";
import { LocaleContext, useTranslator } from "@jutro/locale";
import { Flex } from "@jutro/layout";
import { IconButton } from "../../../IconButton/IconButton";
import { Card } from "../../../card/Card";
import { SimpleProgressBar } from "../../../ProgressIndicator/SimpleProgress/SimpleProgressBar";
import styles from "./UploadingProgress.module.css";
import { messages } from "./UploadingProgress.messages";
import { ValidationIconAndMessage } from "../../FieldComponent/ValidationIconAndMessage";
export var UploadingProgress = function (_ref) {
  var _cx,
    id = _ref.id,
    filename = _ref.filename,
    completed = _ref.completed,
    total = _ref.total,
    _ref$state = _ref.state,
    state = void 0 === _ref$state ? "selection" : _ref$state,
    messageProps = _ref.messageProps,
    renderMessages = _ref.renderMessages,
    onActionClick = _ref.onActionClick,
    onActionKeyDown = _ref.onActionKeyDown,
    variant = _ref.variant,
    errorMessages = _ref.errorMessages,
    other = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator(),
    locale = useContext(LocaleContext).locale,
    progressBarClasses = cx((_defineProperty(_cx = {}, styles.cancelled, "cancelled" === state), _defineProperty(_cx, styles.failed, "failed" === state), _defineProperty(_cx, styles.done, "completed" === state), _cx)),
    iconAriaLabelTranslated = translator(messages.removeFileAriaLabel, {
      fileName: filename
    });
  return React.createElement(Card, _extends({
    className: "single" === variant ? styles.card : styles.cardMultiple,
    id: "".concat(id, "_progress_card")
  }, other), React.createElement("div", {
    className: styles.container
  }, React.createElement("div", {
    className: styles.progressWrapper
  }, React.createElement(Flex, {
    justifyContent: "between",
    className: cx("multiple" === variant && styles.label)
  }, React.createElement("span", null, filename), React.createElement("span", null, function () {
    switch (state) {
      case "failed":
        return translator(getMessageProp("uploadFailedMessage", messageProps, messages));
      case "cancelled":
        return translator(getMessageProp("uploadCancelledMessage", messageProps, messages));
      case "completed":
        return translator(getMessageProp("uploadCompletedMessage", messageProps, messages));
      case "progress":
        return translator(getMessageProp("uploadProgressMessage", messageProps, messages), {
          completed: prettyBytes(completed, {
            locale: locale
          }),
          total: prettyBytes(total, {
            locale: locale
          })
        });
      default:
        return translator(getMessageProp("uploadSelectedMessage", messageProps, messages));
    }
  }())), React.createElement(SimpleProgressBar, {
    stepClassName: progressBarClasses,
    id: "".concat(id, "_progress_bar"),
    showProgressLabel: !1,
    total: total,
    completed: completed
  })), React.createElement(IconButton, {
    id: "".concat(filename, "_removeButton"),
    testId: "".concat(filename, "_removeButton"),
    className: styles.FileUploadActionButton,
    icon: "gw-close",
    iconColor: "neutral",
    onClick: onActionClick,
    onKeyDown: onActionKeyDown,
    ariaLabel: iconAriaLabelTranslated
  })), null == errorMessages ? void 0 : _mapInstanceProperty(errorMessages).call(errorMessages, function (errorMessage, index) {
    var _context;
    return React.createElement(ValidationIconAndMessage, {
      message: errorMessage,
      icon: "gw-error",
      key: _concatInstanceProperty(_context = "".concat(errorMessage)).call(_context, index)
    });
  }), null == renderMessages ? void 0 : renderMessages());
};
UploadingProgress.defaultProps = {
  completed: 0,
  total: 1
}, UploadingProgress.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "UploadingProgress",
  props: {
    state: {
      defaultValue: {
        value: "'selection'",
        computed: !1
      },
      required: !1
    },
    completed: {
      defaultValue: {
        value: "0",
        computed: !1
      },
      required: !1
    },
    total: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      required: !1
    }
  }
}, UploadingProgress.__docgenInfo = {
  componentName: "UploadingProgress",
  packageName: "@jutro/components",
  description: "",
  displayName: "UploadingProgress",
  methods: [],
  actualName: "UploadingProgress",
  props: {
    state: {
      defaultValue: {
        value: "'selection'",
        computed: !1
      },
      required: !1
    },
    completed: {
      defaultValue: {
        value: "0",
        computed: !1
      },
      required: !1
    },
    total: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      required: !1
    }
  }
};