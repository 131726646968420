import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useRef } from "react";
import { useKeyActive } from "@jutro/platform";
import { isEmptyValue, isNilValue } from "@jutro/data";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import PropTypes from "prop-types";
import { defaultAvailableValuePropType, intlMessageShape } from "@jutro/prop-types";
import { getOptionCode, getOptionName } from "../availableValues";
import { Icon } from "../../Icon/Icon";
import styles from "./ToggleField.module.css";
import { buttonIconPositions } from "../../button/Button";
var toggleButtonPropTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
  option: PropTypes.object,
  styleClasses: PropTypes.string,
  active: PropTypes.string,
  activeKeypress: PropTypes.string,
  uniqueId: PropTypes.string,
  value: defaultAvailableValuePropType,
  handleButtonClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  iconClassName: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(buttonIconPositions),
  iconSize: PropTypes.oneOf(["small", "medium", "large"]),
  ariaLabel: intlMessageShape
};
export var ToggleButton = function (_ref) {
  var _cx,
    _context,
    id = _ref.id,
    disabled = _ref.disabled,
    option = _ref.option,
    styleClasses = _ref.styleClasses,
    active = _ref.active,
    activeKeypress = _ref.activeKeypress,
    value = _ref.value,
    handleButtonClick = _ref.handleButtonClick,
    onFocus = _ref.onFocus,
    onBlur = _ref.onBlur,
    icon = _ref.icon,
    iconClassName = _ref.iconClassName,
    iconPosition = _ref.iconPosition,
    iconSize = _ref.iconSize,
    ariaLabel = _ref.ariaLabel,
    translator = useTranslator(),
    code = getOptionCode(option),
    name = getOptionName(option),
    selectedValue = value,
    checked = !isEmptyValue(selectedValue) && selectedValue.toString() === code.toString();
  isNilValue(code) && (code = "empty", checked = isEmptyValue(selectedValue));
  var ref = useRef(),
    isActiveKeyPressed = useKeyActive(ref),
    isRightIcon = "right" === iconPosition,
    isLeftIcon = !isRightIcon,
    iconClasses = cx((_defineProperty(_cx = {}, styles.leftIcon, isLeftIcon && name), _defineProperty(_cx, styles.rightIcon, isRightIcon && name), _defineProperty(_cx, styles.iconChecked, checked), _defineProperty(_cx, "iconClassName", iconClassName), _defineProperty(_cx, styles["icon-".concat(icon)], "gw-warning" === icon || "gw-error" === icon || "gw-check-circle" === icon || "gw-info" === icon), _cx)),
    buttonClasses = cx(_defineProperty({}, activeKeypress, isActiveKeyPressed), styleClasses, _defineProperty({}, active, checked)),
    iconComponent = icon && React.createElement(Icon, {
      icon: icon,
      className: iconClasses,
      size: iconSize
    }),
    valueId = _concatInstanceProperty(_context = "".concat(id, "_")).call(_context, code);
  return React.createElement("button", {
    type: "button",
    checked: checked,
    "aria-pressed": checked,
    "aria-label": translator(ariaLabel),
    key: valueId,
    className: buttonClasses,
    onClick: handleButtonClick,
    onFocus: onFocus,
    onBlur: onBlur,
    "data-value": code.toString() || "",
    disabled: disabled,
    ref: ref
  }, isLeftIcon && iconComponent, translator(name), isRightIcon && iconComponent);
};
ToggleButton.propTypes = toggleButtonPropTypes, ToggleButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ToggleButton",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "id"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "disabled flag"
    },
    theme: {
      type: {
        name: "object"
      },
      required: !1,
      description: "theme"
    },
    option: {
      type: {
        name: "object"
      },
      required: !1,
      description: "object that contains the value for the button"
    },
    styleClasses: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class name of button styles"
    },
    active: {
      type: {
        name: "string"
      },
      required: !1,
      description: "active - class name object of button active styles"
    },
    activeKeypress: {
      type: {
        name: "string"
      },
      required: !1,
      description: "activeKeypress - class name object of button activeKeypress styles"
    },
    uniqueId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "uniqueId - uniqueIdentifier"
    },
    value: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "value - selectedValue"
    },
    handleButtonClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "handleButtonClick function"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "callback to onFocus"
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "callback to onBlur"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for icon"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    iconPosition: {
      type: {
        name: "enum",
        computed: !0,
        value: "buttonIconPositions"
      },
      required: !1,
      description: "Determines where the icon is placed relative to the text"
    },
    iconSize: {
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: !1
        }, {
          value: "'medium'",
          computed: !1
        }, {
          value: "'large'",
          computed: !1
        }]
      },
      required: !1,
      description: "Sets the size of the icon"
    },
    ariaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Aria label text"
    }
  }
}, ToggleButton.__docgenInfo = {
  componentName: "ToggleButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "ToggleButton",
  methods: [],
  actualName: "ToggleButton",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "id"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "disabled flag"
    },
    theme: {
      type: {
        name: "object"
      },
      required: !1,
      description: "theme"
    },
    option: {
      type: {
        name: "object"
      },
      required: !1,
      description: "object that contains the value for the button"
    },
    styleClasses: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class name of button styles"
    },
    active: {
      type: {
        name: "string"
      },
      required: !1,
      description: "active - class name object of button active styles"
    },
    activeKeypress: {
      type: {
        name: "string"
      },
      required: !1,
      description: "activeKeypress - class name object of button activeKeypress styles"
    },
    uniqueId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "uniqueId - uniqueIdentifier"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "value - selectedValue"
    },
    handleButtonClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "handleButtonClick function"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "callback to onFocus"
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "callback to onBlur"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for icon"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    iconPosition: {
      type: {
        name: "enum",
        computed: !0,
        value: "buttonIconPositions"
      },
      required: !1,
      description: "Determines where the icon is placed relative to the text"
    },
    iconSize: {
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: !1
        }, {
          value: "'medium'",
          computed: !1
        }, {
          value: "'large'",
          computed: !1
        }]
      },
      required: !1,
      description: "Sets the size of the icon"
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label text"
    }
  }
};