import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { IntlContext, useTranslator } from "@jutro/locale";
import { useContext, useMemo } from "react";
import { messages } from "./GenericSelectControl.messages";
export var useDefaultAriaLiveMessages = function (allowNewValue) {
  var translator = useTranslator(),
    intl = useContext(IntlContext);
  if (!intl) throw new Error("Intl context is not provided");
  return useMemo(function () {
    return {
      guidance: function (props) {
        var _context,
          _context2,
          isSearchable = props.isSearchable,
          isMulti = props.isMulti,
          isDisabled = props.isDisabled,
          tabSelectsValue = props.tabSelectsValue;
        switch (props.context) {
          case "menu":
            return _filterInstanceProperty(_context = [translator(messages.useUpAndDownToChooseOptions), isDisabled && translator(messages.pressEnterToSelectFocused), translator(messages.pressEscapeToExitTheMenu), tabSelectsValue && translator(messages.pressTabToSelectAndExit)]).call(_context, Boolean).join(" ");
          case "input":
            return _filterInstanceProperty(_context2 = [isSearchable && translator(messages.typeToRefineList), allowNewValue && translator(messages.addNewValue), translator(messages.pressDownToOpenTheMenu), isMulti && translator(messages.pressLeftToFocusSelected)]).call(_context2, Boolean).join(" ");
          case "value":
            return translator(messages.guidanceValue);
          default:
            return "";
        }
      },
      onChange: function (props) {
        var action = props.action,
          _props$label = props.label,
          label = void 0 === _props$label ? "" : _props$label,
          labels = props.labels,
          isDisabled = props.isDisabled;
        switch (action) {
          case "deselect-option":
          case "pop-value":
          case "remove-value":
            return translator(messages.optionDeselected, {
              label: label
            });
          case "clear":
            return translator(messages.optionsCleared);
          case "initial-input-focus":
            return translator(messages.initialInputFocus, {
              options: intl.formatList(labels)
            });
          case "select-option":
            return translator(isDisabled ? messages.optionSelectedDisabled : messages.optionSelected, {
              label: label
            });
          default:
            return "";
        }
      },
      onFocus: function (props) {
        var _context3,
          context = props.context,
          focused = props.focused,
          options = props.options,
          _props$label2 = props.label,
          label = void 0 === _props$label2 ? "" : _props$label2,
          selectValue = props.selectValue,
          isDisabled = props.isDisabled,
          isSelected = props.isSelected,
          getArrayIndex = function (arr, item) {
            return arr && arr.length ? translator(messages.arrayIndex, {
              count: arr.length,
              index: _indexOfInstanceProperty(arr).call(arr, item) + 1
            }) : "";
          };
        if ("value" === context && selectValue) return _concatInstanceProperty(_context3 = "".concat(translator(messages.valueFocused, {
          label: label
        }), " ")).call(_context3, getArrayIndex(selectValue, focused), ".");
        var newOption;
        return "menu" === context ? translator(messages.menuFocused, {
          label: label,
          status: translator(isSelected ? messages.selected : messages.focused),
          disabled: isDisabled ? translator(messages.disabled) : "",
          arrayIndex: (newOption = _filterInstanceProperty(options).call(options, function (item) {
            return item.__isNew__;
          }), newOption.length && newOption[0].label === label ? "" : getArrayIndex(options, focused))
        }) : "";
      },
      onFilter: function (props) {
        var _context4,
          inputValue = props.inputValue,
          resultsMessage = props.resultsMessage;
        return _concatInstanceProperty(_context4 = "".concat(inputValue ? translator(messages.searchForTerm, {
          term: inputValue
        }) : "", " ")).call(_context4, resultsMessage);
      }
    };
  }, [translator, allowNewValue, intl]);
};