import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "title", "onSave", "availableThemes", "theme", "onThemeChange"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import { useEvent } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { ThemeContext } from "@jutro/theme";
import { themeList, defaultThemeConfig } from "@jutro/theme-styles";
import { intlMessageShape } from "@jutro/prop-types";
import { ThemeChooser } from "../theme/ThemeChooser/ThemeChooser";
import { SettingsCard } from "./SettingsCard";
import { messages } from "./ThemeSettingsCard.messages";
export var ThemeSettingsCard = function (_ref) {
  var id = _ref.id,
    _ref$title = _ref.title,
    title = void 0 === _ref$title ? "Theme" : _ref$title,
    onSave = _ref.onSave,
    _ref$availableThemes = _ref.availableThemes,
    availableThemes = void 0 === _ref$availableThemes ? themeList : _ref$availableThemes,
    _ref$theme = _ref.theme,
    initialTheme = void 0 === _ref$theme ? defaultThemeConfig : _ref$theme,
    onThemeChange = _ref.onThemeChange,
    otherThemeChooserProps = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator(),
    themeContext = useContext(ThemeContext),
    _useState = useState(initialTheme !== defaultThemeConfig ? initialTheme : themeContext),
    _useState2 = _slicedToArray(_useState, 2),
    theme = _useState2[0],
    setTheme = _useState2[1],
    ref = useRef(theme),
    onSaveClickCallback = useEvent(function () {
      themeContext.switchTheme(theme), null == onSave || onSave(theme), ref.current = _objectSpread({}, theme);
    }),
    onCancelClickCallback = useEvent(function () {
      return setTheme(ref.current);
    }),
    onThemeChangeCallback = useEvent(function (selectedTheme) {
      setTheme(selectedTheme), null == onThemeChange || onThemeChange(selectedTheme);
    });
  return React.createElement(SettingsCard, {
    id: id,
    title: title,
    renderContent: function (isEditMode) {
      return React.createElement(ThemeChooser, _extends({}, otherThemeChooserProps, {
        id: "MetadataThemeChooser",
        label: translator(messages.themeSettingsLabel),
        readOnly: !isEditMode,
        theme: theme,
        availableThemes: availableThemes,
        onThemeChange: onThemeChangeCallback,
        skipPropagation: !0
      }));
    },
    onSaveClick: onSaveClickCallback,
    onCancelClick: onCancelClickCallback
  });
};
export var themeSettingsCardPropTypes = _objectSpread(_objectSpread({}, omit(ThemeChooser.propTypes, ["storybookComponent", "skipPropagation", "availableThemes"])), {}, {
  id: PropTypes.string,
  title: intlMessageShape,
  onSave: PropTypes.func
});
ThemeSettingsCard.propTypes = themeSettingsCardPropTypes, ThemeSettingsCard.displayName = "ThemeSettingsCard", ThemeSettingsCard.__docgenInfo = {
  description: "@metadataType container",
  methods: [],
  displayName: "ThemeSettingsCard",
  props: {
    title: {
      defaultValue: {
        value: "'Theme'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: ""
    },
    availableThemes: {
      defaultValue: {
        value: "themeList",
        computed: !0
      },
      required: !1
    },
    theme: {
      defaultValue: {
        value: "defaultThemeConfig",
        computed: !0
      },
      required: !1
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    onSave: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash"]
}, ThemeSettingsCard.__docgenInfo = {
  componentName: "ThemeSettingsCard",
  packageName: "@jutro/components",
  description: "",
  displayName: "ThemeSettingsCard",
  methods: [],
  actualName: "ThemeSettingsCard",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "'Theme'",
        computed: !1
      }
    },
    onSave: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    availableThemes: {
      defaultValue: {
        value: "themeList",
        computed: !0
      },
      required: !1
    },
    theme: {
      defaultValue: {
        value: "defaultThemeConfig",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["lodash"]
};