import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import cx from "classnames";
import { components } from "react-select";
export var GenericSelectControlOption = function (_ref) {
  var _cx,
    children = _ref.children,
    props = _objectWithoutProperties(_ref, _excluded),
    genericSelectStyles = props.selectProps.genericSelectStyles,
    optionClasses = cx(genericSelectStyles.option, (_defineProperty(_cx = {}, genericSelectStyles.optionFocused, props.isFocused), _defineProperty(_cx, genericSelectStyles.optionSelected, props.isSelected), _cx)),
    innerProps = _objectSpread(_objectSpread({
      role: "option"
    }, props.isSelected && {
      "aria-selected": "true"
    }), props.innerProps);
  return React.createElement("li", null, React.createElement(components.Option, _extends({}, props, {
    className: optionClasses,
    innerProps: innerProps
  }), children));
};
GenericSelectControlOption.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlOption"
}, GenericSelectControlOption.__docgenInfo = {
  componentName: "GenericSelectControlOption",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlOption",
  methods: [],
  actualName: "GenericSelectControlOption"
};