const TYAThemeConfig = {
  name: 'TYATheme',
  prefix: 'tyaTheme',
  rootStyle: {
    '--GW-BRAND-COLOR-1': '#FF0022',
    // toyota Signature Red
    '--GW-BRAND-COLOR-2': '#282830',
    // Toyota Signature Grey
    '--GW-BRAND-COLOR-3': '#d3d3d3',
    // toyota grey
    '--GW-BRAND-COLOR-4': '#494950',
    // toyota grey1
    '--GW-BRAND-COLOR-5': '##6A6B70',
    // toyota grey2
    '--GW-BRAND-COLOR-6': '##8B8C90',
    // toyota grey3
    '--GW-BRAND-COLOR-7': '#ADADB0',
    // toyota grey4
    '--GW-BRAND-COLOR-8': '#CECFD0',
    // toyota grey5
    '--GW-BRAND-COLOR-9': '#EFF0F0',
    // toyota grey6

    '--GW-BORDER-COLOR': 'var(--GW-BRAND-COLOR-7)',
    // toyota border color
    '--GW-FONT-SIZE-H1': '33px',
    '--GW-FONT-SIZE-H2': '29px',
    '--GW-FONT-SIZE-H3': '29px',
    '--GW-FONT-SIZE-H4': '24px',
    '--GW-FONT-SIZE-H2-BOTTOM-MARGIN': '20px',
    '--GW-FONT-SIZE-BODY': '15px',
    '--GW-FONT-SIZE-CAPTION': '15px',
    '--ACCORDION-HEADER-FOCUSED-BORDER': 'none',
    '--ACCORDION-HEADER-FOCUSED-BOX-SHADOW': 'transparent',
    '--GW-ACCORDION-CARDTITLE-FONT-SIZE': '1rem',
    '--GW-FONT-SIZE-LABEL': '15px',
    '--GW-FONT-SECTION-TITLES': '1.8rem',
    '--GW-FONT-SIZE-LABEL-WEIGHT': 'normal',
    '--GW-TEXT-COLOR-1': 'var(--GW-BRAND-COLOR-2)',
    // toyota text color
    '--GW-TEXT-COLOR-2': 'var(--GW-BRAND-COLOR-2)',
    '--GW-TEXT-COLOR-3': '#fff',
    '--GW-MODAL-HEADER-TITLE-WEIGHT': 'normal',
    '--GW-TOOLTIP-COLOR-3': 'var(--GW-BRAND-COLOR-1)',
    '--GW-FONT-FAMILY': '"ToyotaType", sans-serif',
    '--GW-SELECTOR-SELECTED-BACKGROUND-COLOR': 'var(--GW-BRAND-COLOR-1)',
    // radio button selected
    '--GW-RADIO-BUTTON-SECONDARY-COLOR': '#EFF0F0',
    '--GW-BUTTON-BORDER-RADIUS': '50px',
    '--GW-BUTTON-FILLED-COLOR': '#fff',
    '--gw-button-filled-colour-hover': '#fff',
    '--GW-BUTTON-FILLED-BACKGROUND-COLOR': 'var(--GW-BRAND-COLOR-1)',
    '--GW-BUTTON-FILLED-BACKGROUND-COLOR-HOVER': 'var(--GW-BRAND-COLOR-2)',
    '--GW-INPUT-OPACITY-DISABLED': '95%',
    '--gw-footer-padding': '40px 40px 30px',
    '--GW-BACKGROUND-COLOR': '#fff',
    '--gw-header-branding-image': './../../../applications/quote-and-buy/public/branding/tya/toyota-white-logo.svg',
    '--gw-header-branding-container-height': '90px',
    '--gw-header-branding-image-width': '263px',
    '--gw-header-branding-image-height': '45px',
    '--gw-header-branding-mobile-image-width': '212px',
    '--gw-header-branding-mobile-image-height': '36px',
    '--gw-header-branding-max-width': '1440px',
    '--gw-header-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-stepper-branding-title-disabled': '#949498',
    '--gw-stepper-branding-title-size': '1rem',
    '--gw-stepper-branding-title-font-weight': '500',
    '--gw-stepper-branding-marker-color': '#000',
    '--gw-stepper-branding-progress-marker-dimensions': '1.7rem',
    '--gw-stepper-branding-title-line-height': '1.2rem',
    '--gw-stepper-section-seperator': '#999',
    '--gw-stepper-title-colour': '#fff',
    '--gw-required-branding-asterisk-color': '#B94A48',
    '--gw-button-branding-font-weight': '200',
    '--gw-button-branding-width': '190px',
    '--gw-addon-branding-border-colour': '#dedede',
    '--gw-your-branding-quote-box-border': '#d3d3d3',
    '--gw-tooltip-font-style': 'italic',
    '--gw-tooltip-font-weight': '100',
    '--gw-disabled-input-background-color': '#fff',
    '--gw-quote-tick-color': 'invert(75%) sepia(57%) saturate(1582%) hue-rotate(192deg) brightness(90%) contrast(86%)',
    '--gw-quote-tick-alignment': 'flex-start',
    '--gw-stepper-progress-marker-colour': 'var(--GW-BRAND-COLOR-1)',
    '--GW-PROGRESS-BARS-LEFT-BAR-COLOR': 'var(--GW-BRAND-COLOR-7)',
    '--gw-footer-text-colour': 'var(--GW-BRAND-COLOR-2)',
    '--GW-BUTTON-OUTLINED-COLOR': 'var(--GW-BRAND-COLOR-1)',
    '--GW-BUTTON-OUTLINED-COLOR-HOVER': 'var(--GW-BRAND-COLOR-1)',
    '--gw-secondary-button-background-colour-hover': 'var(--GW-TEXT-COLOR-3)',
    '--gw-secondary-outlined-border-colour-hover': 'vfar(--GW-BRAND-COLOR-2)',
    '--GW-LINK-COLOR-HOVER': 'var(--GW-BRAND-COLOR-1)',
    '--gw-quote-price-amount-text-colour': '#fff',
    '--gw-coverage-title-text-colour': 'var(--GW-TEXT-COLOR-1)',
    '--gw-coverage-icon-colour': '#282830',
    '--gw-coverage-highlighted-text-colour': 'var(--GW-TEXT-COLOR-1)',
    '--gw-quote-review-accordian-title-color': 'var(--GW-BRAND-COLOR-2)',
    '--gw-border-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-secondary-button-hover-font-weight': 'var(--GW-FONT-WEIGHT-SEMI-BOLD);',
    '--gw-header-box-shadow': 'var(--GW-SHADOW-2)',
    '--gw-quote-page-border-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-lp1-car-reg-content-font-weight': 'var(--GW-FONT-WEIGHT-REGULAR)',
    '--gw-header-section-padding': 'var(--GW-SPACING-3) 0',
    '--gw-header-section-margin': 'var(--GW-SPACING-3) 0',
    '--gw-brand-h2-font-family': 'ToyotaType',
    '--gw-retrieve-quote-display-format': 'flex',
    '--gw-cancel-button-display': 'block',
    '--gw-cookie-modal-cookie-policy-link-colour': 'var(--GW-BRAND-COLOR-1)',
    '--gw-amend-details-button-text-colour': 'var(--GW-TEXT-COLOR-3)',
    '--gw-payment-complete-step-container-colour': 'var(--GW-BRAND-COLOR-3)',
    '--gw-alternate-button-background-colour': 'var(--GW-BRAND-COLOR-1)',
    '--gw-alternate-button-background-colour-hover': 'var(--GW-BUTTON-FILLED-BACKGROUND-COLOR-HOVER)',
    '--gw-aggs-splash-screen-header-BG-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-cookie-modal-desc-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-payment-complete-step-container-text-colour': 'var(--GW-BRAND-COLOR-2)',
    '--GW-COLOR-ERROR': 'var(--GW-BRAND-COLOR-1)',
    '--gw-payment-selection-icon-mt': '7px',
    '--gw-quote-review-accordion-title-font-size': '18px',
    '--GW-FONT-SIZE-SECONDARY-LABEL': '11px',
    '--gw-activate-your-portal-list-style-type': 'disc',
    '--gw-activate-your-portal-list-item-icon': 'none',
    '--gw-font-size-mandate-label': '16px',
    '--gw-mandate-input-font-weight': '600',
    '--gw-error-validation-message': '0 4px 3px 24px'
  },
  internalTheme: !0
};
export default TYAThemeConfig;