import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import { useTranslator } from "@jutro/locale";
import { InlineLoader } from "../../../../loading/inlineLoader/InlineLoader";
import { Chevron } from "../../../../chevron/Chevron";
import { Icon } from "../../../../Icon/Icon";
import styles from "./MultipleFilesUploadStatusBar.module.css";
import { multipleFileUploadStatusBarMessages } from "./MultipleFilesUploadStatusBar.messages";
export var MultipleFilesUploadStatusBar = function (_ref) {
  var isOpen = _ref.isOpen,
    uploadState = _ref.uploadState,
    uploadErrors = _ref.uploadErrors,
    uploadedFilesNumber = _ref.uploadedFilesNumber,
    translator = useTranslator(),
    numberOfFilesWithError = _Object$keys(uploadErrors).length,
    uploadStatuses = _Object$values(uploadState),
    uploadStateStatusesCounter = _reduceInstanceProperty(uploadStatuses).call(uploadStatuses, function (acc, fileStatus) {
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, fileStatus, acc[fileStatus] ? acc[fileStatus] + 1 : 1));
    }, {}),
    fileErrorStatus = React.createElement(React.Fragment, null, React.createElement(Icon, {
      icon: "gw-error",
      size: "large",
      color: "#C4221F"
    }), React.createElement("span", {
      className: styles.statusBarErrorText
    }, translator(multipleFileUploadStatusBarMessages.filesErrorStatus, {
      numberOfFilesWithError: numberOfFilesWithError
    }))),
    fileInlineLoaderStatus = React.createElement(InlineLoader, {
      className: styles.statusBarTextContainer,
      loading: (null == uploadStateStatusesCounter ? void 0 : uploadStateStatusesCounter.progress) > 0,
      loadingMessage: translator(multipleFileUploadStatusBarMessages.filesUploadingStatus, {
        numberOfFilesUploading: null == uploadStateStatusesCounter ? void 0 : uploadStateStatusesCounter.progress
      }),
      successIcon: "gw-check-circle",
      successMessage: translator(multipleFileUploadStatusBarMessages.filesUploadedStatus, {
        numberOfFilesUploaded: null == uploadStateStatusesCounter ? void 0 : uploadStateStatusesCounter.completed
      })
    });
  return React.createElement("div", {
    className: styles.statusBarContainer
  }, React.createElement("div", {
    className: styles.statusBarTextContainer
  }, numberOfFilesWithError || uploadStatuses.length ? !numberOfFilesWithError || (null == uploadStateStatusesCounter ? void 0 : uploadStateStatusesCounter.progress) > 0 ? fileInlineLoaderStatus : fileErrorStatus : React.createElement(React.Fragment, null, React.createElement(Icon, {
    icon: "gw-check-circle",
    size: "large",
    color: "#2A8440"
  }), React.createElement("span", {
    className: styles.statusBarErrorText
  }, translator(multipleFileUploadStatusBarMessages.filesSelectedStatus, {
    numberOfSelectedFiles: uploadedFilesNumber
  })))), React.createElement(Chevron, {
    isOpen: isOpen,
    "aria-label": null
  }));
};
MultipleFilesUploadStatusBar.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "MultipleFilesUploadStatusBar"
}, MultipleFilesUploadStatusBar.__docgenInfo = {
  componentName: "MultipleFilesUploadStatusBar",
  packageName: "@jutro/components",
  description: "",
  displayName: "MultipleFilesUploadStatusBar",
  methods: [],
  actualName: "MultipleFilesUploadStatusBar"
};