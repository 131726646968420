import _extends from "@babel/runtime-corejs3/helpers/extends";
import pick from "lodash/pick";
import React from "react";
import PropTypes from "prop-types";
import { Grid, GridItem } from "@jutro/layout";
import { layoutShape } from "./Layouts.commons";
var propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    layout: PropTypes.shape({
      desktop: layoutShape,
      tablet: layoutShape,
      phoneWide: layoutShape,
      phone: layoutShape
    })
  },
  getGridProps = function (props) {
    return pick(props, ["repeat", "gap", "columns"]);
  },
  getGridItemProps = function (props) {
    return pick(props, ["colStart", "colSpan"]);
  };
export var Layout = function (_ref) {
  var id = _ref.id,
    children = _ref.children,
    className = _ref.className,
    _ref2 = _ref.layout || {},
    desktop = _ref2.desktop,
    tablet = _ref2.tablet,
    phoneWide = _ref2.phoneWide,
    phone = _ref2.phone;
  return React.createElement(Grid, _extends({
    id: id,
    className: className
  }, getGridProps(desktop || {}), {
    tablet: getGridProps(tablet || {}),
    phoneWide: getGridProps(phoneWide || {}),
    phone: getGridProps(phone || {})
  }), React.createElement(GridItem, _extends({}, getGridItemProps(desktop || {}), {
    tablet: getGridItemProps(tablet || {}),
    phoneWide: getGridItemProps(phoneWide || {}),
    phone: getGridItemProps(phone || {})
  }), children));
};
Layout.propTypes = propTypes, Layout.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Layout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "children; preferably 'GridItem'; works with any child"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional css class(es) to add to the grid tag"
    },
    layout: {
      type: {
        name: "shape",
        value: {
          desktop: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override the default layout",
            required: !1
          },
          tablet: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override layout on tablet",
            required: !1
          },
          phoneWide: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override layout on phoneWide",
            required: !1
          },
          phone: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override layout on phone",
            required: !1
          }
        }
      },
      required: !1,
      description: "Optional props to override the default device layout"
    }
  }
}, Layout.__docgenInfo = {
  componentName: "Layout",
  packageName: "@jutro/components",
  description: "",
  displayName: "Layout",
  methods: [],
  actualName: "Layout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "children; preferably 'GridItem'; works with any child"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional css class(es) to add to the grid tag"
    },
    layout: {
      type: {
        name: "shape",
        value: {
          desktop: {
            name: "shape",
            description: "Optional props to override the default layout",
            required: !1,
            value: {
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: !1
                  }, {
                    value: "'auto-fill'",
                    computed: !1
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: !1
              },
              gap: {
                name: "enum",
                computed: !0,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: !1
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: !1
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "specifies how many columns an element should span across",
                required: !1
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "define column to start",
                required: !1
              }
            }
          },
          tablet: {
            name: "shape",
            description: "Optional props to override layout on tablet",
            required: !1,
            value: {
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: !1
                  }, {
                    value: "'auto-fill'",
                    computed: !1
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: !1
              },
              gap: {
                name: "enum",
                computed: !0,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: !1
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: !1
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "specifies how many columns an element should span across",
                required: !1
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "define column to start",
                required: !1
              }
            }
          },
          phoneWide: {
            name: "shape",
            description: "Optional props to override layout on phoneWide",
            required: !1,
            value: {
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: !1
                  }, {
                    value: "'auto-fill'",
                    computed: !1
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: !1
              },
              gap: {
                name: "enum",
                computed: !0,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: !1
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: !1
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "specifies how many columns an element should span across",
                required: !1
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "define column to start",
                required: !1
              }
            }
          },
          phone: {
            name: "shape",
            description: "Optional props to override layout on phone",
            required: !1,
            value: {
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: !1
                  }, {
                    value: "'auto-fill'",
                    computed: !1
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: !1
              },
              gap: {
                name: "enum",
                computed: !0,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: !1
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: !1
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "specifies how many columns an element should span across",
                required: !1
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "define column to start",
                required: !1
              }
            }
          }
        }
      },
      required: !1,
      description: "Optional props to override the default device layout"
    }
  }
};