import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "className", "activeClassName", "disabled", "onClick", "visible", "focused", "dangerouslySetInnerHTML", "to", "href", "target", "rel", "icon", "iconPosition", "iconClassName"];
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { useAccessibleRef } from "@jutro/platform";
import { intlMessageShape, intlToShape, IntlMessageShape, IntlToShape } from "@jutro/prop-types";
import { useSafeTranslatedUrls, useTranslator } from "@jutro/locale";
import { Icon } from "../Icon/Icon";
import { InlineLabel } from "../InlineLabel/InlineLabel";
import styles from "./DropdownMenu.module.css";
var LabelWithIcon = function (_ref) {
  var icon = _ref.icon,
    iconPosition = _ref.iconPosition,
    iconClassNameProp = _ref.iconClassName,
    children = _ref.children,
    iconClassName = "right" === iconPosition ? styles.iconRight : styles.iconLeft,
    iconComponent = React.createElement(Icon, {
      icon: icon,
      className: cx(iconClassName, iconClassNameProp)
    });
  return React.createElement(InlineLabel, {
    tag: "div",
    icon: iconComponent,
    iconPosition: iconPosition
  }, children);
};
export var DropdownMenuLink = function (props) {
  var id = props.id,
    className = props.className,
    activeClassName = props.activeClassName,
    disabled = props.disabled,
    onClickProp = props.onClick,
    visible = props.visible,
    focused = props.focused,
    to = (props.dangerouslySetInnerHTML, props.to),
    href = props.href,
    target = props.target,
    relProp = props.rel,
    icon = props.icon,
    iconPosition = props.iconPosition,
    iconClassName = props.iconClassName,
    other = _objectWithoutProperties(props, _excluded),
    translator = useTranslator(),
    urlTranslatorAndSanitizer = useSafeTranslatedUrls(),
    onClick = useMemo(function () {
      return disabled ? function (evt) {
        return evt.preventDefault();
      } : function (evt) {
        null == onClickProp || onClickProp(evt);
      };
    }, [disabled, onClickProp]),
    linkRef = useAccessibleRef(focused);
  if (!1 === visible) return null;
  var children = icon ? React.createElement(LabelWithIcon, {
      icon: icon,
      iconPosition: iconPosition,
      iconClassName: iconClassName
    }, translator(props.children)) : translator(props.children),
    classes = cx(styles.link, _defineProperty({}, styles.disabled, disabled), className),
    safeTo = to && urlTranslatorAndSanitizer(to);
  if (safeTo) return React.createElement(NavLink, _extends({
    id: id,
    className: classes,
    activeClassName: cx(styles.activeLink, activeClassName),
    onClick: onClick,
    disabled: disabled,
    "aria-disabled": disabled,
    innerRef: linkRef,
    tabIndex: disabled ? -1 : 0,
    to: safeTo,
    role: "menuitem"
  }, other), children);
  var safeHref = href ? urlTranslatorAndSanitizer(href) : safeTo,
    rel = "_blank" === target ? "noreferrer" : relProp;
  return React.createElement("a", _extends({
    id: id,
    className: classes,
    onClick: onClick,
    href: safeHref,
    "aria-disabled": disabled,
    ref: linkRef,
    target: target,
    rel: rel,
    tabIndex: disabled ? -1 : 0,
    role: "menuitem"
  }, other), children);
};
var dropdownMenuLinkPropTypes = {
  id: PropTypes.string,
  to: intlToShape,
  href: intlMessageShape,
  target: PropTypes.string,
  rel: PropTypes.string,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  iconClassName: PropTypes.string
};
DropdownMenuLink.propTypes = dropdownMenuLinkPropTypes, DropdownMenuLink.__docgenInfo = {
  description: "DropdownMenuLink\n\n@type {React.FC<DropdownMenuLinkProps>}\n\n@metadataType action",
  methods: [],
  displayName: "DropdownMenuLink",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the dropdown menu link component"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "Internal link"
    },
    href: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "External link"
    },
    target: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Specifies where to open the external link, like the HTML `target` attribute"
    },
    rel: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Specifies the relationship between the current and an external document, like the HTML `rel` attribute"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this link is disabled"
    },
    focused: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the link is considered as the currently active one"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The class name of the link"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The class to give the link when it is active"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this link is visible"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On link click event handler"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "The children elements to render inside of the DropdownMenuLink"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for icon"
    }
  }
}, LabelWithIcon.__docgenInfo = {
  componentName: "LabelWithIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "LabelWithIcon",
  methods: [],
  actualName: "LabelWithIcon"
}, DropdownMenuLink.__docgenInfo = {
  componentName: "DropdownMenuLink",
  packageName: "@jutro/components",
  description: "DropdownMenuLink",
  displayName: "DropdownMenuLink",
  methods: [],
  actualName: "DropdownMenuLink",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the dropdown menu link component"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "Internal link"
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "External link"
    },
    target: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Specifies where to open the external link, like the HTML `target` attribute"
    },
    rel: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Specifies the relationship between the current and an external document, like the HTML `rel` attribute"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this link is disabled"
    },
    focused: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the link is considered as the currently active one"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The class name of the link"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The class to give the link when it is active"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this link is visible"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On link click event handler"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "The children elements to render inside of the DropdownMenuLink"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for icon"
    }
  }
};