import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["isOpen", "menuId"];
import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { useAuth } from "@jutro/auth";
import cx from "classnames";
import { Avatar } from "../Avatar/Avatar";
import { DropdownMenu } from "./DropdownMenu";
import styles from "./DropdownMenuAvatar.module.css";
import { messages } from "./DropdownMenuAvatar.messages.js";
import { DropdownMenuAvatarContent } from "./DropdownMenuAvatarContent";
export var DropdownMenuAvatar = function (_ref) {
  var username = _ref.username,
    icon = _ref.icon,
    className = _ref.className,
    imageSource = _ref.imageSource,
    messageProps = _ref.messageProps,
    showImageBorder = _ref.showImageBorder,
    useAuthInfo = _ref.useAuthInfo,
    id = _ref.id,
    isOpenProp = _ref.isOpen,
    dropUp = _ref.dropUp,
    alignRight = _ref.alignRight,
    title = _ref.title,
    subtitle = _ref.subtitle,
    children = _ref.children,
    onBeforeLogout = _ref.onBeforeLogout,
    headerClassName = _ref.headerClassName,
    authLinksClassName = _ref.authLinksClassName,
    separatorClassName = _ref.separatorClassName,
    dropdownClassName = _ref.dropdownClassName,
    autoFocus = _ref.autoFocus,
    auth = useAuth(),
    translator = useTranslator(),
    dropdownMenuAvatarContentProps = {
      title: title,
      subtitle: subtitle,
      children: children,
      username: username,
      icon: icon,
      imageSource: imageSource,
      messageProps: messageProps,
      useAuthInfo: useAuthInfo,
      showImageBorder: showImageBorder,
      onBeforeLogout: onBeforeLogout,
      headerClassName: headerClassName,
      authLinksClassName: authLinksClassName,
      separatorClassName: separatorClassName,
      className: dropdownClassName
    },
    menuProps = {
      id: id,
      isOpen: isOpenProp,
      dropUp: dropUp,
      alignRight: alignRight,
      autoFocus: autoFocus
    },
    avatarProps = {
      username: username,
      icon: icon,
      className: cx(className, "dropdownMenuAvatarTrigger"),
      imageSource: useAuthInfo ? get(auth, "userInfo.pictureUrl", null) : imageSource,
      messageProps: messageProps,
      showImageBorder: showImageBorder,
      useAuthInfo: useAuthInfo
    };
  return React.createElement(DropdownMenu, _extends({}, menuProps, {
    className: styles.dropdownMenuAvatar,
    renderTrigger: function (_ref2, toggleMenu) {
      var isOpen = _ref2.isOpen,
        menuId = _ref2.menuId,
        other = _objectWithoutProperties(_ref2, _excluded);
      return React.createElement(Avatar, _extends({}, other, avatarProps, {
        onClick: function () {
          return toggleMenu(!isOpen);
        },
        "aria-label": translator(messages.profileMenu),
        "aria-controls": menuId
      }));
    },
    menuClassName: styles.dropdownMenuAvatarMenu
  }), React.createElement(DropdownMenuAvatarContent, dropdownMenuAvatarContentProps));
};
DropdownMenuAvatar.propTypes = {
  title: intlMessageShape,
  subtitle: intlMessageShape,
  username: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  imageSource: PropTypes.string,
  messageProps: PropTypes.shape({
    userAvatar: intlMessageShape
  }),
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  dropUp: PropTypes.bool,
  alignRight: PropTypes.bool,
  useAuthInfo: PropTypes.bool,
  children: PropTypes.node,
  showImageBorder: PropTypes.bool,
  headerClassName: PropTypes.string,
  authLinksClassName: PropTypes.string,
  separatorClassName: PropTypes.string,
  onBeforeLogout: PropTypes.func,
  dropdownClassName: PropTypes.string,
  autoFocus: PropTypes.bool
}, DropdownMenuAvatar.defaultAvatarProps = {
  alignRight: !0,
  useAuthInfo: !1
}, DropdownMenuAvatar.__docgenInfo = {
  description: "DropdownMenuAvatar\n@metadataType container",
  methods: [],
  displayName: "DropdownMenuAvatar",
  props: {
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Menu header title text"
    },
    subtitle: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Menu header subtitle text"
    },
    username: {
      type: {
        name: "string"
      },
      required: !1,
      description: "User's first and last name, used as component alt text and to display initials if no image is selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to a user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "custom",
            raw: "intlMessageShape",
            description: "userAvatar message",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the menu component."
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu is currently visible"
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu appears above the trigger component"
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, items are aligned to the right edge of the menu."
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, display header information from Okta (if available) and the Logout menu link"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children, wrapped by the dropdown menu avatar component"
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the image border"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar header"
    },
    authLinksClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar auth links"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the dropdown menu separator"
    },
    onBeforeLogout: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called before Okta logout"
    },
    dropdownClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the dropdown menu"
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the first menu item gets focused after opening the dropdown menu"
    }
  }
}, DropdownMenuAvatar.__docgenInfo = {
  componentName: "DropdownMenuAvatar",
  packageName: "@jutro/components",
  description: "DropdownMenuAvatar",
  displayName: "DropdownMenuAvatar",
  methods: [],
  actualName: "DropdownMenuAvatar",
  metadataType: "container",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Menu header title text"
    },
    subtitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Menu header subtitle text"
    },
    username: {
      type: {
        name: "string"
      },
      required: !1,
      description: "User's first and last name, used as component alt text and to display initials if no image is selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to a user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "union",
            description: "userAvatar message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the menu component."
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu is currently visible"
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu appears above the trigger component"
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, items are aligned to the right edge of the menu."
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, display header information from Okta (if available) and the Logout menu link"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children, wrapped by the dropdown menu avatar component"
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the image border"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar header"
    },
    authLinksClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar auth links"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the dropdown menu separator"
    },
    onBeforeLogout: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called before Okta logout"
    },
    dropdownClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the dropdown menu"
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the first menu item gets focused after opening the dropdown menu"
    }
  }
};