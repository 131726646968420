import React from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { messages } from "./GenericDatePicker.messages";
import styles from "./GenericDatePicker.module.css";
export var TodayButton = function (_ref) {
  var text = _ref.text,
    translator = useTranslator();
  return React.createElement("button", {
    className: cx(styles.todayButton, "today-button")
  }, translator(text || messages.today));
};
TodayButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TodayButton"
}, TodayButton.__docgenInfo = {
  componentName: "TodayButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "TodayButton",
  methods: [],
  actualName: "TodayButton"
};