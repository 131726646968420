import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./Footer.module.css";
var footerTextPropTypes = {
  className: PropTypes.string,
  text: intlMessageShape
};
export var FooterText = function (_ref) {
  var text = _ref.text,
    className = _ref.className,
    translator = useTranslator();
  if (isEmpty(text)) return null;
  var classes = cx(styles.footerText, className);
  return React.createElement("div", {
    className: classes
  }, translator(text));
};
FooterText.propTypes = footerTextPropTypes, FooterText.__docgenInfo = {
  description: "The `FooterText` component is designed as a simple component presenting text inside the `Footer` container.\n\n@type {React.FC<PropTypes.InferProps<typeof footerTextPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "FooterText",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    text: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Text to render"
    }
  }
}, FooterText.__docgenInfo = {
  componentName: "FooterText",
  packageName: "@jutro/components",
  description: "The `FooterText` component is designed as a simple component presenting text inside the `Footer` container.",
  displayName: "FooterText",
  methods: [],
  actualName: "FooterText",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    text: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Text to render"
    }
  }
};