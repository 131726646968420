import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["id", "label", "icon", "hideIcon", "renderIcon", "renderExpandIcon", "renderChildren", "renderHeader", "children", "onClick", "className", "nodeHeaderClassName", "disabled"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { Fragment, useCallback, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import cx from "classnames";
import { Icon } from "../Icon/Icon";
import { InlineLabel } from "../InlineLabel/InlineLabel";
import { Collapse } from "../collapse/Collapse";
import { TreeViewDescendantContext } from "./TreeViewDescendantContext";
import { TreeNodeHeader } from "./TreeNodeHeader";
import { useTreeNode } from "./useTreeNode";
import styles from "./TreeNode.module.css";
var defaultDefaultRenderIcon = function (_ref) {
    var _context,
      icon = _ref.icon,
      nodeId = _ref.nodeId;
    return React.createElement(Fragment, null, icon && React.createElement(Icon, {
      "data-testid": _concatInstanceProperty(_context = "".concat(nodeId, "-icon-")).call(_context, icon),
      icon: icon,
      "aria-hidden": !0
    }));
  },
  defaultDefaultRenderExpandIcon = function (_ref2) {
    var _context2,
      _cx,
      expanded = _ref2.expanded,
      isEndNode = _ref2.isEndNode,
      nodeId = _ref2.nodeId;
    return React.createElement(Icon, {
      "data-testid": _concatInstanceProperty(_context2 = "".concat(nodeId, "-expandIcon-")).call(_context2, expanded ? "expanded" : "collapsed"),
      icon: "gw-keyboard-arrow-right",
      "aria-hidden": !isEndNode || void 0,
      className: cx(styles.icon, (_cx = {}, _defineProperty(_cx, styles.iconExpanded, expanded), _defineProperty(_cx, styles.iconHidden, isEndNode), _cx))
    });
  },
  defaultDefaultRenderChildren = function (_ref3) {
    var expanded = _ref3.expanded,
      children = _ref3.children;
    return React.createElement("ul", {
      role: "group"
    }, React.createElement(Collapse, {
      isOpen: expanded,
      timeout: 100,
      allowOverflowContent: !0
    }, children));
  };
export var TreeNode = function (_ref4) {
  var _cx2,
    id = _ref4.id,
    label = _ref4.label,
    icon = _ref4.icon,
    hideIcon = _ref4.hideIcon,
    renderIconProp = _ref4.renderIcon,
    renderExpandIconProp = _ref4.renderExpandIcon,
    renderChildren = _ref4.renderChildren,
    renderHeader = _ref4.renderHeader,
    children = _ref4.children,
    onClick = _ref4.onClick,
    className = _ref4.className,
    nodeHeaderClassName = _ref4.nodeHeaderClassName,
    _ref4$disabled = _ref4.disabled,
    disabled = void 0 !== _ref4$disabled && _ref4$disabled,
    props = _objectWithoutProperties(_ref4, _excluded),
    ref = useRef(null),
    _useTreeNode = useTreeNode(id, label, disabled),
    translatedLabel = _useTreeNode.translatedLabel,
    expanded = _useTreeNode.expanded,
    visible = _useTreeNode.visible,
    labelMatches = _useTreeNode.labelMatches,
    path = _useTreeNode.path,
    toggle = _useTreeNode.toggle,
    defaultOnClick = _useTreeNode.onClick,
    defaultParentIcon = _useTreeNode.defaultParentIcon,
    defaultEndIcon = _useTreeNode.defaultEndIcon,
    _useTreeNode$defaultR = _useTreeNode.defaultRenderIcon,
    defaultRenderIcon = void 0 === _useTreeNode$defaultR ? defaultDefaultRenderIcon : _useTreeNode$defaultR,
    _useTreeNode$defaultR2 = _useTreeNode.defaultRenderExpandIcon,
    defaultRenderExpandIcon = void 0 === _useTreeNode$defaultR2 ? defaultDefaultRenderExpandIcon : _useTreeNode$defaultR2,
    _useTreeNode$defaultR3 = _useTreeNode.defaultRenderChildren,
    defaultRenderChildren = void 0 === _useTreeNode$defaultR3 ? defaultDefaultRenderChildren : _useTreeNode$defaultR3,
    _useTreeNode$defaultR4 = _useTreeNode.defaultRenderHeader,
    defaultRenderHeader = void 0 === _useTreeNode$defaultR4 ? TreeNodeHeader : _useTreeNode$defaultR4,
    activeNodeId = _useTreeNode.activeNodeId,
    setActiveNodeId = _useTreeNode.setActiveNodeId,
    isActiveNode = _useTreeNode.isActiveNode,
    tabIndex = _useTreeNode.tabIndex,
    selectedNodeId = _useTreeNode.selectedNodeId,
    setSelectedNodeId = _useTreeNode.setSelectedNodeId,
    renderIcon = renderIconProp || defaultRenderIcon,
    RenderExpandIcon = renderExpandIconProp || defaultRenderExpandIcon,
    RenderChildren = renderChildren || defaultRenderChildren,
    RenderHeader = renderHeader || defaultRenderHeader,
    isEndNode = !children,
    hasNodes = !!children,
    isSelectedNode = selectedNodeId === id;
  useEffect(function () {
    var _ref$current, _ref$current$focus;
    isActiveNode && (null === (_ref$current = ref.current) || void 0 === _ref$current || null === (_ref$current$focus = _ref$current.focus) || void 0 === _ref$current$focus || _ref$current$focus.call(_ref$current));
  }, [activeNodeId]);
  var onFocusHandler = useCallback(function (event) {
      event.target === ref.current && setActiveNodeId(id);
    }, [ref]),
    onClickHandler = useCallback(function () {
      var payload = {
        id: id,
        hasNodes: hasNodes
      };
      setActiveNodeId(id), setSelectedNodeId(id), hasNodes && (toggle(), payload = _objectSpread(_objectSpread({}, payload), {}, {
        expanded: !expanded
      })), onClick && onClick(payload), defaultOnClick && defaultOnClick(payload);
    }, [defaultOnClick, expanded, hasNodes, id, onClick, toggle]),
    nodeClasses = cx(styles.node, (_defineProperty(_cx2 = {}, styles.disabled, disabled), _defineProperty(_cx2, styles.selected, isSelectedNode), _cx2), nodeHeaderClassName),
    labelId = "".concat(id, "-header"),
    descendantContextValue = useMemo(function () {
      return {
        parentPath: path
      };
    }, [path]);
  return React.createElement(TreeViewDescendantContext.Provider, {
    value: descendantContextValue
  }, React.createElement("li", _extends({
    id: id,
    ref: ref,
    tabIndex: tabIndex,
    role: "treeitem",
    className: cx(styles.nodeWrapper, className),
    hidden: !visible,
    onFocus: onFocusHandler,
    "aria-hidden": visible ? "false" : "true",
    "aria-expanded": hasNodes ? expanded : void 0,
    "aria-selected": !!isSelectedNode || void 0,
    "aria-labelledby": labelId,
    "aria-disabled": !!disabled || void 0
  }, props), React.createElement(InlineLabel, {
    tag: "div",
    icon: React.createElement(RenderExpandIcon, {
      nodeId: id,
      expanded: expanded,
      isEndNode: isEndNode
    }),
    onClick: disabled ? void 0 : onClickHandler,
    className: nodeClasses,
    style: {}
  }, React.createElement(RenderHeader, {
    id: labelId,
    nodeId: id,
    label: translatedLabel,
    icon: icon || isEndNode ? defaultEndIcon : defaultParentIcon,
    hideIcon: hideIcon,
    renderIcon: renderIcon,
    labelMatches: labelMatches
  })), children && React.createElement(RenderChildren, {
    nodeId: id,
    expanded: expanded
  }, children)));
};
TreeNode.propTypes = {
  id: PropTypes.string.isRequired,
  label: intlMessageShape.isRequired,
  icon: PropTypes.string,
  hideIcon: PropTypes.bool,
  renderIcon: PropTypes.func,
  renderExpandIcon: PropTypes.func,
  renderChildren: PropTypes.func,
  renderHeader: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  nodeHeaderClassName: PropTypes.string,
  disabled: PropTypes.bool
}, TreeNode.__docgenInfo = {
  description: "@metadataType container",
  methods: [],
  displayName: "TreeNode",
  props: {
    disabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Mark node as disabled"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Unique node identifier"
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Node label"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Node's icon"
    },
    hideIcon: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide icon if true. Icon is hidden by default if icon not provided"
    },
    renderIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Node's header icon render function or component. Passed to renderHeader"
    },
    renderExpandIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Node's expand icon render function or component"
    },
    renderChildren: {
      type: {
        name: "func"
      },
      required: !1,
      description: "TreeNode's children render function or component"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "TreeNode's header render function or component"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Node click handler"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for whole node"
    },
    nodeHeaderClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for node header with icon and arrow"
    }
  }
}, defaultDefaultRenderIcon.__docgenInfo = {
  componentName: "defaultDefaultRenderIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "defaultDefaultRenderIcon",
  methods: [],
  actualName: "defaultDefaultRenderIcon"
}, defaultDefaultRenderExpandIcon.__docgenInfo = {
  componentName: "defaultDefaultRenderExpandIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "defaultDefaultRenderExpandIcon",
  methods: [],
  actualName: "defaultDefaultRenderExpandIcon"
}, defaultDefaultRenderChildren.__docgenInfo = {
  componentName: "defaultDefaultRenderChildren",
  packageName: "@jutro/components",
  description: "",
  displayName: "defaultDefaultRenderChildren",
  methods: [],
  actualName: "defaultDefaultRenderChildren"
}, TreeNode.__docgenInfo = {
  componentName: "TreeNode",
  packageName: "@jutro/components",
  description: "",
  displayName: "TreeNode",
  methods: [],
  actualName: "TreeNode",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Unique node identifier"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Node label"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Node's icon"
    },
    hideIcon: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide icon if true. Icon is hidden by default if icon not provided"
    },
    renderIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Node's header icon render function or component. Passed to renderHeader"
    },
    renderExpandIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Node's expand icon render function or component"
    },
    renderChildren: {
      type: {
        name: "func"
      },
      required: !1,
      description: "TreeNode's children render function or component"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "TreeNode's header render function or component"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Node click handler"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for whole node"
    },
    nodeHeaderClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for node header with icon and arrow"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Mark node as disabled",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  }
};