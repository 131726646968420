import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { metadataTypes } from "@jutro/uimetadata";
import styles from "./TableTitleBar.module.css";
export var TableTitleBar = function (props) {
  var titleId = props.titleId,
    titlePosition = props.titlePosition,
    translator = useTranslator(),
    title = function (title, translator) {
      isFunction(title) && (title = title(translator));
      translator && (title = translator(title));
      return title;
    }(props.title, translator);
  return renderTitleBar(renderTitle(title, titleId, titlePosition), renderTitleAction(props.titleAction));
};
function renderTitle(title, titleId, titlePosition) {
  var classNames = cx(styles.title, styles[titlePosition]);
  return title ? React.createElement("div", {
    id: titleId,
    className: classNames
  }, title) : null;
}
function renderTitleAction(titleAction) {
  return titleAction ? React.createElement("div", {
    className: styles.titleAction
  }, titleAction()) : null;
}
function renderTitleBar(titleElement, titleActionElement) {
  return titleElement || titleActionElement ? React.createElement("div", {
    className: styles.tableTitleBar
  }, titleElement, titleActionElement) : null;
}
TableTitleBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleAction: PropTypes.func,
  theme: PropTypes.object,
  titleId: PropTypes.string,
  position: PropTypes.oneOf(["left", "right", "center"])
}, TableTitleBar.defaultProps = {
  titlePosition: "left"
}, TableTitleBar.metadataType = metadataTypes.CONTAINER, TableTitleBar.__docgenInfo = {
  description: "TableTitleBar\n@metadataType container",
  methods: [],
  displayName: "TableTitleBar",
  props: {
    titlePosition: {
      defaultValue: {
        value: "'left'",
        computed: !1
      },
      required: !1
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: ""
    },
    titleAction: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    theme: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    titleId: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    position: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'center'",
          computed: !1
        }]
      },
      required: !1,
      description: ""
    }
  }
}, TableTitleBar.__docgenInfo = {
  componentName: "TableTitleBar",
  packageName: "@jutro/datatable",
  description: "TableTitleBar",
  displayName: "TableTitleBar",
  methods: [],
  actualName: "TableTitleBar",
  metadataType: "container",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: ""
    },
    titleAction: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    theme: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    titleId: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    position: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'center'",
          computed: !1
        }]
      },
      required: !1,
      description: ""
    },
    titlePosition: {
      defaultValue: {
        value: "'left'",
        computed: !1
      },
      required: !1
    }
  }
}, renderTitle.__docgenInfo = {
  componentName: "renderTitle",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderTitle",
  methods: [],
  actualName: "renderTitle"
}, renderTitleAction.__docgenInfo = {
  componentName: "renderTitleAction",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderTitleAction",
  methods: [],
  actualName: "renderTitleAction"
}, renderTitleBar.__docgenInfo = {
  componentName: "renderTitleBar",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderTitleBar",
  methods: [],
  actualName: "renderTitleBar"
};