import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { log } from "@jutro/logger";
import { DATA_TYPE_STRING } from "@jutro/prop-types";
import { uniqueInnerId, getMessageProp } from "@jutro/platform";
import { OldFieldMessage } from "./OldFieldMessage";
import { messages } from "./FieldComponent.messages";
export var OldFieldComponentValidationImplementation = function () {
  function OldFieldComponentValidationImplementation() {
    _classCallCheck(this, OldFieldComponentValidationImplementation);
  }
  return _createClass(OldFieldComponentValidationImplementation, [{
    key: "bind",
    value: function (entityToBind) {
      var _this = this,
        methods = ["componentDidMount", "componentDidUpdate", "componentWillUnmount", "getValidationMessages", "getMessages", "getRules", "isValid", "notifyChange", "renderMessages", "validate", "handleFocus", "handleBlur", "isRequired"];
      return _reduceInstanceProperty(methods).call(methods, function (acc, method) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, method, _this[method].bind(entityToBind)));
      }, {});
    }
  }, {
    key: "componentDidMount",
    value: function () {}
  }, {
    key: "componentDidUpdate",
    value: function (prevProps) {
      (function (prevProps, newProps, dependencyProps) {
        var _context;
        return _concatInstanceProperty(_context = []).call(_context, basicValidationDependencyProps, _toConsumableArray(dependencyProps)).some(function (propName) {
          return !isEqual(prevProps[propName], newProps[propName]);
        });
      })(prevProps, this.props, this.validationDependencyProps || []) && this.validate(this.props.value);
    }
  }, {
    key: "componentWillUnmount",
    value: function () {
      this.updateTimeout && clearTimeout(this.updateTimeout);
    }
  }, {
    key: "isRequired",
    value: function () {
      var _this$props = this.props,
        required = _this$props.required,
        schemaRequired = _this$props.schemaRequired;
      return required || schemaRequired;
    }
  }, {
    key: "getRules",
    value: function () {}
  }, {
    key: "getValidationMessages",
    value: function () {
      var value = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.props.value,
        _this$props2 = this.props,
        validationMessages = _this$props2.validationMessages,
        requiredFieldValidationMessage = _this$props2.requiredFieldValidationMessage,
        validator = _this$props2.validator,
        messageProps = _this$props2.messageProps,
        dataType = _this$props2.dataType,
        readOnly = _this$props2.readOnly,
        requiredField = getMessageProp("requiredField", messageProps, messages);
      if (this.isRequired() && this.isEmpty(value) && isEmpty(validationMessages) && !readOnly) {
        var _translator = this.translator;
        return [requiredFieldValidationMessage || _translator(requiredField)];
      }
      var translator = this.translator,
        validatePattern = function (fieldValue, pattern) {
          var _value$match;
          return Array.isArray(fieldValue) ? fieldValue.some(function (entry) {
            var _entry$match;
            return !(null !== (_entry$match = entry.match) && void 0 !== _entry$match && _entry$match.call(entry, pattern));
          }) : !(null !== (_value$match = value.match) && void 0 !== _value$match && _value$match.call(value, pattern));
        };
      if (validator && !this.isEmpty() && dataType === DATA_TYPE_STRING) {
        var pattern = validator.pattern,
          message = validator.message;
        if (pattern && message && validatePattern(value, pattern)) return [translator(message)];
      }
      return null == validationMessages ? void 0 : _mapInstanceProperty(validationMessages).call(validationMessages, function (msg) {
        return translator(msg);
      });
    }
  }, {
    key: "validate",
    value: function (value) {
      var noNotification = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
        _this$props3 = this.props,
        onValidationChange = _this$props3.onValidationChange,
        model = _this$props3.model,
        path = _this$props3.path,
        readOnly = _this$props3.readOnly;
      if (onValidationChange && !readOnly) {
        var currentValidationMessages = this.getValidationMessages(value) || [],
          prevValidationMessages = this.prevValidationMessages || [];
        this.prevValidationMessages = currentValidationMessages, this.valid = isEmpty(currentValidationMessages);
        var areMessagesEqual = isEqual(currentValidationMessages, prevValidationMessages);
        return areMessagesEqual || noNotification || onValidationChange(this.valid, model || path, currentValidationMessages), this.valid;
      }
    }
  }, {
    key: "getMessages",
    value: function (validationMessages, successMessage, isValid) {
      return isValid ? successMessage ? {
        successMessage: successMessage
      } : {} : (validationMessages = _filterInstanceProperty(validationMessages).call(validationMessages, function (msg) {
        return !!msg || (log.error("This component is set to show errors, but the validation error message is undefined."), !1);
      }), {
        errorMessage: function () {
          return _mapInstanceProperty(validationMessages).call(validationMessages, function (msg) {
            return React.createElement("div", {
              key: msg
            }, msg);
          });
        }
      });
    }
  }, {
    key: "notifyChange",
    value: function (value) {
      this.validate(value);
    }
  }, {
    key: "handleFocus",
    value: function () {}
  }, {
    key: "handleBlur",
    value: function () {}
  }, {
    key: "renderMessages",
    value: function (validationMessages, successMessage, isValid) {
      var translator = this.translator,
        fieldUniqueId = this.fieldUniqueId,
        readOnly = this.props.readOnly,
        messageId = uniqueInnerId(fieldUniqueId, "messageId").messageId,
        messagesList = this.getMessages(validationMessages, successMessage, isValid),
        messageProps = {
          translator: translator
        };
      if (!readOnly) return React.createElement(OldFieldMessage, _extends({
        id: messageId
      }, messagesList, messageProps));
    }
  }, {
    key: "isValid",
    value: function (validationMessages) {
      return !this.showErrors() || isEmpty(validationMessages);
    }
  }]), OldFieldComponentValidationImplementation;
}();
var basicValidationDependencyProps = ["value", "validationMessages"];