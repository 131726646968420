import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["value", "unmaskedValue", "showMask", "mask", "forwardedRef"],
  _excluded2 = ["onClick", "showCalendar", "openWithIconOnly", "focusCalendar", "icon", "value", "mask", "noMask", "showMask", "disabled", "className"];
import React, { forwardRef, useRef } from "react";
import defer from "lodash/defer";
import NumberFormat from "react-number-format";
import cx from "classnames";
import styles from "./GenericDatePicker.module.css";
import inputStyles from "../../InputField/InputField.module.css";
import customInputStyles from "./CustomInput.module.css";
import { IconButton } from "../../../IconButton/IconButton";
import { messages } from "../DateField/DateField.messages";
function maskToFormat(mask) {
  return mask.replace(/[mMdDyY]/g, "#");
}
function maskToCharsArray(mask) {
  return mask.replace(/[^mMdDyY]/g, "").split("");
}
var CustomTextInput = function (props) {
  var value = props.value,
    unmaskedValue = props.unmaskedValue,
    showMask = props.showMask,
    forwardedRef = (props.mask, props.forwardedRef),
    other = _objectWithoutProperties(props, _excluded);
  return React.createElement("input", _extends({}, other, {
    ref: forwardedRef,
    value: showMask ? value : unmaskedValue,
    maxLength: null,
    className: customInputStyles.customInput
  }));
};
export var CustomInput = forwardRef(function (props, ref) {
  var onClick = props.onClick,
    showCalendar = props.showCalendar,
    openWithIconOnly = props.openWithIconOnly,
    focusCalendar = props.focusCalendar,
    icon = props.icon,
    value = props.value,
    mask = props.mask,
    noMask = props.noMask,
    showMask = props.showMask,
    disabled = props.disabled,
    className = props.className,
    other = _objectWithoutProperties(props, _excluded2),
    inputRef = useRef();
  "function" == typeof ref && ref(inputRef.current);
  var wrapperClasses = cx(inputStyles.input, customInputStyles.inputWrapper, customInputStyles.inputContainer, className);
  return React.createElement("div", {
    className: wrapperClasses
  }, noMask ? React.createElement("input", _extends({}, other, {
    onClick: showCalendar && !openWithIconOnly ? onClick : void 0,
    ref: inputRef,
    value: value,
    disabled: disabled,
    className: customInputStyles.customInput
  })) : React.createElement(NumberFormat, _extends({}, other, {
    forwardedRef: inputRef,
    showMask: showMask,
    format: mask && maskToFormat(mask),
    mask: mask && maskToCharsArray(mask),
    customInput: CustomTextInput,
    value: value,
    unmaskedValue: value,
    disabled: disabled,
    onClick: showCalendar && !openWithIconOnly ? onClick : void 0
  })), showCalendar && React.createElement(IconButton, {
    icon: icon,
    onClick: function (event) {
      onClick(event), defer(focusCalendar, event);
    },
    ariaLabel: messages.openCalendar,
    disabled: disabled,
    className: styles.icon
  }));
});
CustomInput.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "CustomInput"
}, CustomTextInput.__docgenInfo = {
  componentName: "CustomTextInput",
  packageName: "@jutro/components",
  description: "",
  displayName: "CustomTextInput",
  methods: [],
  actualName: "CustomTextInput"
};