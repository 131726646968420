import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import constant from "lodash/constant";
import assignWith from "lodash/assignWith";
import memoize from "memoize-one";
import { intlMessageShape, availableValuesIdDisplayNameObject, DATA_TYPE_OBJECT, availableValuesCodeNameObject, dataTypeShape } from "@jutro/prop-types";
import { isNilValue } from "@jutro/data";
import { messages } from "./LookupField.messages";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { GenericSelectControl } from "../GenericSelectControl/GenericSelectControl";
import { LookupOption as Option } from "./components/LookupOption";
import { LookupValue as Value } from "./components/LookupValue";
import { LookupDropdownIndicator as DropdownIndicator } from "./components/LookupDropdownIndicator";
import { LookupClearIndicator as ClearIndicator } from "./components/LookupClearIndicator";
import { LookupControl as Control } from "./components/LookupControl";
import { LookupMenu as Menu } from "./components/LookupMenu";
import { LookupPlaceholder as Placeholder } from "./components/LookupPlaceholder";
import styles from "./LookupField.module.css";
import { getOptionCode, getOptionName } from "../availableValues";
var lookupComponents = {
    Option: Option,
    Value: Value,
    DropdownIndicator: DropdownIndicator,
    ClearIndicator: ClearIndicator,
    Control: Control,
    Menu: Menu,
    Placeholder: Placeholder
  },
  shouldCreateNew = function (text) {
    return !!text;
  },
  FALSE = constant(!1),
  getOptionTypesMap = memoize(function () {
    var optionTypes = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return _reduceInstanceProperty(optionTypes).call(optionTypes, function (map, _ref) {
      var type = _ref.type,
        icon = _ref.icon,
        className = _ref.className,
        displayName = _ref.displayName;
      return _Object$assign(map, _defineProperty({}, type, {
        icon: icon,
        className: className,
        displayName: displayName
      }));
    }, {});
  }),
  getMergedStyles = memoize(function (internalClassNames) {
    return internalClassNames ? assignWith(_objectSpread({}, styles), internalClassNames, function (lookupFieldStyle, additionalStyle) {
      return additionalStyle ? cx(lookupFieldStyle, additionalStyle) : lookupFieldStyle;
    }) : styles;
  }),
  LookupOptionShape = PropTypes.shape(_objectSpread(_objectSpread({}, availableValuesIdDisplayNameObject), {}, {
    type: PropTypes.string.isRequired
  })),
  LookupCodeOptionShape = PropTypes.shape(_objectSpread(_objectSpread({}, availableValuesCodeNameObject), {}, {
    type: PropTypes.string.isRequired
  }));
export var LookupField = function (_FieldComponent) {
  _inherits(LookupField, FieldComponent);
  var _super = _createSuper(LookupField);
  function LookupField() {
    var _context, _this;
    _classCallCheck(this, LookupField);
    for (var _len = arguments.length, _args = new Array(_len), _key = 0; _key < _len; _key++) _args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, _args)), _defineProperty(_assertThisInitialized(_this), "translateObjectValue", function (targetValue) {
      return isNilValue(targetValue) ? targetValue : isNilValue(targetValue.name) ? _objectSpread(_objectSpread({}, targetValue), {}, {
        displayName: _this.translator(targetValue.displayName)
      }) : _objectSpread(_objectSpread({}, targetValue), {}, {
        name: _this.translator(targetValue.name)
      });
    }), _defineProperty(_assertThisInitialized(_this), "handleValueChange", function (value) {
      var dataType = _this.props.dataType;
      if (_this.beforeValue = _this.props.value, dataType === DATA_TYPE_OBJECT) _this.notifyChange(_this.translateObjectValue(value));else _this.notifyChange(getOptionCode(value));
    }), _defineProperty(_assertThisInitialized(_this), "getMatchedValue", function (value, availableValues) {
      return value && availableValues ? _findInstanceProperty(availableValues).call(availableValues, function (availableValue) {
        return getOptionCode(availableValue) === getOptionCode(value);
      }) : value;
    }), _defineProperty(_assertThisInitialized(_this), "recent", _this.props.onLoadValues ? [] : _this.props.availableValues), _defineProperty(_assertThisInitialized(_this), "state", {
      recent: _this.recent
    }), _defineProperty(_assertThisInitialized(_this), "loadValues", function (onLoadValues) {
      return _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
        var _args2 = arguments;
        return _regeneratorRuntime.wrap(function (_context2) {
          for (;;) switch (_context2.prev = _context2.next) {
            case 0:
              return _context2.next = 2, onLoadValues.apply(void 0, _args2);
            case 2:
              return _this.recent = _context2.sent, _context2.abrupt("return", _this.recent);
            case 4:
            case "end":
              return _context2.stop();
          }
        }, _callee);
      }));
    }), _defineProperty(_assertThisInitialized(_this), "onMenuClose", function () {
      _this.setState({
        recent: _this.recent
      });
    }), _this;
  }
  return _createClass(LookupField, [{
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        optionTypes = breakpointProps.optionTypes,
        className = breakpointProps.className,
        availableValues = breakpointProps.availableValues,
        optionTypesMap = getOptionTypesMap(optionTypes),
        mergedStyles = getMergedStyles(this.props.internalClassNames),
        matchedValue = this.getMatchedValue(value, availableValues);
      return matchedValue && React.createElement("div", _extends({
        id: id,
        "data-read-only": !0,
        className: cx(mergedStyles.lookupField, className)
      }, this.generateReadOnlyAccessibilityProperties()), React.createElement(lookupComponents.Value, {
        data: matchedValue,
        selectProps: {
          customStyles: mergedStyles,
          optionTypesMap: optionTypesMap
        }
      }));
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, _ref3) {
      var isValid = _ref3.isValid,
        labelId = _ref3.labelId,
        availableValues = breakpointProps.availableValues,
        createNewMessage = breakpointProps.createNewMessage,
        isClearable = breakpointProps.isClearable,
        onLoadValues = breakpointProps.onLoadValues,
        onAddNew = breakpointProps.onAddNew,
        optionTypes = breakpointProps.optionTypes,
        placeholder = breakpointProps.placeholder,
        value = breakpointProps.value,
        disabled = breakpointProps.disabled,
        isInitiallyOpen = breakpointProps.isInitiallyOpen,
        internalClassNames = breakpointProps.internalClassNames,
        id = breakpointProps.id,
        testId = breakpointProps.testId,
        showRecentlyViewed = breakpointProps.showRecentlyViewed,
        recentlyViewedMessage = breakpointProps.recentlyViewedMessage,
        required = breakpointProps.required,
        mergedStyles = getMergedStyles(internalClassNames),
        fieldUniqueId = this.fieldUniqueId,
        translator = this.translator,
        allowNewValue = onAddNew ? shouldCreateNew : FALSE,
        matchedValue = this.getMatchedValue(value, availableValues);
      return React.createElement(GenericSelectControl, {
        id: id,
        fieldUniqueId: fieldUniqueId,
        testId: testId,
        availableValues: availableValues,
        components: lookupComponents,
        createNewMessage: createNewMessage,
        defaultOptions: showRecentlyViewed ? this.state.recent : [],
        disabled: disabled,
        filter: lookupFilter(translator),
        getOptionValue: getOptionCode,
        getOptionLabel: getOptionLabel(optionTypes, translator),
        isClearable: isClearable,
        allowNewValue: allowNewValue,
        labelId: labelId,
        loadValues: onLoadValues ? this.loadValues(onLoadValues) : void 0,
        onAddNew: onAddNew,
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        onInputChange: this.onInputChange,
        onMenuClose: this.onMenuClose,
        onValueChange: this.handleValueChange,
        placeholder: placeholder || " ",
        valid: isValid,
        value: matchedValue,
        optionTypesMap: getOptionTypesMap(optionTypes),
        customStyles: mergedStyles,
        showRecent: showRecentlyViewed,
        recentlyViewedMessage: recentlyViewedMessage,
        isInitiallyOpen: isInitiallyOpen,
        required: required
      });
    }
  }, {
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(LookupField.prototype), "render", this).call(this);
    }
  }]), LookupField;
}();
_defineProperty(LookupField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  availableValues: PropTypes.arrayOf(PropTypes.oneOfType([LookupCodeOptionShape, LookupOptionShape])),
  optionTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    displayName: intlMessageShape.isRequired
  })),
  createNewMessage: intlMessageShape,
  defaultValue: PropTypes.oneOfType([LookupCodeOptionShape, LookupOptionShape]),
  isClearable: PropTypes.bool,
  onLoadValues: PropTypes.func,
  onAddNew: PropTypes.func,
  showRecentlyViewed: PropTypes.bool,
  recentlyViewedMessage: intlMessageShape,
  value: PropTypes.oneOfType([LookupCodeOptionShape, LookupOptionShape]),
  isInitiallyOpen: PropTypes.bool,
  internalClassNames: PropTypes.object,
  dataType: dataTypeShape
})), _defineProperty(LookupField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  createNewMessage: messages.addItem,
  isClearable: !0,
  dataType: DATA_TYPE_OBJECT
}));
var lookupFilter = function (translator) {
    return function (option, text) {
      var _context3;
      return _includesInstanceProperty(_context3 = translator(getOptionName(option)).toLowerCase()).call(_context3, text.toLowerCase());
    };
  },
  getOptionLabel = function (optionTypes, translator) {
    return function (option) {
      var _context4,
        _optionType$displayNa,
        optionType = null == optionTypes ? void 0 : _findInstanceProperty(optionTypes).call(optionTypes, function (el) {
          return el.type === option.type;
        });
      return _concatInstanceProperty(_context4 = "".concat(translator(getOptionName(option)), ", ")).call(_context4, translator(null !== (_optionType$displayNa = null == optionType ? void 0 : optionType.displayName) && void 0 !== _optionType$displayNa ? _optionType$displayNa : messages.unknownType));
    };
  };
LookupField.__docgenInfo = {
  description: "@typedef {typeof LookupField.propTypes} LookupFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<LookupFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "translateObjectValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "targetValue",
      type: null
    }],
    returns: null
  }, {
    name: "handleValueChange",
    docblock: "handle value change\n@param {object} value",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "handle value change"
  }, {
    name: "getMatchedValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "value",
      type: null
    }, {
      name: "availableValues",
      type: null
    }],
    returns: null
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }, {
      name: "{ isValid, labelId }",
      type: null
    }],
    returns: null
  }, {
    name: "loadValues",
    docblock: null,
    modifiers: [],
    params: [{
      name: "onLoadValues",
      type: null
    }],
    returns: null
  }, {
    name: "onMenuClose",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  displayName: "LookupField",
  props: {
    createNewMessage: {
      defaultValue: {
        value: "messages.addItem",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message to display when a new option is being created by the user"
    },
    isClearable: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, ClearIndicator will be shown"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShape"
      },
      required: !1,
      description: "The format of the value"
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "custom",
            raw: "LookupCodeOptionShape"
          }, {
            name: "custom",
            raw: "LookupOptionShape"
          }]
        }
      },
      required: !1,
      description: "Array of choice objects to display"
    },
    optionTypes: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            type: {
              name: "string",
              required: !0
            },
            icon: {
              name: "string",
              required: !0
            },
            className: {
              name: "string",
              required: !1
            },
            displayName: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              required: !0
            }
          }
        }
      },
      required: !1,
      description: "Description for available option types"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "LookupCodeOptionShape"
        }, {
          name: "custom",
          raw: "LookupOptionShape"
        }]
      },
      required: !1,
      description: "Sets the default field value on render if there is a default value; needs `onValueChange` to work"
    },
    onLoadValues: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function for asynchronous data loading"
    },
    onAddNew: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when a new item is created"
    },
    showRecentlyViewed: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'If true, the "recently viewed" bar will be shown'
    },
    recentlyViewedMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: 'The message to display for the "recently viewed" bar.'
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "LookupCodeOptionShape"
        }, {
          name: "custom",
          raw: "LookupOptionShape"
        }]
      },
      required: !1,
      description: "Value to display in the component"
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should dropdown be initially opened"
    },
    internalClassNames: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Map of CSS class names for overriding individual parts of the component's styles"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, LookupField.__docgenInfo = {
  componentName: "LookupField",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupField",
  methods: [{
    name: "translateObjectValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "targetValue",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleValueChange",
    docblock: "handle value change\n@param {object} value",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "handle value change"
  }, {
    name: "getMatchedValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "value",
      optional: void 0,
      type: null
    }, {
      name: "availableValues",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }, {
      name: "{ isValid, labelId }",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "loadValues",
    docblock: null,
    modifiers: [],
    params: [{
      name: "onLoadValues",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "onMenuClose",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "LookupField",
  metadataType: "field",
  props: {
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "custom",
            raw: "LookupCodeOptionShape"
          }, {
            name: "custom",
            raw: "LookupOptionShape"
          }]
        }
      },
      required: !1,
      description: "Array of choice objects to display"
    },
    optionTypes: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            type: {
              name: "string",
              required: !0
            },
            icon: {
              name: "string",
              required: !0
            },
            className: {
              name: "string",
              required: !1
            },
            displayName: {
              name: "union",
              required: !0,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: !1
                  },
                  defaultMessage: {
                    name: "string",
                    required: !1
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: !1
                  }
                }
              }]
            }
          }
        }
      },
      required: !1,
      description: "Description for available option types"
    },
    createNewMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message to display when a new option is being created by the user",
      defaultValue: {
        value: "messages.addItem",
        computed: !0
      }
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "LookupCodeOptionShape"
        }, {
          name: "custom",
          raw: "LookupOptionShape"
        }]
      },
      required: !1,
      description: "Sets the default field value on render if there is a default value; needs `onValueChange` to work"
    },
    isClearable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, ClearIndicator will be shown",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    onLoadValues: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function for asynchronous data loading"
    },
    onAddNew: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when a new item is created"
    },
    showRecentlyViewed: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'If true, the "recently viewed" bar will be shown'
    },
    recentlyViewedMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: 'The message to display for the "recently viewed" bar.'
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "LookupCodeOptionShape"
        }, {
          name: "custom",
          raw: "LookupOptionShape"
        }]
      },
      required: !1,
      description: "Value to display in the component"
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should dropdown be initially opened"
    },
    internalClassNames: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Map of CSS class names for overriding individual parts of the component's styles"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }]
      },
      required: !1,
      description: "The format of the value",
      defaultValue: {
        value: "'object'",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};