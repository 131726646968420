import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["auth"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React, { memo, useCallback } from "react";
import { AuthProvider, SecureRoute } from "@jutro/auth";
var missingAuthCode = {
    errorCode: "missing_auth"
  },
  AuthRouteInternal = function (_ref) {
    var path = _ref.path,
      renderBody = _ref.render,
      AuthErrorComponent = _ref.authErrorComponent,
      _ref$authComponent = _ref.authComponent,
      ProviderComponent = void 0 === _ref$authComponent ? AuthProvider : _ref$authComponent,
      render = useCallback(function (_ref2) {
        var auth = _ref2.auth,
          routeProps = _objectWithoutProperties(_ref2, _excluded);
        return auth ? renderBody(function (target) {
          for (var i = 1; i < arguments.length; i++) {
            var source = null != arguments[i] ? arguments[i] : {};
            i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
              _defineProperty(target, key, source[key]);
            }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
              _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
            });
          }
          return target;
        }({
          isAuthenticated: auth.authenticated
        }, routeProps)) : React.createElement(AuthErrorComponent, {
          error: missingAuthCode
        });
      }, [AuthErrorComponent, renderBody]);
    return React.createElement(ProviderComponent, {
      authErrorComponent: AuthErrorComponent
    }, React.createElement(SecureRoute, {
      path: path,
      render: render
    }));
  };
AuthRouteInternal.displayName = "AuthRoute";
export var AuthRoute = memo(AuthRouteInternal);
AuthRouteInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AuthRoute",
  props: {
    authComponent: {
      defaultValue: {
        value: "AuthProvider",
        computed: !0
      },
      required: !1
    }
  }
}, AuthRouteInternal.__docgenInfo = {
  componentName: "AuthRoute",
  packageName: "@jutro/app",
  description: "",
  displayName: "AuthRoute",
  methods: [],
  actualName: "AuthRouteInternal",
  props: {
    authComponent: {
      defaultValue: {
        value: "AuthProvider",
        computed: !0
      },
      required: !1
    }
  }
};