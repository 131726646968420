import _extends from "@babel/runtime-corejs3/helpers/extends";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import React, { memo, useRef, useImperativeHandle, useMemo } from "react";
import cx from "classnames";
import { generateComponentId } from "@jutro/platform";
import styles from "./Icon.module.css";
var IconInternal = React.forwardRef(function (_ref, ref) {
  var id = _ref.id,
    icon = _ref.icon,
    className = _ref.className,
    color = _ref.color,
    _rest = _ref._rest,
    iconRef = useRef(null);
  useImperativeHandle(ref, function () {
    return iconRef.current;
  });
  var iconId = useMemo(function () {
    return id || generateComponentId(icon);
  }, [id, icon]);
  return icon ? React.createElement("svg", _extends({
    id: iconId,
    "data-testid": "icon-".concat(icon),
    ref: iconRef,
    className: cx(styles.svgIcon, className),
    viewBox: "0 0 1024 1024",
    role: "object" === _typeof(_rest) && _rest && ("aria-label" in _rest || "aria-labelledby" in _rest) ? void 0 : "presentation"
  }, _rest), React.createElement("use", {
    fill: color || void 0,
    href: "#".concat(icon)
  })) : null;
});
IconInternal.displayName = "IconSVG";
export var IconSVG = memo(IconInternal);
IconSVG.displayName = "IconSVG", IconInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "IconSVG",
  props: {
    id: {
      required: !1,
      tsType: {
        name: "string"
      },
      description: "Id of the icon"
    },
    className: {
      required: !1,
      tsType: {
        name: "union",
        raw: "string | null",
        elements: [{
          name: "string"
        }, {
          name: "null"
        }]
      },
      description: "CSS class name for this component"
    },
    icon: {
      required: !0,
      tsType: {
        name: "string"
      },
      description: "Icon name (gw-*) to be used"
    },
    color: {
      required: !1,
      tsType: {
        name: "union",
        raw: "string | null",
        elements: [{
          name: "string"
        }, {
          name: "null"
        }]
      },
      description: "Icon color to be used"
    },
    _rest: {
      required: !1,
      tsType: {
        name: "unknown"
      },
      description: "Set of custom props, do not set this field directly - this is a placeholder for the rest of properties"
    }
  }
}, IconSVG.__docgenInfo = {
  componentName: "IconSVG",
  packageName: "@jutro/components",
  description: "",
  displayName: "IconSVG",
  methods: [],
  actualName: "IconSVG"
};