import noop from "lodash/noop";
import { messages } from "./alertHelper.messages";
export function showAlert(message, title) {
  var callback = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : noop,
    modalEmitter = arguments.length > 3 ? arguments[3] : void 0,
    useDefault = !1;
  if (useDefault) dom.window.alert(message);else {
    var icon = "gw-error-outline",
      status = "warning",
      showModalAlert = modalEmitter.showAlert,
      answer = showModalAlert({
        title: title || messages.actionWarning,
        message: message,
        status: status,
        icon: icon,
        confirmButtonText: messages.ok
      });
    answer.then(callback, noop);
  }
}