import React from "react";
import find from "lodash/find";
import cx from "classnames";
import { getMenuListId } from "../helpers";
export var GenericSelectControlMenuList = function (_ref) {
  var _find,
    children = _ref.children,
    className = _ref.className,
    isMulti = _ref.isMulti,
    innerRef = _ref.innerRef,
    selectProps = _ref.selectProps,
    menuIsOpen = selectProps.menuIsOpen,
    genericSelectStyles = selectProps.genericSelectStyles,
    id = selectProps.id,
    menuListClasses = cx({
      "menu-list": !0,
      "menu-list--is-multi": isMulti
    }, genericSelectStyles.menuList, className),
    activeOptionId = null === (_find = find(children, function (child) {
      var _child$props;
      return null == child || null === (_child$props = child.props) || void 0 === _child$props ? void 0 : _child$props.isFocused;
    })) || void 0 === _find ? void 0 : _find.key;
  return React.createElement("ul", {
    id: getMenuListId(id),
    className: menuListClasses,
    ref: innerRef,
    role: "listbox",
    "aria-labelledby": selectProps["aria-labelledby"],
    "aria-activedescendant": activeOptionId,
    "aria-expanded": menuIsOpen,
    tabIndex: "0"
  }, children);
};
GenericSelectControlMenuList.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlMenuList"
}, GenericSelectControlMenuList.__docgenInfo = {
  componentName: "GenericSelectControlMenuList",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlMenuList",
  methods: [],
  actualName: "GenericSelectControlMenuList"
};