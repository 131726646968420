import React from "react";
import cx from "classnames";
import { getMessageProp } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import styles from "./InlineNotification.module.css";
import { Icon } from "../Icon/Icon";
import { IconButton } from "../IconButton/IconButton";
import { InlineNotificationTooltip } from "./InlineNotificationTooltip";
import { messages } from "./InlineNotification.messages";
import { getKeyPressHandler } from "../../accessibility/getKeyPressHandler";
import { styleIconWrapperByType } from "./InlineNotificationStyleValues";
var typeIconMap = {
  warning: "gw-warning",
  error: "gw-error",
  info: "gw-info",
  success: "gw-check-circle"
};
export var InlineNotificationContent = function (_ref) {
  var handleDismiss = _ref.handleDismiss,
    linkProps = _ref.linkProps,
    id = _ref.id,
    isDismissable = _ref.isDismissable,
    isEmbeddedNotification = _ref.isEmbeddedNotification,
    message = _ref.message,
    messageProps = _ref.messageProps,
    type = _ref.type,
    isTooltipEnabled = _ref.isTooltipEnabled,
    translator = useTranslator();
  return React.createElement(React.Fragment, null, React.createElement("span", {
    className: cx(styles.iconWrapper, styleIconWrapperByType[type]),
    "aria-hidden": "true"
  }, React.createElement(Icon, {
    icon: typeIconMap[type],
    size: "large"
  })), React.createElement("div", {
    className: styles.message
  }, React.createElement(InlineNotificationTooltip, {
    title: React.createElement("span", {
      className: styles.messageDefault
    }, translator(function (type, messageProps) {
      switch (type) {
        case "success":
          return getMessageProp("success", messageProps, messages);
        case "info":
          return getMessageProp("info", messageProps, messages);
        case "warning":
          return getMessageProp("warning", messageProps, messages);
        case "error":
          return getMessageProp("error", messageProps, messages);
        default:
          throw new Error("Unhandled notification type message: ".concat(type));
      }
    }(type, messageProps))),
    message: message,
    id: id,
    linkProps: linkProps,
    isTooltipEnabled: isTooltipEnabled
  })), !isEmbeddedNotification && isDismissable && handleDismiss && React.createElement(IconButton, {
    icon: "gw-close",
    className: styles.dismissButton,
    iconClassName: styles.dismissButtonIcon,
    onClick: handleDismiss,
    ariaLabel: getMessageProp("dismiss", messageProps, messages),
    onKeyPress: getKeyPressHandler(handleDismiss)
  }));
};
InlineNotificationContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "InlineNotificationContent",
  props: {
    handleDismiss: {
      required: !1,
      tsType: {
        name: "signature",
        type: "function",
        raw: "() => unknown",
        signature: {
          arguments: [],
          return: {
            name: "unknown"
          }
        }
      },
      description: ""
    },
    linkProps: {
      required: !1,
      tsType: {
        name: "LinkProps"
      },
      description: ""
    },
    id: {
      required: !0,
      tsType: {
        name: "string"
      },
      description: ""
    },
    isDismissable: {
      required: !1,
      tsType: {
        name: "boolean"
      },
      description: ""
    },
    isEmbeddedNotification: {
      required: !1,
      tsType: {
        name: "boolean"
      },
      description: ""
    },
    message: {
      required: !0,
      tsType: {
        name: "IntlMessageShape"
      },
      description: ""
    },
    messageProps: {
      required: !1,
      tsType: {
        name: "MessageProps"
      },
      description: ""
    },
    type: {
      required: !0,
      tsType: {
        name: "NotificationType"
      },
      description: ""
    },
    isTooltipEnabled: {
      required: !1,
      tsType: {
        name: "boolean"
      },
      description: ""
    }
  }
}, InlineNotificationContent.__docgenInfo = {
  componentName: "InlineNotificationContent",
  packageName: "@jutro/components",
  description: "",
  displayName: "InlineNotificationContent",
  methods: [],
  actualName: "InlineNotificationContent"
};