import React, { useRef, useEffect } from "react";
import { useTranslator } from "@jutro/locale";
import { IconButton } from "../IconButton/IconButton";
import { getKeyPressHandler } from "../../accessibility/getKeyPressHandler";
import { messages } from "./Toast.messages";
export var CloseToastButton = function (_ref) {
  var closeToast = _ref.closeToast,
    iconColor = _ref.iconColor,
    className = _ref.className,
    setFocus = _ref.setFocus,
    translator = useTranslator(),
    focusReturn = useRef(document.activeElement),
    buttonRef = useRef(null),
    setPreviouslyFocusedElement = function (event) {
      event.target !== buttonRef.current && (focusReturn.current = document.activeElement);
    };
  useEffect(function () {
    var _buttonRef$current, _buttonRef$current$fo;
    (document.addEventListener("focusin", setPreviouslyFocusedElement), setFocus && buttonRef.current) && (null === (_buttonRef$current = buttonRef.current) || void 0 === _buttonRef$current || null === (_buttonRef$current$fo = _buttonRef$current.focus) || void 0 === _buttonRef$current$fo || _buttonRef$current$fo.call(_buttonRef$current));
    return function () {
      document.removeEventListener("focusin", setPreviouslyFocusedElement);
    };
  }, [setFocus]);
  var onClose = function (event) {
    var _focusReturn$current, _focusReturn$current$;
    (null == closeToast || closeToast(event), focusReturn.current) && (null === (_focusReturn$current = focusReturn.current) || void 0 === _focusReturn$current || null === (_focusReturn$current$ = _focusReturn$current.focus) || void 0 === _focusReturn$current$ || _focusReturn$current$.call(_focusReturn$current));
  };
  return React.createElement(IconButton, {
    icon: "gw-close",
    iconColor: iconColor,
    className: className,
    onKeyPress: getKeyPressHandler(onClose),
    onClick: onClose,
    ref: buttonRef,
    ariaLabel: translator(messages.dismissButton)
  });
};
CloseToastButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "CloseToastButton"
}, CloseToastButton.__docgenInfo = {
  componentName: "CloseToastButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "CloseToastButton",
  methods: [],
  actualName: "CloseToastButton"
};