import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["content", "className", "tag"];
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./HelpPopover.module.css";
var helpComponentPropTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  tag: PropTypes.elementType
};
export var HelpElement = function (_ref) {
  var content = _ref.content,
    className = _ref.className,
    Tag = _ref.tag,
    tagProps = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator();
  if (isEmpty(content)) return null;
  var classes = cx(styles.helpComponent, className);
  return React.createElement(Tag, _extends({
    className: classes
  }, tagProps), translator(content));
};
HelpElement.propTypes = helpComponentPropTypes, HelpElement.defaultProps = {
  tag: "div"
}, HelpElement.__docgenInfo = {
  description: "The `HelpComponent` component is designed as a simple component which displays\ntext inside the Help drop-down list in the Application Header.\n\n@type {React.FC<PropTypes.InferProps<typeof helpComponentPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "HelpElement",
  props: {
    tag: {
      defaultValue: {
        value: "'div'",
        computed: !1
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "The element type (a.k.a. tag) in which to render the text"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "The children elements to render"
    }
  }
}, HelpElement.__docgenInfo = {
  componentName: "HelpElement",
  packageName: "@jutro/components",
  description: "The `HelpComponent` component is designed as a simple component which displays\ntext inside the Help drop-down list in the Application Header.",
  displayName: "HelpElement",
  methods: [],
  actualName: "HelpElement",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "The children elements to render"
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "The element type (a.k.a. tag) in which to render the text",
      defaultValue: {
        value: "'div'",
        computed: !1
      }
    }
  }
};