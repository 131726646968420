import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _globalThis from "@babel/runtime-corejs3/core-js/global-this";
import { Tracer, ExplicitContext, ConsoleRecorder, BatchRecorder, jsonEncoder } from "zipkin";
import wrapFetch from "zipkin-instrumentation-fetch";
import { HttpLogger } from "zipkin-transport-http";
import { getConfigValue } from "@jutro/config";
import { REQUEST_HANDLER_TYPE } from "../HttpRequestBuilder";
var wrapWindowFetch = wrapFetch,
  JSON_V2 = jsonEncoder.JSON_V2;
export function zipkinTraceHandler(remoteServiceName, localServiceName, zipkinUrl, debug) {
  var recorder,
    fetchCall = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : _globalThis.fetch,
    zipkinDebugConfigValue = getConfigValue("zipkinDebug"),
    zipkinUrlConfigValue = getConfigValue("JUTRO_ZIPKIN_URL");
  recorder = debug || zipkinDebugConfigValue ? new ConsoleRecorder() : zipkinUrl || zipkinUrlConfigValue ? new BatchRecorder({
    logger: new HttpLogger({
      endpoint: zipkinUrl || (null == zipkinUrlConfigValue ? void 0 : zipkinUrlConfigValue.toString()),
      jsonEncoder: JSON_V2
    })
  }) : {
    record: function () {}
  };
  var zipkinLocalServiceNameConfigValue = getConfigValue("JUTRO_ZIPKIN_LOCAL_SERVICE_NAME"),
    tracer = new Tracer({
      ctxImpl: new ExplicitContext(),
      recorder: recorder,
      localServiceName: localServiceName || (null == zipkinLocalServiceNameConfigValue ? void 0 : zipkinLocalServiceNameConfigValue.toString())
    }),
    traceFetch = function (url, options) {
      var theFetch = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : fetchCall;
      return wrapWindowFetch(theFetch, {
        tracer: tracer,
        remoteServiceName: remoteServiceName
      })(url, options);
    };
  return _defineProperty({}, REQUEST_HANDLER_TYPE.FETCH, traceFetch);
}