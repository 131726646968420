import React from "react";
import { getMessageProp } from "@jutro/platform";
import { Button } from "../../../button/Button";
import { messages } from "../FileUploadField.messages";
export var FileUploadActionButton = function (_ref) {
  var size = _ref.size,
    type = _ref.type,
    disabled = _ref.disabled,
    onClick = _ref.onClick,
    _ref$icon = _ref.icon,
    icon = void 0 === _ref$icon ? "gw-cloud-upload" : _ref$icon,
    messageProps = _ref.messageProps,
    uploadFilesMessage = getMessageProp("uploadFilesMessage", messageProps, messages);
  return React.createElement(Button, {
    icon: icon,
    type: type,
    size: size,
    onClick: onClick,
    disabled: disabled
  }, uploadFilesMessage);
};
FileUploadActionButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FileUploadActionButton",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-cloud-upload'",
        computed: !1
      },
      required: !1
    }
  }
}, FileUploadActionButton.__docgenInfo = {
  componentName: "FileUploadActionButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "FileUploadActionButton",
  methods: [],
  actualName: "FileUploadActionButton",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-cloud-upload'",
        computed: !1
      },
      required: !1
    }
  }
};