import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { uniqueInnerId } from "@jutro/platform";
import { ComponentPropTypes } from "@jutro/prop-types";
import { Tab } from "./Tab";
import styles from "./TabSet.module.css";
import { TabBar } from "./TabBar";
import { TabSetContext } from "./TabSetContext";
var extractTabsMetadata = function (children, activeTab) {
    var _context;
    return _mapInstanceProperty(_context = React.Children.toArray(function (children) {
      return "object" === _typeof(children) && children.type === React.Fragment ? children.props.children : children;
    }(children))).call(_context, function (child) {
      var _child$props = child.props,
        id = _child$props.id;
      return {
        id: id,
        heading: _child$props.heading,
        disabled: _child$props.disabled,
        visible: _child$props.visible,
        className: _child$props.className,
        active: activeTab === id
      };
    });
  },
  TabSetPropTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.string,
    onTabChange: PropTypes.func,
    className: PropTypes.string,
    children: ComponentPropTypes.childOfComponentType(Tab),
    showFrame: PropTypes.bool,
    defaultActiveTab: PropTypes.string,
    label: PropTypes.string
  };
export var TabSet = function (_ref) {
  var _tabsMetadata$filter,
    _tabsMetadata$filter$,
    id = _ref.id,
    label = _ref.label,
    children = _ref.children,
    _ref$showFrame = _ref.showFrame,
    showFrame = void 0 !== _ref$showFrame && _ref$showFrame,
    className = _ref.className,
    onTabChange = _ref.onTabChange,
    defaultActiveTab = _ref.defaultActiveTab,
    propsActiveTab = _ref.activeTab,
    _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    activeTab = _useState2[0],
    setActiveTab = _useState2[1];
  useEffect(function () {
    setActiveTab(propsActiveTab || defaultActiveTab);
  }, [propsActiveTab, defaultActiveTab]);
  var onTabClick = useCallback(function (evt) {
      var _currentTarget$datase,
        currentTarget = evt.currentTarget,
        currentActiveTab = null == currentTarget || null === (_currentTarget$datase = currentTarget.dataset) || void 0 === _currentTarget$datase ? void 0 : _currentTarget$datase.tab;
      evt.preventDefault(), onTabChange ? onTabChange(currentActiveTab) : setActiveTab(currentActiveTab);
    }, [onTabChange, setActiveTab]),
    contentId = uniqueInnerId(id, "contentId").contentId,
    barId = uniqueInnerId(id, "barId").barId,
    tabsMetadata = extractTabsMetadata(children, activeTab),
    activeTabHeading = null === (_tabsMetadata$filter = _filterInstanceProperty(tabsMetadata).call(tabsMetadata, function (tab) {
      return tab.active;
    })) || void 0 === _tabsMetadata$filter || null === (_tabsMetadata$filter$ = _tabsMetadata$filter[0]) || void 0 === _tabsMetadata$filter$ ? void 0 : _tabsMetadata$filter$.heading;
  return React.createElement("div", {
    className: cx(styles.tabSet, _defineProperty({}, styles.frame, showFrame), className)
  }, React.createElement(TabSetContext.Provider, {
    value: activeTab
  }, React.createElement(TabBar, {
    id: barId,
    onHeadingClick: onTabClick,
    tabs: tabsMetadata,
    ariaControls: contentId,
    label: label
  }), React.createElement("hr", {
    className: styles.divider
  }), React.createElement("div", {
    id: contentId,
    className: cx(styles.content),
    role: "region",
    "aria-label": activeTabHeading
  }, children)));
};
TabSet.propTypes = TabSetPropTypes, TabSet.__docgenInfo = {
  description: "Renders a container for a set of `Tab` components.  Extracts the `heading`\nprop from each of its `Tab` children and uses them to render the tab navigation bar.\n\n@typedef {typeof TabSet.propTypes} TabSetPropTypes\n@extends Component<PropTypes.InferProps<TabSetPropTypes>>\n\n@metadataType container",
  methods: [],
  displayName: "TabSet",
  props: {
    showFrame: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, displays a frame surrounding `TabSet`"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    activeTab: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The 'tabId' of the active tab; used in conjunction with `onTabChange`"
    },
    onTabChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The callback to call when the tab is changed; if provided, `activeTab` must also be provided"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional class name to apply to this component"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(Tab)"
      },
      required: !1,
      description: "A set of `<Tab>` children for this component"
    },
    defaultActiveTab: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Which tab is active by default"
    },
    label: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional label describing the purpose of the tab list"
    }
  }
}, TabSet.__docgenInfo = {
  componentName: "TabSet",
  packageName: "@jutro/components",
  description: "Renders a container for a set of `Tab` components.  Extracts the `heading`\nprop from each of its `Tab` children and uses them to render the tab navigation bar.",
  displayName: "TabSet",
  methods: [],
  actualName: "TabSet",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    activeTab: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The 'tabId' of the active tab; used in conjunction with `onTabChange`"
    },
    onTabChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The callback to call when the tab is changed; if provided, `activeTab` must also be provided"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional class name to apply to this component"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(Tab)"
      },
      required: !1,
      description: "A set of `<Tab>` children for this component"
    },
    showFrame: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, displays a frame surrounding `TabSet`",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    defaultActiveTab: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Which tab is active by default"
    },
    label: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional label describing the purpose of the tab list"
    }
  }
};