import _extends from "@babel/runtime-corejs3/helpers/extends";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useContext, useEffect, useCallback, useMemo } from "react";
import { logDeprecationMessage } from "@jutro/platform";
import { ThemeContext } from "@jutro/theme";
import { DropdownSelectField } from "../../inputs/DropdownSelectField/DropdownSelectField";
import { themeChooserPropTypes, themeChooserDefaultProps } from "./propTypes";
export var ThemeChooser = function (_ref) {
  var id = _ref.id,
    availableThemes = _ref.availableThemes,
    themeInput = _ref.theme,
    defaultTheme = _ref.defaultTheme,
    onThemeChange = _ref.onThemeChange,
    className = _ref.className,
    label = _ref.label,
    isValueControlledOutside = _ref.skipPropagation,
    readOnly = _ref.readOnly,
    hideLabel = _ref.hideLabel,
    internalClassNames = _ref.internalClassNames,
    alwaysShowPlaceholder = _ref.alwaysShowPlaceholder,
    searchable = _ref.searchable,
    isInitiallyOpen = _ref.isInitiallyOpen,
    controlClassName = _ref.controlClassName,
    secondaryLabel = _ref.secondaryLabel,
    tooltip = _ref.tooltip,
    placeholder = _ref.placeholder,
    required = _ref.required,
    schemaRequired = _ref.schemaRequired,
    disabled = _ref.disabled,
    nullable = _ref.nullable,
    visible = _ref.visible,
    onValidationChange = _ref.onValidationChange,
    onBlur = _ref.onBlur,
    onFocus = _ref.onFocus,
    showErrors = _ref.showErrors,
    showRequired = _ref.showRequired,
    showOptional = _ref.showOptional,
    layout = _ref.layout,
    labelClassName = _ref.labelClassName,
    secondaryLabelClassName = _ref.secondaryLabelClassName,
    showValidationIcon = _ref.showValidationIcon,
    requiredFieldValidationMessage = _ref.requiredFieldValidationMessage,
    successMessage = _ref.successMessage,
    messageProps = _ref.messageProps,
    labelPosition = _ref.labelPosition,
    phone = _ref.phone,
    phoneWide = _ref.phoneWide,
    tablet = _ref.tablet,
    themeContextConfig = useContext(ThemeContext),
    theme = useMemo(function () {
      return isValueControlledOutside && _findInstanceProperty(availableThemes).call(availableThemes, function (_ref2) {
        return _ref2.name === (themeInput && themeInput.name);
      });
    }, [themeInput]) || themeContextConfig;
  useEffect(function () {
    availableThemes.forEach(function (_ref3) {
      var dropTarget = _ref3.dropTarget,
        name = _ref3.name;
      dropTarget && logDeprecationMessage("ThemeChooser: ".concat(name, " Theme"), void 0, void 0, dropTarget);
    });
  }, [availableThemes]);
  var availableThemesOptions = useMemo(function () {
      return _mapInstanceProperty(availableThemes).call(availableThemes, function (_ref4) {
        var name = _ref4.name;
        return {
          displayName: name,
          id: name
        };
      });
    }, [availableThemes]),
    handleOnChange = useCallback(function (themeName) {
      var newTheme = _findInstanceProperty(availableThemes).call(availableThemes, function (themeItem) {
        return themeItem.name === themeName;
      });
      newTheme && (onThemeChange && onThemeChange(newTheme), !isValueControlledOutside && function (theme) {
        return "switchTheme" in theme;
      }(theme) && theme.switchTheme(newTheme));
    }, [onThemeChange, availableThemes, theme]),
    dropdownSelectProps = {
      id: id,
      className: className,
      label: label,
      hideLabel: hideLabel,
      readOnly: readOnly,
      internalClassNames: internalClassNames,
      alwaysShowPlaceholder: alwaysShowPlaceholder,
      searchable: searchable,
      isInitiallyOpen: isInitiallyOpen,
      controlClassName: controlClassName,
      secondaryLabel: secondaryLabel,
      tooltip: tooltip,
      placeholder: placeholder,
      required: required,
      schemaRequired: schemaRequired,
      disabled: disabled,
      nullable: nullable,
      visible: visible,
      onValidationChange: onValidationChange,
      onBlur: onBlur,
      onFocus: onFocus,
      showErrors: showErrors,
      showRequired: showRequired,
      showOptional: showOptional,
      layout: layout,
      labelClassName: labelClassName,
      secondaryLabelClassName: secondaryLabelClassName,
      showValidationIcon: showValidationIcon,
      requiredFieldValidationMessage: requiredFieldValidationMessage,
      successMessage: successMessage,
      messageProps: messageProps,
      labelPosition: labelPosition,
      phone: phone,
      phoneWide: phoneWide,
      tablet: tablet,
      defaultValue: defaultTheme ? {
        id: defaultTheme.name,
        displayName: defaultTheme.name
      } : void 0
    };
  return React.createElement(DropdownSelectField, _extends({
    availableValues: availableThemesOptions,
    value: theme.name,
    onValueChange: handleOnChange
  }, dropdownSelectProps));
};
ThemeChooser.propTypes = themeChooserPropTypes, ThemeChooser.defaultProps = themeChooserDefaultProps, ThemeChooser.displayName = "ThemeChooser", ThemeChooser.__docgenInfo = {
  description: "Component that allows the user to select the theme configuration\n\n@metadataType element",
  methods: [],
  displayName: "ThemeChooser",
  composes: ["./propTypes"]
}, ThemeChooser.__docgenInfo = {
  componentName: "ThemeChooser",
  packageName: "@jutro/components",
  description: "Component that allows the user to select the theme configuration",
  displayName: "ThemeChooser",
  methods: [],
  actualName: "ThemeChooser",
  metadataType: "element",
  composes: ["./propTypes"]
};