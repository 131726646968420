import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["className", "id"],
  _excluded2 = ["heading"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import each from "lodash/each";
import isString from "lodash/isString";
import reduce from "lodash/reduce";
import forEachRight from "lodash/forEachRight";
import delay from "lodash/delay";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { uniqueInnerId } from "@jutro/platform";
import { IconButton } from "../IconButton/IconButton";
import styles from "./TabBar.module.css";
export var getTabHeadingId = function (id) {
  return uniqueInnerId(id, "headingElemId");
};
var tabBarPropTypes = {
  onHeadingClick: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    heading: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    active: PropTypes.bool
  })).isRequired,
  className: PropTypes.string,
  ariaControls: PropTypes.string,
  label: PropTypes.string
};
export var TabBar = function (_ref) {
  var id = _ref.id,
    tabs = _ref.tabs,
    onHeadingClick = _ref.onHeadingClick,
    className = _ref.className,
    label = _ref.label,
    visibleTabs = _filterInstanceProperty(tabs).call(tabs, function (_ref2) {
      return !1 !== _ref2.visible;
    }),
    headingButtonRefs = useRef({}),
    nextIconRef = useRef(),
    prevIconRef = useRef(),
    tabBarRef = useRef(),
    tabBarContainerRef = useRef(),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isPrevArrowVisible = _useState2[0],
    setPrevArrowVisibility = _useState2[1],
    _useState3 = useState(!1),
    _useState4 = _slicedToArray(_useState3, 2),
    isNextArrowVisible = _useState4[0],
    setNextArrowVisibility = _useState4[1],
    translator = useTranslator(),
    areTabsInMotion = !1,
    selectedTabId = -1,
    updateNavIconsVisibilityOnResize = function () {
      if (null != tabBarRef && tabBarRef.current) {
        setPrevArrowVisibility(!1), setNextArrowVisibility(!1);
        var width = 0,
          isSelectedTabFound = !1;
        each(tabBarRef.current.children, function (button) {
          button.id === selectedTabId && (tabBarRef.current.scrollTo({
            left: width,
            behavior: "smooth"
          }), isSelectedTabFound = !0, delay(updateNavIconsVisibility, 500)), width += button.offsetWidth;
        }), isSelectedTabFound || (tabBarRef.current.scrollTo({
          left: 0,
          behavior: "smooth"
        }), delay(updateNavIconsVisibility, 500));
      }
    },
    updateNavIconsVisibility = function () {
      null != tabBarRef && tabBarRef.current && (setPrevArrowVisibility(0 !== tabBarRef.current.scrollLeft), tabBarContainerRef.current.offsetWidth + tabBarRef.current.scrollLeft >= getTabsWidth() ? setNextArrowVisibility(!1) : setNextArrowVisibility(!0));
    },
    handleKeyDown = function (event, prevTab, nextTab) {
      var newTab = null;
      switch (event.key) {
        case "ArrowLeft":
          newTab = headingButtonRefs.current[prevTab];
          break;
        case "ArrowRight":
          newTab = headingButtonRefs.current[nextTab];
          break;
        default:
          return;
      }
      event.preventDefault(), newTab.focus(), newTab.click(), updateNavIconsVisibility();
    };
  useLayoutEffect(function () {
    updateNavIconsVisibility();
  }, [tabBarRef.current]), useEffect(function () {
    var handleWindowResize = function () {
      delay(updateNavIconsVisibilityOnResize, 300);
    };
    return window.addEventListener("resize", handleWindowResize), function () {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [updateNavIconsVisibilityOnResize]);
  var moveTabs = function (action) {
      areTabsInMotion || (areTabsInMotion = !0, moveTabsContinue(action));
    },
    showPrevTabs = function () {
      moveTabs("prev");
    },
    showNextTabs = function () {
      moveTabs("next");
    },
    renderHeading = function (props) {
      var headingClassName = props.className,
        heading = props.heading,
        headingId = props.id,
        disabled = props.disabled,
        onClick = props.onClick,
        onKeyDown = props.onKeyDown,
        active = props.active,
        collapsible = props.collapsible,
        onFocus = props.onFocus,
        onWheel = props.onWheel,
        ref = props.ref,
        headingClasses = cx(headingClassName, {
          active: active
        }),
        headingElemId = getTabHeadingId(headingId).headingElemId;
      return active && (selectedTabId = headingElemId), React.createElement("button", {
        type: "button",
        className: headingClasses,
        onClick: onClick,
        onKeyDown: onKeyDown,
        onWheel: onWheel,
        onFocus: onFocus,
        id: headingElemId,
        "data-tab": headingId,
        key: headingId,
        disabled: disabled,
        role: "tab",
        "aria-controls": headingId,
        "aria-selected": active,
        "data-collapsible": collapsible,
        tabIndex: active ? 0 : -1,
        ref: ref
      }, heading);
    };
  useEffect(function () {
    null != tabBarContainerRef && tabBarContainerRef.current && updateNavIconsVisibility();
  }, [visibleTabs.length]);
  var moveTabsContinue = function (action) {
      var leftWidthOfNextTab = function (action) {
        if (null != tabBarRef && tabBarRef.current) {
          var tabWidthCounter = 0,
            leftWidthOfNextTab = 0;
          return "next" === action && each(tabBarRef.current.children, function (button) {
            (tabWidthCounter += button.offsetWidth) >= tabBarRef.current.scrollLeft && tabWidthCounter <= tabBarRef.current.scrollLeft + tabBarContainerRef.current.offsetWidth && (leftWidthOfNextTab = tabWidthCounter - button.offsetWidth);
          }), "prev" === action && forEachRight(tabBarRef.current.children, function (button) {
            tabWidthCounter += button.offsetWidth, tabBarContainerRef.current.offsetWidth > tabBarRef.current.scrollLeft && (leftWidthOfNextTab = 0), tabWidthCounter < tabBarContainerRef.current.offsetWidth && (leftWidthOfNextTab = tabBarRef.current.scrollLeft - (tabWidthCounter - button.offsetWidth));
          }), leftWidthOfNextTab;
        }
      }(action);
      setPrevArrowVisibility(0 !== leftWidthOfNextTab), tabBarRef.current.scrollTo({
        left: leftWidthOfNextTab,
        behavior: "smooth"
      }), tabBarContainerRef.current.offsetWidth + leftWidthOfNextTab > getTabsWidth() ? setNextArrowVisibility(!1) : setNextArrowVisibility(!0), areTabsInMotion = !1;
    },
    getTabsWidth = function () {
      return reduce(tabBarRef.current.children, function (width, button) {
        return width + button.offsetWidth;
      }, 0);
    },
    translateHeader = function (heading) {
      return isString(heading) || heading instanceof Object ? translator(heading) : heading;
    },
    headings = _mapInstanceProperty(visibleTabs).call(visibleTabs, function (_ref5) {
      var heading = _ref5.heading,
        otherProps = _objectWithoutProperties(_ref5, _excluded2);
      return _objectSpread({
        heading: translateHeader(heading),
        onClick: onHeadingClick
      }, otherProps);
    }),
    tabBarClasses = cx(styles.tabBarContainer, className);
  return React.createElement("div", {
    ref: tabBarContainerRef,
    id: "".concat(id, "-container"),
    className: tabBarClasses
  }, isPrevArrowVisible && React.createElement("div", {
    ref: prevIconRef,
    id: "prevTab",
    className: cx(styles.prevIcon, className)
  }, React.createElement(IconButton, {
    disabled: !1,
    icon: "gw-chevron-left",
    path: "iconbutton",
    className: cx(styles.prevIconButton, className),
    value: null,
    onClick: showPrevTabs,
    tabIndex: -1
  })), React.createElement("div", {
    id: id,
    ref: tabBarRef,
    className: cx(styles.tabBar, className),
    role: "tablist",
    "aria-label": label
  }, function () {
    var headings = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
      collapsible = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return _mapInstanceProperty(headings).call(headings, function (_ref3, i) {
      var headingClassName = _ref3.className,
        headingId = _ref3.id,
        otherProps = _objectWithoutProperties(_ref3, _excluded);
      return renderHeading(_objectSpread(_objectSpread({}, otherProps), {}, {
        id: headingId,
        className: [styles.heading, headingClassName],
        onKeyDown: function (e) {
          var enabledHeadings = _filterInstanceProperty(headings).call(headings, function (el) {
              return !el.disabled;
            }),
            enabledHeadingIndex = _indexOfInstanceProperty(enabledHeadings).call(enabledHeadings, headings[i]),
            prevHeadingIdx = 0 === enabledHeadingIndex ? enabledHeadings.length - 1 : enabledHeadingIndex - 1,
            nextHeadingIdx = enabledHeadingIndex === enabledHeadings.length - 1 ? 0 : enabledHeadingIndex + 1;
          return handleKeyDown(e, enabledHeadings[prevHeadingIdx].id, enabledHeadings[nextHeadingIdx].id);
        },
        onWheel: function () {
          return updateNavIconsVisibility();
        },
        collapsible: collapsible,
        ref: function (_ref4) {
          headingButtonRefs.current[headingId] = _ref4;
        }
      }));
    });
  }(headings)), isNextArrowVisible && React.createElement("div", {
    ref: nextIconRef,
    id: "nextTab",
    className: cx(styles.nextIcon, className)
  }, React.createElement(IconButton, {
    disabled: !1,
    icon: "gw-chevron-right",
    path: "iconbutton",
    className: cx(styles.nextIconButton, className),
    value: null,
    onClick: showNextTabs,
    tabIndex: -1
  })));
};
TabBar.propTypes = tabBarPropTypes, TabBar.__docgenInfo = {
  description: "TabBar\n@type {React.FC<PropTypes.InferProps<typeof tabBarPropTypes>>}",
  methods: [],
  displayName: "TabBar",
  props: {
    onHeadingClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for clicking on bar items"
    },
    tabs: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            heading: {
              name: "union",
              value: [{
                name: "node"
              }, {
                name: "custom",
                raw: "intlMessageShape"
              }],
              required: !1
            },
            disabled: {
              name: "bool",
              required: !1
            },
            visible: {
              name: "bool",
              required: !1
            },
            active: {
              name: "bool",
              required: !1
            }
          }
        }
      },
      required: !0,
      description: "A set of tabs rendering metadata"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional class name to apply to this component"
    },
    ariaControls: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Identifier of the region displaying the content of the selected tab"
    },
    label: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional label describing the purpose of the tablist"
    }
  }
}, TabBar.__docgenInfo = {
  componentName: "TabBar",
  packageName: "@jutro/components",
  description: "TabBar",
  displayName: "TabBar",
  methods: [],
  actualName: "TabBar",
  props: {
    onHeadingClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for clicking on bar items"
    },
    tabs: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            heading: {
              name: "union",
              value: [{
                name: "node"
              }, {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }],
              required: !1
            },
            disabled: {
              name: "bool",
              required: !1
            },
            visible: {
              name: "bool",
              required: !1
            },
            active: {
              name: "bool",
              required: !1
            }
          }
        }
      },
      required: !0,
      description: "A set of tabs rendering metadata"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional class name to apply to this component"
    },
    ariaControls: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Identifier of the region displaying the content of the selected tab"
    },
    label: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional label describing the purpose of the tablist"
    }
  }
};