import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { intlMessageShape } from "@jutro/prop-types";
import { useEvent } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { error } from "@jutro/logger";
import { Card } from "../card/Card";
import { EditCancelSaveTitleBar } from "./titleBars/EditCancelSaveTitleBar";
import { messages } from "./SettingsCard.messages";
import styles from "./SettingsCard.module.css";
export var SettingsCard = function (_ref) {
  var id = _ref.id,
    className = _ref.className,
    title = _ref.title,
    renderContent = _ref.renderContent,
    onSaveClick = _ref.onSaveClick,
    onCancelClick = _ref.onCancelClick,
    readOnly = _ref.readOnly,
    _ref$isValid = _ref.isValid,
    isValid = void 0 === _ref$isValid || _ref$isValid,
    translator = useTranslator(),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isEditMode = _useState2[0],
    setEditMode = _useState2[1];
  useEffect(function () {
    renderContent || error("Function renderContent is required. MetadataForm has been removed from SettingCard. Please use renderContent function.");
  }, [renderContent]);
  var labels = {
      edit: translator(messages.editButtonLabel),
      save: translator(messages.saveButtonLabel),
      cancel: translator(messages.cancelButtonLabel)
    },
    onEditClickCallback = useEvent(function () {
      return setEditMode(!0);
    }),
    onSaveClickCallback = useEvent(function () {
      setEditMode(!1), null == onSaveClick || onSaveClick();
    }),
    onCancelClickCallback = useEvent(function () {
      setEditMode(!1), null == onCancelClick || onCancelClick();
    });
  return React.createElement(Card, {
    id: id,
    className: cx(styles.settingsCard, styles.settingsCardContainer, className)
  }, React.createElement(EditCancelSaveTitleBar, {
    title: translator(title),
    labels: labels,
    readOnly: readOnly,
    isEditMode: isEditMode,
    isSaveEnabled: isValid,
    onEditClick: onEditClickCallback,
    onCancelClick: onCancelClickCallback,
    onSaveClick: onSaveClickCallback
  }), React.createElement("div", {
    className: styles.settingsCardContent
  }, renderContent && renderContent(isEditMode)));
};
export var settingsCardPropTypes = {
  renderContent: PropTypes.func.isRequired,
  title: intlMessageShape.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  onSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  readOnly: PropTypes.bool,
  isValid: PropTypes.bool
};
SettingsCard.propTypes = settingsCardPropTypes, SettingsCard.displayName = "SettingsCard", SettingsCard.__docgenInfo = {
  description: "@metadataType container",
  methods: [],
  displayName: "SettingsCard",
  props: {
    isValid: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    },
    renderContent: {
      type: {
        name: "func"
      },
      required: !0,
      description: ""
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: ""
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    onSaveClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    onCancelClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    }
  }
}, SettingsCard.__docgenInfo = {
  componentName: "SettingsCard",
  packageName: "@jutro/components",
  description: "",
  displayName: "SettingsCard",
  methods: [],
  actualName: "SettingsCard",
  metadataType: "container",
  props: {
    renderContent: {
      type: {
        name: "func"
      },
      required: !0,
      description: ""
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: ""
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    onSaveClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    onCancelClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    },
    isValid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "true",
        computed: !1
      }
    }
  }
};