import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children", "className", "containerClassName", "sticky", "fluid", "dangerouslySetInnerHTML"];
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Header.module.css";
export var Header = function (props) {
  var children = props.children,
    className = props.className,
    containerClassName = props.containerClassName,
    sticky = props.sticky,
    fluid = props.fluid,
    rest = (props.dangerouslySetInnerHTML, _objectWithoutProperties(props, _excluded)),
    classes = cx(styles.header, _defineProperty({}, styles.positionSticky, sticky), className),
    containerClasses = cx(styles.headerContainer, styles.headerContent, containerClassName, _defineProperty({}, styles.fluid, fluid));
  return React.createElement("header", _extends({
    className: classes
  }, rest), React.createElement("div", {
    "data-testid": "header-container",
    className: containerClasses
  }, children));
};
var headerPropTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  sticky: PropTypes.bool,
  fluid: PropTypes.bool
};
Header.propTypes = headerPropTypes, Header.__docgenInfo = {
  description: "Children of header, such as buttons, icons, and search boxes. The immediate children\nare laid out in a flexbox with space-between and it is up to the individual children\nto grow/shrink as appropriate.\n\n@metadataType container",
  methods: [],
  displayName: "Header",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for children container"
    },
    sticky: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sets the position to sticky"
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true content will span the whole width of the header"
    }
  }
}, Header.__docgenInfo = {
  componentName: "Header",
  packageName: "@jutro/components",
  description: "Children of header, such as buttons, icons, and search boxes. The immediate children\nare laid out in a flexbox with space-between and it is up to the individual children\nto grow/shrink as appropriate.",
  displayName: "Header",
  methods: [],
  actualName: "Header",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for children container"
    },
    sticky: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sets the position to sticky"
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true content will span the whole width of the header"
    }
  }
};