import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["id", "isOpen", "menuId"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { Icon, InlineLabel, DropdownMenu, DropdownMenuLink } from "@jutro/components";
import { usePathname } from "@jutro/platform";
import some from "lodash/some";
import styles from "../../NavBarItem.module.css";
export var NavBarDropdownInternal = function (_ref) {
  var _cx,
    header = _ref.header,
    icon = _ref.icon,
    disabled = _ref.disabled,
    className = _ref.className,
    activeClassName = _ref.activeClassName,
    alignment = _ref.alignment,
    routes = _ref.routes,
    id = _ref.id,
    translator = useTranslator(),
    pathname = usePathname(),
    isTopNavBar = "top" === alignment,
    hasActiveRoute = some(routes, function (route) {
      return route.path === pathname;
    }),
    classes = cx(styles.navBarItem, className, (_defineProperty(_cx = {}, styles.topNavBarItem, isTopNavBar), _defineProperty(_cx, styles.activeLeftNavBarItem, hasActiveRoute && !isTopNavBar), _defineProperty(_cx, styles.activeTopNavBarItem, hasActiveRoute), _defineProperty(_cx, activeClassName, hasActiveRoute), _cx)),
    handleRenderTrigger = useCallback(function (_ref2, toggleMenu) {
      var triggerId = _ref2.id,
        isOpen = _ref2.isOpen,
        others = (_ref2.menuId, _objectWithoutProperties(_ref2, _excluded)),
        iconComponent = icon && React.createElement(Icon, {
          icon: icon,
          className: styles.navBarItemIcon
        });
      return React.createElement("button", _extends({
        id: triggerId,
        tabIndex: disabled ? -1 : 0,
        disabled: disabled,
        className: classes,
        onClick: function () {
          toggleMenu(!isOpen);
        },
        "aria-expanded": isOpen
      }, others), React.createElement(InlineLabel, {
        icon: iconComponent
      }, translator(header)), React.createElement(Icon, {
        icon: "gw-expand-more",
        className: styles.navBarItemChevron
      }));
    }, [classes, disabled, header, icon, translator]),
    links = useMemo(function () {
      return routes && _mapInstanceProperty(routes).call(routes, function (_ref3) {
        var title = _ref3.title,
          path = _ref3.path;
        return React.createElement(DropdownMenuLink, {
          key: path,
          activeClassName: styles.active,
          to: path
        }, title);
      });
    }, [routes]);
  return React.createElement(DropdownMenu, {
    className: styles.navBarDropdown,
    id: id,
    renderTrigger: handleRenderTrigger,
    portalTargetElement: document.querySelector("#root")
  }, links);
};
NavBarDropdownInternal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  header: intlMessageShape.isRequired,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  alignment: PropTypes.oneOf(["top", "left"])
}, NavBarDropdownInternal.displayName = "NavBarDropdown";
export var NavBarDropdown = NavBarDropdownInternal;
NavBarDropdownInternal.__docgenInfo = {
  description: "The NavBarLink component is designed as a simple component for presenting a dropdown inside the NavBar container.\n\n@param {Array<any>} props properties for NavBarLink component\n\n@returns {React.ReactElement} JSX for the NavBarLink component",
  methods: [],
  displayName: "NavBarDropdown",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    header: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Link header"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag to make the dropdown disabled"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Navigation alignment"
    }
  }
}, NavBarDropdownInternal.__docgenInfo = {
  componentName: "NavBarDropdown",
  packageName: "@jutro/router",
  description: "The NavBarLink component is designed as a simple component for presenting a dropdown inside the NavBar container.",
  displayName: "NavBarDropdown",
  methods: [],
  actualName: "NavBarDropdownInternal",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Link header"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag to make the dropdown disabled"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Navigation alignment"
    }
  }
};