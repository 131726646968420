import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useValidation, ValidationContext } from "@jutro/validation/internal";
import { intlMessageShape, deprecated } from "@jutro/prop-types";
import { MetadataContent } from "../metadataContent/MetadataContent";
import useOldValidation from "./hooks/useOldValidation";
function MetadataForm(props) {
  var isValidationIgnored,
    callbackMap = props.callbackMap,
    classNameMap = props.classNameMap,
    componentMap = props.componentMap,
    data = props.data,
    onDataChange = props.onDataChange,
    onValidationChange = props.onValidationChange,
    overrideProps = props.overrideProps,
    resolveDataProps = props.resolveDataProps,
    resolveValidation = props.resolveValidation,
    resolveValueProp = props.resolveValue,
    showErrors = props.showErrors,
    showOptional = props.showOptional,
    showRequired = props.showRequired,
    uiProps = props.uiProps,
    parentPath = props.parentPath,
    formRules = props.formRules,
    formPath = props.formPath,
    formInvalidMessage = props.formInvalidMessage,
    formWarningMessage = props.formWarningMessage,
    isUsingNewValidation = props.isUsingNewValidation,
    onFieldValidationChanged = useOldValidation(isUsingNewValidation)({
      data: data,
      parentPath: parentPath,
      onValidationChange: onValidationChange
    }),
    _memoUseValidation = useMemo(function () {
      return function (arg0) {
        for (var _context, _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) params[_key - 1] = arguments[_key];
        return useValidation.apply(void 0, _concatInstanceProperty(_context = [_objectSpread(_objectSpread({}, arg0), {}, {
          isValidationIgnored: isValidationIgnored
        })]).call(_context, params));
      };
    }, [isValidationIgnored = !isUsingNewValidation])({
      formData: data,
      formPath: formPath,
      formRules: formRules,
      onValidationChange: onValidationChange,
      formInvalidMessage: formInvalidMessage,
      formWarningMessage: formWarningMessage
    }, ValidationContext),
    notifyParentForm = _memoUseValidation.notifyParentForm,
    registerValidation = _memoUseValidation.registerValidation,
    resolveFormValidation = _memoUseValidation.resolveFormValidation,
    resolveValue = null != resolveValueProp ? resolveValueProp : function (_id, path) {
      return get(data, path);
    },
    resolveCommonOverrides = function (type) {
      return _objectSpread(_objectSpread({}, overrideProps ? overrideProps["@".concat(type)] : {}), {}, {
        resolvers: {
          resolveComponentMap: componentMap,
          resolveClassNameMap: classNameMap
        }
      });
    },
    extendedProps = _objectSpread(_objectSpread({}, overrideProps), {}, {
      "@field": _objectSpread({
        onValueChange: onDataChange,
        onValidationChange: onFieldValidationChanged,
        registerValidation: registerValidation,
        showErrors: showErrors,
        showOptional: showOptional,
        showRequired: showRequired
      }, resolveCommonOverrides("field")),
      "@container": function (id, path) {
        var containerOverrides = resolveCommonOverrides("container");
        return path ? _objectSpread({
          onDataChange: onDataChange
        }, containerOverrides) : containerOverrides;
      }
    }),
    contextValue = useMemo(function () {
      return {
        registerValidation: registerValidation,
        resolveFormValidation: resolveFormValidation,
        notifyParentForm: notifyParentForm
      };
    }, [registerValidation, resolveFormValidation, notifyParentForm]);
  return React.createElement(ValidationContext.Provider, {
    value: contextValue
  }, React.createElement(MetadataContent, {
    uiProps: uiProps,
    overrideProps: extendedProps,
    resolveCallbackMap: callbackMap,
    resolveComponentMap: componentMap,
    resolveFormValidation: resolveFormValidation,
    resolveValue: resolveValue,
    resolveClassNameMap: classNameMap,
    resolveDataProps: resolveDataProps,
    resolveValidation: resolveValidation
  }));
}
MetadataForm.displayName = "MetadataForm", MetadataForm.defaultProps = {
  isUsingNewValidation: !1
}, MetadataForm.propTypes = {
  callbackMap: PropTypes.objectOf(PropTypes.func.isRequired),
  classNameMap: PropTypes.objectOf(PropTypes.string.isRequired),
  componentMap: PropTypes.objectOf(PropTypes.elementType.isRequired),
  data: PropTypes.object,
  onDataChange: PropTypes.func,
  onValidationChange: PropTypes.func,
  formRules: PropTypes.object,
  overrideProps: PropTypes.object,
  resolveValidation: PropTypes.func,
  resolveDataProps: PropTypes.func,
  parentPath: PropTypes.string,
  resolveValue: PropTypes.func,
  showErrors: PropTypes.bool,
  showOptional: PropTypes.bool,
  showRequired: PropTypes.bool,
  formPath: PropTypes.string,
  uiProps: PropTypes.object.isRequired,
  formInvalidMessage: intlMessageShape,
  formWarningMessage: intlMessageShape,
  isUsingNewValidation: deprecated(PropTypes.bool, void 0, "@jutro/validation package has been deprecated. Please use FieldComponent's validation mechanism.")
}, MetadataForm.__docgenInfo = {
  description: "MetadataForm\n\n@param {PropTypes.InferProps<typeof MetadataForm.propTypes>} props",
  methods: [],
  displayName: "MetadataForm",
  props: {
    isUsingNewValidation: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "custom",
        raw: 'deprecated(\n    PropTypes.bool,\n    undefined,\n    "@jutro/validation package has been deprecated. Please use FieldComponent\'s validation mechanism."\n)'
      },
      required: !1,
      description: "Flag to indicate whether the MetadataForm is going to use the old validation or the new (deprecated) one\n@deprecated"
    },
    callbackMap: {
      type: {
        name: "objectOf",
        value: {
          name: "func"
        }
      },
      required: !1,
      description: "Resolve callback string to callback function"
    },
    classNameMap: {
      type: {
        name: "objectOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Resolve class names to css module names"
    },
    componentMap: {
      type: {
        name: "objectOf",
        value: {
          name: "elementType"
        }
      },
      required: !1,
      description: "Resolve component string to component"
    },
    data: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Data for this form"
    },
    onDataChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when value is changed; receives new value and path for this component"
    },
    onValidationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when validation is changed; receives isValid and validation props for this component"
    },
    formRules: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Additional validation rules to evaluate when data changes"
    },
    overrideProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Override default data props for rendered content"
    },
    resolveValidation: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Resolve validation from json schema"
    },
    resolveDataProps: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Resolve data props from json schema"
    },
    parentPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Parent path to be concatenated to the component path when using validation"
    },
    resolveValue: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Resolve value from data using path"
    },
    showErrors: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Override to force showing input errors"
    },
    showOptional: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the `Optional` span"
    },
    showRequired: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the require symbol on required fields"
    },
    formPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path for the form to use in validation, essentially the same as a path defined at the component level.\nWhen using nested form validations, this should be set on the nested child form so that the parent form can\nbuild relative paths to the child form for validation"
    },
    uiProps: {
      type: {
        name: "object"
      },
      required: !0,
      description: "Content metadata or an array of metadata"
    },
    formInvalidMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Validation message that will be communicated to a parent MetadataForm if this MetadataForm has errors"
    },
    formWarningMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Validation message that will be communicated to a parent MetadataForm if this MetadataForm has no errors and has some warnings"
    }
  }
};
export default MetadataForm;
MetadataForm.__docgenInfo = {
  componentName: "MetadataForm",
  packageName: "@jutro/uiconfig",
  description: "MetadataForm",
  displayName: "MetadataForm",
  methods: [],
  actualName: "MetadataForm",
  props: {
    callbackMap: {
      type: {
        name: "objectOf",
        value: {
          name: "func"
        }
      },
      required: !1,
      description: "Resolve callback string to callback function"
    },
    classNameMap: {
      type: {
        name: "objectOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Resolve class names to css module names"
    },
    componentMap: {
      type: {
        name: "objectOf",
        value: {
          name: "elementType"
        }
      },
      required: !1,
      description: "Resolve component string to component"
    },
    data: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Data for this form"
    },
    onDataChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when value is changed; receives new value and path for this component"
    },
    onValidationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when validation is changed; receives isValid and validation props for this component"
    },
    formRules: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Additional validation rules to evaluate when data changes"
    },
    overrideProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Override default data props for rendered content"
    },
    resolveValidation: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Resolve validation from json schema"
    },
    resolveDataProps: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Resolve data props from json schema"
    },
    parentPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Parent path to be concatenated to the component path when using validation"
    },
    resolveValue: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Resolve value from data using path"
    },
    showErrors: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Override to force showing input errors"
    },
    showOptional: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the `Optional` span"
    },
    showRequired: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the require symbol on required fields"
    },
    formPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path for the form to use in validation, essentially the same as a path defined at the component level.\nWhen using nested form validations, this should be set on the nested child form so that the parent form can\nbuild relative paths to the child form for validation"
    },
    uiProps: {
      type: {
        name: "object"
      },
      required: !0,
      description: "Content metadata or an array of metadata"
    },
    formInvalidMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Validation message that will be communicated to a parent MetadataForm if this MetadataForm has errors"
    },
    formWarningMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Validation message that will be communicated to a parent MetadataForm if this MetadataForm has no errors and has some warnings"
    },
    isUsingNewValidation: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag to indicate whether the MetadataForm is going to use the old validation or the new (deprecated) one\n@deprecated",
      defaultValue: {
        value: "false",
        computed: !1
      },
      deprecationInfo: {
        version: "@next",
        mapTo: "@jutro/validation package has been deprecated. Please use FieldComponent's validation mechanism."
      }
    }
  }
};