import React from "react";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { HelpElement } from "./HelpElement";
var helpHeadingPropTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, intlMessageShape])
};
export var HelpHeading = function (_ref) {
  var className = _ref.className,
    content = _ref.content;
  return React.createElement(HelpElement, {
    className: className,
    tag: "h5",
    content: content
  });
};
HelpHeading.propTypes = helpHeadingPropTypes, HelpHeading.__docgenInfo = {
  description: "The `HelpHeading` component is designed as a simple component which displays\na heading inside the `HelpPopover` container.\n\n@metadataType element",
  methods: [],
  displayName: "HelpHeading",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "Content to render"
    }
  }
}, HelpHeading.__docgenInfo = {
  componentName: "HelpHeading",
  packageName: "@jutro/components",
  description: "The `HelpHeading` component is designed as a simple component which displays\na heading inside the `HelpPopover` container.",
  displayName: "HelpHeading",
  methods: [],
  actualName: "HelpHeading",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "Content to render"
    }
  }
};