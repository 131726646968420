import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["value", "name", "checked", "disabled", "iconClassName", "onFocus", "onBlur", "onChange", "children", "dangerouslySetInnerHTML"];
import React, { useContext, useState, forwardRef, Fragment, useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { RadioContext, RadioGroupContext, useRadio } from "./RadioContext";
import styles from "./Radio.module.css";
var RadioIcon = function (_ref) {
  var className = _ref.className,
    _useRadio = useRadio(),
    checked = _useRadio.checked,
    focused = _useRadio.focused,
    disabled = _useRadio.disabled,
    classes = cx(styles.icon, checked ? styles.iconChecked : styles.iconUnchecked, focused && styles.iconFocus, !disabled && styles.iconEnabled, className);
  return React.createElement("div", {
    className: classes
  });
};
RadioIcon.displayName = "RadioIcon";
export var Radio = forwardRef(function (_ref2, ref) {
  var _ref3,
    value = _ref2.value,
    nameProp = _ref2.name,
    checkedProp = _ref2.checked,
    disabledProp = _ref2.disabled,
    iconClassName = _ref2.iconClassName,
    onFocus = _ref2.onFocus,
    onBlur = _ref2.onBlur,
    onChange = _ref2.onChange,
    _ref2$children = _ref2.children,
    children = void 0 === _ref2$children ? React.createElement(RadioIcon, {
      className: iconClassName
    }) : _ref2$children,
    inputDOMProps = (_ref2.dangerouslySetInnerHTML, _objectWithoutProperties(_ref2, _excluded)),
    radioGroup = useContext(RadioGroupContext),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    focused = _useState2[0],
    setFocused = _useState2[1],
    checked = radioGroup ? radioGroup.value === value : Boolean(checkedProp),
    disabled = null !== (_ref3 = disabledProp || (null == radioGroup ? void 0 : radioGroup.disabled)) && void 0 !== _ref3 && _ref3,
    name = null != nameProp ? nameProp : null == radioGroup ? void 0 : radioGroup.name,
    radioContext = useMemo(function () {
      return {
        checked: checked,
        focused: focused,
        disabled: disabled
      };
    }, [checked, focused, disabled]);
  return React.createElement(Fragment, null, React.createElement("input", _extends({
    className: styles.input,
    checked: checked,
    type: "radio",
    value: value,
    name: name,
    disabled: disabled,
    onChange: function (event) {
      null == radioGroup || radioGroup.onChange(event), null == onChange || onChange(event);
    },
    onFocus: function (event) {
      null == radioGroup || radioGroup.onFocus(event), setFocused(!0), null == onFocus || onFocus(event);
    },
    onBlur: function (event) {
      null == radioGroup || radioGroup.onBlur(event), setFocused(!1), null == onBlur || onBlur(event);
    },
    ref: ref
  }, inputDOMProps)), React.createElement(RadioContext.Provider, {
    value: radioContext
  }, children));
});
Radio.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  iconClassName: PropTypes.string,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(HTMLLabelElement)
  })]),
  onValueChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}, Radio.displayName = "Radio", Radio.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Radio",
  props: {
    children: {
      defaultValue: {
        value: "<RadioIcon className={iconClassName} />",
        computed: !1
      },
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to be rendered as an icon of the radio, instead of a default one"
    },
    checked: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "marks a radio as checked, use only for standalone radios (not wrapped in RadioGroup) in custom components"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "marks a radio as disabled, use only for standalone radios (not wrapped in RadioGroup) in custom components"
    },
    name: {
      type: {
        name: "string"
      },
      required: !1,
      description: "name of the radio group, must be the same for all radios in the group https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-name"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !0,
      description: "value of the radio"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "additional className applied to default icon"
    },
    ref: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "instanceOf",
              value: "HTMLLabelElement",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "React Ref to underlying `<input>` element"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when radio is selected; receives DOM ChangeEvent"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback on input focus event"
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback on input blur event"
    }
  }
}, RadioIcon.__docgenInfo = {
  componentName: "RadioIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "RadioIcon",
  methods: [],
  actualName: "RadioIcon"
}, Radio.__docgenInfo = {
  componentName: "Radio",
  packageName: "@jutro/components",
  description: "",
  displayName: "Radio",
  methods: [],
  actualName: "Radio",
  props: {
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to be rendered as an icon of the radio, instead of a default one",
      defaultValue: {
        value: "React.createElement(RadioIcon, { className: iconClassName })",
        computed: !0
      }
    },
    checked: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "marks a radio as checked, use only for standalone radios (not wrapped in RadioGroup) in custom components"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "marks a radio as disabled, use only for standalone radios (not wrapped in RadioGroup) in custom components"
    },
    name: {
      type: {
        name: "string"
      },
      required: !1,
      description: "name of the radio group, must be the same for all radios in the group https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-name"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !0,
      description: "value of the radio"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "additional className applied to default icon"
    },
    ref: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "instanceOf",
              value: "HTMLLabelElement",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "React Ref to underlying `<input>` element"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when radio is selected; receives DOM ChangeEvent"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback on input focus event"
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback on input blur event"
    }
  }
};