import React from "react";
import { useTranslator } from "@jutro/locale";
import { Chevron } from "../chevron/Chevron";
import { Icon } from "../Icon/Icon";
import styles from "./Accordion.module.css";
export var AccordionCardHeader = function (_ref) {
  var isOpen = _ref.isOpen,
    chevron = _ref.chevron,
    chevronAlignment = _ref.chevronAlignment,
    errorState = _ref.errorState,
    title = _ref.title,
    isCollapsible = _ref.isCollapsible,
    className = styles.header,
    translator = useTranslator(),
    chevronNode = chevron && isCollapsible && React.createElement(Chevron, {
      className: styles.chevron,
      isOpen: isOpen,
      "aria-label": null
    });
  return React.createElement(React.Fragment, null, "left" === chevronAlignment && chevronNode, React.createElement("span", {
    className: className
  }, translator(title)), errorState && React.createElement(Icon, {
    className: styles.errorIcon,
    icon: "gw-error-outline",
    size: "large"
  }), "right" === chevronAlignment && chevronNode);
};
AccordionCardHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AccordionCardHeader",
  props: {
    isOpen: {
      required: !1,
      tsType: {
        name: "boolean"
      },
      description: ""
    },
    chevron: {
      required: !1,
      tsType: {
        name: "boolean"
      },
      description: ""
    },
    chevronAlignment: {
      required: !1,
      tsType: {
        name: "union",
        raw: "'left' | 'right'",
        elements: [{
          name: "literal",
          value: "'left'"
        }, {
          name: "literal",
          value: "'right'"
        }]
      },
      description: ""
    },
    errorState: {
      required: !1,
      tsType: {
        name: "boolean"
      },
      description: ""
    },
    title: {
      required: !1,
      tsType: {
        name: "IntlMessageShape"
      },
      description: ""
    },
    isCollapsible: {
      required: !1,
      tsType: {
        name: "boolean"
      },
      description: ""
    }
  }
}, AccordionCardHeader.__docgenInfo = {
  componentName: "AccordionCardHeader",
  packageName: "@jutro/components",
  description: "",
  displayName: "AccordionCardHeader",
  methods: [],
  actualName: "AccordionCardHeader"
};