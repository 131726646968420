import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import { useTranslator } from "@jutro/locale";
import cx from "classnames";
import styles from "./CheckboxField.module.css";
import { messages } from "./Checkbox.messages.js";
import { Icon } from "../../Icon/Icon";
export var Checkbox = function (_ref) {
  var id = _ref.id,
    checked = _ref.checked,
    label = _ref.label,
    required = _ref.required,
    icon = _ref.icon,
    controlClassName = _ref.controlClassName,
    hideLabel = _ref.hideLabel,
    showInlineLabel = _ref.showInlineLabel,
    onClick = _ref.onClick,
    onChange = _ref.onChange,
    onBlur = _ref.onBlur,
    onFocus = _ref.onFocus,
    onKeyDown = _ref.onKeyDown,
    disabled = _ref.disabled,
    accessibilityProps = _ref.accessibilityProps,
    dataPathProps = _ref.dataPathProps,
    renderInlineLabel = _ref.renderInlineLabel,
    children = _ref.children,
    detailElement = _ref.detailElement,
    value = _ref.value,
    ariaLabel = _ref["aria-label"],
    testId = _ref.testId,
    checkboxRef = _ref.checkboxRef,
    secondaryLabelId = _ref.secondaryLabelId,
    translator = useTranslator(),
    tabIndex = disabled ? void 0 : 0,
    checkboxElementClasses = cx(styles.checkboxElement, controlClassName),
    checkboxElement = React.createElement("span", {
      onClick: onClick,
      className: checkboxElementClasses,
      "aria-label": ariaLabel || translator(label),
      tabIndex: tabIndex,
      onBlur: onBlur,
      onFocus: onFocus,
      onKeyDown: onKeyDown,
      role: "checkbox",
      "aria-checked": checked,
      ref: checkboxRef,
      "aria-disabled": !!disabled || null,
      "aria-describedby": secondaryLabelId
    }, checked && React.createElement(Icon, {
      icon: icon,
      className: styles.checkboxIcon,
      size: "small"
    })),
    detailVisible = checked && (children || detailElement),
    detailAriaLabel = translator(messages.details, {
      id: id
    }),
    detail = React.createElement("div", {
      className: cx(_defineProperty({}, styles.detailVisible, detailVisible)),
      role: "region",
      "aria-live": "assertive",
      "aria-label": detailAriaLabel
    }, detailVisible && (children || detailElement)),
    classes = cx(styles.checkbox, {
      disabled: disabled
    });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: classes
  }, React.createElement("input", _extends({
    id: id,
    type: "checkbox",
    value: value,
    checked: checked,
    onChange: onChange,
    disabled: disabled,
    className: styles.input,
    required: required
  }, dataPathProps, accessibilityProps, {
    "data-testid": testId,
    "aria-hidden": "true",
    tabIndex: -1
  })), checkboxElement, !hideLabel && showInlineLabel && renderInlineLabel(styles)), detail);
};
Checkbox.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Checkbox"
}, Checkbox.__docgenInfo = {
  componentName: "Checkbox",
  packageName: "@jutro/components",
  description: "",
  displayName: "Checkbox",
  methods: [],
  actualName: "Checkbox"
};