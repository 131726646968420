import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { validationMessageShape, intlMessageShape } from "@jutro/prop-types";
import { defaultTheme } from "@jutro/theme";
import head from "lodash/head";
import { Card } from "../../card/Card";
import styles from "./FieldMessage.module.css";
import { messages } from "./FieldComponent.messages";
import { ValidationIconAndMessage } from "./ValidationIconAndMessage";
var typeIconMap = {
    error: "gw-error",
    warning: "gw-warning"
  },
  getMessage = function (errorMessageArray, label) {
    return errorMessageArray.some(function (message) {
      return "error" === (null == message ? void 0 : message.type);
    }) ? label ? messages.multipleErrorsWithLabel : messages.multipleErrors : label ? messages.multipleWarningsWithLabel : messages.multipleWarnings;
  };
export var FieldMessage = function (_PureComponent) {
  _inherits(FieldMessage, PureComponent);
  var _super = _createSuper(FieldMessage);
  function FieldMessage() {
    return _classCallCheck(this, FieldMessage), _super.apply(this, arguments);
  }
  return _createClass(FieldMessage, [{
    key: "render",
    value: function () {
      var _head8,
        _head9,
        _head,
        message,
        _this$props = this.props,
        id = _this$props.id,
        translator = _this$props.translator,
        errorMessage = _this$props.errorMessage,
        isFocused = _this$props.isFocused,
        label = _this$props.label,
        errorMessageArray = Array.isArray(errorMessage) ? errorMessage : [errorMessage];
      return React.createElement("div", {
        id: id,
        key: id,
        role: "alert",
        className: styles.fieldMessage,
        "aria-hidden": !errorMessageArray
      }, errorMessageArray.length > 1 && isFocused ? (message = translator(getMessage(errorMessageArray, label), {
        label: label,
        value: errorMessageArray.length
      }), React.createElement(Card, {
        id: "card",
        className: styles.card
      }, React.createElement("div", {
        className: styles.fieldValidationMessage
      }, React.createElement(ValidationIconAndMessage, {
        message: message,
        icon: typeIconMap[head(errorMessageArray).type],
        iconWrapperClassName: cx(styles.iconWrapper),
        iconClassName: styles[head(errorMessageArray).type],
        isFocused: isFocused,
        errorDetails: null === (_head = head(errorMessageArray)) || void 0 === _head ? void 0 : _head.details
      }), React.createElement("ul", {
        style: {
          marginBottom: 0
        }
      }, _mapInstanceProperty(errorMessageArray).call(errorMessageArray, function (messageObject, indx) {
        return React.createElement("li", {
          key: indx
        }, messageObject.message);
      }))))) : null !== (_head8 = head(errorMessageArray)) && void 0 !== _head8 && _head8.details && isFocused ? function () {
        var _context,
          _head2,
          _head3,
          _head4,
          message = translator(_concatInstanceProperty(_context = "".concat(null === (_head2 = head(errorMessageArray)) || void 0 === _head2 ? void 0 : _head2.message, ". ")).call(_context, null === (_head3 = head(errorMessageArray)) || void 0 === _head3 ? void 0 : _head3.details));
        return React.createElement(Card, {
          id: "card",
          className: styles.card
        }, React.createElement("div", {
          className: styles.fieldValidationMessage
        }, React.createElement(ValidationIconAndMessage, {
          message: message,
          icon: typeIconMap[head(errorMessageArray).type],
          iconWrapperClassName: cx(styles.iconWrapper),
          iconClassName: styles[head(errorMessageArray).type],
          isFocused: isFocused,
          errorDetails: null === (_head4 = head(errorMessageArray)) || void 0 === _head4 ? void 0 : _head4.details
        })));
      }() : null !== (_head9 = head(errorMessageArray)) && void 0 !== _head9 && _head9.message ? function () {
        var _head6, _head7;
        if (errorMessageArray.length > 1) {
          var _head5,
            multipleErrorsMessage = translator(getMessage(errorMessageArray, label), {
              label: label,
              value: errorMessageArray.length
            });
          return React.createElement("div", {
            className: styles.fieldValidationMessage
          }, React.createElement(ValidationIconAndMessage, {
            message: multipleErrorsMessage,
            icon: typeIconMap[head(errorMessageArray).type],
            iconWrapperClassName: cx(styles.iconWrapper),
            iconClassName: styles[head(errorMessageArray).type],
            isFocused: isFocused,
            errorDetails: null === (_head5 = head(errorMessageArray)) || void 0 === _head5 ? void 0 : _head5.details
          }));
        }
        var message = translator(null === (_head6 = head(errorMessageArray)) || void 0 === _head6 ? void 0 : _head6.message);
        return React.createElement("div", {
          className: styles.fieldValidationMessage
        }, React.createElement(ValidationIconAndMessage, {
          message: message,
          icon: typeIconMap[head(errorMessageArray).type],
          iconWrapperClassName: cx(styles.iconWrapper),
          iconClassName: styles[head(errorMessageArray).type],
          isFocused: isFocused,
          errorDetails: null === (_head7 = head(errorMessageArray)) || void 0 === _head7 ? void 0 : _head7.details
        }));
      }() : void 0);
    }
  }]), FieldMessage;
}();
_defineProperty(FieldMessage, "propTypes", {
  id: PropTypes.string.isRequired,
  errorMessage: PropTypes.oneOfType([validationMessageShape, PropTypes.arrayOf(validationMessageShape)]),
  label: intlMessageShape,
  theme: PropTypes.object,
  translator: PropTypes.func,
  isFocused: PropTypes.bool
}), _defineProperty(FieldMessage, "defaultProps", {
  theme: defaultTheme
}), FieldMessage.__docgenInfo = {
  description: "Class renders messages for `FieldComponent`.\nValidation messages are expected to be translated before they are passed.",
  methods: [],
  displayName: "FieldMessage",
  props: {
    theme: {
      defaultValue: {
        value: "defaultTheme",
        computed: !0
      },
      type: {
        name: "object"
      },
      required: !1,
      description: "Theme to apply to component"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    errorMessage: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "validationMessageShape"
        }, {
          name: "arrayOf",
          value: {
            name: "custom",
            raw: "validationMessageShape"
          }
        }]
      },
      required: !1,
      description: "Error message to display"
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Field label"
    },
    translator: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The function used to translate strings"
    },
    isFocused: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the field is focused"
    }
  }
}, FieldMessage.__docgenInfo = {
  componentName: "FieldMessage",
  packageName: "@jutro/components",
  description: "Class renders messages for `FieldComponent`.\nValidation messages are expected to be translated before they are passed.",
  displayName: "FieldMessage",
  methods: [],
  actualName: "FieldMessage",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    errorMessage: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "validationMessageShape"
        }, {
          name: "arrayOf",
          value: {
            name: "custom",
            raw: "validationMessageShape"
          }
        }]
      },
      required: !1,
      description: "Error message to display"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Field label"
    },
    theme: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Theme to apply to component",
      defaultValue: {
        value: "defaultTheme",
        computed: !0
      }
    },
    translator: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The function used to translate strings"
    },
    isFocused: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the field is focused"
    }
  }
};