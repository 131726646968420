import _extends from "@babel/runtime-corejs3/helpers/extends";
import _endsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/ends-with";
import React from "react";
import omitBy from "lodash/omitBy";
import { components } from "react-select";
import { getMenuListId } from "../helpers";
export var GenericSelectControlInput = function (props) {
  var _props$selectProps = props.selectProps,
    required = _props$selectProps.required,
    id = _props$selectProps.id,
    menuIsOpen = _props$selectProps.menuIsOpen,
    testId = _props$selectProps.testId;
  return React.createElement(components.Input, _extends({}, function (props) {
    return omitBy(props, function (value, key) {
      return _endsWithInstanceProperty(key).call(key, "ClassName");
    });
  }(props), {
    required: required,
    "aria-required": required,
    "aria-haspopup": "listbox"
  }, menuIsOpen && {
    "aria-controls": getMenuListId(id),
    "aria-autocomplete": "list"
  }, {
    "data-testid": testId
  }));
};
GenericSelectControlInput.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlInput"
}, GenericSelectControlInput.__docgenInfo = {
  componentName: "GenericSelectControlInput",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlInput",
  methods: [],
  actualName: "GenericSelectControlInput"
};