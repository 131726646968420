import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["fallbackComponent", "children", "onError"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import { JUTRO_TOPICS, publish } from "@jutro/events";
import { log } from "@jutro/logger";
import { ErrorNotice } from "../ErrorNotice/ErrorNotice";
export var ErrorBoundary = function (_React$Component) {
  _inherits(ErrorBoundary, _React$Component);
  var _super = _createSuper(ErrorBoundary);
  function ErrorBoundary() {
    var _context, _this;
    _classCallCheck(this, ErrorBoundary);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "state", {
      error: null
    }), _this;
  }
  return _createClass(ErrorBoundary, [{
    key: "componentDidCatch",
    value: function (error, errorInfo) {
      var _this$props$onError, _this$props;
      null === (_this$props$onError = (_this$props = this.props).onError) || void 0 === _this$props$onError || _this$props$onError.call(_this$props, error, errorInfo);
    }
  }, {
    key: "render",
    value: function () {
      var error = this.state.error;
      if (error) {
        var _ref = this.props,
          FallbackComponent = _ref.fallbackComponent,
          otherProps = (_ref.children, _ref.onError, _objectWithoutProperties(_ref, _excluded));
        return React.createElement(FallbackComponent, _extends({}, otherProps, {
          error: error
        }));
      }
      return this.props.children;
    }
  }], [{
    key: "getDerivedStateFromError",
    value: function (error) {
      return {
        error: error
      };
    }
  }]), ErrorBoundary;
}(React.Component);
_defineProperty(ErrorBoundary, "propTypes", {
  onError: PropTypes.func,
  fallbackComponent: PropTypes.elementType,
  children: PropTypes.node
}), _defineProperty(ErrorBoundary, "defaultProps", {
  onError: function (error, errorInfo) {
    var errorDetails = {
      error: error,
      errorInfo: errorInfo
    };
    log.error(error.toString()), publish(JUTRO_TOPICS.UNKNOWN_ERROR, errorDetails);
  },
  fallbackComponent: ErrorNotice,
  children: null
}), ErrorBoundary.__docgenInfo = {
  description: "Introduces a customizable error boundary capable of reporting errors\nto Jutro events and rendering acceptable UIs in production\n\n@metadataType container",
  methods: [],
  displayName: "ErrorBoundary",
  props: {
    onError: {
      defaultValue: {
        value: "(error: Error, errorInfo: ErrorInfo): void => {\n    const errorDetails = { error, errorInfo };\n\n    if (!__DEV__) {\n        // React already logs error in dev\n        log.error(error.toString());\n    }\n\n    publish(JUTRO_TOPICS.UNKNOWN_ERROR, errorDetails);\n}",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "An optional action to be triggered when errors occur",
      tsType: {
        name: "signature",
        type: "function",
        raw: "(error: Error, errorInfo: ErrorInfo) => unknown",
        signature: {
          arguments: [{
            name: "error",
            type: {
              name: "Error"
            }
          }, {
            name: "errorInfo",
            type: {
              name: "ErrorInfo"
            }
          }],
          return: {
            name: "unknown"
          }
        }
      }
    },
    fallbackComponent: {
      defaultValue: {
        value: "ErrorNotice",
        computed: !0
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "An optional custom component to replace the error UI while keeping the error reporting",
      tsType: {
        name: "ReactComponentType",
        raw: "React.ComponentType<FallbackComponentProps>",
        elements: [{
          name: "signature",
          type: "object",
          raw: "{\n    error: Error;\n}",
          signature: {
            properties: [{
              key: "error",
              value: {
                name: "Error",
                required: !0
              }
            }]
          }
        }]
      }
    },
    children: {
      defaultValue: {
        value: "null",
        computed: !1
      },
      type: {
        name: "node"
      },
      required: !1,
      description: "Children to be wrapped by the error boundary",
      tsType: {
        name: "ReactNode"
      }
    }
  }
}, ErrorBoundary.__docgenInfo = {
  componentName: "ErrorBoundary",
  packageName: "@jutro/components",
  description: "Introduces a customizable error boundary capable of reporting errors\nto Jutro events and rendering acceptable UIs in production",
  displayName: "ErrorBoundary",
  methods: [],
  actualName: "ErrorBoundary",
  metadataType: "container",
  props: {
    onError: {
      type: {
        name: "func"
      },
      required: !1,
      description: "An optional action to be triggered when errors occur",
      defaultValue: {
        value: "(error, errorInfo) => {\n    const errorDetails = { error, errorInfo };\n    if (!__DEV__) {\n        // React already logs error in dev\n        log.error(error.toString());\n    }\n    publish(JUTRO_TOPICS.UNKNOWN_ERROR, errorDetails);\n}",
        computed: !1
      }
    },
    fallbackComponent: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "An optional custom component to replace the error UI while keeping the error reporting",
      defaultValue: {
        value: "ErrorNotice",
        computed: !0
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children to be wrapped by the error boundary",
      defaultValue: {
        value: "null",
        computed: !1
      }
    }
  }
};