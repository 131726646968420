import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["inputFormat"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useContext, useState, useEffect, useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import { DATA_TYPE_OBJECT, dataTypeShapeWithDateTime, intlMessageShape, dateValueShape, dateValidationRuleShape, stringValidationRuleShape } from "@jutro/prop-types";
import defaultLocaleObject from "date-fns/locale/en-US";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { LocaleContext, IntlContext, getDatePatternFromLocale } from "@jutro/locale";
import { FieldComponent } from "../../FieldComponent/FieldComponent";
import { FieldIcon } from "../../FieldComponent/FieldIcon";
import { DateFieldInternal, MAX_DEFAULT_YEAR, MIN_DEFAULT_YEAR } from "./DateFieldInternal";
export var dateFormatTypes = ["vshort", "short", "long", "abbreviated", "full"];
export var popperPlacementPositions = ["auto", "auto-end", "bottom", "bottom-end", "bottom-start", "left", "left-end", "left-start", "right", "right-end", "right-start", "top", "top-end", "top-start"];
var dateFieldDefaultProps = _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  icon: "gw-calendar-today",
  dataType: DATA_TYPE_OBJECT,
  displayFormat: "short",
  minDate: {
    year: MIN_DEFAULT_YEAR,
    month: 0,
    day: 1
  },
  maxDate: {
    year: MAX_DEFAULT_YEAR,
    month: 0,
    day: 1
  },
  showCalendar: !0
});
export var dateFieldPropTypes = _objectSpread(_objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  todayButtonText: intlMessageShape,
  maxDate: dateValidationRuleShape,
  minDate: dateValidationRuleShape,
  maxYear: dateValidationRuleShape,
  minYear: dateValidationRuleShape,
  value: dateValueShape,
  dataType: dataTypeShapeWithDateTime,
  popperPlacement: PropTypes.oneOf(popperPlacementPositions),
  displayFormat: PropTypes.oneOf(dateFormatTypes),
  inputFormat: stringValidationRuleShape,
  theme: PropTypes.shape({})
}, FieldIcon.propTypes), {}, {
  isInitiallyOpen: PropTypes.bool,
  showCalendar: PropTypes.bool,
  name: PropTypes.string,
  openWithIconOnly: PropTypes.bool,
  hideTodayButton: PropTypes.bool,
  noMask: PropTypes.bool,
  calendarClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  popperClassName: PropTypes.string
});
export var DateField = forwardRef(function (_ref, ref) {
  var inputFormatProp = _ref.inputFormat,
    props = _objectWithoutProperties(_ref, _excluded),
    intl = useContext(IntlContext),
    _useContext = useContext(LocaleContext),
    locale = _useContext.locale,
    dateLocale = _useContext.dateLocale,
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    updateFlag = _useState2[0],
    triggerUpdate = _useState2[1],
    inputFormat = useMemo(function () {
      var datePattern = getDatePatternFromLocale(isEmpty(dateLocale) ? defaultLocaleObject : dateLocale);
      if (isArray(inputFormatProp)) {
        var _inputFormatProp = _slicedToArray(inputFormatProp, 2);
        return [_inputFormatProp[0] || datePattern, _inputFormatProp[1]];
      }
      return inputFormatProp || datePattern;
    }, [dateLocale, inputFormatProp]);
  return useEffect(function () {
    triggerUpdate(!updateFlag);
  }, [intl]), React.createElement(DateFieldInternal, _extends({
    ref: ref,
    inputFormat: inputFormat
  }, props, {
    intl: intl,
    updateFlag: updateFlag,
    locale: dateLocale,
    localeCode: locale,
    autoTrim: !1
  }));
});
DateField.displayName = "DateField", DateField.propTypes = dateFieldPropTypes, DateField.defaultProps = dateFieldDefaultProps, DateField.__docgenInfo = {
  description: "Allows the user to either type or select a date. Displays them according to locale settings.\nValid format for manual input is ISO YYYY-MM-DD or MM/DD/YYYY. The time part (for the date and time\nfield) should be appended to the date and follow ISO standard. For example: 2020-05-03T:10:30.\nNote that time values are assumed to be in client time zone. Unless time zone is explicitly\nprovided through manual input (as per ISO format). Date only input treats dates as UTC dates.\n\n@extends {FieldComponent<DateFieldPropTypes>}\n\n@metadataType field",
  methods: [],
  displayName: "DateField",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-calendar-today'",
        computed: !1
      },
      required: !1
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShapeWithDateTime"
      },
      required: !1,
      description: "Format of the value"
    },
    displayFormat: {
      defaultValue: {
        value: "'short'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'vshort'",
          computed: !1
        }, {
          value: "'short'",
          computed: !1
        }, {
          value: "'long'",
          computed: !1
        }, {
          value: "'abbreviated'",
          computed: !1
        }, {
          value: "'full'",
          computed: !1
        }]
      },
      required: !1,
      description: "The readonly date format: 'short', 'long', 'abbreviated' or 'full'"
    },
    minDate: {
      defaultValue: {
        value: "{\n    year: MIN_DEFAULT_YEAR,\n    month: 0,\n    day: 1,\n}",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets minDate validation on DateField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: minDate: [SomeDate, "a custom message"].'
    },
    maxDate: {
      defaultValue: {
        value: "{\n    year: MAX_DEFAULT_YEAR,\n    month: 0,\n    day: 1,\n}",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets maxDate validation on DateField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: maxDate: [SomeDate, "a custom message"].'
    },
    showCalendar: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should display the calendar pop-over"
    },
    todayButtonText: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Display today button"
    },
    maxYear: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: "Max year used when showYearPicker is set to true"
    },
    minYear: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: "Min year used when showYearPicker is set to true"
    },
    value: {
      type: {
        name: "custom",
        raw: "dateValueShape"
      },
      required: !1,
      description: "Date to display"
    },
    popperPlacement: {
      type: {
        name: "enum",
        value: [{
          value: "'auto'",
          computed: !1
        }, {
          value: "'auto-end'",
          computed: !1
        }, {
          value: "'bottom'",
          computed: !1
        }, {
          value: "'bottom-end'",
          computed: !1
        }, {
          value: "'bottom-start'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }, {
          value: "'left-end'",
          computed: !1
        }, {
          value: "'left-start'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'right-end'",
          computed: !1
        }, {
          value: "'right-start'",
          computed: !1
        }, {
          value: "'top'",
          computed: !1
        }, {
          value: "'top-end'",
          computed: !1
        }, {
          value: "'top-start'",
          computed: !1
        }]
      },
      required: !1,
      description: "Popper placement option"
    },
    inputFormat: {
      type: {
        name: "custom",
        raw: "stringValidationRuleShape"
      },
      required: !1,
      description: "Format of date input used for masking. e.g. 'd/M/yyyy'\nd - day\nM - month\ny - year\nBy default it is generated based on user's locale, and\nit is recommended to not set it manually\n\nProp can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as undefined or desired mask and the second value as the custom message. For example: [undefined, \"a custom message\"]."
    },
    theme: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Theme config to apply"
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should dropdown be initially opened"
    },
    name: {
      type: {
        name: "string"
      },
      required: !1,
      description: "This name will be passed to input name attribute and turn on autocomplete"
    },
    openWithIconOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, clicking on the input does not open the calendar. Open it by clicking on the icon.\nIf false, clicking on both the input or the icon opens the calendar."
    },
    hideTodayButton: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'Should "today" button be displayed'
    },
    noMask: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide mask when typing inside field"
    },
    calendarClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for calendar"
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for children container"
    },
    popperClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for popper"
    }
  },
  composes: ["../../FieldComponent/FieldComponent", "../../FieldComponent/FieldIcon"]
}, DateField.__docgenInfo = {
  componentName: "DateField",
  packageName: "@jutro/components",
  description: "Allows the user to either type or select a date. Displays them according to locale settings.\nValid format for manual input is ISO YYYY-MM-DD or MM/DD/YYYY. The time part (for the date and time\nfield) should be appended to the date and follow ISO standard. For example: 2020-05-03T:10:30.\nNote that time values are assumed to be in client time zone. Unless time zone is explicitly\nprovided through manual input (as per ISO format). Date only input treats dates as UTC dates.",
  displayName: "DateField",
  methods: [],
  actualName: "DateField",
  metadataType: "field",
  props: {
    todayButtonText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Display today button"
    },
    maxDate: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets maxDate validation on DateField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: maxDate: [SomeDate, "a custom message"].',
      defaultValue: {
        value: "{\n    year: MAX_DEFAULT_YEAR,\n    month: 0,\n    day: 1,\n}",
        computed: !1
      }
    },
    minDate: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets minDate validation on DateField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: minDate: [SomeDate, "a custom message"].',
      defaultValue: {
        value: "{\n    year: MIN_DEFAULT_YEAR,\n    month: 0,\n    day: 1,\n}",
        computed: !1
      }
    },
    maxYear: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: "Max year used when showYearPicker is set to true"
    },
    minYear: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: "Min year used when showYearPicker is set to true"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !0
            },
            month: {
              name: "number",
              required: !1
            },
            day: {
              name: "number",
              required: !1
            },
            hour: {
              name: "number",
              required: !1
            },
            minute: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Date to display"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }, {
          value: "'date-time'",
          computed: !1
        }]
      },
      required: !1,
      description: "Format of the value",
      defaultValue: {
        value: "'object'",
        computed: !1
      }
    },
    popperPlacement: {
      type: {
        name: "enum",
        value: [{
          value: "'auto'",
          computed: !1
        }, {
          value: "'auto-end'",
          computed: !1
        }, {
          value: "'bottom'",
          computed: !1
        }, {
          value: "'bottom-end'",
          computed: !1
        }, {
          value: "'bottom-start'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }, {
          value: "'left-end'",
          computed: !1
        }, {
          value: "'left-start'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'right-end'",
          computed: !1
        }, {
          value: "'right-start'",
          computed: !1
        }, {
          value: "'top'",
          computed: !1
        }, {
          value: "'top-end'",
          computed: !1
        }, {
          value: "'top-start'",
          computed: !1
        }]
      },
      required: !1,
      description: "Popper placement option"
    },
    displayFormat: {
      type: {
        name: "enum",
        value: [{
          value: "'vshort'",
          computed: !1
        }, {
          value: "'short'",
          computed: !1
        }, {
          value: "'long'",
          computed: !1
        }, {
          value: "'abbreviated'",
          computed: !1
        }, {
          value: "'full'",
          computed: !1
        }]
      },
      required: !1,
      description: "The readonly date format: 'short', 'long', 'abbreviated' or 'full'",
      defaultValue: {
        value: "'short'",
        computed: !1
      }
    },
    inputFormat: {
      type: {
        name: "custom",
        raw: "stringValidationRuleShape"
      },
      required: !1,
      description: "Format of date input used for masking. e.g. 'd/M/yyyy'\nd - day\nM - month\ny - year\nBy default it is generated based on user's locale, and\nit is recommended to not set it manually\n\nProp can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as undefined or desired mask and the second value as the custom message. For example: [undefined, \"a custom message\"]."
    },
    theme: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Theme config to apply"
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should dropdown be initially opened"
    },
    showCalendar: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should display the calendar pop-over",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    name: {
      type: {
        name: "string"
      },
      required: !1,
      description: "This name will be passed to input name attribute and turn on autocomplete"
    },
    openWithIconOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, clicking on the input does not open the calendar. Open it by clicking on the icon.\nIf false, clicking on both the input or the icon opens the calendar."
    },
    hideTodayButton: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'Should "today" button be displayed'
    },
    noMask: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide mask when typing inside field"
    },
    calendarClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for calendar"
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for children container"
    },
    popperClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for popper"
    },
    icon: {
      defaultValue: {
        value: "'gw-calendar-today'",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["../../FieldComponent/FieldComponent", "../../FieldComponent/FieldIcon"]
};