import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { layoutShape } from "./Layouts.commons";
import { Layout } from "./Layout";
var propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  layout: PropTypes.shape({
    desktop: layoutShape,
    tablet: layoutShape,
    phoneWide: layoutShape,
    phone: layoutShape
  })
};
export var PanelLayout = function (_ref) {
  var id = _ref.id,
    children = _ref.children,
    className = _ref.className,
    _ref2 = _ref.layout || {},
    desktop = _ref2.desktop,
    tablet = _ref2.tablet,
    phoneWide = _ref2.phoneWide,
    phone = _ref2.phone;
  return React.createElement(Layout, {
    id: id,
    layout: {
      desktop: _objectSpread({
        columns: ["1fr"],
        repeat: "8",
        gap: "large",
        colStart: "2",
        colSpan: "6"
      }, desktop),
      tablet: _objectSpread({
        repeat: "6",
        gap: "medium",
        colStart: "2",
        colSpan: "4"
      }, tablet),
      phoneWide: _objectSpread({
        repeat: "6",
        gap: "medium",
        colStart: "2",
        colSpan: "4"
      }, phoneWide),
      phone: _objectSpread({
        repeat: "4",
        gap: "medium",
        colStart: "1",
        colSpan: "4"
      }, phone)
    },
    className: className
  }, children);
};
PanelLayout.propTypes = propTypes, PanelLayout.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "PanelLayout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "children; preferably 'GridItem'; works with any child"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional css class(es) to add to the grid tag"
    },
    layout: {
      type: {
        name: "shape",
        value: {
          desktop: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override the default layout",
            required: !1
          },
          tablet: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override the layout on a tablet",
            required: !1
          },
          phoneWide: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override the layout on phoneWide",
            required: !1
          },
          phone: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override the layout on phone",
            required: !1
          }
        }
      },
      required: !1,
      description: "Optional props to override the default device layout"
    }
  }
}, PanelLayout.__docgenInfo = {
  componentName: "PanelLayout",
  packageName: "@jutro/components",
  description: "",
  displayName: "PanelLayout",
  methods: [],
  actualName: "PanelLayout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "children; preferably 'GridItem'; works with any child"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional css class(es) to add to the grid tag"
    },
    layout: {
      type: {
        name: "shape",
        value: {
          desktop: {
            name: "shape",
            description: "Optional props to override the default layout",
            required: !1,
            value: {
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: !1
                  }, {
                    value: "'auto-fill'",
                    computed: !1
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: !1
              },
              gap: {
                name: "enum",
                computed: !0,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: !1
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: !1
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "specifies how many columns an element should span across",
                required: !1
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "define column to start",
                required: !1
              }
            }
          },
          tablet: {
            name: "shape",
            description: "Optional props to override the layout on a tablet",
            required: !1,
            value: {
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: !1
                  }, {
                    value: "'auto-fill'",
                    computed: !1
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: !1
              },
              gap: {
                name: "enum",
                computed: !0,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: !1
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: !1
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "specifies how many columns an element should span across",
                required: !1
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "define column to start",
                required: !1
              }
            }
          },
          phoneWide: {
            name: "shape",
            description: "Optional props to override the layout on phoneWide",
            required: !1,
            value: {
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: !1
                  }, {
                    value: "'auto-fill'",
                    computed: !1
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: !1
              },
              gap: {
                name: "enum",
                computed: !0,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: !1
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: !1
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "specifies how many columns an element should span across",
                required: !1
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "define column to start",
                required: !1
              }
            }
          },
          phone: {
            name: "shape",
            description: "Optional props to override the layout on phone",
            required: !1,
            value: {
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: !1
                  }, {
                    value: "'auto-fill'",
                    computed: !1
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: !1
              },
              gap: {
                name: "enum",
                computed: !0,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: !1
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: !1
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "specifies how many columns an element should span across",
                required: !1
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "define column to start",
                required: !1
              }
            }
          }
        }
      },
      required: !1,
      description: "Optional props to override the default device layout"
    }
  }
};