import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { useSafeTranslatedUrls } from "@jutro/locale";
import { Link } from "../Link/Link";
import { HelpElement } from "./HelpElement";
import styles from "./HelpPopover.module.css";
var helpLinkPropTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  onClick: PropTypes.func,
  href: intlMessageShape
};
export var HelpLink = function (_ref) {
  var content = _ref.content,
    onClick = _ref.onClick,
    href = _ref.href,
    className = _ref.className,
    urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  if (!content || !onClick && !href) return null;
  var classes = cx(styles.helpLink, className);
  return React.createElement(HelpElement, {
    className: classes,
    content: content,
    tag: Link,
    onClick: onClick,
    href: urlTranslatorAndSanitizer(href),
    target: "_blank",
    rel: "noopener noreferrer"
  });
};
HelpLink.propTypes = helpLinkPropTypes, HelpLink.__docgenInfo = {
  description: "The `HelpLink` component is designed as a simple component which displays a\nlink inside the Help drop-down list in the Application Header.\n\n@type {React.FC<PropTypes.InferProps<typeof helpLinkPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "HelpLink",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "Content of the link"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The callback to invoke on click"
    },
    href: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "External location"
    }
  }
}, HelpLink.__docgenInfo = {
  componentName: "HelpLink",
  packageName: "@jutro/components",
  description: "The `HelpLink` component is designed as a simple component which displays a\nlink inside the Help drop-down list in the Application Header.",
  displayName: "HelpLink",
  methods: [],
  actualName: "HelpLink",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "Content of the link"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The callback to invoke on click"
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "External location"
    }
  }
};