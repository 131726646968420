const KFIThemeConfig = {
  name: 'KFITheme',
  prefix: 'kfiTheme',
  rootStyle: {
    '--GW-BRAND-COLOR-1': '#FFB81c',
    // KFI yellow
    '--GW-BRAND-COLOR-2': '#385e9d',
    // kfi blue
    '--GW-BRAND-COLOR-3': '#59646d',
    // kfi secondary grey 1
    '--GW-BRAND-COLOR-4': '#ebeced',
    // kfi secondary grey 2
    '--GW-BRAND-COLOR-5': '#373737',
    // kfi wording colour
    '--GW-BRAND-COLOR-6': '#dedede',
    // kfi grey 3

    '--GW-BORDER-COLOR': '#ccc',
    '--GW-FONT-SIZE-H1': '33px',
    '--GW-FONT-SIZE-H2': '29px',
    '--GW-FONT-SIZE-H3': '29px',
    '--GW-FONT-SIZE-H4': '18px',
    '--GW-FONT-SIZE-H2-BOTTOM-MARGIN': '20px',
    '--GW-FONT-SIZE-BODY': '15px',
    '--GW-FONT-SIZE-CAPTION': '15px',
    '--ACCORDION-HEADER-FOCUSED-BORDER': 'none',
    '--ACCORDION-HEADER-FOCUSED-BOX-SHADOW': 'transparent',
    '--GW-ACCORDION-CARDTITLE-FONT-SIZE': '1rem',
    '--GW-FONT-SIZE-LABEL': '15px',
    '--GW-FONT-SECTION-TITLES': '1.8rem',
    '--GW-FONT-SIZE-LABEL-WEIGHT': 'normal',
    '--GW-TEXT-COLOR-1': 'var(--GW-BRAND-COLOR-3)',
    '--GW-TEXT-COLOR-2': 'var(--GW-BRAND-COLOR-2)',
    '--GW-TEXT-COLOR-3': '#fff',
    '--GW-MODAL-HEADER-TITLE-WEIGHT': 'normal',
    '--GW-TOOLTIP-COLOR-3': 'var(--GW-BRAND-COLOR-1)',
    '--GW-TOOLTIP-ICON-COLOR': 'var(--GW-BRAND-COLOR-2)',
    '--GW-FONT-FAMILY': '"kfiRegular", sans-serif',
    '--GW-SELECTOR-SELECTED-BACKGROUND-COLOR': 'var(--GW-BRAND-COLOR-2)',
    '--GW-RADIO-BUTTON-SECONDARY-COLOR': '#EFF0F0',
    '--GW-BUTTON-BORDER-RADIUS': '50px',
    '--GW-BUTTON-FILLED-COLOR': '#fff',
    '--gw-button-filled-colour-hover': '#fff',
    '--GW-BUTTON-FILLED-BACKGROUND-COLOR': 'var(--GW-BRAND-COLOR-1)',
    '--GW-BUTTON-FILLED-BACKGROUND-COLOR-HOVER': 'var(--GW-BRAND-COLOR-2)',
    '--GW-INPUT-OPACITY-DISABLED': '95%',
    '--gw-footer-padding': '40px 40px 30px',
    '--GW-BACKGROUND-COLOR': '#fff',
    '--GW-FIELD-COMPONENT-PLACEHOLDER-COLOR': 'var(--GW-TEXT-COLOR-1)',
    '--gw-header-branding-image': './../../../applications/quote-and-buy/public/branding/tya/toyota-white-logo.svg',
    '--gw-header-branding-container-height': '90px',
    '--gw-header-branding-image-width': '263px',
    '--gw-header-branding-image-height': '45px',
    '--gw-header-branding-mobile-image-width': '212px',
    '--gw-header-branding-mobile-image-height': '36px',
    '--gw-header-branding-max-width': '1440px',
    '--gw-header-colour': '#fff',
    '--gw-stepper-branding-title-disabled': '#949498',
    '--gw-stepper-branding-title-size': '1rem',
    '--gw-stepper-branding-title-font-weight': '500',
    '--gw-stepper-branding-marker-color': '#000',
    '--gw-stepper-branding-progress-marker-dimensions': '1.7rem',
    '--gw-stepper-branding-title-line-height': '1.2rem',
    '--gw-stepper-section-seperator': '#999',
    '--gw-stepper-title-colour': '#000',
    '--gw-required-branding-asterisk-color': '#B94A48',
    '--gw-button-branding-font-weight': '200',
    '--gw-button-branding-width': '190px',
    '--gw-addon-branding-border-colour': '#dedede',
    '--gw-your-branding-quote-box-border': 'var(--GW-BRAND-COLOR-1)',
    '--gw-tooltip-font-style': 'italic',
    '--gw-tooltip-font-weight': '100',
    '--gw-disabled-input-background-color': '#ececeb',
    '--gw-quote-tick-color': 'invert(76%) sepia(99%) saturate(2225%) hue-rotate(-10deg) brightness(102%) contrast(89%)',
    '--gw-quote-tick-alignment': 'center',
    '--gw-stepper-progress-marker-colour': 'var(--GW-BRAND-COLOR-2)',
    '--GW-PROGRESS-BARS-LEFT-BAR-COLOR': 'var(--GW-BRAND-COLOR-1)',
    '--gw-footer-text-colour': 'var(--GW-BRAND-COLOR-3)',
    '--GW-BUTTON-OUTLINED-COLOR': 'var(--GW-BRAND-COLOR-2)',
    '--GW-BUTTON-OUTLINED-COLOR-HOVER': 'var(--GW-BRAND-COLOR-2)',
    '--GW-BUTTON-OUTLINED-BORDER-COLOR': 'var(--GW-BRAND-COLOR-2)',
    '--gw-secondary-button-background-colour-hover': 'var(--GW-BRAND-COLOR-4)',
    '--gw-secondary-outlined-border-colour-hover': 'var(--GW-BRAND-COLOR-4)',
    '--GW-FIELD-COMPONENT-COLOR-SECONDARY': 'var(--GW-BRAND-COLOR-3)',
    '--GW-LINK-COLOR-HOVER': 'var(--GW-BRAND-COLOR-2)',
    '--gw-quote-price-amount-text-colour': 'var(--GW-BRAND-COLOR-1)',
    '--gw-coverage-title-text-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-coverage-icon-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-coverage-highlighted-text-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-section-title-font-size': '24px',
    '--gw-quote-review-accordian-title-color': 'var(--GW-TEXT-COLOR-1)',
    '--gw-quote-page-border-colour': 'var(--GW-BRAND-COLOR-6)',
    '--GW-BACKGROUND-COLOR-BODY': '#fff',
    '--GW-BUTTON-OUTLINED-BORDER-WIDTH': '1px',
    '--gw-secondary-button-hover-font-weight': 'var(--GW-FONT-WEIGHT-LIGHT)',
    '--gw-header-box-shadow': 'none',
    '--gw-paragraph-mb': '10px',
    '--gw-lp1-car-reg-content-font-weight': 'var(--GW-FONT-WEIGHT-BOLD)',
    '--and-spacing-3': '20px',
    '--gw-header-section-padding': '20px 0 5px 0',
    '--gw-header-section-margin': '0 0 5px 0',
    '--GW-DROPDOWN-OPTION-SELECTED-BACKGROUND-COLOR': 'var(--GW-BRAND-COLOR-2)',
    '--gw-brand-h2-font-family': 'HeadersBold',
    '--gw-retrieve-quote-display-format': 'block',
    '--gw-cancel-button-display': 'none',
    '--gw-nav-buttons-container-display': 'block',
    '--gw-nav-transitions-buttons-display': 'flex',
    '--gw-cookie-moda-background-colour': 'var(--GW-BRAND-COLOR-1)',
    '--gw-cookie-modal-desc-colour': '#fff',
    '--gw-cookie-modal-cookie-policy-link-colour': 'var(--GW-BRAND-COLOR-2)',
    '--GW-SWITCH-FIELD-BACKGROUND-COLOR-CHECKED': 'var(--GW-BRAND-COLOR-2)',
    '--gw-alternate-button-background-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-alternate-button-background-colour-hover': 'var(--GW-BRAND-COLOR-2)',
    '--gw-cookie-modal-padding': '3rem',
    '--gw-cookie-modal-border-radius': '25px',
    '--gw-monthly-representative-example-font-size': '18px',
    '--gw-breakdown-coverage-bullet-point-title-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-document-link-margin-bottom': '5px',
    '--gw-back-button-width': '20%',
    '--gw-amend-details-button-text-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-field-component-MB': '0.75rem',
    '--gw-payment-complete-step-container-colour': 'lightgrey',
    '--gw-payment-complete-step-container-text-colour': 'var(--GW-TEXT-COLOR-1)',
    '--gw-aggs-splash-screen-header-BG-colour': '#fff',
    '--gw-aggs-splash-screen-text-colour': 'var(--GW-BRAND-COLOR-2)',
    '--gw-aggs-splash-screen-font-weight': 'bold',
    '--gw-quote-review-accordion-title-font-size': '18px',
    '--GW-FONT-SIZE-SECONDARY-LABEL': '13px',
    '--gw-payment-selection-icon-mt': '3px',
    '--gw-kfi-back-button': '20px',
    '--gw-kfi-set-main-driver-button': '0px',
    '--gw-activate-your-portal-list-style-type': 'none',
    '--gw-font-size-mandate-label': '16px',
    '--gw-mandate-input-font-weight': '600',
    '--gw-error-validation-message': '5px 3px 1px 24px'
  },
  internalTheme: !0
};
export default KFIThemeConfig;