import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { useAuth } from "@jutro/auth";
import { Avatar } from "../Avatar/Avatar";
import styles from "./DropdownMenuAvatar.module.css";
export var DropdownMenuAvatarHeader = function (_ref) {
  var _auth$userInfo,
    _auth$userInfo3,
    _auth$userInfo$email,
    _auth$userInfo4,
    translatedSubtitle,
    _auth$userInfo$name,
    _auth$userInfo2,
    translatedTitle,
    title = _ref.title,
    subtitle = _ref.subtitle,
    username = _ref.username,
    icon = _ref.icon,
    useAuthInfo = _ref.useAuthInfo,
    className = _ref.className,
    imageSource = _ref.imageSource,
    messageProps = _ref.messageProps,
    showImageBorder = _ref.showImageBorder,
    auth = useAuth(),
    translator = useTranslator(),
    avatarProps = {
      username: username,
      icon: icon,
      imageSource: useAuthInfo ? null == auth || null === (_auth$userInfo = auth.userInfo) || void 0 === _auth$userInfo ? void 0 : _auth$userInfo.pictureUrl : imageSource,
      messageProps: messageProps,
      showImageBorder: showImageBorder,
      useAuthInfo: useAuthInfo
    };
  return React.createElement("div", {
    className: cx(styles.dropdownMenuAvatarHeader, className)
  }, React.createElement(Avatar, _extends({
    disabled: !0
  }, avatarProps)), React.createElement("div", {
    className: styles.headerText
  }, React.createElement("div", {
    className: styles.headerTitle
  }, (translatedTitle = translator(title), useAuthInfo && null !== (_auth$userInfo$name = null == auth || null === (_auth$userInfo2 = auth.userInfo) || void 0 === _auth$userInfo2 ? void 0 : _auth$userInfo2.name) && void 0 !== _auth$userInfo$name ? _auth$userInfo$name : translatedTitle)), React.createElement("div", {
    className: styles.headerSubtitle
  }, (translatedSubtitle = translator(subtitle), useAuthInfo ? (null == auth || null === (_auth$userInfo3 = auth.userInfo) || void 0 === _auth$userInfo3 ? void 0 : _auth$userInfo3.preferred_username) || (null !== (_auth$userInfo$email = null == auth || null === (_auth$userInfo4 = auth.userInfo) || void 0 === _auth$userInfo4 ? void 0 : _auth$userInfo4.email) && void 0 !== _auth$userInfo$email ? _auth$userInfo$email : translatedSubtitle) : translatedSubtitle))));
};
DropdownMenuAvatarHeader.propTypes = {
  title: intlMessageShape,
  subtitle: intlMessageShape,
  username: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  imageSource: PropTypes.string,
  messageProps: PropTypes.shape({
    userAvatar: intlMessageShape
  }),
  useAuthInfo: PropTypes.bool,
  showImageBorder: PropTypes.bool,
  headerClassName: PropTypes.string
}, DropdownMenuAvatarHeader.defaultAvatarProps = {
  useAuthInfo: !1
}, DropdownMenuAvatarHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DropdownMenuAvatarHeader",
  props: {
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Header title text"
    },
    subtitle: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Menu header subtitle text"
    },
    username: {
      type: {
        name: "string"
      },
      required: !1,
      description: "User's first and last name, used as component alt text and to display initials if no image selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to a user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "custom",
            raw: "intlMessageShape",
            description: "userAvatar message",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, display header information from Okta (if available) and the Logout menu link"
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays an image border"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar header"
    }
  }
}, DropdownMenuAvatarHeader.__docgenInfo = {
  componentName: "DropdownMenuAvatarHeader",
  packageName: "@jutro/components",
  description: "",
  displayName: "DropdownMenuAvatarHeader",
  methods: [],
  actualName: "DropdownMenuAvatarHeader",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Header title text"
    },
    subtitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Menu header subtitle text"
    },
    username: {
      type: {
        name: "string"
      },
      required: !1,
      description: "User's first and last name, used as component alt text and to display initials if no image selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to a user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "union",
            description: "userAvatar message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, display header information from Okta (if available) and the Logout menu link"
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays an image border"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar header"
    }
  }
};