import { defineMessages } from "react-intl";
export var messages = defineMessages({
  validatePhone: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.PhoneNumberIncorrect",
    defaultMessage: "Phone number is incorrect."
  },
  validatePhoneError0: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.validatePhoneError.IsPossible",
    defaultMessage: "Most likely the country does not allow phone numbers with these digits."
  },
  validatePhoneError1: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.validatePhoneError.InvalidCountryCode",
    defaultMessage: "Country code does not match any country."
  },
  validatePhoneError2: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.validatePhoneError.TooShort",
    defaultMessage: "The phone number is too short."
  },
  validatePhoneError3: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.validatePhoneError.TooLong",
    defaultMessage: "The phone number is too long."
  },
  validatePhoneError4: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.validatePhoneError.IsPossibleLocalOnly",
    defaultMessage: "Enter a number with characters specific to the country."
  },
  validatePhoneError5: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.validatePhoneError.InvalidLength",
    defaultMessage: "Make sure you have a proper phone number for the selected country."
  },
  "validatePhoneError-99": {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.validatePhoneError.UnknownError",
    defaultMessage: "Unknown error."
  }
});
export var countryMessages = defineMessages({
  af: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.af",
    defaultMessage: "Afghanistan"
  },
  al: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.al",
    defaultMessage: "Albania"
  },
  dz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.dz",
    defaultMessage: "Algeria"
  },
  as: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.as",
    defaultMessage: "American Samoa"
  },
  ad: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ad",
    defaultMessage: "Andorra"
  },
  ao: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ao",
    defaultMessage: "Angola"
  },
  ai: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ai",
    defaultMessage: "Anguilla"
  },
  ag: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ag",
    defaultMessage: "Antigua and Barbuda"
  },
  ar: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ar",
    defaultMessage: "Argentina"
  },
  am: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.am",
    defaultMessage: "Armenia"
  },
  aw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.aw",
    defaultMessage: "Aruba"
  },
  au: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.au",
    defaultMessage: "Australia"
  },
  at: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.at",
    defaultMessage: "Austria"
  },
  az: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.az",
    defaultMessage: "Azerbaijan"
  },
  bs: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bs",
    defaultMessage: "Bahamas"
  },
  bh: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bh",
    defaultMessage: "Bahrain"
  },
  bd: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bd",
    defaultMessage: "Bangladesh"
  },
  bb: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bb",
    defaultMessage: "Barbados"
  },
  by: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.by",
    defaultMessage: "Belarus"
  },
  be: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.be",
    defaultMessage: "Belgium"
  },
  bz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bz",
    defaultMessage: "Belize"
  },
  bj: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bj",
    defaultMessage: "Benin"
  },
  bm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bm",
    defaultMessage: "Bermuda"
  },
  bt: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bt",
    defaultMessage: "Bhutan"
  },
  bo: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bo",
    defaultMessage: "Bolivia"
  },
  ba: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ba",
    defaultMessage: "Bosnia and Herzegovina"
  },
  bw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bw",
    defaultMessage: "Botswana"
  },
  br: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.br",
    defaultMessage: "Brazil"
  },
  io: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.io",
    defaultMessage: "British Indian Ocean Territory"
  },
  vg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.vg",
    defaultMessage: "British Virgin Islands"
  },
  bn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bn",
    defaultMessage: "Brunei"
  },
  bg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bg",
    defaultMessage: "Bulgaria"
  },
  bf: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bf",
    defaultMessage: "Burkina Faso"
  },
  bi: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bi",
    defaultMessage: "Burundi"
  },
  kh: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.kh",
    defaultMessage: "Cambodia"
  },
  cm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cm",
    defaultMessage: "Cameroon"
  },
  ca: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ca",
    defaultMessage: "Canada"
  },
  cv: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cv",
    defaultMessage: "Cape Verde"
  },
  bq: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bq",
    defaultMessage: "Caribbean Netherlands"
  },
  ky: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ky",
    defaultMessage: "Cayman Islands"
  },
  cf: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cf",
    defaultMessage: "Central African Republic"
  },
  td: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.td",
    defaultMessage: "Chad"
  },
  cl: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cl",
    defaultMessage: "Chile"
  },
  cn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cn",
    defaultMessage: "China"
  },
  cx: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cx",
    defaultMessage: "Christmas Island"
  },
  cc: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cc",
    defaultMessage: "Cocos"
  },
  co: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.co",
    defaultMessage: "Colombia"
  },
  km: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.km",
    defaultMessage: "Comoros"
  },
  cd: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cd",
    defaultMessage: "Congo"
  },
  cg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cg",
    defaultMessage: "Congo"
  },
  ck: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ck",
    defaultMessage: "Cook Islands"
  },
  cr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cr",
    defaultMessage: "Costa Rica"
  },
  ci: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ci",
    defaultMessage: "Côte d’Ivoire"
  },
  hr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.hr",
    defaultMessage: "Croatia"
  },
  cu: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cu",
    defaultMessage: "Cuba"
  },
  cw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cw",
    defaultMessage: "Curaçao"
  },
  cy: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cy",
    defaultMessage: "Cyprus"
  },
  cz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.cz",
    defaultMessage: "Czech Republic"
  },
  dk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.dk",
    defaultMessage: "Denmark"
  },
  dj: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.dj",
    defaultMessage: "Djibouti"
  },
  dm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.dm",
    defaultMessage: "Dominica"
  },
  do: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.do",
    defaultMessage: "Dominican Republic"
  },
  ec: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ec",
    defaultMessage: "Ecuador"
  },
  eg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.eg",
    defaultMessage: "Egypt"
  },
  sv: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sv",
    defaultMessage: "El Salvador"
  },
  gq: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gq",
    defaultMessage: "Equatorial Guinea"
  },
  er: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.er",
    defaultMessage: "Eritrea"
  },
  ee: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ee",
    defaultMessage: "Estonia"
  },
  et: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.et",
    defaultMessage: "Ethiopia"
  },
  fk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.fk",
    defaultMessage: "Falkland Islands"
  },
  fo: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.fo",
    defaultMessage: "Faroe Islands"
  },
  fj: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.fj",
    defaultMessage: "Fiji"
  },
  fi: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.fi",
    defaultMessage: "Finland"
  },
  fr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.fr",
    defaultMessage: "France"
  },
  gf: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gf",
    defaultMessage: "French Guiana"
  },
  pf: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pf",
    defaultMessage: "French Polynesia"
  },
  ga: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ga",
    defaultMessage: "Gabon"
  },
  gm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gm",
    defaultMessage: "Gambia"
  },
  ge: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ge",
    defaultMessage: "Georgia"
  },
  de: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.de",
    defaultMessage: "Germany"
  },
  gh: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gh",
    defaultMessage: "Ghana"
  },
  gi: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gi",
    defaultMessage: "Gibraltar"
  },
  gr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gr",
    defaultMessage: "Greece"
  },
  gl: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gl",
    defaultMessage: "Greenland"
  },
  gd: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gd",
    defaultMessage: "Grenada"
  },
  gp: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gp",
    defaultMessage: "Guadeloupe"
  },
  gu: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gu",
    defaultMessage: "Guam"
  },
  gt: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gt",
    defaultMessage: "Guatemala"
  },
  gg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gg",
    defaultMessage: "Guernsey"
  },
  gn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gn",
    defaultMessage: "Guinea"
  },
  gw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gw",
    defaultMessage: "Guinea-Bissau"
  },
  gy: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gy",
    defaultMessage: "Guyana"
  },
  ht: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ht",
    defaultMessage: "Haiti"
  },
  hn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.hn",
    defaultMessage: "Honduras"
  },
  hk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.hk",
    defaultMessage: "Hong Kong"
  },
  hu: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.hu",
    defaultMessage: "Hungary"
  },
  is: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.is",
    defaultMessage: "Iceland"
  },
  in: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.in",
    defaultMessage: "India"
  },
  id: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.id",
    defaultMessage: "Indonesia"
  },
  ir: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ir",
    defaultMessage: "Iran"
  },
  iq: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.iq",
    defaultMessage: "Iraq"
  },
  ie: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ie",
    defaultMessage: "Ireland"
  },
  im: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.im",
    defaultMessage: "Isle of Man"
  },
  il: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.il",
    defaultMessage: "Israel"
  },
  it: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.it",
    defaultMessage: "Italy"
  },
  jm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.jm",
    defaultMessage: "Jamaica"
  },
  jp: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.jp",
    defaultMessage: "Japan"
  },
  je: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.je",
    defaultMessage: "Jersey"
  },
  jo: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.jo",
    defaultMessage: "Jordan"
  },
  kz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.kz",
    defaultMessage: "Kazakhstan"
  },
  ke: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ke",
    defaultMessage: "Kenya"
  },
  ki: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ki",
    defaultMessage: "Kiribati"
  },
  xk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.xk",
    defaultMessage: "Kosovo"
  },
  kw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.kw",
    defaultMessage: "Kuwait"
  },
  kg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.kg",
    defaultMessage: "Kyrgyzstan"
  },
  la: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.la",
    defaultMessage: "Laos"
  },
  lv: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.lv",
    defaultMessage: "Latvia"
  },
  lb: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.lb",
    defaultMessage: "Lebanon"
  },
  ls: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ls",
    defaultMessage: "Lesotho"
  },
  lr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.lr",
    defaultMessage: "Liberia"
  },
  ly: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ly",
    defaultMessage: "Libya"
  },
  li: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.li",
    defaultMessage: "Liechtenstein"
  },
  lt: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.lt",
    defaultMessage: "Lithuania"
  },
  lu: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.lu",
    defaultMessage: "Luxembourg"
  },
  mo: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mo",
    defaultMessage: "Macau"
  },
  mk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mk",
    defaultMessage: "Macedonia"
  },
  mg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mg",
    defaultMessage: "Madagascar"
  },
  mw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mw",
    defaultMessage: "Malawi"
  },
  my: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.my",
    defaultMessage: "Malaysia"
  },
  mv: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mv",
    defaultMessage: "Maldives"
  },
  ml: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ml",
    defaultMessage: "Mali"
  },
  mt: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mt",
    defaultMessage: "Malta"
  },
  mh: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mh",
    defaultMessage: "Marshall Islands"
  },
  mq: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mq",
    defaultMessage: "Martinique"
  },
  mr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mr",
    defaultMessage: "Mauritania"
  },
  mu: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mu",
    defaultMessage: "Mauritius"
  },
  yt: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.yt",
    defaultMessage: "Mayotte"
  },
  mx: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mx",
    defaultMessage: "Mexico"
  },
  fm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.fm",
    defaultMessage: "Micronesia"
  },
  md: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.md",
    defaultMessage: "Moldova"
  },
  mc: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mc",
    defaultMessage: "Monaco"
  },
  mn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mn",
    defaultMessage: "Mongolia"
  },
  me: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.me",
    defaultMessage: "Montenegro"
  },
  ms: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ms",
    defaultMessage: "Montserrat"
  },
  ma: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ma",
    defaultMessage: "Morocco"
  },
  mz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mz",
    defaultMessage: "Mozambique"
  },
  mm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mm",
    defaultMessage: "Myanmar"
  },
  na: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.na",
    defaultMessage: "Namibia"
  },
  nr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.nr",
    defaultMessage: "Nauru"
  },
  np: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.np",
    defaultMessage: "Nepal"
  },
  nl: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.nl",
    defaultMessage: "Netherlands"
  },
  nc: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.nc",
    defaultMessage: "New Caledonia"
  },
  nz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.nz",
    defaultMessage: "New Zealand"
  },
  ni: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ni",
    defaultMessage: "Nicaragua"
  },
  ne: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ne",
    defaultMessage: "Niger"
  },
  ng: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ng",
    defaultMessage: "Nigeria"
  },
  nu: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.nu",
    defaultMessage: "Niue"
  },
  nf: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.nf",
    defaultMessage: "Norfolk Island"
  },
  kp: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.kp",
    defaultMessage: "North Korea"
  },
  mp: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mp",
    defaultMessage: "Northern Mariana Islands"
  },
  no: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.no",
    defaultMessage: "Norway"
  },
  om: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.om",
    defaultMessage: "Oman"
  },
  pk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pk",
    defaultMessage: "Pakistan"
  },
  pw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pw",
    defaultMessage: "Palau"
  },
  ps: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ps",
    defaultMessage: "Palestine"
  },
  pa: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pa",
    defaultMessage: "Panama"
  },
  pg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pg",
    defaultMessage: "Papua New Guinea"
  },
  py: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.py",
    defaultMessage: "Paraguay"
  },
  pe: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pe",
    defaultMessage: "Peru"
  },
  ph: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ph",
    defaultMessage: "Philippines"
  },
  pl: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pl",
    defaultMessage: "Poland"
  },
  pt: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pt",
    defaultMessage: "Portugal"
  },
  pr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pr",
    defaultMessage: "Puerto Rico"
  },
  qa: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.qa",
    defaultMessage: "Qatar"
  },
  re: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.re",
    defaultMessage: "Réunion"
  },
  ro: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ro",
    defaultMessage: "Romania"
  },
  ru: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ru",
    defaultMessage: "Russia"
  },
  rw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.rw",
    defaultMessage: "Rwanda"
  },
  bl: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.bl",
    defaultMessage: "Saint Barthélemy"
  },
  sh: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sh",
    defaultMessage: "Saint Helena"
  },
  kn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.kn",
    defaultMessage: "Saint Kitts and Nevis"
  },
  lc: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.lc",
    defaultMessage: "Saint Lucia"
  },
  mf: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.mf",
    defaultMessage: "Saint Martin"
  },
  pm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.pm",
    defaultMessage: "Saint Pierre and Miquelon"
  },
  vc: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.vc",
    defaultMessage: "Saint Vincent and the Grenadines"
  },
  ws: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ws",
    defaultMessage: "Samoa"
  },
  sm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sm",
    defaultMessage: "San Marino"
  },
  st: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.st",
    defaultMessage: "São Tomé and Príncipe"
  },
  sa: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sa",
    defaultMessage: "Saudi Arabia"
  },
  sn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sn",
    defaultMessage: "Senegal"
  },
  rs: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.rs",
    defaultMessage: "Serbia"
  },
  sc: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sc",
    defaultMessage: "Seychelles"
  },
  sl: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sl",
    defaultMessage: "Sierra Leone"
  },
  sg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sg",
    defaultMessage: "Singapore"
  },
  sx: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sx",
    defaultMessage: "Sint Maarten"
  },
  sk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sk",
    defaultMessage: "Slovakia"
  },
  si: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.si",
    defaultMessage: "Slovenia"
  },
  sb: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sb",
    defaultMessage: "Solomon Islands"
  },
  so: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.so",
    defaultMessage: "Somalia"
  },
  za: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.za",
    defaultMessage: "South Africa"
  },
  kr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.kr",
    defaultMessage: "South Korea"
  },
  ss: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ss",
    defaultMessage: "South Sudan"
  },
  es: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.es",
    defaultMessage: "Spain"
  },
  lk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.lk",
    defaultMessage: "Sri Lanka"
  },
  sd: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sd",
    defaultMessage: "Sudan"
  },
  sr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sr",
    defaultMessage: "Suriname"
  },
  sj: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sj",
    defaultMessage: "Svalbard and Jan Mayen"
  },
  sz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sz",
    defaultMessage: "Swaziland"
  },
  se: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.se",
    defaultMessage: "Sweden"
  },
  ch: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ch",
    defaultMessage: "Switzerland"
  },
  sy: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.sy",
    defaultMessage: "Syria"
  },
  tw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tw",
    defaultMessage: "Taiwan"
  },
  tj: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tj",
    defaultMessage: "Tajikistan"
  },
  tz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tz",
    defaultMessage: "Tanzania"
  },
  th: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.th",
    defaultMessage: "Thailand"
  },
  tl: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tl",
    defaultMessage: "Timor-Leste"
  },
  tg: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tg",
    defaultMessage: "Togo"
  },
  tk: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tk",
    defaultMessage: "Tokelau"
  },
  to: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.to",
    defaultMessage: "Tonga"
  },
  tt: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tt",
    defaultMessage: "Trinidad and Tobago"
  },
  tn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tn",
    defaultMessage: "Tunisia"
  },
  tr: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tr",
    defaultMessage: "Turkey"
  },
  tm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tm",
    defaultMessage: "Turkmenistan"
  },
  tc: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tc",
    defaultMessage: "Turks and Caicos Islands"
  },
  tv: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.tv",
    defaultMessage: "Tuvalu"
  },
  vi: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.vi",
    defaultMessage: "U.S. Virgin Islands"
  },
  ug: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ug",
    defaultMessage: "Uganda"
  },
  ua: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ua",
    defaultMessage: "Ukraine"
  },
  ae: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ae",
    defaultMessage: "United Arab Emirates"
  },
  gb: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.gb",
    defaultMessage: "United Kingdom"
  },
  us: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.us",
    defaultMessage: "United States"
  },
  uy: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.uy",
    defaultMessage: "Uruguay"
  },
  uz: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.uz",
    defaultMessage: "Uzbekistan"
  },
  vu: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.vu",
    defaultMessage: "Vanuatu"
  },
  va: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.va",
    defaultMessage: "Vatican City"
  },
  ve: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ve",
    defaultMessage: "Venezuela"
  },
  vn: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.vn",
    defaultMessage: "Vietnam"
  },
  wf: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.wf",
    defaultMessage: "Wallis and Futuna"
  },
  eh: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.eh",
    defaultMessage: "Western Sahara"
  },
  ye: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ye",
    defaultMessage: "Yemen"
  },
  zm: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.zm",
    defaultMessage: "Zambia"
  },
  zw: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.zw",
    defaultMessage: "Zimbabwe"
  },
  ax: {
    id: "jutro-components.widgets.inputs.IntlPhoneNumberField.country.ax",
    defaultMessage: "Åland Islands"
  }
});